import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  AuthError,
  EventMessage,
  EventType,
  RedirectRequest
} from '@azure/msal-browser';
import { Router, NavigationEnd } from '@angular/router';
import { NJWC } from '@engie-group/fluid-design-system/lib-esm/fluid-design-system';
import { environment } from 'src/environments/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AdminService } from '@core/services/admin.service';
import { GlobalService } from '@core/services/global.service';
declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Customer Portal';
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();
  isGuestPage: boolean;
  guestUrls: string[] = ['/', '/resend', '/guest', '/user/activate'];

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    public router: Router,
    private globalService: GlobalService,
    private adminService: AdminService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-163622933-1', {
          page_path: event.urlAfterRedirects
        });
        let destinationUrl = event.url.substring(0, 14);
        this.isGuestPage = this.guestUrls.some(s => s === destinationUrl);
      }
    });
  }

  ngOnInit() {
    // Initialize every engie.design components
    NJWC.AutoInit();

    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.updateLastSignIn();
      });

    this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        if (result.error instanceof AuthError) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
          if (result.error.message.includes('AADB2C90091')) {
            window.location.href = environment.b2cRedirectUri; //using window.location.href because we want to reload whole app to handle this error
          }
        }
      });

    this.loadExternalScript(environment.forteJs);
    this.updateLastSignIn();
  }

  updateLastSignIn() {
    if (
      !this.globalService.has('EmulatingUser') &&
      this.authService.instance.getActiveAccount() != null
    ) {
      this.adminService.updateLastSignIn().subscribe(
        (data: string) => {
          console.log('Successfully updated the last sign in.');
        },
        error => {
          console.log('Failed updating the last sign in.');
        }
      );
    }
  }

  loadExternalScript(scriptUrl: string) {
    return new Promise(resolve => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.type = 'text/javascript';
      scriptElement.onload = resolve;
      document.body.append(scriptElement);
    });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
