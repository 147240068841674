import { FormGroup } from '@angular/forms';

export function areValid(firstControlName: string, secondControlName: string) {
  return (formGroup: FormGroup) => {
    const firstControl = formGroup.controls[firstControlName];
    const secondControl = formGroup.controls[secondControlName];

    if (
      secondControl.errors &&
      !secondControl.errors.bothEmpty &&
      !secondControl.errors.bothUndefined &&
      !secondControl.errors.ucbAndEmptyUAN
    ) {
      // return if another validator has already found an error on the secondControl
      return;
    }

    // set error on secondControl if validation fails
    if (
      (firstControl.value && firstControl.value.engieAccountId === undefined) ||
      (secondControl.value !== '' &&
        secondControl.value.engieAccountId === undefined)
    ) {
      secondControl.setErrors({ bothUndefined: true });
      firstControl.setErrors({ bothUndefined: true });
    } else if (firstControl.value === '' && secondControl.value === '') {
      secondControl.setErrors({ bothEmpty: true });
      firstControl.setErrors({ bothEmpty: true });
    } else if (
      typeof firstControl.value === 'object' &&
      firstControl.value.billingAccountNumber.toLowerCase() === 'ucb' &&
      secondControl.value.engieAccountId === undefined
    ) {
      firstControl.setErrors({ ucbAndEmptyUAN: true });
      secondControl.setErrors({ ucbAndEmptyUAN: true });
    } else {
      secondControl.setErrors(null);
      firstControl.setErrors(null);
    }
  };
}
