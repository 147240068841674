import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpHeaderResponse,
  HttpBackend
} from '@angular/common/http';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperGuestService {
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public httpGetRequestNoAuth<T>(url: string): Observable<T> {
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.coreApiKey
    });
    const httpOptions = new HttpHeaderResponse({ headers });

    return this.httpClient.get<T>(url, httpOptions).pipe(
      map((response: T) => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public httpPostRequestNoAuth<T>(url: string, data: T): Observable<T> {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': environment.coreApiKey
    });
    const httpOptions = new HttpHeaderResponse({ headers });

    return this.httpClient.post<T>(url, data, httpOptions).pipe(
      map((response: T) => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public httpPatchRequestNoAuth<T>(url: string, data: T): Observable<T> {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': environment.coreApiKey
    });
    const httpOptions = new HttpHeaderResponse({ headers });

    return this.httpClient.patch<T>(url, data, httpOptions).pipe(
      map((response: T) => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
