import { Injectable } from '@angular/core';
import { HttpHelperService } from '@core/services/http-helper.service';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '@core/services/global.service';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { UserRolePermission } from '@shared/models/userRolePermission';

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {
  public PREFERENCES = 'USER_PREFERENCE';
  public REPORT_PREFERENCE = 'Report';
  public ACCOUNTLIST_PREFERENCE = 'AccountList';
  public ACCOUNTSUMMARY_PREFERENCE = 'AccountSummary';

  constructor(
    private httpHelper: HttpHelperService,
    private globalService: GlobalService
  ) {}

  getPreferenceFromCache(): any {
    return this.globalService.get(this.PREFERENCES);
  }

  public RefreshUserPreferenceCache(): Observable<any> {
    return this.httpHelper
      .httpGetRequest<any>(environment.apimWebRoot + environment.getPreferences)
      .pipe(
        map((response: any) => {
          response;
          this.globalService.set(this.PREFERENCES, response);
        }),
        catchError(response => throwError(response))
      );
  }

  public saveAccountListPreference(data: any): Observable<any> {
    return this.httpHelper
      .httpPostRequest<any>(
        environment.apimWebRoot + environment.saveGridPreference,
        data
      )
      .pipe(
        map((response: any) => {
          this.RefreshUserPreferenceCache().subscribe(data => data);
        }),
        catchError(response => throwError(response))
      );
  }
}
