import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, throwError, of } from 'rxjs';
import { environment } from '@environments/environment';
import { User } from '@shared/models/user';
import { UserProfile } from '@shared/models/userProfile';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserSearch } from '@shared/models/userSearch';
import { UserAccount } from '@shared/models/userAccount';
import { ChangeEmail } from '@shared/models/changeEmail';
import { AccountSettingBase } from '@shared/models/accountSettingBase';
import { ApplicationEventLog } from '@shared/models/applicationEventLog';
import { Ticket } from '@shared/models/ticket';
import { rejects } from 'assert';
import { resolve } from 'path';
import { HttpResponse } from '@angular/common/http';
import { HttpHelperService } from '@core/services/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private msalService: MsalService,
    private httpHelper: HttpHelperService
  ) {}

  public getUsers(userSearch: UserSearch): Observable<User[]> {
    return this.httpHelper
      .httpPostWithReturnRequest<UserSearch>(
        `${environment.apimWebRoot}${environment.getUserList}`,
        userSearch
      )
      .pipe(
        map((response: User[]) => response),
        catchError(response => throwError(response))
      );
  }

  public getUserProfile(usersEmailAddress: string): Observable<UserProfile> {
    return this.httpHelper
      .httpGetRequest<UserProfile>(
        `${environment.apimWebRoot}${environment.getUserProfile}/${usersEmailAddress}`
      )
      .pipe(
        map((response: UserProfile) => response),
        catchError(response => throwError(response))
      );
  }

  public addUserProfile(userProfile: UserProfile) {
    return this.httpHelper.httpPostRequestWithTimeZone<UserProfile>(
      `${environment.apimWebRoot}${environment.getUserProfile}`,
      userProfile
    );
  }

  activateUser(email: string) {
    return this.httpHelper.httpPatchRequest(
      `${environment.apimWebRoot}${environment.activateLogin.replace(
        '{email}',
        email
      )}`,
      ''
    );
  }

  deactivateUser(email: string) {
    return this.httpHelper.httpPatchRequest(
      `${environment.apimWebRoot}${environment.deactivateLogin.replace(
        '{email}',
        email
      )}`,
      ''
    );
  }

  resendActivationEmail(email: string) {
    return this.httpHelper.httpPatchRequest(
      `${environment.apimWebRoot}${environment.sendActivationEmail.replace(
        '{email}',
        email
      )}`,
      ''
    );
  }

  deleteUser(email: string) {
    return this.httpHelper.httpPatchRequest(
      `${environment.apimWebRoot}${environment.deleteUser.replace(
        '{email}',
        email
      )}`,
      ''
    );
  }

  addUserAccount(userAccount: UserAccount) {
    return this.httpHelper.httpPostRequest<UserAccount>(
      `${environment.apimWebRoot}${environment.addUserAccount}`,
      userAccount
    );
  }

  changeEmail(changeEmail: ChangeEmail) {
    return this.httpHelper.httpPostRequest<ChangeEmail>(
      `${environment.apimWebRoot}${environment.changeEmail}`,
      changeEmail
    );
  }

  getSettings(): Observable<AccountSettingBase[]> {
    return this.httpHelper
      .httpGetRequest(
        `${environment.apimWebRoot}${environment.getAllAccountSettings}`
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }

  getEventLogs(): Observable<ApplicationEventLog[]> {
    return this.httpHelper
      .httpGetRequest(
        `${environment.apimWebRoot}${environment.getAllApplicationEventLogs}`
      )
      .pipe(
        // @ts-ignore
        map((response: ApplicationEventLog[]) => response),
        catchError(response => throwError(response))
      );
  }

  fetchFreshdeskTicket(id: string) {
    return this.httpHelper
      .httpGetRequest<Ticket>(
        `${environment.apimWebRoot}${environment.Freshdesk.ViewTicket}/${id}`
      )
      .toPromise();
  }

  getUserAccess(userSearch: UserSearch): Observable<any> {
    return this.httpHelper
      .httpPostWithReturnRequest<UserSearch>(
        `${environment.apimWebRoot}${environment.getUserAccess}`,
        userSearch
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }

  updateUserAccess(userAccess: any) {
    return this.httpHelper.httpPostRequest<any>(
      `${environment.apimWebRoot}${environment.updateUserAccess}`,
      userAccess
    );
  }

  updateLastSignIn() {
    return this.httpHelper.httpPatchRequest(
      `${environment.apimWebRoot}${environment.updateLastSignIn}`,
      ''
    );
  }
}
