import { Component, OnInit } from '@angular/core';
import { version } from '@root/package.json';

@Component({
  selector: 'app-guest-header',
  templateUrl: './guest-header.component.html',
  styleUrls: ['./guest-header.component.css']
})
export class GuestHeaderComponent implements OnInit {
  version: string;

  constructor() {}

  ngOnInit(): void {
    this.version = version;
  }
}
