import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { Questions } from '@shared/models/Questions';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.css']
})
export class HelpCenterComponent implements OnInit {
  searchText = '';
  questions: Questions[] = [];
  allQuestions: Questions[] = [];
  MSG_PHONE = `${environment.customerCarePhoneAlt} (${environment.customerCarePhone})`;
  constructor(
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit() {    
    const scrollToSection = this.route.snapshot.paramMap.get('contactus');
    if (scrollToSection) {
      this.viewportScroller.scrollToAnchor(scrollToSection);
    }

    this.http.get<Questions[]>('./assets/questions.json')
      .subscribe((data: Questions[]) => {
        this.questions = data;
        this.allQuestions = this.questions;
      });
  }

  search(value: string): void {
    this.questions = this.allQuestions.filter((val) =>
      val.title.toLowerCase().includes(value)
    );
  }
}
