export class SharedAccount {
  constructor(emailAddresses: string) {
    this.EmailAddresses = emailAddresses;
  }

  EngieAccountIds: string[];
  EmailAddresses: string;
  Role: string;
  AccessType: string;
  IsEmulatingUser: boolean;
}
