import { Component, OnInit } from '@angular/core';
import { AdminService } from '@core/services/admin.service';
import { UserProfile } from '@shared/models/userProfile';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from '@core/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '@core/services/global.service';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {
  userProfile: UserProfile;
  usersEmailAddress: string;
  emulate = false;

  constructor(
    private adminService: AdminService,
    private location: Location,
    private utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit() {
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.spinner.show();
    this.usersEmailAddress = this.route.snapshot.paramMap.get('email');
    this.getUserProfile();
  }

  getUserProfile() {
    const self = this;
    self.adminService.getUserProfile(this.usersEmailAddress).subscribe(
      result => {
        self.userProfile = result;
        this.spinner.hide();
      },
      error => {
        console.log('Failed to get user profile.');
        this.spinner.hide();
        const message = error.error;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  onSubmit(event) {
    this.spinner.show();

    this.addUserProfile(this.userProfile).subscribe(
      (data: UserProfile) => {
        const message = 'The user profile has been successfully updated.';
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(
          message,
          '',
          'Dismiss',
          () => {},
          'Go To Dashboard',
          () => {
            this.goBack();
          },
          false
        );
      },
      error => {
        console.log('Failed to update the user profile.');
        const message = error.error;
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  addUserProfile(userProfile: UserProfile) {
    return this.adminService.addUserProfile(this.userProfile);
  }

  goBack() {
    this.location.back();
  }
}
