export class DownloadedFileDetails {
  constructor(
    accountNumber: string,
    engieAccountId: string,
    engieStatementId: string,
    engieInvoiceId: string,
    accountName: string
  ) {
    this.accountNumber = accountNumber;
    this.engieAccountId = engieAccountId;
    this.engieStatementId = engieStatementId;
    this.engieInvoiceId = engieInvoiceId;
    this.accountName = accountName;
  }
  accountNumber: string;
  engieAccountId: string;
  engieStatementId: string;
  engieInvoiceId: string;
  accountName: string;
}

export class ZipFile {
  accountDetails: DownloadedFileDetails[];
}
