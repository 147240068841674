import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  RequiredValidator
} from '@angular/forms';
import { UtilityService } from '@core/services/utility.service';
import { Address } from '@shared/models/address';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.sass']
})
export class AddressFormComponent implements OnInit {
  @Output() saveBtnClicked = new EventEmitter<any>();
  @Input() selectedAccounts: string[];
  BillingAddressGroup: FormGroup;
  result: Address;
  stateAbbreviation: string;
  states = this.utilityService.getUSStates();
  submitted: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private utilityService: UtilityService
  ) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.BillingAddressGroup.controls;
  }

  ngOnInit(): void {
    this.initForm(this.formBuilder);
  }

  initForm(formBuilder: FormBuilder) {
    this.BillingAddressGroup = formBuilder.group({
      EngieAccountIds: new FormControl(this.selectedAccounts),
      BillingAddress: formBuilder.group({
        Street: new FormControl('', Validators.required),
        Street2: new FormControl(''),
        City: new FormControl('', Validators.required),
        Stateabbreviation: new FormControl(
          this.stateAbbreviation,
          Validators.required
        ),
        PostalCode: new FormControl('', [
          Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'),
          Validators.required
        ])
      })
    });
  }

  onSave(e) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.BillingAddressGroup.invalid) {
      return;
    }

    // Take a deep copy of the form-model
    this.result = Object.assign({}, this.BillingAddressGroup.value);
    this.saveBtnClicked.emit({ data: this.result, childType: 'address' });
  }

  onStateChangedHandler(event) {
    this.f.BillingAddress.get('Stateabbreviation').setErrors(RequiredValidator);
    const state = event.target.form.selectedState.value;
    this.f.BillingAddress.get('Stateabbreviation').setValue(state);
  }
}
