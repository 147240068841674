export class ReportInfo {
  EngieAccountIds: string[];
  FromDate: string;
  ToDate: string;
  BillingInfo: any;
  MeterInfo: any;
  HasBilling: boolean;
  HasMeter: boolean;
  SavePreference: boolean;
  EmailAddresses: string;
}
