import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { formatDate } from '@angular/common';
import 'ag-grid-enterprise';
import {
  ExcelCell,
  ExcelExportParams,
  ExcelStyle,
  GridOptions
} from 'ag-grid-community';
import { BaseGridComponentComponent } from '@shared/components/base-grid-component/base-grid-component.component';
import { Router } from '@angular/router';

import { AccountService } from '@core/services/account.service';
import { IdentityService } from '@core/services/identity.service';
import { Permission } from '@shared/enums/Permission';
import { UtilityService } from '@core/services/utility.service';
import { GlobalService } from '@core/services/global.service';
import { CustomTooltipComponent } from '@shared/components/grid-custom-tooltip/custom-tooltip.component';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { AccessPermission } from '@shared/enums/AccessPermission';
import Collapse from '@engie-group/fluid-design-system/lib-esm/components/collapse';
import { Ownership } from '@shared/models/ownership';
import { DownloadedFileDetails } from '@shared/models/downloadedFileDetails';
import { GridOption } from '@shared/models/userPreference/gridOption';
import { UserPreferenceService } from '@core/services/user-preference.service';
import { Uan } from '@shared/models/uan';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  SelectedRows,
  SelectedRowsMap,
  SelectedRowsMapWithAccessType
} from '@shared/models/selectedRows';
import { BillingTypes } from '@shared/enums/BillingTypes';
import { GlobalConstants } from '@shared/constants/global-constants';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-account-list-summary',
  templateUrl: './account-list-summary.component.html',
  styleUrls: ['./account-list-summary.component.sass']
})
export class AccountListSummaryComponent
  extends BaseGridComponentComponent
  implements OnInit {
  userName: string;
  private gridApi: any;
  private gridColumnApi: any;
  rowData$;
  autoHeight: number;
  rowHeight: number;
  paginationPageSize = GlobalConstants.gridDefaultPageSize;
  paginationNumberFormatter;
  pageSize: number;
  params: any;
  gridOptions: GridOptions;
  accountCounts: string;
  accountName: string;
  domLayout;
  emulate = false;
  tooltipShowDelay;
  frameworkComponents;
  defaultColDef;
  confirmationBody: string;
  includedSelectedRows: string[][];
  includedSelectedRowsMap: Map<string, string>;
  includedSelectedRowsMapWithAccessType: Map<string, Map<string, string>>;
  bulkActionNavigation: string;
  includedAccountsToDownloadFile: DownloadedFileDetails[];
  isBulkActionForDownloadingFile: boolean;
  isBulkActionForMap: boolean;
  isBulkActionForMapWithAccessType: boolean;
  bulkActionDisabled: boolean = true;

  constructor(
    private authService: MsalService,
    private accountService: AccountService,
    private globalService: GlobalService,
    private identity: IdentityService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private utilityService: UtilityService,
    private userPreference: UserPreferenceService,
    private modalDialogService: ModalDialogService
  ) {
    super();
    this.accountName = '';
    this.getAccountSummary();
  }

  ngOnInit() {
    this.globalService.remove(this.globalService.BULK);
    this.globalService.remove(this.globalService.BULKMAP);
    this.globalService.remove(this.globalService.BULKMAPWITHACCESSTYPE);
    this.globalService.remove(this.globalService.DETAIL);
    this.globalService.remove(this.globalService.REPORT);
    this.globalService.remove('ACCOUNT_LEVEL_ROLE_PERM');
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.initGridConfig();
  }

  initGridConfig() {
    this.gridOptions = {
      columnDefs: this.createColumnDefs(),
      detailCellRendererParams: this.getDetailRendererParams(),
      excelStyles: this.excelStyles,
      defaultExcelExportParams: this.defaultExcelExportParams,
      context: {
        componentParent: this
      }
    };
    this.defaultColDef = {
      editable: false,
      sortable: true,
      filter: true,
      resizable: true,
      tooltipComponent: 'customTooltip'
    };
    this.tooltipShowDelay = 0;
    this.frameworkComponents = { customTooltip: CustomTooltipComponent };
  }

  getDetailRendererParams() {
    return {
      autoHeight: true,
      detailGridOptions: {
        onCellClicked: this.onViewCellClicked.bind(this),
        columnDefs: [
          {
            headerName: 'UTILITY ACCOUNT',
            field: 'uanAccount',
            minWidth: 200,
            maxWidth: 250,
            cellStyle: { justifyContent: 'left' },
            cellRenderer: this.copyIcon.bind(this)
          },
          {
            headerName: 'STATUS',
            field: 'status',
            minWidth: 67,
            maxWidth: 67,
            suppressMenu: true,
            cellStyle: { justifyContent: 'center' },
            cellRenderer: this.getStatusIcon.bind(this),
            tooltipComponentParams: (params: any) => {
              const getStatusIcon = params.api
                .getDisplayedRowAtIndex(params.rowIndex)
                .data.status.toLowerCase();

              if (getStatusIcon === 'account accepted') {
                return {
                  color: 'black',
                  tooltip: 'Account Accepted'
                };
              }
              if (getStatusIcon === 'account dropped') {
                return {
                  color: 'black',
                  tooltip: 'Account Dropped'
                };
              } else {
                return {
                  color: 'black',
                  tooltip: 'Account Rejected'
                };
              }
            },
            tooltipValueGetter: this.customTooltip
          },
          {
            headerName: 'SERVICE START DATE',
            field: 'suppyStartDate',
            minWidth: 200,
            maxWidth: 250,
            sort: 'asc',
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: super.dateComparator,
              browserDatePicker: true
            },
            resizable: true,
            valueFormatter: super.dateFormatter,
            cellStyle: { justifyContent: 'left' }
          },
          {
            headerName: 'DROP DATE',
            field: 'dropDate',
            minWidth: 200,
            maxWidth: 250,
            hide: true,
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: super.dateComparator,
              browserDatePicker: true
            },
            resizable: true,
            valueFormatter: super.dateFormatter,
            cellStyle: { justifyContent: 'left' }
          },
          {
            headerName: 'SERVICE ADDRESS',
            field: 'serviceAddress',
            autoHeight: true,
            minWidth: 200,
            maxWidth: 250,
            cellRenderer: super.serviceaddressFormatter,
            cellStyle: { justifyContent: 'left' },
            keyCreator: this.addressFilterKey,
            getQuickFilterText: super.serviceAddressQuickFilter
          },
          {
            headerName: 'UTILITY',
            field: 'utility',
            minWidth: 175,
            maxWidth: 200,
            cellStyle: { justifyContent: 'left' }
          }
        ],
        suppressHorizontalScroll: true,
        defaultColDef: { editable: false, resizable: true }
      },
      getDetailRowData: params => {
        params.successCallback(params.data.uan);
      }
    };
  }

  addressFilterKey(params) {
    if (params.value) {
      if (params.value.street2) {
        return `${params.value.street},${params.value.street2},${params.value.state},${params.value.postalCode}`;
      } else {
        return `${params.value.street},${params.value.state},${params.value.postalCode}`;
      }
    }
  }

  addressFilter(params) {
    let address: string;
    address =
      params.street +
      ' ' +
      params.street2 +
      ' ' +
      params.city +
      ' ' +
      params.state +
      ' ' +
      params.postalCode;

    return address;
  }

  getAccountSummary() {
    this.spinner.show();
    this.accountService.getAccountSummary().subscribe(
      data => {
        this.rowData$ = data;
        let uanCount = 0;
        data.map(param => {
          if (param.uan !== null) {
            uanCount += param.uan.length;
          }
        });

        if (data != null && data.length) {
          this.accountCounts =
            data.length + ` Bill Accounts | ${uanCount} Utility Accounts`;
          const companyName = this.globalService.get(
            this.globalService.COMPANYNAME
          );
          if (companyName != null && companyName !== '') {
            this.accountName = companyName + ` - ${data.length} Account(s)`;
          } else {
            this.accountName = `${data.length} Account(s)`;
          }
        }
        this.spinner.hide();
      },
      error => {
        console.log(`fetch Account Summary failed`);
        this.spinner.hide();
      }
    );
  }

  onFirstDataRendered(e) {
    let preference: any;
    const allPreference: any[] = this.userPreference.getPreferenceFromCache();
    const preferenceFiltered = allPreference.filter(x => {
      return x.type === this.userPreference.ACCOUNTSUMMARY_PREFERENCE;
    });

    if (preferenceFiltered) {
      preference = preferenceFiltered[0];
    }

    this.gridApi = e.api;
    this.gridColumnApi = e.columnApi;

    if (preference) {
      const filterModel = preference.filterModel;

      if (filterModel) {
        this.gridApi.setFilterModel(filterModel);
      }
      const sortModel = preference.sortModel;
      if (sortModel) {
        this.gridApi.setSortModel(sortModel);
      }
      const columnState = preference.columnState;
      if (columnState) {
        this.gridColumnApi.setColumnState(columnState);
      }
    }
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.onFilterChanged();
    this.spinner.hide();
  }

  private createColumnDefs() {
    return [
      {
        headerName: 'SELECT ALL',
        suppressMenu: true,
        minWidth: 100,
        maxWidth: 120,
        resizable: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { justifyContent: 'left' },
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'BILLING ACCOUNT',
        field: 'billingAccount',
        cellClass: 'textFormat',
        minWidth: 138,
        maxWidth: 150,
        resizable: true,
        cellStyle: { justifyContent: 'left' },
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          innerRenderer: this.copyIcon.bind(this)
        },
        tooltipComponentParams: (params: any) => {
          if (params.data.isUcb) {
            return {
              color: 'black',
              tooltip: 'Utility Consolidated Billing'
            };
          }
        },
        tooltipValueGetter: this.customTooltip.bind(this)
      },
      {
        headerName: '',
        field: 'billingAccount',
        sortable: false,
        filter: false,
        resizable: true,
        hide: true,
        suppressColumnsToolPanel: true,
        valueGetter: params => {
          let uans: Uan[];
          uans = params.data.uan;
          let result = '';
          uans.forEach(uan => {
            let supplyDt = '';
            const dt = new Date(uan.suppyStartDate);
            if (dt.getFullYear() <= 1) {
              supplyDt = '';
            }
            if (params.value) {
              supplyDt = formatDate(params.value, 'MM/dd/yyyy', 'en-US');
            }
            result +=
              uan.uanAccount +
              ',' +
              this.addressFilter(uan.serviceAddress) +
              ',' +
              uan.utility +
              ',' +
              supplyDt +
              '|';
          });
          return result;
        }
      },
      {
        headerName: 'NAME',
        field: 'name',
        flex: 6,
        minWidth: 250,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'BILLING ADDRESS',
        field: 'billingAddress',
        autoHeight: true,
        flex: 3,
        minWidth: 125,
        cellRenderer: super.billaddressFormatter,
        cellStyle: { justifyContent: 'left' },
        keyCreator: this.addressFilterKey,
        getQuickFilterText: super.billAddressQuickFilter
      },
      {
        headerName: 'ACCOUNT BALANCE',
        field: 'accountBalance',
        minWidth: 150,
        maxWidth: 150,
        suppressMenu: true,
        filter: 'agNumberColumnFilter',
        cellRenderer: this.getFormattedBalance.bind(this),
        getQuickFilterText: super.noQuickFilter,
        cellStyle: { justifyContent: 'flex-end' },
        tooltipComponentParams: (params: any) => {
          if (params.data.isUcb) {
            return {
              color: 'black',
              tooltip: 'Please pay your utility'
            };
          }
        },
        tooltipValueGetter: this.customTooltip.bind(this)
      },
      {
        headerName: 'DUE BY',
        field: 'dueDate',
        suppressMenu: true,
        minWidth: 100,
        maxWidth: 100,
        resizable: false,
        sort: 'desc',
        filter: 'agDateColumnFilter',
        cellRenderer: (params: any) => {
          return params.data.isUcb ? 'N/A' : super.dateFormatter(params);
        },
        // filterParams: {
        //   comparator: super.dateComparator,
        //   browserDatePicker: true
        // },
        // valueFormatter: super.dateFormatter,
        // getQuickFilterText: super.noQuickFilter,
        cellStyle: { justifyContent: 'left' }
      },
      {
        headerName: 'Users Access',
        field: 'userAccessOwnership',
        suppressMenu: true,
        minWidth: 70,
        maxWidth: 70,
        resizable: false,
        sortable: false,
        filter: false,
        cellStyle: { justifyContent: 'center' },
        cellRenderer: this.UserAccessOwnership,
        tooltipComponentParams: (params: any) => {
          const newTooltip = params.api.getDisplayedRowAtIndex(params.rowIndex)
            .data.userAccessOwnership.shareeTooltip;
          return {
            color: 'black',
            tooltip: newTooltip
          };
        },
        tooltipValueGetter: this.customTooltip
      },
      {
        headerName: 'OWNER',
        field: 'ownership',
        suppressMenu: true,
        minWidth: 70,
        maxWidth: 70,
        resizable: false,
        sortable: false,
        filter: false,
        cellStyle: { justifyContent: 'center' },
        cellRenderer: this.ownership,
        tooltipComponentParams: (params: any) => {
          const newTooltip = params.api.getDisplayedRowAtIndex(params.rowIndex)
            .data.ownership.toolTip;
          return {
            color: 'black',
            tooltip: newTooltip
          };
        },
        tooltipValueGetter: this.customTooltip
      },
      {
        headerName: '',
        suppressMenu: true,
        field: 'autoPayEnabled',
        suppressColumnsToolPanel: true,
        sortable: false,
        minWidth: 40,
        maxWidth: 40,
        resizable: false,
        filter: 'agSetColumnFilter',
        cellRenderer: this.isAutoPayRenderer,
        keyCreator: this.autoPayFilterKey,
        tooltipComponentParams: (params: any) => {
          const isAutopayEnabled = params.api.getDisplayedRowAtIndex(
            params.rowIndex
          ).data.autoPayEnabled;
          if (isAutopayEnabled) {
            return {
              color: 'black',
              tooltip: 'Auto Pay Enabled'
            };
          } else {
            return {
              color: 'black'
            };
          }
        },
        tooltipValueGetter: this.customTooltip
      },
      {
        headerName: '',
        suppressMenu: true,
        cellStyle: { justifyContent: 'center' },
        suppressColumnsToolPanel: true,
        maxWidth: 75,
        minWidth: 75,
        sortable: false,
        filter: false,
        resizable: false,
        cellRenderer: this.pay.bind(this),
        tooltipComponentParams: (params: any) => {
          const accountBalance = params.api.getDisplayedRowAtIndex(
            params.rowIndex
          ).data.accountBalance;
          if (params.api.getDisplayedRowAtIndex(params.rowIndex).data.isUcb) {
            return {
              color: 'black',
              tooltip:
                'You cannot make a payment on a utility consolidated account'
            };
          } else if (accountBalance > 0) {
            return {
              color: 'black',
              tooltip: 'Make a Payment'
            };
          } else if (accountBalance <= 0) {
            return {
              color: 'black',
              tooltip:
                'You cannot make a payment on an account with zero balance'
            };
          }
        },
        tooltipValueGetter: this.customTooltip.bind(this),
        cellRendererParams: {
          _self: this
        }
      },
      {
        headerName: '',
        maxWidth: 40,
        minWidth: 40,
        field: 'status',
        sortable: false,
        filter: false,
        resizable: false,
        cellStyle: { justifyContent: 'center' },
        cellRenderer: this.billIcon.bind(this),
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        tooltipComponentParams: (params: any) => {
          if (
            params.api.getDisplayedRowAtIndex(params.rowIndex).data
              .engieStatementId === null
          ) {
            return {
              color: 'black',
              tooltip: 'No Available Statement'
            };
          } else {
            return {
              color: 'black',
              tooltip: 'View Statement'
            };
          }
        },
        tooltipValueGetter: this.customTooltip.bind(this)
      },
      {
        headerName: '',
        maxWidth: 40,
        minWidth: 40,
        field: 'status',
        sortable: false,
        filter: false,
        resizable: false,
        cellStyle: { justifyContent: 'center' },
        cellRenderer: this.backingSheetIcon.bind(this),
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        tooltipComponentParams: (params: any) => {
          if (
            (params.data.engieStatementId === null && !params.data.isUcb) ||
            params.data.billingType === BillingTypes.RateReady
          ) {
            return {
              color: 'black',
              tooltip: 'No Available Supporting Documents'
            };
          } else {
            return {
              color: 'black',
              tooltip: 'Download Supporting Documents'
            };
          }
        },
        tooltipValueGetter: this.customTooltip.bind(this)
      },
      {
        headerName: '',
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        maxWidth: 40,
        minWidth: 40,
        sortable: false,
        filter: false,
        resizable: false,
        cellRenderer: this.ellipse,
        tooltipComponentParams: {
          color: 'black',
          tooltip: `Additional Options`
        },
        tooltipValueGetter: this.customTooltip.bind(this),
        onCellClicked(params: any): void {
          const rowIndex = params.rowIndex;
          const rowNode = params.node;
          const column = params.column;
          const mouseEvent = params.event;
          const value = params.value;
          const cell = params.api.context.beanWrappers.rowRenderer.beanInstance.rowConsByRowIndex[
            rowIndex
          ].getRenderedCellForColumn(column);
          cell.beans.contextMenuFactory.showMenu(
            rowNode,
            column,
            value,
            mouseEvent
          );
        }
      }
    ];
  }

  copyIcon(params) {
    const divTag = document.createElement('div');
    let fieldValue = '';
    if (params.column.colId === 'uanAccount') {
      fieldValue = `<a href="javascript:void(0);">` + params.value + `</a>`;
    } else {
      fieldValue = params.value;
      if (params.data.isUcb) {
        return fieldValue;
      }
    }
    this.utilityService.fieldWithCopyButton(divTag, fieldValue, params.value);
    return divTag;
  }

  getFormattedBalance(params) {
    return params.data.isUcb ? 'Pay Utility' : super.currencyFormatter(params);
  }

  billIcon(params) {
    const self = this;
    let htmlString = '';
    if (params.data.engieStatementId === null || params.data.isUcb) {
      htmlString = `<span class="btn-simple disabled"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>picture_as_pdf</i></span>`;
    } else {
      htmlString = `<span class="btn-simple"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>picture_as_pdf</i></span>`;
    }
    const divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();
    const eButtonBill = divTag.querySelector('.btn-simple');
    eButtonBill.addEventListener('click', () => {
      self.utilityService.viewStatement(
        new DownloadedFileDetails(
          null,
          null,
          params.data.engieStatementId,
          null,
          null
        ),
        true
      );
    });
    return divTag;
  }

  getStatusIcon(params) {
    const self = this;
    let divTag: HTMLDivElement;
    let htmlString = '';
    const confirmBody = '';

    if (params.data.status.toLowerCase() === 'account accepted') {
      htmlString = `<i style='color:green;' class='material-icons'>fiber_manual_record</i></span>`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
    } else if (params.data.status.toLowerCase() === 'account dropped') {
      htmlString = `<i style='color:gray;' class='material-icons'>fiber_manual_record</i></span>`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
    } else if (params.data.status.toLowerCase() === 'account rejected') {
      htmlString = `<i style='color:red;' class='material-icons'>fiber_manual_record</i></span>`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
    }
    return divTag;
  }

  backingSheetIcon(params) {
    const self = this;
    let htmlString = '';
    if (
      (params.data.engieStatementId === null && !params.data.isUcb) ||
      params.data.billingType === BillingTypes.RateReady
    ) {
      htmlString = `<span class="btn-simple disabled"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>article</i></span>`;
    } else {
      htmlString = `<span class="btn-simple"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>article</i></span>`;
    }
    const divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();
    const eButtonBackingSheet = divTag.querySelector('.btn-simple');
    eButtonBackingSheet.addEventListener('click', () => {
      self.utilityService.downloadBackingSheet(
        new DownloadedFileDetails(
          params.data.isUcb
            ? params.node.data.uan[0].uanAccount
            : params.data.billingAccount,
          params.data.isUcb ? params.data.engieAccountId : null,
          params.data.isUcb ? null : params.data.engieStatementId,
          params.data.isUcb ? params.data.engieInvoiceId : null,
          params.data.name
        ),
        false,
        true
      );
    });
    return divTag;
  }

  customTooltip(params) {
    if (params.value === undefined) {
      return true;
    }

    return params.value;
  }

  protected pay(params) {
    const self = this;
    let divTag: HTMLDivElement;
    let htmlString: string;

    const accessType: AccessTypes = this.utilityService.convertToAccessType(
      params.node.data.accessType
    );
    const canPay = params._self.identity.hasAccessPermission(
      Permission.Pay_Bill,
      accessType
    );

    if (params.data.accountBalance > 0) {
      htmlString = `<a href="javascript:void(0);" class="btn-simple nj-btn nj-btn--primary"
        role="button" aria-pressed="true">PAY</a>`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
      const eButtonPay = divTag.querySelectorAll('.btn-simple')[0];
      eButtonPay.addEventListener('click', () => {
        const obj = {
          engieAccountId: params.node.data['engieAccountId'],
          billingAccount: params.node.data['billingAccount'],
          accessType: accessType
        };
        self.utilityService.setDetailContext(
          params.data.engieAccountId,
          params.node.data.uan[0].uanAccount,
          params.data.isUcb
        );
        self.utilityService.goToPayment(obj);
      });
    } else {
      htmlString = `<button type='button' disabled=true class='nj-btn nj-btn--primary'>PAY</button>&nbsp;`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
    }

    if (!canPay) {
      htmlString = `<button type='button' disabled=true class='nj-btn nj-btn--primary'>PAY</button>&nbsp;`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
    }
    return divTag;
  }

  onPageSizeChanged(selectedValue: number) {
    const value = selectedValue;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  onQuickSearchChanged(event: any) {
    const searchText: string = event.target.value;
    // if (searchText.length > 0) {
    //   this.gridApi.forEachNode((node) => {
    //     node.setExpanded(true);
    //   });
    // } else {
    //   this.gridApi.forEachNode((node) => {
    //     node.setExpanded(false);
    //   });
    // }

    this.gridApi.setQuickFilter(searchText);

    this.gridApi.forEachDetailGridInfo(detailGridInfo => {
      detailGridInfo.api.setQuickFilter(searchText);
    });
  }

  onViewCellClicked(event: any) {
    if (event.column.colId === 'uanAccount') {
      const accessType: AccessTypes = (<any>AccessTypes)[
        event.data['accessType']
      ];
      const obj = {
        engieAccountId: event.data['engieAccountId'],
        billingAccount: null,
        accessType: accessType
      };
      this.utilityService.setDetailContext(
        event.data.engieAccountId,
        event.data.uanAccount,
        event.data.isUcb
      );
      this.utilityService.goToDetail(obj);
    }
  }

  ellipse(params) {
    const htmlString = `<i class='material-icons md-36 md--blue-engie'>more_horiz</i>`;
    const divTag = document.createElement('span');
    divTag.innerHTML = htmlString.trim();
    return divTag;
  }

  getContextMenuItems = params => {
    const accountNumber = params.node.data.isUcb
      ? params.node.data.uan[0].uanAccount
      : params.node.data.billingAccount;

    const accessType: AccessTypes = this.utilityService.convertToAccessType(
      params.node.data.accessType
    );
    let canShare = false;
    let canTransfer = false;
    let canRelease = false;
    let canPaper = false;
    let canAutoPay = false;
    let canAddPayMethod = false;
    let canUpdateBillingAddress = false;

    const obj = {
      engieAccountId: params.node.data['engieAccountId'],
      billingAccount: accountNumber,
      accessType: accessType
    };

    canShare = this.identity.hasAccessPermission(
      AccessPermission.Share_Account,
      accessType
    );
    canTransfer = this.identity.hasAccessPermission(
      AccessPermission.Transfer_Account,
      accessType
    );
    canRelease = this.identity.hasAccessPermission(
      AccessPermission.Release_Accounts,
      accessType
    );
    canPaper = this.identity.hasAccessPermission(
      AccessPermission.Enroll_Paperless,
      accessType
    );
    canAutoPay =
      !params.node.data.isUcb &&
      this.identity.hasAccessPermission(
        AccessPermission.Enroll_Auto_Pay,
        accessType
      );
    canAddPayMethod =
      !params.node.data.isUcb &&
      this.identity.hasAccessPermission(
        AccessPermission.Manage_PayMethods,
        accessType
      );
    canUpdateBillingAddress =
      !params.node.data.isUcb &&
      this.identity.hasAccessPermission(
        AccessPermission.Update_BillingAddress,
        accessType
      );

    const historyObj = {
      name: 'View History',
      action: () => {
        this.utilityService.setDetailContext(
          params.node.data.engieAccountId,
          params.node.data.uan[0].uanAccount,
          params.node.data.isUcb
        );
        this.utilityService.goToHistory();
      }
    };
    const shareObj = {
      name: 'Share Account',
      action: () => {
        this.utilityService.setGlobalBulkFromSelectedAccount(
          accountNumber,
          params.node.data.engieAccountId
        );
        this.router.navigate(['/share']);
      }
    };
    const transferObj = {
      name: 'Transfer Account',
      action: () => {
        this.utilityService.setGlobalBulkFromSelectedAccount(
          accountNumber,
          params.node.data.engieAccountId
        );
        this.router.navigate(['/transfer']);
      }
    };
    const releaseObj = {
      name: 'Release Account',
      action: () => {
        this.utilityService.setGlobalBulkFromSelectedAccount(
          accountNumber,
          params.node.data.engieAccountId
        );
        this.router.navigate(['/release']);
      }
    };
    const autoObj = {
      name:
        params.node.data.autoPayEnabled === true
          ? 'Unenroll in AutoPay'
          : 'Enroll in AutoPay',
      action: () => {
        this.utilityService.setDetailContext(
          params.node.data.engieAccountId,
          params.node.data.uan[0].uanAccount,
          params.node.data.isUcb
        );
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.utilityService.goToServices();
      }
    };

    const payMethod = {
      name: 'Add Payment Method',
      action: () => {
        this.utilityService.setDetailContext(
          params.node.data.engieAccountId,
          params.node.data.uan[0].uanAccount,
          params.node.data.isUcb
        );
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.utilityService.goToPayMethod();
      }
    };

    const updateBillingAddress = {
      name: 'Update Billing Contact',
      action: () => {
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.utilityService.goToUpdateBillingAddress(
          params.node.data.billingAccount,
          params.node.data.engieAccountId
        );
      }
    };

    // const paperObj = {
    //   name: 'Enroll in Paperless Billing',
    //   action: () => {
    //     this.utilityService.goToServices(params.node.data.billingAccount);
    //   }
    // };
    const reportObj = {
      name: 'Generate Report',
      action: () => {
        this.globalService.set(
          this.globalService.REPORT,
          params.node.data.engieAccountId
        );
        this.utilityService.setDetailContext(
          params.node.data.engieAccountId,
          params.node.data.uan[0].uanAccount,
          params.node.data.isUcb
        );
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.utilityService.goToReporting(
          params.node.data.engieAccountId,
          accountNumber
        );
      }
    };

    const result = [];
    if (canAddPayMethod) {
      result.push(payMethod);
    }
    if (canUpdateBillingAddress) {
      result.push(updateBillingAddress);
    }
    if (canAutoPay) {
      result.push(autoObj);
    }
    // if (canPaper) {
    //   result.push(paperObj);
    // }

    if (canAddPayMethod || canAutoPay) {
      result.push('separator');
    }

    result.push(historyObj);
    result.push(reportObj);

    if (canShare || canTransfer || canRelease) {
      result.push('separator');
    }

    if (canShare) {
      result.push(shareObj);
    }
    if (canTransfer) {
      result.push(transferObj);
    }
    if (canRelease) {
      result.push(releaseObj);
    }

    if (params.column.colId === '2') {
      return result;
    }
  };

  onSelectionChanged() {
    if (
      this.gridApi.getSelectedRows().length > 0 &&
      this.gridApi.getDisplayedRowCount() > 0
    ) {
      this.bulkActionDisabled = false;
    } else {
      this.bulkActionDisabled = true;
    }
  }

  bulkReport() {
    const selectedAccountEngieIds: string[] = [];
    const selectedAccountNumbers: string[] = [];
    this.gridApi.getSelectedRows().forEach(x => {
      const accountNumber = x.isUcb ? x.uan[0].uanAccount : x.billingAccount;
      selectedAccountNumbers.push(accountNumber);
      selectedAccountEngieIds.push(x.engieAccountId);
    });

    const selectedRows = new Array(
      selectedAccountNumbers,
      selectedAccountEngieIds
    );
    this.globalService.set(this.globalService.BULK, selectedRows);

    this.globalService.remove(this.globalService.REPORT);
    this.globalService.remove(this.globalService.DETAIL);
    this.globalService.set(this.globalService.REPORT, selectedAccountEngieIds);
    if (selectedAccountEngieIds.length === 1) {
      const selectedAccount = this.gridApi.getSelectedRows()[0];
      this.utilityService.setDetailContext(
        selectedAccount.engieAccountId,
        selectedAccount.uan[0].uanAccount,
        selectedAccount.isUcb
      );
    }
    this.router.navigate([`reporting`]);
  }

  bulkDownloadStatements() {
    this.downloadFileForSelectedRows('statements');
  }

  bulkDownloadSupportingDocuments() {
    this.downloadFileForSelectedRows('supporting documents');
  }

  downloadFileForSelectedRows(bulkAction: string) {
    let includedAccounts: DownloadedFileDetails[] = [];
    const excludedAccounts: string[] = [];
    this.gridApi.getSelectedRows().forEach(x => {
      if (x.isUcb) {
        if (bulkAction === 'statements') {
          excludedAccounts.push(x.uan[0].uanAccount);
        } else if (x.billingType === BillingTypes.RateReady) {
          excludedAccounts.push(x.uan[0].uanAccount);
        } else {
          includedAccounts.push(
            new DownloadedFileDetails(
              x.uan[0].uanAccount,
              x.engieAccountId,
              null,
              x.engieInvoiceId,
              x.name
            )
          );
        }
      } else if (x.engieStatementId === null) {
        excludedAccounts.push(x.billingAccount);
      } else {
        includedAccounts.push(
          new DownloadedFileDetails(
            x.billingAccount,
            null,
            x.engieStatementId,
            null,
            x.name
          )
        );
      }
    });

    this.showConfirmationModalToDownloadFile(
      includedAccounts,
      excludedAccounts,
      bulkAction
    );
  }

  showConfirmationModalToDownloadFile(
    includedAccounts: DownloadedFileDetails[],
    excludedAccounts: string[],
    bulkAction: string
  ) {
    let message = '';
    if (!includedAccounts.length) {
      message =
        'There are no available ' + bulkAction + ' for the selected accounts.';
      this.modalDialogService.openErrorDialog(message);
      return;
    }

    this.includedAccountsToDownloadFile = includedAccounts;
    this.bulkActionNavigation = bulkAction;
    this.isBulkActionForDownloadingFile = true;
    this.isBulkActionForMap = false;
    this.isBulkActionForMapWithAccessType = false;
    if (!excludedAccounts.length) {
      message =
        'Would you like to compress the selected files into a single .zip file or download as individual files?';
      this.modalDialogService.openWarningDialog(
        'Individual Files',
        () => {
          this.downloadIndividualFiles();
        },
        'Zip File',
        () => {
          this.downloadZipFile();
        },
        message,
        'Download Options'
      );
      return;
    }
    if (includedAccounts.length && excludedAccounts.length) {
      message =
        'The following accounts do not have available ' +
        bulkAction +
        ': ' +
        excludedAccounts.join(', ') +
        '. <br>Are you sure you want to continue?';
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continue();
        },
        '',
        null,
        message,
        'Warning'
      );
    }
  }

  bulkAddress() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Update_BillingAddress,
      'bulk-address'
    );
    this.showConfirmationModal(
      selectedRows,
      'bulk-address',
      'update billing address for'
    );
  }

  isAutoPayRenderer(params) {
    let htmlString: string;
    if (params.data.autoPayEnabled) {
      htmlString = `<i class='material-icons nj-icon--blue-corporate'>autorenew</i>`;
    } else {
      htmlString = '';
    }
    const divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();

    return htmlString;
  }

  autoPayFilterKey(params) {
    if (params.value) {
      return 'Auto pay enabled';
    } else {
      return 'Auto pay disabled';
    }
  }

  ownership(params) {
    const ownership: Ownership = params.value;
    const divTag = document.createElement('div');
    if (ownership.emailInitial != null) {
      if (ownership.hasMultipleOwner) {
        const divAvatarPic = document.createElement('div');
        divAvatarPic.setAttribute('class', 'nj-avatar__picture');

        const divBadge = document.createElement('div');
        divBadge.setAttribute('class', 'nj-badge nj-badge--orange');
        divBadge.setAttribute('style', 'width: 1rem; height: 1rem;');
        divBadge.appendChild(
          document.createTextNode(ownership.numberOfAccountOwners?.toString())
        );

        const divAvatar = document.createElement('div');
        divAvatar.setAttribute('class', 'nj-avatar nj-avatar--sm');
        divAvatar.setAttribute('style', 'width: 2rem; height: 2rem;');
        divAvatar.appendChild(divAvatarPic);
        divAvatar.appendChild(divBadge);

        divTag.appendChild(divAvatar);
      } else {
        divTag.setAttribute('class', 'nj-badge nj-badge--blue-corporate');
        divTag.appendChild(document.createTextNode(ownership.emailInitial));
      }
    }
    return divTag;
  }

  UserAccessOwnership(params) {
    const userAccessOwnership: Ownership = params.value;

    if (userAccessOwnership.shareeTooltip !== null) {
      const elemImg = document.createElement('img');

      if (userAccessOwnership.iOwnThisAccount) {
        elemImg.setAttribute('src', '/assets/brand/Shared_Image.png');
        elemImg.setAttribute('alt', 'Shared');
      } else {
        elemImg.setAttribute('src', '/assets/brand/Sharee_Image.png');
        elemImg.setAttribute('alt', 'Sharee');
      }

      const divAvatarPic = document.createElement('div');
      divAvatarPic.setAttribute('class', 'nj-avatar__picture');
      divAvatarPic.appendChild(elemImg);

      const divAvatar = document.createElement('div');
      divAvatar.setAttribute('class', 'nj-avatar nj-avatar--sm');
      divAvatar.appendChild(divAvatarPic);

      const divTag = document.createElement('div');
      divTag.appendChild(divAvatar);

      return divTag;
    }
  }

  saveState() {
    this.saveAccountListPreference();
  }

  saveAccountListPreference() {
    this.spinner.show();
    const filterModel = this.gridApi.getFilterModel();
    const sortModel = this.gridApi.getSortModel();
    const columnState = this.gridColumnApi.getColumnState();

    const columnOptions: GridOption = new GridOption();
    columnOptions.Type = 'AccountSummary';
    columnOptions.FilterModel = filterModel;
    columnOptions.SortModel = sortModel;
    columnOptions.ColumnState = columnState;

    this.userPreference
      .saveAccountListPreference(columnOptions)
      .subscribe(data => {
        data;
        this.spinner.hide();
      });
  }

  bulkShare() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Share_Account,
      'share'
    );
    this.showConfirmationModal(selectedRows, 'share', 'share');
  }

  bulkTransfer() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Transfer_Account,
      'transfer'
    );
    this.showConfirmationModal(selectedRows, 'transfer', 'transfer');
  }

  bulkRelease() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Release_Accounts,
      'release'
    );
    this.showConfirmationModal(selectedRows, 'release', 'release');
  }

  bulkAutopayAndPaperless() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Enroll_Auto_Pay,
      'bulk-autopay'
    );
    this.showConfirmationModal(
      selectedRows,
      'bulk-autopay',
      'enroll or unenroll in autopay or paperless for'
    );
  }

  getSelectedRows(
    accessPermissionToSelect: AccessPermission,
    bulkAction: string
  ): SelectedRows {
    const includedEngieAccounts: string[] = [];
    const includedAccountNumbers: string[] = [];
    const excludedAccounts: string[] = [];
    this.gridApi.getSelectedRows().forEach(x => {
      const accountAccessType: AccessTypes = this.utilityService.convertToAccessType(
        x.accessType
      );
      const hasAccessPermission = this.identity.hasAccessPermission(
        accessPermissionToSelect,
        accountAccessType
      );
      const accountNumber = x.isUcb ? x.uan[0].uanAccount : x.billingAccount;
      if (
        x.isUcb &&
        (bulkAction === 'bulk-address' || bulkAction === 'bulk-autopay')
      ) {
        excludedAccounts.push(x.uan[0].uanAccount);
      } else if (hasAccessPermission) {
        includedAccountNumbers.push(accountNumber);
        includedEngieAccounts.push(x.engieAccountId);
      } else if (
        bulkAction === 'bulk-autopay' &&
        this.identity.hasAccessPermission(
          AccessPermission.Enroll_Paperless,
          accountAccessType
        )
      ) {
        includedAccountNumbers.push(accountNumber);
        includedEngieAccounts.push(x.engieAccountId);
      } else {
        excludedAccounts.push(accountNumber);
      }
    });
    const includedAccounts = new Array(
      includedAccountNumbers,
      includedEngieAccounts
    );
    return new SelectedRows(includedAccounts, excludedAccounts);
  }

  getSelectedRowsMap(
    accessPermissionToSelect: AccessPermission
  ): SelectedRowsMap {
    const included = new Map();
    const excluded = new Map();
    this.gridApi.getSelectedRows().forEach(x => {
      const accountAccessType: AccessTypes = this.utilityService.convertToAccessType(
        x.accessType
      );
      const hasAccessPermission = this.identity.hasAccessPermission(
        accessPermissionToSelect,
        accountAccessType
      );
      if (x.isUcb) {
        x.uan.forEach(element => {
          excluded.set(x.engieAccountId, element.uanAccount);
        });
      } else if (hasAccessPermission) {
        included.set(x.engieAccountId, x.billingAccount);
      } else {
        excluded.set(x.engieAccountId, x.billingAccount);
      }
    });
    return new SelectedRowsMap(included, excluded);
  }

  getSelectedRowsMapWithAccessType(
    accessPermissionToSelect: AccessPermission
  ): SelectedRowsMapWithAccessType {
    const included = new Map();
    const excluded = new Map();
    this.gridApi.getSelectedRows().forEach(x => {
      const accountAccessType: AccessTypes = this.utilityService.convertToAccessType(
        x.accessType
      );
      const hasAccessPermission = this.identity.hasAccessPermission(
        accessPermissionToSelect,
        accountAccessType
      );
      const excludedWithAccessType = new Map();
      const includedWithAccessType = new Map();
      if (x.isUcb) {
        x.uan.forEach(element => {
          excludedWithAccessType.set(element.uanAccount, accountAccessType);
          excluded.set(x.engieAccountId, excludedWithAccessType);
        });
      } else if (hasAccessPermission) {
        includedWithAccessType.set(x.billingAccount, accountAccessType);
        included.set(x.engieAccountId, includedWithAccessType);
      } else {
        excludedWithAccessType.set(x.billingAccount, accountAccessType);
        excluded.set(x.engieAccountId, excludedWithAccessType);
      }
    });
    return new SelectedRowsMapWithAccessType(included, excluded);
  }

  showConfirmationModal(
    selectedRows: SelectedRows,
    bulkActionNavigation: string,
    bulkAction
  ) {
    let message = '';
    if (!selectedRows.includedRows[0].length) {
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the selected accounts.';
      this.modalDialogService.openErrorDialog(message);
    }
    if (!selectedRows.excludedRows.length) {
      this.globalService.set(
        this.globalService.BULK,
        selectedRows.includedRows
      );
      this.router.navigate(['/' + bulkActionNavigation]);
    }
    if (
      selectedRows.includedRows[0].length &&
      selectedRows.excludedRows.length
    ) {
      this.includedSelectedRows = selectedRows.includedRows;
      this.bulkActionNavigation = bulkActionNavigation;
      this.isBulkActionForDownloadingFile = false;
      this.isBulkActionForMap = false;
      this.isBulkActionForMapWithAccessType = false;
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the following accounts: ' +
        selectedRows.excludedRows.join(', ') +
        '. <br>Are you sure you want to continue?';
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continue();
        },
        '',
        null,
        message,
        'Warning'
      );
    }
  }

  showConfirmationModalMap(
    selectedRows: SelectedRowsMap,
    bulkActionNavigation: string,
    bulkAction
  ) {
    let message = '';
    if (!selectedRows.includedRows.size) {
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the selected accounts.';
      this.modalDialogService.openErrorDialog(message);
    }
    if (!selectedRows.excludedRows.size) {
      this.globalService.set(
        this.globalService.BULKMAP,
        JSON.stringify(Array.from(selectedRows.includedRows.entries()))
      );
      this.router.navigate(['/' + bulkActionNavigation]);
    }
    if (selectedRows.includedRows.size && selectedRows.excludedRows.size) {
      this.includedSelectedRowsMap = selectedRows.includedRows;
      this.bulkActionNavigation = bulkActionNavigation;
      this.isBulkActionForDownloadingFile = false;
      this.isBulkActionForMap = true;
      this.isBulkActionForMapWithAccessType = false;
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the following accounts: ' +
        Array.from(
          selectedRows.excludedRows.values(),
          x => ' ' + x
        ).toString() +
        '. <br>Are you sure you want to continue?';
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continue();
        },
        '',
        null,
        message,
        'Warning'
      );
    }
  }

  showConfirmationModalMapWithAccessType(
    selectedRows: SelectedRowsMapWithAccessType,
    bulkActionNavigation: string,
    bulkAction
  ) {
    let message = '';
    if (!selectedRows.includedRows.size) {
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the selected accounts.';
      this.modalDialogService.openErrorDialog(message);
    }
    if (!selectedRows.excludedRows.size) {
      var includedRowsWithAccessType = this.mapToObjectRec(
        selectedRows.includedRows
      );
      this.globalService.set(
        this.globalService.BULKMAPWITHACCESSTYPE,
        JSON.stringify(includedRowsWithAccessType)
      );
      this.router.navigate(['/' + bulkActionNavigation]);
    }
    if (selectedRows.includedRows.size && selectedRows.excludedRows.size) {
      this.includedSelectedRowsMapWithAccessType = selectedRows.includedRows;
      this.bulkActionNavigation = bulkActionNavigation;
      this.isBulkActionForDownloadingFile = false;
      this.isBulkActionForMap = false;
      this.isBulkActionForMapWithAccessType = true;
      var excludedAccounts = new Map<string, string>();
      for (let value of selectedRows.excludedRows.values()) {
        for (let key of value.keys()) {
          excludedAccounts.set(key, value.get(key));
        }
      }
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the following accounts: ' +
        Array.from(excludedAccounts.keys(), x => ' ' + x).toString() +
        '. <br>Are you sure you want to continue?';
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continue();
        },
        '',
        null,
        message
      );
    }
  }

  mapToObjectRec(m) {
    let lo = {};
    for (let [k, v] of m) {
      if (v instanceof Map) {
        lo[k] = this.mapToObjectRec(v);
      } else {
        lo[k] = v;
      }
    }
    return lo;
  }

  continue() {
    if (this.isBulkActionForDownloadingFile) {
      let message =
        'Would you like to compress the selected files into a single .zip file or download as individual files?';
      this.modalDialogService.openWarningDialog(
        'Individual Files',
        () => {
          this.downloadIndividualFiles();
        },
        'Zip File',
        () => {
          this.downloadZipFile();
        },
        message,
        'Download Options'
      );
    } else {
      if (this.isBulkActionForMap) {
        this.globalService.set(
          this.globalService.BULKMAP,
          JSON.stringify(Array.from(this.includedSelectedRowsMap.entries()))
        );
      } else if (this.isBulkActionForMapWithAccessType) {
        var includedRowsWithAccessType = this.mapToObjectRec(
          this.includedSelectedRowsMapWithAccessType
        );
        this.globalService.set(
          this.globalService.BULKMAPWITHACCESSTYPE,
          JSON.stringify(includedRowsWithAccessType)
        );
      } else {
        this.globalService.set(
          this.globalService.BULK,
          this.includedSelectedRows
        );
      }
      this.router.navigate(['/' + this.bulkActionNavigation]);
    }
  }

  downloadIndividualFiles() {
    this.includedAccountsToDownloadFile.forEach(x => {
      if (this.bulkActionNavigation === 'statements') {
        this.utilityService.downloadStatement(x, false);
      } else {
        this.utilityService.downloadBackingSheet(x, false);
      }
    });
  }

  downloadZipFile() {
    if (this.bulkActionNavigation === 'statements') {
      this.utilityService.zipStatements(this.includedAccountsToDownloadFile);
    } else {
      this.utilityService.zipBackingSheets(this.includedAccountsToDownloadFile);
    }
  }

  exportToExcel() {
    const params = {
      columnWidth: 100,
      sheetName: 'AccountSummaryList',
      columnKeys: [
        'billingAccount',
        'name',
        'billingAddress',
        'accountBalance',
        'dueDate',
        'ownership'
      ],

      processCellCallback: this.processExportedCell
    };
    this.gridApi.exportDataAsExcel(params);
  }

  processExportedCell(params) {
    if (
      params.column.colId === 'suppyStartDate' ||
      params.column.colId === 'dueDate'
    ) {
      if (params.value != null && params.value !== '0001-01-01T00:00:00') {
        return formatDate(params.value, 'MM/dd/yyyy', 'en-US');
      }
      return null;
    } else if (
      params.column.colId === 'billingAddress' ||
      params.column.colId === 'serviceAddress'
    ) {
      const data = params.value;
      if (data != null) {
        let currentAddress: any[];
        currentAddress = [
          data.street,
          data.street2,
          data.city,
          data.state,
          data.postalCode
        ];
        let formattedAddress = '';
        currentAddress.forEach(x => {
          if (formattedAddress && x) {
            formattedAddress = formattedAddress + ' ' + x;
          } else {
            if (!formattedAddress && x) {
              formattedAddress = x;
            }
          }
        });
        return formattedAddress;
      }
      return null;
    } else if (
      params.column.colId === 'autoPayEnabled' ||
      params.column.colId === '0' ||
      params.column.colId === 'accountBalance_1' ||
      params.column.colId === ''
    ) {
      return null;
    } else if (params.column.colId === 'ownership') {
      return params.value.toolTip;
    } else {
      return params.value;
    }
  }

  public excelStyles: ExcelStyle[] = [
    {
      id: 'header',
      interior: {
        color: '#aaaaaa',
        pattern: 'Solid',
        patternColor: undefined
      }
    },
    {
      id: 'body',
      interior: {
        color: '#dddddd',
        pattern: 'Solid',
        patternColor: undefined
      }
    },
    {
      id: 'textFormat',
      dataType: 'String'
    }
  ];

  public defaultExcelExportParams: ExcelExportParams = {
    getCustomContentBelowRow: params => {
      return [
        [
          //column header names
          cell(''),
          cell(''),
          cell(''),
          cell(''),
          cell(''),
          cell(''),
          cell('UTILITY ACCOUNT', 'header'),
          cell('STATUS', 'header'),
          cell('SERVICE START DATE', 'header'),
          cell('SERVICE ADDRESS', 'header'),
          cell('UTILITY', 'header')
        ]
      ].concat(
        params.node.data.uan.map(record => {
          //values from data
          return [
            cell(''),
            cell(''),
            cell(''),
            cell(''),
            cell(''),
            cell(''),
            cell(record.uanAccount, 'body'),
            cell(record.status, 'body'),
            cell(
              formatDate(record.suppyStartDate, 'MM/dd/yyyy', 'en-US'),
              'body'
            ),
            cell(this.addressFilter(record.serviceAddress), 'body'),
            cell(record.utility, 'body')
          ];
        }),
        [[]]
      );
    },
    columnWidth: 120 //this is important
  };
}

function cell(text: string, styleId?: string): ExcelCell {
  return {
    styleId: styleId,
    data: {
      type: 'String',
      value: String(text)
    }
  };
}
