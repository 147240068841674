import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { AccountSettingAdd } from '@shared/models/accountSettingAdd';
import { HttpHelperService } from '@core/services/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingsService {
  constructor(private httpHelper: HttpHelperService) {}

  getSettings() {
    return this.httpHelper
      .httpGetRequest(
        `${environment.apimWebRoot}${environment.getAllAccountSettings}`
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }

  addSetting(account: AccountSettingAdd) {
    return this.httpHelper
      .httpPostRequest(
        `${environment.apimWebRoot}${environment.addAccountSetting}`,
        account
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }

  saveSetting(account: string, setting: string, payload: any) {
    return this.httpHelper
      .httpPostRequest(
        `${environment.apimWebRoot}${environment.saveAccountSetting
          .replace('{0}', setting)
          .replace('{1}', account)}`,
        payload
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }

  deleteSetting(account: string, setting: string) {
    return this.httpHelper
      .httpDeleteRequest(
        `${environment.apimWebRoot}${environment.deleteAccountSetting
          .replace('{0}', setting)
          .replace('{1}', account)}`
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }
}
