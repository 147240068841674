import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  RequiredValidator
} from '@angular/forms';
import { AccountService } from '@core/services/account.service';
import { SharedAccount } from '@shared/models/shareAccount';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { GlobalService } from '@core/services/global.service';
import { UtilityService } from '@core/services/utility.service';
import { Role } from '@shared/enums/Role';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { AccessTypes } from '@shared/enums/AccessTypes';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.sass']
})
export class ShareComponent implements OnInit {
  accountId;
  shareForm: FormGroup;
  confirm = false;
  emailAddresses$;
  invalidEmailAddresses$;
  role$;
  accessType$;
  data$: SharedAccount;
  emulate = false;
  roles: string[];
  accessTypes: string[];
  selectedEngieAccountIds: string[];
  selectedAccountNumbers: string[];
  numberOfAccounts: number;
  defaultAccessType: string;
  defaultAccessTypeDesc: string;

  constructor(
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private location: Location,
    private utilityService: UtilityService,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit() {
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.initForm(this.formBuilder);
    this.selectedAccountNumbers = this.globalService.get(
      this.globalService.BULK
    )[0];
    this.selectedEngieAccountIds = this.globalService.get(
      this.globalService.BULK
    )[1];
    this.numberOfAccounts = this.selectedEngieAccountIds.length;
    this.roles = this.getRoles();
  }

  getRoles() {
    const roles = [];
    roles.push({ key: Role.Customer, value: Role.Customer });
    roles.push({ key: Role.Partner, value: Role.Partner });
    return roles;
  }

  initForm(formBuilder: FormBuilder) {
    this.shareForm = this.formBuilder.group({
      emailAddresses: new FormControl(
        '',
        [Validators.required],
        // @ts-ignore
        this.emailValidator.bind(this)
      ),
      selectedRole: new FormControl('', [Validators.required]),
      selectedAccessType: new FormControl('', [Validators.required]),
      validate: new FormControl(this.confirm)
    });
  }

  emailValidator(g: FormGroup) {
    const emailAddresses: string = this.f.emailAddresses.value;
    const invalidEmailAddresses = this.utilityService.validateEmailAddresses(
      emailAddresses
    );

    if (invalidEmailAddresses === '') {
      this.f.emailAddresses.setErrors(null);
      this.invalidEmailAddresses$ = invalidEmailAddresses;
      return null;
    }

    this.f.emailAddresses.setErrors({ pattern: true });
    this.invalidEmailAddresses$ = invalidEmailAddresses;
    return { pattern: true };
  }

  get f() {
    return this.shareForm.controls;
  }

  onSubmit(event) {
    this.confirm = true;

    if (
      this.shareForm.invalid ||
      !this.isAccessTypeValid() ||
      !this.selectedEngieAccountIds.length
    ) {
      return;
    }

    this.spinner.show();

    this.emailAddresses$ = this.f.emailAddresses.value;
    this.role$ = this.f.selectedRole.value;
    this.accessType$ = this.f.selectedAccessType.value;

    const g: SharedAccount = new SharedAccount(this.emailAddresses$);
    g.Role = this.role$;
    g.AccessType = this.accessType$;
    g.EngieAccountIds = this.selectedEngieAccountIds;

    this.shareAccounts(g).subscribe(
      (data: SharedAccount) => {
        const message = 'Your accounts have been successfully shared.';
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(
          message,
          '',
          'Go To Accounts',
          () => {
            this.goBack();
          }
        );
      },
      error => {
        console.log('Failed to share accounts.');
        const message = error.error;
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  //If this validation is created as a validator in the initForm(), for some reason it does not fire properly.
  //For now just adding it as a validation called on submit.
  isAccessTypeValid() {
    const partner = this.utilityService.getEnumKeyByEnumValue(
      Role,
      Role.Partner.toString()
    );
    const readOnly = this.utilityService.getEnumKeyByEnumValue(
      AccessTypes,
      AccessTypes.ReadOnly.toString()
    );
    if (
      this.f.selectedRole.value === partner &&
      this.f.selectedAccessType.value !== readOnly
    ) {
      this.f.selectedAccessType.setErrors({ invalidAccessType: true });
      return false;
    }
    return true;
  }

  shareAccounts(account: SharedAccount) {
    return this.accountService.shareAccount(account);
  }

  onReset() {
    this.confirm = false;
    this.location.back();
  }

  roleOnChanged(event) {
    this.f.selectedRole.setErrors(RequiredValidator);
    const role = event.target.value;
    this.f.selectedRole.setValue(role);
    this.accessTypes = this.getAccessTypesByRole(role);
    if (role === Role.Partner) {
      const readOnly = this.utilityService.getEnumKeyByEnumValue(
        AccessTypes,
        AccessTypes.ReadOnly.toString()
      );
      if (this.f.selectedAccessType.value === '') {
        this.defaultAccessType = readOnly;
        this.defaultAccessTypeDesc = AccessTypes.ReadOnly.toString();
        this.f.selectedAccessType.setValue(readOnly);
      }
    }
  }

  getAccessTypesByRole(role: Role): string[] {
    const accessTypes = this.utilityService.getAccessTypesByRole(role);
    const index = accessTypes.findIndex(d => d['key'] === AccessTypes.Owner);
    if (index !== -1) {
      accessTypes.splice(index, 1);
    }
    return accessTypes;
  }

  accessTypeOnChanged(event) {
    this.f.selectedAccessType.setValue(event.target.value);
  }

  goBack() {
    this.location.back();
  }
}
