import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-checkbox-renderer',
  template: `
    <input
      type="checkbox"
      style="width:15px;height:15px;"
      (click)="checkedHandler($event)"
      [checked]="params.value"
      [disabled]="params.isDisabled"
    />
  `
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  refresh(): boolean {
    return false;
  }
}
