import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { GuestPayment } from '@shared/models/guestPayment';
import { HttpHelperGuestService } from '@core/services/http-helper-guest.service';

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  constructor(private guestHelper: HttpHelperGuestService) {}

  postPaymentDetail(account: GuestPayment): Observable<GuestPayment> {
    return this.guestHelper
      .httpPostRequestNoAuth<GuestPayment>(
        `${environment.apimGuestRoot}${environment.getGuestPayDetail}`,
        account
      )
      .pipe(
        map((response: any) => response),
        catchError(err =>
          err.code === 404 ? throwError('Not found') : throwError(err)
        )
      );
  }
}
