import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavMenuVerticalComponent } from './components/nav-menu-vertical/nav-menu-vertical.component';
import { FilterPipe } from './pipes/filter.pipe';
import { JsonPipe } from './pipes/json.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { ButtonCellRendererComponent } from './components/button-cell-renderer.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CustomTooltipComponent } from './components/grid-custom-tooltip/custom-tooltip.component';
import { AgGridModule } from 'ag-grid-angular';
import { MaterialModule } from './modules/material-module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MsalModule } from '@azure/msal-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GuidedTourService } from 'ngx-guided-tour';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GuestFooterComponent } from './components/guest/guest-footer/guest-footer.component';
import { GuestHeaderComponent } from './components/guest/guest-header/guest-header.component';
import { EmulateComponent } from './components/emulate/emulate.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { CheckboxRendererComponent } from './components/checkbox-renderer';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    FilterPipe,
    SearchFilterPipe,
    HeaderComponent,
    FooterComponent,
    NavMenuVerticalComponent,
    JsonPipe,
    ButtonCellRendererComponent,
    SpinnerComponent,
    CustomTooltipComponent,
    GuestHeaderComponent,
    GuestFooterComponent,
    EmulateComponent,
    ModalDialogComponent,
    CheckboxRendererComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    RouterModule,
    MsalModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory, // exported factory function needed for AoT compilation
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(),
    AgGridModule.withComponents([
      CustomTooltipComponent,
      ButtonCellRendererComponent,
      CheckboxRendererComponent
    ])
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    GuidedTourService
    // services should go in the CoreModule so that they are singleton!
  ],
  exports: [
    CommonModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FilterPipe,
    SearchFilterPipe,
    HeaderComponent,
    FooterComponent,
    NavMenuVerticalComponent,
    ButtonCellRendererComponent,
    SpinnerComponent,
    CustomTooltipComponent,
    MaterialModule,
    GuestHeaderComponent,
    GuestFooterComponent,
    EmulateComponent,
    CheckboxRendererComponent
  ]
})
export class SharedModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
