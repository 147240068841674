import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import {
  Location,
  formatCurrency,
  formatDate,
  getCurrencySymbol
} from '@angular/common';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { AccountService } from '@core/services/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '@environments/environment';
import { GuestPayment } from '@shared/models/guestPayment';
import { ChargesService } from '@core/services/charges.service';

@Component({
  selector: 'app-guest-payment-modal',
  templateUrl: './guest-payment-modal.component.html',
  styleUrls: ['./guest-payment-modal.component.sass']
})
export class GuestPaymentModalComponent implements OnInit {
  public isDialogOpen = false;
  submitButtonEnabled = false;
  fiservEventListener: EventListener;
  showACH: boolean;
  submitButtonText: string;
  mainHeadingText: string;
  isCreditCard: boolean;
  charges;
  billingAccount: string;
  accountId: string;
  paymentDetail$: GuestPayment;
  paymethodNickname: string;
  MSG_SERVICEFEE = `${environment.forteServiceFeeMsg}`;
  MSG_ACHADVISORY = `${environment.fiservAchAdvisoryMsg}`;
  MSG_PAYMENTNOTE = `${environment.paymentNoteMsg}`;
  amount;
  totalAmount;
  body: string = '';
  showReviewModalBannerMessage = false;
  paymentDate;
  PaymethodToken;
  authorizationCode;

  constructor(
    @Optional() public dialogRef: MatDialogRef<GuestPaymentModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: GuestPaymentDialogData,
    private modalDialogService: ModalDialogService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private location: Location,
    public modalDialog: MatDialog,
    private chargesService: ChargesService
  ) {}

  ngOnInit(): void {
    this.billingAccount = this.data.billingAccount;
    this.accountId = this.data.engieAccountId;
    this.paymentDetail$ = this.data.guestPaymentDetail;
    this.paymethodNickname = this.data.paymethodNickname;
    this.isCreditCard = this.data.isCreditCard;
    this.paymentDate = formatDate(new Date(), 'MM/dd/yyyy', 'en_US');
    this.PaymethodToken = this.data.fundingToken;

    if (this.data.isCreditCard) {
      this.getChargeFees('creditcard', 0);
    }
  }

  closeButton_click() {
    this.dialogRef.close();
  }

  goBack() {
    this.location.back();
  }

  getChargeFees(type: string, amount: any) {
    this.chargesService.getServiceFee(type, amount).subscribe(data => {
      this.charges = JSON.parse(data.message);
      this.totalAmount = this.charges.totalAmount.toFixed(2);
    });
  }

  onChangeAmount(amount: any) {
    this.amount = amount;
    if (this.isCreditCard) {
      this.getChargeFees('creditcard', amount);
    } else {
      this.totalAmount = amount;
    }
  }

  continueButton_click() {
    let confirmationMessage: string;
    let paymentAmount = `${formatCurrency(
      this.paymentDetail$.paymentAmount,
      'en-US',
      getCurrencySymbol('USD', 'wide')
    )}`;

    if (this.paymentDetail$.paymentStatus === 'Pending') {
      confirmationMessage =
        'A payment of ' +
        paymentAmount +
        ' was submitted on ' +
        this.paymentDate +
        ' and is pending, are you sure you want to make an additional payment?';
      this.body = confirmationMessage;
      this.showReviewModalBannerMessage = true;
    }
    if (this.paymentDetail$.paymentStatus === 'Scheduled') {
      confirmationMessage =
        'A payment of ' +
        paymentAmount +
        ' is scheduled for ' +
        this.paymentDate +
        ', are you sure you want to make an additional payment?';
      this.body = confirmationMessage;
      this.showReviewModalBannerMessage = true;
    }
    this.modalDialogService.openWarningDialog(
      'Submit Payment',
      () => {
        this.sendPayment();
      },
      '',
      null,
      this.getReviewModalText(),
      'Review Payment',
      this.reviewModalBannerMessage()
    );
  }

  getReviewModalText(): string {
    return `
    <div class="row">
      <div class="col">
        <div class="review-modal-header">Billing Account:</div>
      </div>
      <div class="col">
        <div>
          ${this.data.guestPaymentDetail.billingAccount}
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="review-modal-header">Payment Method:</div>
      </div>
      <div class="col">
        <div>${this.paymethodNickname}</div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="review-modal-header">Payment Amount:</div>
      </div>
      <div class="col">
        <div>${formatCurrency(
          this.amount,
          'en-US',
          getCurrencySymbol('USD', 'wide')
        )}</div>
      </div>
    </div>
    ${
      this.isCreditCard && this.charges
        ? `<div class="row mt-2">
      <div class="col">
        <div class="review-modal-header">Credit Card Service Fee:</div>
      </div>
      <div class="col">
        <div>${formatCurrency(
          this.charges.serviceFee,
          'en-US',
          getCurrencySymbol('USD', 'wide')
        )}</div>
      </div>
    </div>`
        : ''
    }
    ${
      this.isCreditCard && this.charges
        ? `<div class="row mt-2">
      <div class="col">
        <div class="review-modal-header">Total Amount:</div>
      </div>
      <div class="col">
        <div>${formatCurrency(
          this.charges.totalAmount,
          'en-US',
          getCurrencySymbol('USD', 'wide')
        )}</div>
      </div>
    </div>`
        : ''
    }`;
  }

  reviewModalBannerMessage(): string {
    return `
    ${
      this.showReviewModalBannerMessage
        ? `
    <ng-container>
      <div class="row justify-content-center">
        <div class="pr-1 pl-1 pb-0" style="font-size: 0.8rem; text-align: left">
          <nj-alert>
            <div class="nj-alert nj-alert--danger mb-0 mt-4" role="alert">
              <i class="material-icons nj-alert__icon">error</i>
              <div>
                ${this.body}
              </div>
            </div>
          </nj-alert>
        </div>
      </div>
    </ng-container>
    `
        : ''
    }`;
  }

  sendPayment() {
    this.spinner.show();

    this.accountService
      .fiservOneTimeGuestPayment(
        this.accountId,
        this.amount,
        this.charges ? this.charges.serviceFee : 0,
        this.charges ? this.charges.totalAmount : this.totalAmount,
        new Date(),
        this.PaymethodToken
      )
      .subscribe(
        response => {
          if (!response.success) {
            this.spinner.hide();
            this.body = `Status: ${response.statusCode} ${response.status} - ${response.errorMessage} `;
            this.modalDialogService.openErrorDialog(
              'Payment Declined!',
              this.body
            );
          } else {
            this.authorizationCode = response.authorizationCode;
            this.modalDialogService.openSuccessDialog(
              this.getPaymentSuccessMainMessage(),
              this.getPaymentSuccessAdditionalMessage(),
              'Dismiss',
              () => {
                this.goBack();
              }
            );
            this.spinner.hide();
          }
        },
        error => {
          this.spinner.hide();
          this.modalDialogService.openErrorDialog(
            'Unable to make one time payment',
            'Error: ' + error.message,
            'Dismiss',
            () => {
              this.goBack();
            }
          );
        }
      );
  }

  getPaymentSuccessMainMessage(): string {
    return `
      <div class="row">
        <div class="col">
          <div class="review-modal-header">
            Billing Account:
          </div>
        </div>
        <div class="col">
          <div>${this.paymentDetail$.billingAccount}</div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="review-modal-header">
            Payment Date:
          </div>
        </div>
        <div class="col">
          <div>${this.paymentDate}
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="review-modal-header">
            Payment Method:
          </div>
        </div>
        <div class="col">
          <div>${this.paymethodNickname}</div>
        </div>
      </div>
      ${
        this.authorizationCode
          ? `
            <div class="row mt-2">
              <div class="col">
                <div class="review-modal-header">
                  Confirmation Code:
                </div>
              </div>
              <div class="col">
                <div>${this.authorizationCode}</div>
              </div>
            </div>`
          : ``
      }
      ${
        !this.isCreditCard
          ? `
      <div class="row mt-2">
        <div class="col">
          <div class="review-modal-header">Total Amount:</div>
        </div>
        <div class="col">
          <div>${formatCurrency(
            this.amount,
            'en-US',
            getCurrencySymbol('USD', 'wide')
          )}
          </div>
        </div>
      </div> `
          : `
      <div>
        <div class="row mt-2">
          <div class="col">
            <div class="review-modal-header">Subtotal:</div>
          </div>
          <div class="col">
            <div>${formatCurrency(
              this.amount,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            )} 
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div class="review-modal-header">Service Fee:</div>
          </div>
          <div class="col">
            <div>${formatCurrency(
              this.charges.serviceFee,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            )}
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div class="review-modal-header">Total Amount:</div>
          </div>
          <div class="col">
            <div>${formatCurrency(
              this.totalAmount,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            )}
            </div>
          </div>
        </div>
      </div>`
      }
    `;
  }

  getPaymentSuccessAdditionalMessage(): string {
    return `Thank you for your payment. A confirmation message has been sent
    to the account owner and any linked users who have elected to
    receive payment notifications.`;
  }
}

export interface GuestPaymentDialogData {
  engieAccountId: string;
  billingAccount: string;
  fundingToken: string;
  guestPaymentDetail: GuestPayment;
  paymethodNickname: string;
  isCreditCard: boolean;
}
