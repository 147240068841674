import { Component, OnInit, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountService } from '@core/services/account.service';
import { PayDetail } from '@shared/models/payDetail';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamiteResponse } from '@shared/models/dynamiteResponse';
import { GlobalService } from '@core/services/global.service';
import { ChargesService } from '@core/services/charges.service';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { FiservModalComponent } from './fiserv-modal/fiserv-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-method',
  templateUrl: './method.component.html',
  styleUrls: ['./method.component.sass']
})
export class MethodComponent implements OnInit {
  paymentDetail$: PayDetail;
  paymethodTypes;
  accountId;
  billingAccount;
  accountType;
  payMethodToken;
  body: string;
  display = 'none';
  emulate = false;
  hasCCMethod = false;
  hasACHMethod = true;
  serviceFee = `${environment.forteServiceFee}`;
  serviceFeeMsg = `${environment.forteServiceFeeMsg}`;
  MSG_ACHADVISORY = `${environment.fiservAchAdvisoryMsg}`;
  MSG_PAYMENTNOTE = `${environment.paymentNoteMsg}`;
  allowedMethods$;

  MSG_NOTFOUND = `This account is not set up in the payment system. Please contact customer care at ${environment.customerCarePhoneAlt} (${environment.customerCarePhone}) or ${environment.customerCareEmail}.`;

  constructor(
    private router: Router,
    private globalService: GlobalService,
    private location: Location,
    private accountService: AccountService,
    private chargesService: ChargesService,
    private ngZone: NgZone,
    private spinner: NgxSpinnerService,
    private modalDialogService: ModalDialogService,
    public modalDialog: MatDialog
  ) {
    window['fireNgEvent'] = { component: this, zone: this.ngZone };
  }

  ngOnInit() {
    const detail: string = this.globalService.get(this.globalService.DETAIL);
    this.accountId = detail.split('/')[0];
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    // load screen with payment methods from Dynamite API for the given account
    this.spinner.show();
    this.loadPayMethods(this.accountId);
  }

  loadPayMethods(accountId: string) {
    this.accountService.getPayMethodList(accountId).subscribe(
      data => {
        this.paymentDetail$ = data;
        this.getMethodTypes(this.paymentDetail$);
        this.getPaymethodTypes(this.accountId);
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(
          this.MSG_NOTFOUND,
          '',
          'Dismiss',
          () => {
            this.goBack();
          }
        );
      }
    );
  }

  getPaymethodTypes(accountId: string) {
    this.chargesService.getPaymethodTypes(accountId).subscribe(
      data => {
        if (data.message.search('creditcard') > 0) {
          if (this.hasACHMethod == true) {
            this.allowedMethods$ =
              environment.forteAllowedMethodsACH +
              environment.forteAllowedMethodsCC;
          } else {
            this.allowedMethods$ = environment.forteAllowedMethodsCC;
          }
        }
      },
      error => {
        this.modalDialogService.openErrorDialog(
          this.MSG_NOTFOUND,
          '',
          'Dismiss',
          () => {
            this.goBack();
          }
        );
      }
    );
  }

  onDelete(token: string) {
    this.spinner.show();
    this.accountService
      .removePayMethod(this.accountId, token)
      .subscribe((response: DynamiteResponse) => {
        if (!response.isSuccess) {
          this.spinner.hide();
          this.body = response.message;
          const message = `Unable to add/delete Payment Method. Reason: ${this.body}`;
          this.modalDialogService.openErrorDialog(
            message,
            '',
            'Dismiss',
            () => {
              this.onCloseHandled();
            },
            'Unable to Delete Payment Method'
          );
        } else {
          this.loadPayMethods(this.accountId);
        }
      });
  }

  onSetDefault(token: string) {
    this.spinner.show();
    this.accountService.setDefaultPayMethod(this.accountId, token).subscribe(
      (response: DynamiteResponse) => {
        this.loadPayMethods(this.accountId);
      },
      error => {
        this.spinner.hide();
        console.log('Unable to set default Payment Method.');
      }
    );
  }

  goAutoPay() {
    this.router.navigate(['/account/services']);
  }

  goPayment() {
    this.router.navigate(['/account/payment']);
  }

  onCloseHandled(refresh: boolean = true) {
    if (refresh) {
      this.spinner.show();
      this.loadPayMethods(this.accountId);
    }
  }

  openDeleteModal(token: string, body: string) {
    this.body = body;
    this.payMethodToken = token;
    const message = `Are you sure you want to delete ${this.body}?`;
    this.modalDialogService.openWarningDialog(
      'Continue',
      () => {
        this.onDelete(this.payMethodToken);
      },
      '',
      null,
      message,
      'Delete Payment Method',
      '',
      'Cancel',
      () => {
        this.onCloseHandled(false);
      }
    );
  }

  openModalAutoPay(token: string, body: string, isAutoPayEnabled: boolean) {
    if (isAutoPayEnabled) {
      this.body =
        'Changing your default payment method will also change AutoPay to use ' +
        body.toLowerCase() +
        ', are you sure?';
    } else {
      this.body =
        'Are you sure you want to set ' +
        body.toLowerCase() +
        ' as the default Payment Method?';
    }
    this.payMethodToken = token;
    this.modalDialogService.openWarningDialog(
      'Continue',
      () => {
        this.onSetDefault(this.payMethodToken);
      },
      '',
      null,
      this.body,
      'Set Default Payment Method',
      '',
      'Cancel',
      () => {
        this.onCloseHandled(false);
      }
    );
  }

  goBack() {
    this.location.back();
  }

  getMethodTypes(detail: PayDetail) {
    let settingsElement: any[] = [];
    if (detail.settings.length > 0) {
      for (let settings of detail.settings) {
        settingsElement = settings.settings.filter(obj => {
          return obj.key === 'paymentRestricted';
        });
      }
      if (settingsElement[0].value == 'true') {
        this.hasACHMethod = false;
      }
    }
    for (let payMethod of detail.payMethods) {
      if (payMethod.type === 'creditcard') this.hasCCMethod = true;
    }
  }

  fiservButtonClick() {
    this.modalDialog.open(FiservModalComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: {
        engieAccountId: this.accountId,
        guestPayment: false,
        onCloseHandled: () => {
          this.onCloseHandled(true);
        }
      }
    });
  }
}
