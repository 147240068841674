export class ActivateUser {
  constructor(
    emailAddress: string,
    newPassword: string,
    firstname: string,
    lastname: string,
    preferredname: string,
    companyname: string,
    key: string
  ) {
    this.Key = key;
    this.EmailAddress = emailAddress;
    this.NewPassword = newPassword;
    this.FirstName = firstname;
    this.LastName = lastname;
    this.PreferredName = preferredname;
    this.CompanyName = companyname;
  }

  Key: string;
  EmailAddress: string;
  NewPassword: string;
  FirstName: string;
  LastName: string;
  PreferredName: string;
  CompanyName: string;
}
