import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AccountListComponent } from './components/accounts/account-list/account-list.component';
import { AccountHeaderComponent } from './components/accounts/account/account-header/account-header.component';
import { AccountDetailComponent } from './components/accounts/account/account-detail/account-detail.component';
import { HistoryComponent } from './components/accounts/history/history.component';
import { PaymentComponent } from './components/accounts/payment/payment.component';
import { AddressFormComponent } from './components/accounts/bulk/billing-contact-information/address-form/address-form.component';
import { BillingAddressComponent } from './components/accounts/bulk/billing-contact-information/billing-address/billing-address.component';
import { EmailFormComponent } from './components/accounts/bulk/billing-contact-information/email-form/email-form.component';
import { PhoneFormComponent } from './components/accounts/bulk/billing-contact-information/phone-form/phone-form.component';
import { ConfirmationComponent } from './components/accounts/payment/confirmation/confirmation.component';
import { GuestComponent } from './components/accounts/payment/guest/guest.component';
import { AchFormComponent } from './components/accounts/payment/method/ach-form/ach-form.component';
import { CreditCardFormComponent } from './components/accounts/payment/method/credit-card-form/credit-card-form.component';
import { MethodComponent } from './components/accounts/payment/method/method.component';
import { ReleaseComponent } from './components/accounts/release/release.component';
import { ReportingComponent } from './components/accounts/reporting/reporting.component';
import { ResendActivationComponent } from './components/accounts/resend-activation/resend-activation.component';
import { AutopayComponent } from './components/accounts/services/autopay/autopay.component';
import { PaperlessComponent } from './components/accounts/services/paperless/paperless.component';
import { ServicesComponent } from './components/accounts/services/services.component';
import { ShareComponent } from './components/accounts/share/share.component';
import { AccountListSummaryComponent } from './components/accounts/account-list-summary/account-list-summary.component';
import { TransferComponent } from './components/accounts/transfer/transfer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AccountComponent } from './components/accounts/account/account.component';
import { AccountHeaderUcbComponent } from './components/accounts/account/account-header-ucb/account-header-ucb.component';
import { AccountDetailUcbComponent } from './components/accounts/account/account-detail-ucb/account-detail-ucb.component';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AutopayPaperlessComponent } from './components/accounts/bulk/autopay-paperless/autopay-paperless.component';
import { FiservModalComponent } from './components/accounts/payment/method/fiserv-modal/fiserv-modal.component';
import { GuestPaymentModalComponent } from './components/accounts/payment/method/guest-payment-modal/guest-payment-modal.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AccountListComponent,
    AccountHeaderComponent,
    AccountDetailComponent,
    HistoryComponent,
    PaymentComponent,
    ReportingComponent,
    GuestComponent,
    ShareComponent,
    AccountListSummaryComponent,
    TransferComponent,
    ReleaseComponent,
    ConfirmationComponent,
    MethodComponent,
    AutopayComponent,
    PaperlessComponent,
    ServicesComponent,
    ResendActivationComponent,
    DashboardComponent,
    BillingAddressComponent,
    AchFormComponent,
    CreditCardFormComponent,
    AddressFormComponent,
    EmailFormComponent,
    PhoneFormComponent,
    AccountComponent,
    AccountHeaderUcbComponent,
    AccountDetailUcbComponent,
    AutopayPaperlessComponent,
    FiservModalComponent,
    GuestPaymentModalComponent
  ],
  imports: [
    RouterModule,
    NgxMaskModule,
    CommonModule,
    SharedModule,
    AgChartsAngularModule
  ]
})
export class CustomerModule {}
