import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { forkJoin, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalService } from '@core/services/global.service';
import { Dialog } from '@shared/models/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '@core/services/account.service';
import { UserLandingPageTypes } from '@shared/enums/UserLandingPageTypes';
import { IdentityService } from '@core/services/identity.service';
import { Role } from '@shared/enums/Role';
import { UserPreferenceService } from '@core/services/user-preference.service';
import { environment } from '@environments/environment';
import { AdminService } from '@core/services/admin.service';
import { GuideService } from '@core/services/guide.service';
import { catchError, map } from 'rxjs/operators';
import { AccessTypePermission } from '@shared/models/accessTypePermission';
import { UtilityService } from '@core/services/utility.service';
import { UserRolePermission } from '../../../shared/models/userRolePermission';
import { SystemStatus } from '../../../shared/models/systemStatus';
import { HttpHelperService } from '../../../core/services/http-helper.service';
import { version } from '@root/package.json';

declare let FreshworksWidget: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public ACCOUNT_LISTING = 'ACCOUNT_LISTING';

  userInfo;
  perms$;
  accountType;
  accountId;
  billingAccount;
  utility;
  username;
  welcomeName = '';
  roles: string[];
  emulate = false;
  emulateUser;
  arePermissionsAndPreferencesLoaded = false;
  isUserPermissionLoaded = false;
  showUserGuide: boolean;
  data: Dialog;
  message$ = `There are no accounts associated with this email.
    If you have any questions call customer care at ${environment.customerCarePhoneAlt} (${environment.customerCarePhone}).`;
  icon$ = 'local_phone';
  showCancel$ = false;
  dashboardMessage = '';
  version: string;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    private globalService: GlobalService,
    private router: Router,
    private accountService: AccountService,
    private identity: IdentityService,
    private spinner: NgxSpinnerService,
    private userPreference: UserPreferenceService,
    private adminService: AdminService,
    private httpHelper: HttpHelperService,
    private guideService: GuideService,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.globalService.remove(this.globalService.DETAIL);
    this.globalService.remove(this.globalService.REPORT);
    this.userInfo = this.authService.instance.getActiveAccount()?.idTokenClaims;

    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
      this.username = this.globalService.get('EmulatingUser');
    } else {
      this.username = this.userInfo.email;
    }

    FreshworksWidget('prefill', 'ticketForm', {
      company: 'engie',
      email: this.userInfo.email
    });

    this.loadInitializationDetails();

    const appversion = 'appversion=' + version;
    if (document.cookie.indexOf(appversion) === -1) {
      // clear old MSAL session tokens
      localStorage.clear();
      sessionStorage.clear();
      document.cookie = appversion + ';secure';
    }
    console.log(appversion);
  }

  async loadInitializationDetails() {
    let sources: any[] = [];
    let defaultSources = [
      this.loadUserPermissions(),
      this.loadDashboardMessage(),
      this.loadUserProfile(this.username),
      this.loadUserPreferences(),
      this.loadAccessTypePermissions()
    ];

    if (this.emulate || !this.globalService.has('accountType')) {
      sources.push(this.loadLandingPage());
    } else {
      this.accountType = this.globalService.get('accountType');
    }
    sources = sources.concat(defaultSources);
    await new Promise(res => {
      forkJoin(sources).subscribe(
        () => {
          this.arePermissionsAndPreferencesLoaded = true;
          this.spinner.hide();
          if (!this.emulate && this.showUserGuide) {
            this.guideService.startTour(this.router.url);
          }
        },
        error => {
          console.log(
            'At least one permission or preference initialization failed.'
          );
          this.spinner.hide();
        }
      );
    });
  }

  loadUserPreferences() {
    return this.userPreference.RefreshUserPreferenceCache().pipe(
      map(() => {}),
      catchError(err => {
        console.log('Failed: loadUserPreferences');
        return throwError(err);
      })
    );
  }

  loadAccessTypePermissions() {
    return this.identity.loadAccessTypePermissions().pipe(
      map(data => {
        const accessTypes: AccessTypePermission[] = [];
        data.forEach(item => {
          const accessType = new AccessTypePermission();
          accessType.access = this.utilityService.convertToAccessType(
            Number(item.access)
          );
          accessType.permission = item.permission;
          accessTypes.push(accessType);
        });
        this.globalService.set(
          this.identity.ACCESS_TYPE_ROLE_PERM,
          accessTypes
        );
      }),
      catchError(err => {
        console.log('Failed: loadAccessTypePermissions');
        return throwError(err);
      })
    );
  }

  loadUserPermissions() {
    return this.identity.getUserRolesPermissions().pipe(
      map(data => {
        const userRolePermissions = new UserRolePermission();
        userRolePermissions.userName = data.userName;
        userRolePermissions.permission = data.permission;
        userRolePermissions.role = [];
        data.role.forEach(item => {
          const roleType: Role = (<any>Role)[item];
          userRolePermissions.role.push(roleType);
        });
        this.globalService.set(
          this.identity.USER_LEVEL_ROLE_PERM,
          userRolePermissions
        );
        if (!this.emulate) {
          this.routeToAdminForInternalRoles();
        }
        this.isUserPermissionLoaded = true;
      }),
      catchError(err => {
        console.log('Failed: loadUserPermissions');
        return throwError(err);
      })
    );
  }

  isPartner(): boolean {
    return this.identity.isInRole(Role.Partner);
  }

  loadLandingPage() {
    return this.accountService.getUserLandingPage().pipe(
      map(data => {
        this.accountType = data;
        this.globalService.set('accountType', data);
      }),
      catchError(err => {
        console.log('Failed: getUserLandingPage');
        return throwError(err);
      })
    );
  }

  loadUserProfile(usersEmailAddress) {
    return this.adminService.getUserProfile(usersEmailAddress).pipe(
      map(data => {
        this.showUserGuide = data.showUserGuide;
        if (data.preferredName != null && data.preferredName !== '') {
          this.welcomeName = data.preferredName;
        } else {
          if (data.firstName != null && data.firstName !== '') {
            this.welcomeName = data.firstName;
          }
        }
        if (data.companyName != null && data.companyName !== '') {
          this.globalService.set(
            this.globalService.COMPANYNAME,
            data.companyName
          );
        }
      }),
      catchError(err => {
        console.log('Failed: loadUserProfile');
        return throwError(err);
      })
    );
  }

  loadDashboardMessage() {
    return this.httpHelper
      .httpGetRequest<SystemStatus>(
        `${environment.apimWebRoot}${environment.systemStatus}`
      )
      .pipe(
        map(data => {
          const dashboardMessage = data.dashboardMessage;
          this.dashboardMessage =
            dashboardMessage !== null && dashboardMessage.trim() !== ''
              ? dashboardMessage
              : '';
        }),
        catchError(err => {
          console.log('Failed: loadDashboardMessage');
          return throwError(err);
        })
      );
  }

  viewAccountHistory() {
    let route: string;
    switch (this.accountType.userLandingPageType) {
      case UserLandingPageTypes.OneBillingToOneUtility: {
        this.accountId = this.accountType.engieAccountId;
        this.billingAccount = this.accountType.billingAccountNumber;
        const utilityAccountNo = this.accountType.utilityAccountNumber;
        const isUcb = this.accountType.isUcb;
        route = `/account/detail`;
        this.globalService.set(this.ACCOUNT_LISTING, route);
        this.utilityService.setDetailContext(
          this.accountId,
          utilityAccountNo,
          isUcb
        );
        break;
      }
      case UserLandingPageTypes.ManyBillingToOneUtilityEach: {
        route = `/account/list`;
        this.globalService.set(this.ACCOUNT_LISTING, route);
        break;
      }
      case UserLandingPageTypes.ManyBillingToManyUtilities: {
        route = `/account/summary`;
        this.globalService.set(this.ACCOUNT_LISTING, route);
        break;
      }
    }
    this.router.navigate(['/account/history']);
  }

  viewAccounts() {
    switch (this.accountType.userLandingPageType) {
      case UserLandingPageTypes.OneBillingToOneUtility: {
        this.accountId = this.accountType.engieAccountId;
        this.billingAccount = this.accountType.billingAccountNumber;
        const utilityAccountNo = this.accountType.utilityAccountNumber;
        const isUcb = this.accountType.isUcb;
        const route = `/account/detail`;
        this.globalService.set(this.ACCOUNT_LISTING, route);
        this.utilityService.setDetailContext(
          this.accountId,
          utilityAccountNo,
          isUcb
        );
        this.router.navigate([route]);
        break;
      }
      case UserLandingPageTypes.ManyBillingToOneUtilityEach:
      case UserLandingPageTypes.NoAccounts: {
        const route = `/account/list`;
        this.globalService.set(this.ACCOUNT_LISTING, route);
        this.router.navigate([route]);
        break;
      }
      case UserLandingPageTypes.ManyBillingToManyUtilities: {
        const route = `/account/summary`;
        this.globalService.set(this.ACCOUNT_LISTING, route);
        this.router.navigate([route]);
        break;
      }
    }
  }

  routeToAdminForInternalRoles() {
    const isAdmin = this.identity.isInRole(Role.Admin);
    const isCare = this.identity.isInRole(Role.Care_Agent);
    const isCollection = this.identity.isInRole(Role.Collections);
    const isEmployee = this.identity.isInRole(Role.Employee);
    const isSales = this.identity.isInRole(Role.Sales);
    if (isAdmin || isCare || isCollection || isEmployee || isSales) {
      this.spinner.hide();
      this.router.navigate(['/admin']);
    }
  }
}
