import { Component, OnInit, Input } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '@core/services/global.service';
import { IdentityService } from '@core/services/identity.service';
import { UtilityService } from '@core/services/utility.service';
import { AccessPermission } from '@shared/enums/AccessPermission';

@Component({
  selector: 'app-nav-menu-vertical',
  templateUrl: './nav-menu-vertical.component.html',
  styleUrls: ['./nav-menu-vertical.component.css']
})
export class NavMenuVerticalComponent implements OnInit {
  public ACCOUNT_LISTING = 'ACCOUNT_LISTING';
  isAuthenticated = false;
  isPermissionLoaded = false;
  userName: string;
  accountListRoute: string;
  isDetail: boolean;
  isBulk = false;
  detailURI: string;
  hasAutoPaynPaperless: boolean;
  hasManagePayMethods: boolean;
  billingAccount: string;
  isUcb: boolean;

  @Input() accountId: string;

  constructor(
    private authService: MsalService,
    private globalService: GlobalService,
    private translate: TranslateService,
    private identityService: IdentityService,
    private utilityService: UtilityService
  ) {}

  UseLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    let profile: any = this.authService.instance.getActiveAccount()
      ?.idTokenClaims;
    if (profile !== null && profile !== undefined) {
      this.isAuthenticated = true;
      this.userName = profile.email;
      this.translate.setDefaultLang('en');

      this.loadPermissions();
      // check to see if user is in a bulk transaction. if true, then hide icons in sideNav that require single account context
      if (this.globalService.has(this.globalService.REPORT)) {
        const bulk: string[] = this.globalService.get(
          this.globalService.REPORT
        );
        this.isBulk = bulk.length > 1;
      }
      this.accountListRoute = this.globalService.get(this.ACCOUNT_LISTING);

      if (this.accountListRoute !== undefined) {
        if (
          !this.accountListRoute.includes('list') &&
          !this.accountListRoute.includes('summary')
        ) {
          this.isDetail = true;
          this.detailURI = this.accountListRoute;
        } else {
          this.isDetail = false;
          this.detailURI = '/account/detail';
        }
      }
    }
  }

  loadPermissions() {
    const access = this.globalService.get('ACCOUNT_LEVEL_ROLE_PERM');
    const detail = this.globalService.get(this.globalService.DETAIL);
    if (detail !== undefined && detail.length > 0) {
      this.isUcb = detail.split('/')[2] === 'true';
    }
    this.billingAccount = '';
    if (access !== undefined && JSON.stringify(access).length > 0) {
      const hasPaperless: boolean = this.identityService.hasAccessPermission(
        AccessPermission.Enroll_Paperless,
        access['accessType']
      );
      const hasAutopay: boolean = this.identityService.hasAccessPermission(
        AccessPermission.Enroll_Auto_Pay,
        access['accessType']
      );
      this.hasManagePayMethods = this.identityService.hasAccessPermission(
        AccessPermission.Manage_PayMethods,
        access['accessType']
      );
      this.billingAccount = access.billingAccount;
      if (hasPaperless || hasAutopay) {
        this.hasAutoPaynPaperless = true;
      } else {
        this.hasAutoPaynPaperless = false;
      }
      this.isPermissionLoaded = true;
    } else if (detail !== undefined && detail.length > 0) {
      const accountId = detail.split('/')[0];
      this.identityService
        .getAccessTypeByAccount(accountId, false)
        .subscribe(result => {
          const accessType = this.utilityService.convertToAccessType(
            result['accessType']
          );
          this.billingAccount = result['billingAccountNumber'];
          const hasPaperless: boolean = this.identityService.hasAccessPermission(
            AccessPermission.Enroll_Paperless,
            accessType
          );
          const hasAutopay: boolean = this.identityService.hasAccessPermission(
            AccessPermission.Enroll_Auto_Pay,
            accessType
          );
          this.hasManagePayMethods = this.identityService.hasAccessPermission(
            AccessPermission.Manage_PayMethods,
            accessType
          );
          if (hasPaperless || hasAutopay) {
            this.hasAutoPaynPaperless = true;
          } else {
            this.hasAutoPaynPaperless = false;
          }
          this.isPermissionLoaded = true;
        });
    } else {
      this.isPermissionLoaded = true;
    }
  }

  goToReporting(accountId: string) {
    if (this.billingAccount === '' || this.billingAccount === null) {
      const access = this.globalService.get('ACCOUNT_LEVEL_ROLE_PERM');
      this.billingAccount = access.billingAccount;
    }
    this.utilityService.goToReporting(accountId, this.billingAccount);
  }

  logout() {
    this.authService.logout();
  }
}
