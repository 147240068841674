import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Address } from '@shared/models/address';
import { GlobalService } from '@core/services/global.service';
import { UtilityService } from '@core/services/utility.service';
import { BulkService } from '@core/services/bulk.service';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.sass']
})
export class BillingAddressComponent implements OnInit {
  selectedAccounts: string[];
  selectedBillingAccounts: string[];
  stateAbbreviation: string;
  defaultStateDesc: string;
  result: Address;
  body;
  states = this.utilityService.getUSStates();
  numberOfAccounts: number;

  constructor(
    private location: Location,
    private globalService: GlobalService,
    private utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private bulkService: BulkService,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit(): void {
    this.selectedBillingAccounts = this.globalService.get(
      this.globalService.BULK
    )[0];
    this.selectedAccounts = this.globalService.get(this.globalService.BULK)[1];
    this.numberOfAccounts = this.selectedBillingAccounts.length;
  }

  onSaveBtnClicked(childFormObject: any) {
    this.bulkUpdateBillingAddress(childFormObject);
  }

  bulkUpdateBillingAddress(updateAddress: any) {
    this.spinner.show();
    this.bulkService.bulkUpdateBillingAddress(updateAddress.data).subscribe(
      status => {
        this.spinner.hide();
        const message = this.getMessage(updateAddress.childType);
        this.modalDialogService.openSuccessDialog(message);
      },
      error => {
        this.spinner.hide();
        const message = `There was a problem updating the billing contact information for the selected accounts: ${error.error}`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  onCancel() {
    this.location.back();
  }

  getMessage(childType: string) {
    switch (childType) {
      case 'address':
        return 'You have successfully updated the billing contact address.';
      case 'email':
        return 'You have successfully updated the billing contact email .';
      case 'phone':
        return 'You have successfully updated the billing contact phone number.';
      default:
        return 'You have successfully updated the billing contact information.';
    }
  }
}
