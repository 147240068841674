import { Component, OnInit, Input } from '@angular/core';
import { AccountView } from '@shared/models/accountView';
import { IdentityService } from '@core/services/identity.service';
import { UtilityService } from '@core/services/utility.service';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { DownloadedFileDetails } from '@shared/models/downloadedFileDetails';
import { AccountShared } from '../account-shared';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.css']
})
export class AccountHeaderComponent extends AccountShared implements OnInit {
  @Input() accountDetail: AccountView;
  @Input() accessType: AccessTypes;

  constructor(
    private headerIdentityService: IdentityService,
    private headerUtilityService: UtilityService
  ) {
    super(headerIdentityService, headerUtilityService);
  }

  ngOnInit() {
    this.baseAccessType = this.accessType;
  }

  viewStatement() {
    this.headerUtilityService.viewStatement(
      new DownloadedFileDetails(
        null,
        null,
        this.accountDetail.statement.engieStatementId,
        null,
        null
      ),
      true
    );
  }
}
