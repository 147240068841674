export const environment = {
  environment: 'dev',
  production: false,

  /* Web API */
  coreApiKey: 'c3a6e39d62ed49d69e91f895d3f413f3',
  apimWebRoot: 'https://engieportaldev.azure-api.net',
  apimReportRoot: 'https://engieportaldev.azure-api.net/reporting',
  apimGuestRoot: 'https://engieportaldev.azure-api.net/guest',
  GetRolesPermissionByUser: '/User/GetRolesPermissionByUser',
  LoadAccessTypePermissions: '/User/LoadAccessTypePermissions',
  getAccountList: '/Account/List',
  getUserAccountList: '/User/Account/List',
  getAccountHistory: '/Account/History',
  getAllAccountSettings: '/AccountSettings',
  addAccountSetting: '/AccountSettings/Add',
  saveAccountSetting: '/AccountSettings/Edit/{0}/Account/{1}',
  deleteAccountSetting: '/AccountSettings/Delete/{0}/Account/{1}',
  getAccountView: '/Account/Detail',
  getAccountSummary: '/Account/AccountSummary',
  getUsageHistory: '/Account/Usage',
  getUserLandingPage: '/User/LandingPage',
  getUserList: '/User/Search',
  getPaymentDetail: '/PayMethod/Details',
  deletePayMethod: '/PayMethod/{0}/Delete/{1}',
  sendPayMethodNotification: '/PayMethod/{account}/Notification',
  defaultPayMethod: '/PayMethod/{0}/SetDefault/{1}',
  addPayMethod: '/PayMethod/Add',
  bulkPayMethod: '/PayMethod/Bulk/Add',
  bulkAddress: '/Address/Bulk/Update',
  accountPatch: '/Payment/Patch',
  sendPayment: '/Payment/Charge',
  cancelPayment: '/Payment/{0}/Cancel/{1}',
  resetPassword: '/User/Reset',
  accountShare: '/Account/Share',
  accountTransfer: '/Account/Transfer',
  accountRelease: '/Account/Release',
  getUserProfile: '/User/Profile',
  getUserAccess: '/User/Access',
  updateUserAccess: '/User/Access/Update',
  getBillingDetails: '/Billing/Details',
  updateBillingDetails: '/Billing/Details/Update',
  viewStatement: '/Reports/ViewStatement',
  downloadStatement: '/Reports/DownloadStatement',
  downloadBackingSheet: '/Reports/DownloadBackingSheet',
  downloadInvoiceBackingSheet: '/Reports/Invoice/DownloadBackingSheet',
  zipStatements: '/Reports/ZipStatements',
  zipBackingSheets: '/Reports/ZipBackingSheets',
  activateLogin: '/User/{email}/Enable',
  deactivateLogin: '/User/{email}/Disable',
  sendActivationEmail: '/User/{email}/Activation',
  getPreferences: '/Preference/GetPreferences',
  saveGridPreference: '/Preference/SaveGridPreferences',
  addUserAccount: '/User',
  deleteUser: '/User/{email}/Delete',
  changeEmail: '/User/Email',
  accessType: '/User/GetAccessType',
  getAllApplicationEventLogs: '/Event/Logs',
  systemStatus: '/System/Status',
  systemOnline: '/System/Online',
  systemOffline: '/System/Offline',
  updateLastSignIn: '/User/UpdateLastSignIn',
  fiservAddPayMethodToWallet: '/PayMethod/addPaymethodToWallet/{0}',
  fiservOneTimePayment: '/Charges/OneTimePayment/{0}',

  /* Reporting API */
  reporting: '/Reports/Create',

  /* Guest API */
  getGuestPayDetail: '/pay',
  activateUser: '/Activate',
  sendGuestActivationEmail: '/User/{email}/Activation',
  guestSystemStatus: '/System/Status',

  /* Application Insights */
  appInsights: {
    instrumentationKey: '8dead981-cf56-4173-8072-ea35e69ace6e'
  },

  /* Dynamite Charges */
  charges: {
    paymethodTypes: '/Charges/PaymethodTypes',
    serviceFee: '/Charges/ServiceFee',
    fiServSessionToken: '/Charges/FiServSessionToken/{0}'
  },

  /* Freshdesk settings */
  Freshdesk: {
    ViewTicket: '/ticket'
  },

  /* Customer Portal settings - B2C */
  b2cClientId: '4eec8808-5001-4764-9a40-93cbb4326218',
  b2cAuthority:
    'https://titancpb2c.b2clogin.com/titancpb2c.onmicrosoft.com/B2C_1A_signup_signin',
  b2cKnownAuthorities: ['titancpb2c.b2clogin.com'],
  b2cRedirectUri: 'https://accounts-dev.engieresources.com',
  b2cPostLogoutRedirectUri: 'https://accounts-dev.engieresources.com',
  b2cLogout: 'https://accounts-dev.engieresources.com/logout',
  b2cCacheLocation: 'localStorage',
  b2cScopeUserImpersonation:
    'https://titancpb2c.onmicrosoft.com/api/user_impersonation',

  /* Customer Portal settings - Forte */
  forteCheckoutUri: 'https://sandbox.forte.net/checkout/v2/js',
  forteJs: 'https://sandbox.forte.net/api/js/v1', // Sandbox environ
  forteApiAccessId: '30d486063d5092743d73525a3d454796',
  forteLocationId: '246473',
  forteMethod: 'sale',
  forteAddPayMethod: 'token',
  fortePayMethodButtonText: 'Save',
  forteCustomer: '',
  forteVersion: '2.0',
  forteOrderNumber: 'A030',
  fortePayMethod: '',
  forteTimezone: 'America/Chicago',
  forteAllowedMethodsACH: 'echeck',
  forteAllowedMethodsCC: ',mast,amex,disc',
  forteServiceFee: '3%',
  forteServiceFeeMsg:
    'A service fee of 3% will be charged on all card payments.',
  forteJsLogin: 'JvyajOpCp', // Sandbox

  /* Customer Portal settings - Fiserv */
  fiservUrl: 'https://payframe.clienttestmatrix.com', //Sandbox
  fiservBillerShortName: 'EngieNA',
  fiservApiKey: 'EngieResource',
  fiservAchAdvisoryMsg:
    'If you have an ACH debit block feature on your business checking account, please instruct your bank or financial institution to authorize the following ACH Company ID to draft your checking account: 7529283411. This Company ID must be authorized by your financial institution or your payment may fail or be returned.',
  paymentNoteMsg:
    'Please note it may take up to 30 minutes for your payment to appear on your account',

  /* Customer Portal settings - Other */
  customerCarePhone: '866-693-6443',
  customerCarePhoneAlt: '866-MyENGIE',
  customerCareEmail: 'care@engieresources.com',
  HealthCheck: {
    AccountSearch: '/HealthCheck/AccountSearch',
    BillingAccountPatch: '/HealthCheck/BillingAccountPatch',
    GetAccountByID: '/HealthCheck/GetAccountByID',
    GetTaxExemptionsByBillingServicePointId:
      '/HealthCheck/GetTaxExemptionsByBillingServicePointId',
    InvoiceSearch: '/HealthCheck/InvoiceSearch',
    PaymentSearch: '/HealthCheck/PaymentSearch',
    ServicePointAgreement: '/HealthCheck/ServicePointAgreement',
    StatementSearch: '/HealthCheck/StatementSearch',
    StatementSearchById: '/HealthCheck/StatementSearchById',
    GetPayMethods: '/HealthCheck/Paymethods',
    SendEmail: '/HealthCheck/SendEmail',
    GetUsageHistory: '/HealthCheck/GetUsageHistory',
    CustomerSearch: '/HealthCheck/CustomerSearch',
    ChargesServiceFee: '/HealthCheck/ChargesServiceFee',
    ChargesPaymethodTypes: '/HealthCheck/ChargesPaymethodTypes',
    customerId: '1-4732Q',
    invoiceId: 'CES_DB_6637966',
    accountId: 'CES_71121',
    statementId: 'CES_2115966',
    billingServicePointId: '1645498',
    systemStatusAndIncidents: '/System/Incidents',
    SslCertificateDetail: '/HealthCheck/SslCertificateDetail'
  }
};
