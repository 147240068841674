import { Injectable, Inject } from '@angular/core';
import { StorageService, SESSION_STORAGE } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  REPORT: string;
  DETAIL: string;
  ACCTNO: string;
  MSG: string;
  COMPANYNAME: string;
  BULK: string;
  BULKMAP: string;
  BULKMAPWITHACCESSTYPE: string;

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {
    this.REPORT = 'REPORT';
    this.DETAIL = 'DETAIL';
    this.ACCTNO = 'ACCTNO';
    this.MSG = 'MSG';
    this.COMPANYNAME = 'COMPANYNAME';
    this.BULK = 'BULK';
    this.BULKMAP = 'BULKMAP';
    this.BULKMAPWITHACCESSTYPE = 'BULKMAPWITHACCESSTYPE';
  }

  get(key: string) {
    return this.storage.get(key);
  }

  set(key: string, value: any) {
    this.storage.set(key, value);
  }

  remove(key: string) {
    this.storage.remove(key);
  }

  has(key: string) {
    return this.storage.has(key);
  }

  clear() {
    this.storage.clear();
  }
}
