import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import { HealthCheckerComponent } from './components/health-checker/health-checker.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [HealthCheckerComponent, ReleaseNotesComponent],
  imports: [CommonModule, SharedModule]
})
export class SystemModule {}
