import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '@core/services/account.service';
import { PayDetail } from '@shared/models/payDetail';
import { environment } from '@environments/environment';
import { MsalService } from '@azure/msal-angular';
import { IdentityService } from '@core/services/identity.service';
import { AccessPermission } from '@shared/enums/AccessPermission';
import { GlobalService } from '@core/services/global.service';
import { PayMethod } from '@shared/models/payMethod';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-autopay',
  templateUrl: './autopay.component.html',
  styleUrls: ['./autopay.component.sass']
})
export class AutopayComponent implements OnInit {
  @Input() account: PayDetail;
  @Input() accountId;
  MSG_BODY = `I hereby authorize my financial institution to charge the above account in the amount of my monthly ENGIE Resources bill and send that amount to ENGIE Resources.
    I agree that each charge to my account shall be the same as if I had signed a check to pay my bill.  This authority will remain in effect until I notify ENGIE Resources otherwise.  If I change the account or financial institution specified, I will provide written authorization for the new financial institution to ENGIE Resources.  I understand both the financial institution and ENGIE Resources reserve the right to terminate this payment plan and/or my participation therein.
    I agree to have any past due balances paid using the above account upon ENGIE Resources’ receipt of this form, including late payment charges and returned check fees.`;
  MSG_PAYMETHOD = `At least one Payment Method is required before enabling AutoPay.`;
  MSG_NOTELIGIBLE = `This account is not eligible for ACH AutoPay. For questions, contact Customer Care at ${environment.customerCarePhoneAlt} (${environment.customerCarePhone}).`;

  userInfo = this.authService.instance.getActiveAccount();
  checked = false;
  agreeChecked = false;
  autopayToggle: FormControl;
  agreementToggle: FormControl;
  autoPayRevoked = false;
  settingSet = false;
  expirySet = false;
  expiryDateSet = false;
  emailAddress$;
  display;
  forteServiceFee = `${environment.forteServiceFee}`;
  forteServiceFeeMsg = `${environment.forteServiceFeeMsg}`;
  defaultOrFirstPayMethod: PayMethod;
  hasACHRestriction = false;

  constructor(
    private router: Router,
    private authService: MsalService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private globalService: GlobalService,
    private identity: IdentityService,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit() {
    this.autopayToggle = new FormControl(
      { value: this.account.autoPayEnabled, disabled: true },
      []
    );
    this.agreementToggle = new FormControl('', []);
    const access = this.globalService.get('ACCOUNT_LEVEL_ROLE_PERM');
    let canAutoPay = false;
    canAutoPay = this.identity.hasAccessPermission(
      AccessPermission.Enroll_Auto_Pay,
      access['accessType']
    );

    this.checkPaymentRestricted();

    if (!canAutoPay) {
      this.autoPayRevoked = true;
    }

    if (!this.autoPayRevoked) {
      if (this.account.payMethods.length > 0 || this.account.autoPayEnabled) {
        this.autopayToggle.enable();
      }
    }

    this.autopayToggle.valueChanges.subscribe(value => {
      this.checked = value;
    });

    this.defaultOrFirstPayMethod = this.getDefaultOrFirstPayMethod(
      this.account
    );
  }

  onChangeAutoPay(value) {
    if (value) {
      document.getElementById('enableAPModal').style.display = 'block';
    } else {
      const message = `You are changing the payment settings for this account. Are you sure you would like to stop AutoPay for this account?`;
      this.modalDialogService.openWarningDialog(
        'Stop AutoPay',
        () => {
          this.onToggleAutoPay(false, this.account.accountId);
        },
        '',
        null,
        message,
        'Stop AutoPay',
        '',
        'Cancel',
        () => {
          this.onCloseHandled();
        }
      );
    }
  }

  onChangeAgreement(value) {
    if (value) {
      this.agreeChecked = true;
    } else {
      this.agreeChecked = false;
    }
  }

  onToggleAutoPay(value: boolean, billingAccount: string) {
    this.spinner.show();
    this.accountService
      .setAutoPay(this.accountId, billingAccount, value, this.userInfo.name)
      .subscribe(
        response => {
          this.reload(`/account/services`);
          // TODO: add success modal?
        },
        error => {
          this.reload(`/account/services`);
          // TODO: add error modal?
        }
      );
  }

  checkPaymentRestricted() {
    // Collections requirement: check if AutoPay should be restricted due to NSF.
    // Check AccountSettings container for accounts that are restricted.
    // Must check if expiration date has passed as well.
    this.account.settings.forEach(setting => {
      setting.settings.forEach(setting => {
        if (setting.key === 'autoPayRestricted' && setting.value === 'true') {
          this.settingSet = true;
          setting.extendedProperties.forEach(property => {
            if (property.key === 'setExpiryDate' && property.value === 'true') {
              this.expirySet = true;
            }
            if (property.key === 'expiryDate') {
              if (Date.parse(property.value) > Date.now()) {
                this.expiryDateSet = true;
              }
            }
          });

          if (this.settingSet && this.expirySet && this.expiryDateSet) {
            this.autoPayRevoked = true;
            this.autopayToggle.disable();
          } else if (this.settingSet && !this.expirySet) {
            this.autoPayRevoked = true;
            this.autopayToggle.disable();
          } else {
            this.autoPayRevoked = false;
            this.autopayToggle.enable();
          }
        }
      });
    });
  }

  // TODO: get rid of document call and remove elem argument
  onCloseHandledAPModal(elem: string) {
    this.onCloseHandled();
    document.getElementById(elem).style.display = 'none';
  }

  onCloseHandled() {
    this.autopayToggle.reset(!this.checked, { emitEvent: false });
    this.agreementToggle.reset(false);
    this.agreeChecked = false;
  }

  async reload(url: string): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigateByUrl(url);
  }

  getDefaultOrFirstPayMethod(account: PayDetail): PayMethod {
    this.getAccountAchRestriction(account);
    for (let payMethod of account.payMethods) {
      if (payMethod.isDefault === 'true') {
        if (this.hasACHRestriction && payMethod.type != 'creditcard') {
          this.autopayToggle.disable();
        }
        return payMethod;
      }
    }
    return account.payMethods[0];
  }

  getAccountAchRestriction(account: PayDetail) {
    let settingsElement: any[] = [];
    if (account.settings.length > 0) {
      for (let settings of account.settings) {
        settingsElement = settings.settings.filter(obj => {
          return obj.key === 'paymentRestricted';
        });
      }
      if (settingsElement.length > 0 && settingsElement[0].value == 'true') {
        this.hasACHRestriction = true;
      }
    }
  }
}
