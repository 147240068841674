import { Component, OnInit } from '@angular/core';
import { HealthCheckerService } from '@core/services/health-checker.service';
import {
  SystemStatus,
  SystemStatusOffline,
  SystemStatusOnline
} from '@shared/models/systemStatus';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.css']
})
export class SystemStatusComponent implements OnInit {
  systemStatus: SystemStatus = new SystemStatus();
  initialStatus: boolean;
  body: string;

  constructor(
    private healthCheck: HealthCheckerService,
    private spinner: NgxSpinnerService,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit(): void {
    this.getSystemStatus();
  }

  getSystemStatus(): void {
    this.healthCheck.getSystemStatus().subscribe(
      data => {
        this.systemStatus = data;
        this.initialStatus = data.isPortalUp;
      },
      error => {
        const message = `There was a problem retrieving the system status: ${error.error}`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  onFormSubmit(result: SystemStatus) {
    this.spinner.show();
    if (result.isPortalUp) {
      this.takeSystemOnline(result);
    } else {
      this.takeSystemOffline(result);
    }
  }

  takeSystemOnline(result: SystemStatus) {
    const systemStatus = new SystemStatusOnline();
    systemStatus.portalUpMessage = result.portalUpMessage;
    systemStatus.dashboardMessage = result.dashboardMessage;

    this.healthCheck.takeSystemOnline(systemStatus).subscribe(
      response => {
        this.updateSystemStatus(result);
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(response);
      },
      error => {
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(error.error);
      }
    );
  }

  takeSystemOffline(result: SystemStatus) {
    const systemStatus = new SystemStatusOffline();
    systemStatus.incidentType = result.incidentType;
    systemStatus.incidentDescription = result.incidentDescription;

    this.healthCheck.takeSystemOffline(systemStatus).subscribe(
      response => {
        this.updateSystemStatus(result);
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(response);
      },
      error => {
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(error.error);
      }
    );
  }

  updateSystemStatus(updatedSystemStatus: SystemStatus) {
    this.systemStatus = updatedSystemStatus;
    this.initialStatus = updatedSystemStatus.isPortalUp;
  }
}
