import { Component, ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  template: `<h1>Page not found</h1>
    <p>This is a Error Page</p>
    <p>{{ errorMessage }}</p> `
})
@Injectable()
export class ErrorComponent implements ErrorHandler {
  errorMessage: string;
  constructor(private injector: Injector) {}
  handleError(error: any) {
    // const errorService = this.injector.get(ErrorService);
    // const logger = this.injector.get(LoggingService);
    // const notifier = this.injector.get(NotificationService);
    let message;
    let stackTrace;
    if (error instanceof HttpErrorResponse) {
      // Server error
      //message = errorService.getServerErrorMessage(error);
      //stackTrace = errorService.getServerErrorStackTrace(error);
      //notifier.showError(message);
      this.errorMessage = 'error code: ' + error.error + '--' + error.message;
    } else {
      // Client Error
      //message = errorService.getClientErrorMessage(error);
      //notifier.showError(message);
      this.errorMessage = 'error code: ' + error.name + '--' + error.message;
    }
    // Always log errors
    //logger.logError(message, stackTrace);
    //console.error(error);
  }
}
