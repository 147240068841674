import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { AccountService } from '@core/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ResetPassword } from '@shared/models/resetPassword';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '@core/services/global.service';
import { GlobalConstants } from '@shared/constants/global-constants';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { Location } from '@angular/common';
import { mustMatch } from '@shared/validators/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  confirm = false;
  newPassword;
  confirmNewPassword;
  currentEmail: string;
  emulate = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private globalService: GlobalService,
    private modalDialogService: ModalDialogService,
    private location: Location
  ) {}

  ngOnInit() {
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.initForm(this.formBuilder);
    this.currentEmail = this.route.snapshot.paramMap.get('emailaddress');
    this.f.currentEmail.setValue(this.currentEmail);
  }

  initForm(formBuilder: FormBuilder) {
    this.resetForm = this.formBuilder.group(
      {
        currentEmail: new FormControl('', [
          Validators.required,
          Validators.pattern(GlobalConstants.emailRegex)
        ]),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8)
        ]),
        confirmNewPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8)
        ])
      },
      {
        validators: [mustMatch('newPassword', 'confirmNewPassword')]
      }
    );
  }

  get f() {
    return this.resetForm.controls;
  }

  onSubmit() {
    this.confirm = true;
    if (this.resetForm.invalid) {
      return;
    }

    this.spinner.show();
    this.newPassword = this.f.newPassword.value;
    this.confirmNewPassword = this.f.confirmNewPassword.value;

    const g: ResetPassword = new ResetPassword(
      this.currentEmail,
      this.newPassword
    );

    this.resetPassword(g).subscribe(
      (data: ResetPassword) => {
        const message = 'Your password has been updated successfully.';
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(
          message,
          '',
          'Dismiss',
          () => {
            this.goBack();
          }
        );
      },
      error => {
        console.log('Failed to change the password.');
        const message = error.error;
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.accountService.resetPassword(resetPassword);
  }

  onCancel() {
    this.confirm = false;
    this.router.navigate(['/dashboard']);
  }

  goBack() {
    this.location.back();
  }
}
