export class ChangeEmail {
  constructor(oldEmail: string, newEmail: string, resendActivation: boolean) {
    this.OldEmail = oldEmail;
    this.NewEmail = newEmail;
    this.ResendActivation = resendActivation;
  }

  OldEmail: string;
  NewEmail: string;
  ResendActivation: boolean;
}
