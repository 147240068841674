import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { GlobalService } from '@core/services/global.service';
import { IdentityService } from '@core/services/identity.service';
import { UtilityService } from '@core/services/utility.service';

declare let FreshworksWidget: any;

@Component({
  selector: 'app-emulate',
  templateUrl: './emulate.component.html',
  styleUrls: ['./emulate.component.sass']
})
export class EmulateComponent implements OnInit {
  userInfo;
  @Input() emulateUser;

  constructor(
    private router: Router,
    private identity: IdentityService,
    private authService: MsalService,
    private globalService: GlobalService,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    if (this.globalService.has('EmulatingUser')) {
      this.emulateUser = this.globalService.get('EmulatingUser');
    }
    this.userInfo = this.authService.instance.getActiveAccount()?.idTokenClaims;
  }

  stopEmulation() {
    this.globalService.remove('EmulatingUser');
    this.globalService.remove(this.globalService.DETAIL);
    this.globalService.remove('ACCOUNT_LISTING');
    this.globalService.remove(this.globalService.COMPANYNAME);
    this.globalService.remove('accountType');
    this.globalService.set('USER_PREFERENCE', []);
    this.globalService.set(
      this.identity.USER_LEVEL_ROLE_PERM,
      this.globalService.get('EmulatorUser')
    );
    this.globalService.remove('EmulatorUser');    
    this.utilityService.notifyHeaderProfile();
    this.router.navigate(['/admin']);
  }
}
