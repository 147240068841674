import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '@core/services/global.service';
import { AccountView } from '@shared/models/accountView';
import { AccountService } from '@core/services/account.service';
import { UtilityService } from '@core/services/utility.service';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { BillingTypes } from '@shared/enums/BillingTypes';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  accountId;
  utility;
  accountDetail$: AccountView;
  emulate = false;
  accessType: AccessTypes;
  isUCB: boolean;

  constructor(
    private accountService: AccountService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    const detail: string = this.globalService.get(this.globalService.DETAIL);

    this.accountId = detail?.split('/')[0];
    this.utility = detail?.split('/')[1];
    if (!this.accountId && !this.utility) {
      console.log('Missing parameters to execute this page');
      throw 'Missing parameters to execute this page';
    }

    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }

    // load all accountDetail
    this.spinner.show();
    this.getAccountDetails();
  }

  getAccountDetails() {
    this.accountService.getAccountView(this.accountId, this.utility).subscribe(
      data => {
        this.accountDetail$ = data;
        this.isUCBAccount(this.accountDetail$.invoice.billMethod);
        this.accessType = this.utilityService.convertToAccessType(
          this.accountDetail$.account.accessType
        );
        const obj = {
          engieAccountId: this.accountDetail$.account['engieAccountId'],
          billingAccount: this.accountDetail$.account['billingAccount'],
          accessType: this.accessType
        };
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.spinner.hide();
      },
      error => {
        console.log('failed to get Account Details');
        this.spinner.hide();
      }
    );
  }

  isUCBAccount(billMethod: string) {
    switch (billMethod) {
      case BillingTypes.BillReady:
        this.isUCB = true;
        break;
      case BillingTypes.RateReady:
        this.isUCB = true;
        break;
      default:
        this.isUCB = false;
    }
  }
}
