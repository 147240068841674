import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { AdminService } from '@core/services/admin.service';
import { ChangeEmail } from '@shared/models/changeEmail';
import { GlobalService } from '@core/services/global.service';
import { MsalService } from '@azure/msal-angular';
import { GlobalConstants } from '@shared/constants/global-constants';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent implements OnInit {
  changeEmailForm: FormGroup;
  confirm = false;
  existingEmail: string;
  newEmail: string;
  confirmNewEmail: string;
  body: string;
  emulate = false;
  isUsersOwnLogin: boolean;
  resendNotification = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private location: Location,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private authService: MsalService,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit(): void {
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.initForm(this.formBuilder);
    const profile: any = this.authService.instance.getActiveAccount()
      ?.idTokenClaims;
    this.isUsersOwnLogin = profile.email === this.existingEmail ? true : false;
  }

  initForm(formBuilder: FormBuilder) {
    this.changeEmailForm = this.formBuilder.group({
      existingEmail: new FormControl('', [
        Validators.required,
        Validators.pattern(GlobalConstants.emailRegex)
      ]),
      newEmail: new FormControl(
        '',
        [Validators.required, Validators.pattern(GlobalConstants.emailRegex)],
        // @ts-ignore
        this.newEmailMatchValidator.bind(this)
      ),
      confirmNewEmail: new FormControl(
        '',
        [Validators.required, Validators.pattern(GlobalConstants.emailRegex)],
        // @ts-ignore
        this.confirmEmailMatchValidator.bind(this)
      ),
      resendNotification: new FormControl()
    });

    this.existingEmail = this.route.snapshot.paramMap.get('emailaddress');
    this.f.existingEmail.setValue(
      this.route.snapshot.paramMap.get('emailaddress')
    );
    const resend = this.route.snapshot.paramMap.get('resend');
    this.resendNotification =
      resend !== null && resend === 'resend' ? true : false;
    this.f.resendNotification.setValue(this.resendNotification);
  }

  newEmailMatchValidator(g: FormGroup) {
    if (this.f.newEmail.value === this.f.confirmNewEmail.value) {
      this.f.confirmNewEmail.setErrors(null);
      if (this.f.existingEmail.value !== this.f.newEmail.value) {
        this.f.newEmail.setErrors(null);
        return null;
      } else {
        this.f.newEmail.setErrors({ match: true });
        return { match: true };
      }
    }
    this.f.newEmail.setErrors({ mismatch: true });
    return { mismatch: true };
  }

  confirmEmailMatchValidator(g: FormGroup) {
    if (this.f.newEmail.value === this.f.confirmNewEmail.value) {
      this.f.newEmail.setErrors(null);
      if (this.f.existingEmail.value !== this.f.newEmail.value) {
        this.f.confirmNewEmail.setErrors(null);
        return null;
      } else {
        this.f.newEmail.setErrors({ match: true });
        return { match: true };
      }
    }

    this.f.confirmNewEmail.setErrors({ mismatch: true });
    return { mismatch: true };
  }

  get f() {
    return this.changeEmailForm.controls;
  }

  onSubmit() {
    this.confirm = true;
    if (
      this.changeEmailForm.invalid ||
      this.f.existingEmail.value === this.f.newEmail.value
    ) {
      return;
    }

    let message = '';
    if (this.isUsersOwnLogin) {
      message =
        'Are you sure you want to change your email address? ' +
        'Once you save, you will be signed out and will need to sign-in again using your new email address.';
    } else {
      message =
        "Are you sure you want to change the user's email address? " +
        'Once you save, the user will need to sign-in using the new email address.';
    }
    this.modalDialogService.openWarningDialog(
      'Continue',
      () => {
        this.continue();
      },
      '',
      null,
      message
    );
  }

  closeSuccessMessage() {
    if (this.isUsersOwnLogin) {
      this.authService.logout();
    } else if (this.emulate) {
      this.globalService.set('EmulatingUser', this.newEmail.toLowerCase());
      this.router.navigate(['/user-profile']);
    } else {
      this.router.navigate(['/admin']);
    }
  }

  continue() {
    this.spinner.show();
    this.newEmail = this.f.newEmail.value;
    this.confirmNewEmail = this.f.confirmNewEmail.value;
    const resendNotification = this.f.resendNotification.value;

    const g: ChangeEmail = new ChangeEmail(
      this.existingEmail,
      this.newEmail,
      resendNotification
    );

    this.changeEmail(g).subscribe(
      (data: ChangeEmail) => {
        let message = '';
        if (this.isUsersOwnLogin) {
          message =
            'Your email address has been successfully changed. You will now be logged out from the portal.';
        } else {
          message = 'The email address has been successfully changed.';
        }
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(
          message,
          '',
          'Dismiss',
          () => {
            this.closeSuccessMessage();
          }
        );
      },
      error => {
        console.log('Failed to change the email address.');
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(error.error);
      }
    );
  }

  changeEmail(changeEmail: ChangeEmail) {
    return this.adminService.changeEmail(changeEmail);
  }

  onCancel() {
    this.confirm = false;
    this.location.back();
  }
}
