import { ErrorHandler, Injectable } from '@angular/core';
import { LogContext } from '../../shared/enums/LogContext';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService extends ErrorHandler {
  constructor(private loggingService: LoggingService) {
    super();
  }

  /**
   * Wraps unhandled errors
   * @param error
   */
  handleError(error: Error) {
    console.error(
      'The following error has been sent to Application Insights:',
      error
    );
    this.loggingService.logException(error, LogContext.Unknown);
  }
}
