import { Component, Injectable, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { GlobalService } from '@core/services/global.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  constructor(
    private authService: MsalService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.logmeOut();
  }

  logmeOut() {
    this.globalService.clear();
    localStorage.clear();
    sessionStorage.clear();
    this.authService.logout();
  }
}
