import { Injectable, OnDestroy } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { tap, catchError, map } from 'rxjs/operators';
import { HttpHelperService } from '@core/services/http-helper.service';
import { SystemStatusAndIncidents } from '@shared/models/systemStatusAndIncidents';
import {
  SystemStatus,
  SystemStatusOffline,
  SystemStatusOnline
} from '@shared/models/systemStatus';
import { SslCertDetail } from '@shared/models/sslCertDetail';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckerService implements OnDestroy {
  constructor(private httpHelper: HttpHelperService) {}
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  public getTaxExceptions(billingServicePointId: string): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.GetTaxExemptionsByBillingServicePointId}/${billingServicePointId}`
      )
      .pipe(
        tap(_ => console.log(`getTaxExceptions = ${billingServicePointId}`)),
        catchError(response => throwError(response))
      );
  }

  public getPayMethods(accountId: string): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.GetPayMethods}/${accountId}`
      )
      .pipe(
        tap(_ => console.log(`getPayMethods = ${accountId}`)),
        catchError(response => throwError(response))
      );
  }

  public getSendEmail(): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.SendEmail}`
      )
      .pipe(
        tap(_ => console.log(`SendEmail`)),
        catchError(response => throwError(response))
      );
  }

  public getUsageHistory(
    servicePointNumber: string,
    year: string
  ): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.GetUsageHistory}/${servicePointNumber}/${year}`
      )
      .pipe(
        tap(_ =>
          console.log(`getUsageHistory = ${servicePointNumber}/${year}`)
        ),
        catchError(response => throwError(response))
      );
  }

  public getCustomerSearch(customerId: string): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.CustomerSearch}/${customerId}`
      )
      .pipe(
        tap(_ => console.log(`getCustomerSearch = ${customerId}`)),
        catchError(response => throwError(response))
      );
  }

  public getChargesServiceFee(
    paymethodType: string,
    amount: string
  ): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.ChargesServiceFee}/${paymethodType}/${amount}`
      )
      .pipe(
        tap(_ =>
          console.log(`getChargesServiceFee = ${paymethodType}/${amount}`)
        ),
        catchError(response => throwError(response))
      );
  }

  public getChargesPaymethodTypes(accountId: string): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.ChargesPaymethodTypes}/${accountId}`
      )
      .pipe(
        tap(_ => console.log(`getChargesPaymethodTypes = ${accountId}`)),
        catchError(response => throwError(response))
      );
  }

  public getStatementSearch(statementId: string): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.StatementSearchById}/${statementId}`
      )
      .pipe(
        tap(_ => console.log(`getStatementSearch = ${statementId}`)),
        catchError(response => throwError(response))
      );
  }

  public postStatementSearch(accountId: string): Observable<boolean> {
    return this.httpHelper
      .httpPostRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.StatementSearch}/${accountId}`,
        true
      )
      .pipe(
        tap(_ => console.log(`postStatementSearch = ${accountId}`)),
        catchError(response => throwError(response))
      );
  }

  public postServicePoint(accountId: string): Observable<boolean> {
    return this.httpHelper
      .httpPostRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.ServicePointAgreement}/${accountId}`,
        true
      )
      .pipe(
        tap(_ => console.log(`postServicePoint = ${accountId}`)),
        catchError(response => throwError(response))
      );
  }

  public postAccountSearch(accountId: string): Observable<boolean> {
    return this.httpHelper
      .httpPostRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.AccountSearch}/${accountId}`,
        true
      )
      .pipe(
        tap(_ => console.log(`postAccountSearch = ${accountId}`)),
        catchError(response => throwError(response))
      );
  }

  public getAccountById(accountId: string): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.GetAccountByID}/${accountId}`
      )
      .pipe(
        tap(_ => console.log(`getAccountById = ${accountId}`)),
        catchError(response => throwError(response))
      );
  }

  public getInvoiceSearch(statementId: string): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.InvoiceSearch}/${statementId}`
      )
      .pipe(
        tap(_ => console.log(`getInvoiceSearch = ${statementId}`)),
        catchError(response => throwError(response))
      );
  }

  public getPaymentSearch(accountId: string): Observable<boolean> {
    return this.httpHelper
      .httpGetRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.PaymentSearch}/${accountId}`
      )
      .pipe(
        tap(_ => console.log(`getPaymentSearch = ${accountId}`)),
        catchError(response => throwError(response))
      );
  }

  public postBillingAccountPatch(accountId: string): Observable<boolean> {
    return this.httpHelper
      .httpPostRequest<boolean>(
        `${environment.apimWebRoot}${environment.HealthCheck.BillingAccountPatch}/${accountId}`,
        true
      )
      .pipe(
        tap(_ => console.log(`postBillingAccountPatch = ${accountId}`)),
        catchError(response => throwError(response))
      );
  }

  public getSystemStatusAndIncidents(): Observable<SystemStatusAndIncidents> {
    return this.httpHelper
      .httpGetRequest<SystemStatusAndIncidents>(
        `${environment.apimWebRoot}${environment.HealthCheck.systemStatusAndIncidents}`
      )
      .pipe(
        map((response: SystemStatusAndIncidents) => response),
        catchError(response => throwError(response))
      );
  }

  public getSystemStatus(): Observable<SystemStatus> {
    return this.httpHelper
      .httpGetRequest<SystemStatus>(
        `${environment.apimWebRoot}${environment.systemStatus}`
      )
      .pipe(
        map((response: SystemStatus) => response),
        catchError(response => throwError(response))
      );
  }

  public takeSystemOnline(systemStatus: SystemStatusOnline) {
    return this.httpHelper
      .httpPostRequest(
        `${environment.apimWebRoot}${environment.systemOnline}`,
        systemStatus
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }

  public takeSystemOffline(systemStatus: SystemStatusOffline) {
    return this.httpHelper
      .httpPostRequest(
        `${environment.apimWebRoot}${environment.systemOffline}`,
        systemStatus
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }

  getCertificates(url: string): Observable<SslCertDetail> {
    const payload = {
      url: url,
      issueDate: '',
      expiryDate: '',
      issuerName: '',
      isValid: false
    };
    return this.httpHelper
      .httpPostRequest<SslCertDetail>(
        `${environment.apimWebRoot}${environment.HealthCheck.SslCertificateDetail}`,
        payload
      )
      .pipe(
        tap(data => data),
        catchError(response => throwError(response))
      );
  }
}
