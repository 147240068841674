import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.css']
})
export class CreditCardFormComponent {
  CreditCardGroup: FormGroup;
  @Input() submitted: boolean;

  constructor(private formBuilder: FormBuilder) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.CreditCardGroup.controls;
  }

  createGroup() {
    if (this.CreditCardGroup === undefined) {
      this.CreditCardGroup = this.formBuilder.group({
        CardNumber: new FormControl(
          '',
          [Validators.required, Validators.minLength(13)],
          // @ts-ignore
          this.cardTypeValidator.bind(this)
        ),
        ExpirationDate: new FormControl('', [
          Validators.required,
          Validators.minLength(6)
        ]),
        CardVerification: new FormControl('', [
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(3)
        ])
      });
    }

    return this.CreditCardGroup;
  }

  cardTypeValidator(g: FormGroup) {
    if (this.CreditCardGroup !== undefined) {
      let supportedCardTypes: string[] = ['3', '5', '6']; //3-Amex; 5-Master; 6-Discover
      let firstCharOfCardNumber = this.f.CardNumber.value.substring(0, 1);
      if (supportedCardTypes.some(s => s.includes(firstCharOfCardNumber))) {
        this.f.CardNumber.setErrors(null);
        return null;
      } else {
        this.f.CardNumber.setErrors({ valid: true });
        return { valid: true };
      }
    }
  }
}
