import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { AccountService } from '@core/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ActivateUser } from '@shared/models/activateUser';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalConstants } from '@shared/constants/global-constants';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { mustMatch } from '@shared/validators/must-match.validator';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.sass']
})
export class ActivateAccountComponent implements OnInit {
  activateForm: FormGroup;
  confirm = false;
  key;
  emailAddress;
  newPassword;
  confirmNewPassword;
  firstname;
  preferredname;
  lastname;
  companyname;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit() {
    this.initForm(this.formBuilder);
    this.f.key.setValue(this.route.snapshot.paramMap.get('key'));
    const emailAddress = this.route.snapshot.paramMap.get('email');
    this.f.emailAddress.setValue(emailAddress.trim());
  }

  initForm(formBuilder: FormBuilder) {
    this.activateForm = this.formBuilder.group(
      {
        emailAddress: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8)
        ]),
        confirmNewPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8)
        ]),
        firstname: new FormControl('', [Validators.required]),
        lastname: new FormControl('', [Validators.required]),
        preferredname: new FormControl(''),
        companyname: new FormControl('', [Validators.required]),
        key: new FormControl()
      },
      {
        validators: [mustMatch('newPassword', 'confirmNewPassword')]
      }
    );
  }

  get f() {
    return this.activateForm.controls;
  }

  onSubmit() {
    this.confirm = true;

    if (this.activateForm.invalid) {
      return;
    }

    this.spinner.show();

    this.key = this.f.key.value;
    this.emailAddress = this.f.emailAddress.value;
    this.newPassword = this.f.newPassword.value;
    this.confirmNewPassword = this.f.confirmNewPassword.value;
    this.firstname = this.f.firstname.value;
    this.lastname = this.f.lastname.value;
    this.preferredname = this.f.preferredname.value;
    this.companyname = this.f.companyname.value;

    const user: ActivateUser = new ActivateUser(
      this.emailAddress,
      this.newPassword,
      this.firstname,
      this.lastname,
      this.preferredname,
      this.companyname,
      this.key
    );

    this.activateUser(user).subscribe(
      (data: ActivateUser) => {
        const message = 'Your account has been activated.';
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(
          message,
          '',
          'Sign In',
          () => {
            this.backToSignIn();
          }
        );
      },
      error => {
        console.log('Failed to activate the account.');
        const message = error.error;
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  activateUser(activateUser: ActivateUser) {
    return this.accountService.activateUser(activateUser);
  }

  backToSignIn() {
    this.router.navigate([`/`]);
  }
}
