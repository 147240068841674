import { Component, Input, OnInit } from '@angular/core';
import { AccountView } from '@shared/models/accountView';
import { IdentityService } from '@core/services/identity.service';
import { UtilityService } from '@core/services/utility.service';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { AccountShared } from '../account-shared';
import { BillingTypes } from '@shared/enums/BillingTypes';
import { DownloadedFileDetails } from '@shared/models/downloadedFileDetails';

@Component({
  selector: 'app-account-header-ucb',
  templateUrl: './account-header-ucb.component.html',
  styleUrls: ['./account-header-ucb.component.css']
})
export class AccountHeaderUcbComponent extends AccountShared implements OnInit {
  @Input() accountDetail: AccountView;
  @Input() accessType: AccessTypes;

  constructor(
    private headerUtilityService: UtilityService,
    private headerIdentityService: IdentityService
  ) {
    super(headerIdentityService, headerUtilityService);
  }

  ngOnInit() {
    this.baseAccessType = this.accessType;
  }

  downloadBackingSheet() {
    this.headerUtilityService.downloadBackingSheet(
      new DownloadedFileDetails(
        this.accountDetail.servicePointAgreement.utilityAccountNumber,
        this.accountDetail.account.engieAccountId,
        null,
        this.accountDetail.invoice.engieInvoiceId,
        this.accountDetail.account.name
      ),
      false,
      true
    );
  }

  enableBackingSheet(): boolean {
    return this.accountDetail.invoice.billMethod === BillingTypes.BillReady
      ? true
      : false;
  }
}
