import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '@core/services/admin.service';
import { IdentityService } from '@core/services/identity.service';
import { GlobalService } from '@core/services/global.service';
import { ButtonCellRendererComponent } from '@shared/components/button-cell-renderer.component';
import { UserSearch } from '@shared/models/userSearch';
import { User } from '@shared/models/user';
import { Permission } from '@shared/enums/Permission';
import { Role } from '@shared/enums/Role';
import { UtilityService } from '@core/services/utility.service';
import { BaseGridComponentComponent } from '@shared/components/base-grid-component/base-grid-component.component';
import { GlobalConstants } from '@shared/constants/global-constants';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { UserStatusTypes } from '@shared/enums/UserStatusTypes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-administration',
  templateUrl: './user-administration.component.html',
  styleUrls: ['./user-administration.component.css']
})
export class UserAdministrationComponent
  extends BaseGridComponentComponent
  implements OnInit {
  @Output() submitted = new EventEmitter<string>();

  isAuthenticated: boolean;
  userName: string;
  public loadingMessage = 'Loading...';
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  columnDefs;
  frameworkComponents;
  defaultColDef;
  detailCellRendererParams;
  users$: User[];
  rowSelection;
  paginationPageSize = GlobalConstants.gridDefaultPageSize;
  paginationNumberFormatter;
  pageSize;
  canSearch: boolean;
  canAddUser: boolean;
  usersEmailAddress: string;
  usersBillingAccountNo: string;
  usersUtilityAccountNo: string;
  body: string;
  continueOption: string;
  rowParams: any;
  adminForm: FormGroup;
  excelStyles: any;
  domLayout;
  gridRowHeight = GlobalConstants.gridRowHeight;

  constructor(
    private authService: MsalService,
    private adminService: AdminService,
    private globalService: GlobalService,
    private identityService: IdentityService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private modalDialogService: ModalDialogService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.isAuthenticated = this.authService.instance.getActiveAccount() != null;
    this.canSearch = this.checkSearch(this);
    this.canAddUser = this.checkAddUser(this);
    this.createColumnDefs();
    this.initForm(this.formBuilder);
  }

  initForm(formBuilder: FormBuilder) {
    this.adminForm = this.formBuilder.group({
      usersEmailAddress: new FormControl(''),
      usersBillingAccountNo: new FormControl(''),
      usersUtilityAccountNo: new FormControl('')
    });
  }

  get f() {
    return this.adminForm.controls;
  }

  searchSpecificUsers(event: any) {
    this.resetSearchFields();
    if (
      this.usersEmailAddress === '' &&
      this.usersBillingAccountNo === '' &&
      this.usersUtilityAccountNo === ''
    ) {
      return;
    }

    const userSearch = new UserSearch(
      this.usersEmailAddress,
      this.usersBillingAccountNo,
      this.usersUtilityAccountNo
    );
    this.getUsersData(userSearch);
  }

  resetSearchFields() {
    this.usersEmailAddress =
      this.usersEmailAddress === undefined ? '' : this.usersEmailAddress.trim();
    this.usersBillingAccountNo =
      this.usersBillingAccountNo === undefined
        ? ''
        : this.usersBillingAccountNo.trim();
    this.usersUtilityAccountNo =
      this.usersUtilityAccountNo === undefined
        ? ''
        : this.usersUtilityAccountNo.trim();

    this.f.usersEmailAddress.setValue(this.usersEmailAddress);
    this.f.usersBillingAccountNo.setValue(this.usersBillingAccountNo);
    this.f.usersUtilityAccountNo.setValue(this.usersUtilityAccountNo);
  }

  getUsersData(userSearch: UserSearch) {
    this.spinner.show();
    this.adminService.getUsers(userSearch).subscribe(
      result => {
        this.users$ = result;
        this.setGridHeight(
          'userGrid',
          this.gridApi,
          GlobalConstants.gridMinimumHeight,
          GlobalConstants.gridDefaultPageSize,
          this.users$.length
        );
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        const message = `Failed to get users - ${error.error}`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  onPaginationChanged(params) {
    if (this.users$ !== undefined) {
      this.setGridHeight_OnPaginationChanged(
        'userGrid',
        this.gridApi,
        GlobalConstants.gridMinimumHeight,
        GlobalConstants.gridDefaultPageSize,
        params
      );
    }
  }

  disableBillingAccountNo() {
    if (
      this.usersUtilityAccountNo !== undefined &&
      this.usersUtilityAccountNo !== ''
    ) {
      return true;
    }
  }

  disableUtilityAccountNo() {
    if (
      this.usersBillingAccountNo !== undefined &&
      this.usersBillingAccountNo !== ''
    ) {
      return true;
    }
  }

  checkSearch(arg): boolean {
    return arg.identityService.hasPermission(Permission.Search_User);
  }

  checkAddUser(arg): boolean {
    return arg.identityService.hasPermission(Permission.Add_User);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout('normal');
    this.gridApi.setHeaderHeight(GlobalConstants.gridHeaderHeight);
    document.getElementById('userGrid').style.height =
      GlobalConstants.gridMinimumHeight;
  }

  onColumnResized() {
    this.gridApi.resetRowHeights();
  }

  onQuickSearchChanged(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  copyIcon(params) {
    const userWasActivated =
      params.node.data.status !== UserStatusTypes.Inactive;
    const divTag = document.createElement('div');
    if (userWasActivated) {
      divTag.innerHTML = params.value;
    } else {
      this.utilityService.fieldWithCopyButton(
        divTag,
        'Inactive',
        params.data.activationLink,
        'Copy activation link to clipboard'
      );
    }
    return divTag;
  }

  ellipse(params) {
    const htmlString = `<i class='material-icons md-36 md--blue-engie'>more_horiz</i>`;
    const divTag = document.createElement('span');
    divTag.innerHTML = htmlString.trim();
    return divTag;
  }

  onBtStartEditing(key, char, pinned) {
    this.gridApi.setFocusedCell(0, 'lastName', pinned);
    this.gridApi.startEditingCell({
      rowIndex: 0,
      colKey: 'lastName',
      rowPinned: pinned,
      keyPress: key,
      charPress: char
    });
  }

  onBtStopEditing() {
    this.gridApi.stopEditing();
  }

  dateFormatter(params): string {
    if (params.value) {
      return formatDate(params.value, 'MM/dd/yyyy h:mm a', 'en-US');
    }
  }

  getContextMenuItems = params => {
    const usersEmailAddress = params.node.data.emailAddress;
    const usersStatus = params.node.data.status;
    const userWasActivated = usersStatus !== UserStatusTypes.Inactive;
    let canEmulate = false;
    if (
      this.identityService.hasPermission(Permission.Emulate_User) &&
      (params.node.data.role === Role.Partner ||
        params.node.data.role === Role.Customer)
    ) {
      canEmulate = true;
    }
    const canEditProfile = this.identityService.hasPermission(
      Permission.Edit_Profile
    );
    const canEditEmail = this.identityService.hasPermission(
      Permission.Edit_Profile
    );
    const canEditPassword =
      this.identityService.hasPermission(Permission.Edit_Profile) &&
      userWasActivated;
    const canEnableUser =
      this.identityService.hasPermission(Permission.Enable_User) &&
      usersStatus === UserStatusTypes.Disabled;
    const canDisableUser =
      this.identityService.hasPermission(Permission.Disable_User) &&
      usersStatus === UserStatusTypes.Active;
    const canResendActivationEmail =
      this.identityService.hasPermission(Permission.Add_User) &&
      usersStatus === UserStatusTypes.Inactive;
    const canDeleteUser =
      this.identityService.hasPermission(Permission.Delete_User) &&
      (usersStatus === UserStatusTypes.Disabled ||
        usersStatus === UserStatusTypes.Inactive);
    const canChangeRole = this.identityService.hasPermission(
      Permission.Change_Role
    );

    const emulateObj = {
      name: 'Emulate User',
      action: () => {
        this.globalService.set('EmulatingUser', usersEmailAddress);
        this.globalService.set(
          'EmulatorUser',
          this.globalService.get('USER_LEVEL_ROLE_PERM')
        );
        this.globalService.set(
          this.globalService.COMPANYNAME,
          params.node.data.companyName
        );
        this.utilityService.notifyHeaderProfile();
        this.router.navigate(['/dashboard']);
      }
    };
    const editProfileObj = {
      name: 'Edit User Profile',
      action: () => {
        this.clearSearchFields();
        this.router.navigate([`user-profile/${usersEmailAddress}`]);
      }
    };
    const editEmailObj = {
      name: "Change User's Email Address",
      action: () => {
        this.clearSearchFields();
        if (userWasActivated) {
          this.router.navigate([`email/${usersEmailAddress}/change`]);
        } else {
          this.router.navigate([`email/${usersEmailAddress}/change/resend`]);
        }
      }
    };
    const editPasswordObj = {
      name: "Change User's Password",
      action: () => {
        this.clearSearchFields();
        this.router.navigate([`reset-pswd/${usersEmailAddress}`]);
      }
    };
    const enableUserObj = {
      name: 'Enable User',
      action: () => {
        this.continueOption = 'enable';
        this.rowParams = params;
        const message = 'Are you sure you want to enable this user?';
        this.modalDialogService.openWarningDialog(
          'Continue',
          () => {
            this.continue();
          },
          '',
          null,
          message
        );
      }
    };
    const disableUserObj = {
      name: 'Disable User',
      action: () => {
        this.continueOption = 'disable';
        this.rowParams = params;
        const message = 'Are you sure you want to disable this user?';
        this.modalDialogService.openWarningDialog(
          'Continue',
          () => {
            this.continue();
          },
          '',
          null,
          message
        );
      }
    };
    const resendActivationUserObj = {
      name: 'Resend Activation Key',
      action: () => {
        this.continueOption = 'resend';
        this.rowParams = params;
        const message = 'Are you sure you want to resend the activation key?';
        this.modalDialogService.openWarningDialog(
          'Continue',
          () => {
            this.continue();
          },
          '',
          null,
          message
        );
      }
    };
    const deleteUserObj = {
      name: 'Delete User',
      action: () => {
        this.continueOption = 'delete';
        this.rowParams = params;
        const message =
          'This will permanently delete the user from the portal. ' +
          'Are you sure you want to delete this user?';
        this.modalDialogService.openWarningDialog(
          'Continue',
          () => {
            this.continue();
          },
          '',
          null,
          message
        );
      }
    };
    const changeRoleObj = {
      name: "Change User's Role",
      action: () => {
        this.clearSearchFields();
        this.router.navigate([`user/${usersEmailAddress}/role`]);
      }
    };

    const result = [];
    if (canEmulate) {
      result.push(emulateObj);
    }
    if (canEditProfile) {
      result.push(editProfileObj);
    }
    if (canEditEmail) {
      result.push(editEmailObj);
    }
    if (canEditPassword) {
      result.push(editPasswordObj);
    }
    // if (canChangeRole) {
    //   result.push(changeRoleObj);
    // }
    if (canEnableUser) {
      result.push(enableUserObj);
    }
    if (canDisableUser) {
      result.push(disableUserObj);
    }
    if (canResendActivationEmail) {
      result.push(resendActivationUserObj);
    }
    if (canDeleteUser) {
      result.push(deleteUserObj);
    }

    if (params.column.colId === 'ellipsis') {
      return result;
    }
  };

  clearSearchFields() {
    this.f.usersEmailAddress.setValue('');
    this.f.usersBillingAccountNo.setValue('');
    this.f.usersUtilityAccountNo.setValue('');
  }

  continue() {
    const usersEmailAddress = this.rowParams.node.data.emailAddress;
    switch (this.continueOption) {
      case 'enable':
        this.continueEnableUser(usersEmailAddress);
        break;
      case 'disable':
        this.continueDisableUser(usersEmailAddress);
        break;
      case 'resend':
        this.continueResendActivation(usersEmailAddress);
        break;
      case 'delete':
        this.continueDeleteUser(usersEmailAddress);
        break;
    }
  }

  continueEnableUser(usersEmailAddress: string) {
    this.spinner.show();
    this.activateUser(usersEmailAddress).subscribe(
      (data: string) => {
        const newRow = this.rowParams.node.data;
        newRow.status = UserStatusTypes.Active;
        const refreshParams = {
          column: 'status'
        };
        this.rowParams.api.refreshCells(refreshParams);
        this.spinner.hide();
        const message = 'The user has been successfully enabled.';
        this.modalDialogService.openSuccessDialog(message);
      },
      error => {
        this.spinner.hide();
        const message = `Failed to enable the user - ${error.error}`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  continueDisableUser(usersEmailAddress: string) {
    this.spinner.show();
    this.deactivateUser(usersEmailAddress).subscribe(
      (data: string) => {
        const message = 'The user has been successfully disabled.';
        const newRow = this.rowParams.node.data;
        newRow.status = UserStatusTypes.Disabled;
        const refreshParams = {
          column: 'status'
        };
        this.rowParams.api.refreshCells(refreshParams);
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(message);
      },
      error => {
        this.spinner.hide();
        const message = `Failed to disable the user - ${error.error}`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  continueResendActivation(usersEmailAddress: string) {
    this.spinner.show();
    this.resendActivationEmail(usersEmailAddress).subscribe(
      (data: string) => {
        this.spinner.hide();
        const message = 'The activation key has been sent successfully.';
        this.modalDialogService.openSuccessDialog(message);
      },
      error => {
        this.spinner.hide();
        const message = `Failed to resend the activation key - ${error.error}`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  continueDeleteUser(usersEmailAddress: string) {
    this.spinner.show();
    this.deleteUser(usersEmailAddress).subscribe(
      (data: string) => {
        this.spinner.hide();
        const deletedRow = this.rowParams.node.data;
        this.gridApi.applyTransaction({ remove: [deletedRow] });
        const message = 'The user has been successfully deleted.';
        this.modalDialogService.openSuccessDialog(message);
      },
      error => {
        this.spinner.hide();
        const message = `Failed to delete the user - ${error.error}`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  activateUser(email: string) {
    return this.adminService.activateUser(email);
  }

  deactivateUser(email: string) {
    return this.adminService.deactivateUser(email);
  }

  resendActivationEmail(email: string) {
    return this.adminService.resendActivationEmail(email);
  }

  deleteUser(email: string) {
    return this.adminService.deleteUser(email);
  }

  addUser() {
    this.router.navigate([`user`]);
  }

  onPageSizeChanged(selectedValue: number) {
    const value = selectedValue;
    this.paginationPageSize = Number(value);
    this.gridApi.paginationSetPageSize(Number(value));
  }

  private createGridOpts() {
    this.excelStyles = [
      {
        id: 'textFormat',
        dataType: 'string'
      }
    ];
  }

  exportToExcel() {
    const params = {
      columnWidth: 100,
      sheetName: 'EventLog',
      processCellCallback: this.processExportedCell
    };
    this.gridApi.exportDataAsExcel(params);
  }

  processExportedCell(params) {
    if (
      params.column.colId === 'createdDateTime' ||
      params.column.colId === 'lastSignInDateTime'
    ) {
      if (params.value != null) {
        return formatDate(params.value, 'medium', 'en-US');
      }
      return null;
    } else if (
      params.column.colId === 'status_1' ||
      params.column.colId === 'status_2'
    ) {
      return null;
    } else {
      return params.value;
    }
  }

  private createColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'userId',
        hide: true
      },
      {
        headerName: 'NAME',
        field: 'displayName',
        sortable: true,
        editable: false,
        flex: 3,
        minWidth: 150,
        filter: true,
        hide: false,
        cellStyle: { justifyContent: 'left' },
        resizable: true
      },
      {
        headerName: 'EMAIL',
        field: 'emailAddress',
        sortable: true,
        editable: false,
        flex: 4,
        minWidth: 150,
        filter: true,
        hide: false,
        cellStyle: { justifyContent: 'left' },
        resizable: true
      },
      {
        headerName: 'ROLE',
        field: 'role',
        sortable: true,
        editable: false,
        filter: true,
        minWidth: 120,
        maxWidth: 120,
        cellStyle: { justifyContent: 'left' },
        hide: false,
        resizable: true
      },
      {
        headerName: 'STATUS',
        field: 'status',
        sortable: true,
        editable: false,
        filter: true,
        minWidth: 100,
        maxWidth: 100,
        cellStyle: { justifyContent: 'left' },
        hide: false,
        resizable: true,
        cellRenderer: this.copyIcon.bind(this)
      },
      {
        headerName: 'CREATION DATE',
        field: 'createdDateTime',
        sortable: true,
        editable: false,
        minWidth: 150,
        maxWidth: 150,
        filter: true,
        hide: false,
        cellStyle: { justifyContent: 'left' },
        resizable: true,
        valueFormatter: this.dateFormatter
      },
      {
        headerName: 'LAST SIGN IN',
        field: 'lastSignInDateTime',
        sortable: true,
        editable: false,
        minWidth: 150,
        maxWidth: 150,
        filter: true,
        hide: false,
        cellStyle: { justifyContent: 'left' },
        resizable: true,
        valueFormatter: this.dateFormatter
      },
      {
        headerName: '',
        field: 'ellipsis',
        minWidth: 50,
        maxWidth: 50,
        sortable: false,
        filter: false,
        resizable: true,
        cellRenderer: this.ellipse,
        onCellClicked(params: any): void {
          const rowIndex = params.rowIndex;
          const rowNode = params.node;
          const column = params.column;
          const mouseEvent = params.event;
          const value = params.value;
          const cell = params.api.context.beanWrappers.rowRenderer.beanInstance.rowConsByRowIndex[
            rowIndex
          ].getRenderedCellForColumn(column);
          cell.beans.contextMenuFactory.showMenu(
            rowNode,
            column,
            value,
            mouseEvent
          );
        }
      }
    ];

    this.rowSelection = 'single';
    this.frameworkComponents = {
      buttonCellRendererComponent: ButtonCellRendererComponent
    };
  }
}
