export class UserSearch {
  constructor(
    emailAddress: string,
    billingAccountNumber: string,
    utilityAccountNumber: string
  ) {
    this.EmailAddress = emailAddress;
    this.BillingAccountNo = billingAccountNumber;
    this.UtilityAccountNo = utilityAccountNumber;
  }

  EmailAddress: string;
  BillingAccountNo: string;
  UtilityAccountNo: string;
}
