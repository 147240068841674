import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSettingComponent } from './components/account-setting/account-setting.component';
import { AddAccountSettingComponent } from './components/account-setting/add-account-setting/add-account-setting.component';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { AdminComponent } from './components/admin/admin.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { AccountAccessManagementComponent } from './components/account-access-management/account-access-management.component';
import { EventLogComponent } from './components/event-log/event-log.component';
import { FreshdeskViewerComponent } from './components/freshdesk-viewer/freshdesk-viewer.component';
import { NewUserComponent } from './components/new-user/new-user.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { UserAdministrationComponent } from './components/user-administration/user-administration.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { SharedModule } from '../shared/shared.module';
import { SystemStatusComponent } from './components/system-status/system-status.component';
import { OnlineFormComponent } from './components/system-status/online-form/online-form.component';
import { OfflineFormComponent } from './components/system-status/offline-form/offline-form.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AccountSettingComponent,
    AddAccountSettingComponent,
    ActivateAccountComponent,
    AdminComponent,
    ChangeEmailComponent,
    AccountAccessManagementComponent,
    EventLogComponent,
    FreshdeskViewerComponent,
    NewUserComponent,
    ResetPasswordComponent,
    UserAdministrationComponent,
    UserProfileComponent,
    SystemStatusComponent,
    OnlineFormComponent,
    OfflineFormComponent
  ],
  imports: [RouterModule, CommonModule, SharedModule]
})
export class AdminModule {}
