import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { AccountService } from '@core/services/account.service';
import { GlobalService } from '@core/services/global.service';
import { GridApi, GridOptions, ColumnApi } from 'ag-grid-community';
import { Router } from '@angular/router';
import { AccountOneTMany } from '@shared/models/accountOneTMany';
import { BaseGridComponentComponent } from '@shared/components/base-grid-component/base-grid-component.component';
import { IdentityService } from '@core/services/identity.service';
import { Permission } from '@shared/enums/Permission';
import { Ownership } from '@shared/models/ownership';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '@core/services/utility.service';
import { UserPreferenceService } from '@core/services/user-preference.service';
import { GridOption } from '@shared/models/userPreference/gridOption';
import { CustomTooltipComponent } from '@shared/components/grid-custom-tooltip/custom-tooltip.component';
import Collapse from '@engie-group/fluid-design-system/lib-esm/components/collapse';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { AccessPermission } from '@shared/enums/AccessPermission';
import { DownloadedFileDetails } from '@shared/models/downloadedFileDetails';
import {
  SelectedRows,
  SelectedRowsMap,
  SelectedRowsMapWithAccessType
} from '@shared/models/selectedRows';
import { BillingTypes } from '@shared/enums/BillingTypes';
import { GlobalConstants } from '@shared/constants/global-constants';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.sass']
})
export class AccountListComponent
  extends BaseGridComponentComponent
  implements OnInit {
  userName: string;
  emulate: boolean;
  private error = '';
  private searchText: string;
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  autoHeight: number;
  columnDefs: any;
  excelStyles: any;
  rowData$: AccountOneTMany[];
  originalData: AccountOneTMany[];
  paginationPageSize = GlobalConstants.gridDefaultPageSize;
  private pageSize: number;
  private selectedBillingAccount: AccountOneTMany;
  modules;
  accountName: string;
  domLayout;
  frameworkComponents;
  defaultColDef;
  tooltipShowDelay;
  gridOptions: GridOptions;
  confirmationBody: string;
  includedSelectedRows: string[][];
  includedSelectedRowsMap: Map<string, string>;
  includedSelectedRowsMapWithAccessType: Map<string, Map<string, string>>;
  bulkActionNavigation: string;
  includedAccountsToDownloadFile: DownloadedFileDetails[];
  isBulkActionForDownloadingFile: boolean;
  isBulkActionForMap: boolean;
  isBulkActionForMapWithAccessType: boolean;
  gridRowHeight = GlobalConstants.gridRowHeight;
  noRowsTemplate = '<span style="font-size:14.0pt">No accounts found</span>';
  bulkActionDisabled: boolean = true;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private accountService: AccountService,
    private router: Router,
    private datepipe: DatePipe,
    private identity: IdentityService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private utilityService: UtilityService,
    private userPreference: UserPreferenceService,
    private modalDialogService: ModalDialogService
  ) {
    super();
    this.createGridOpts();
    this.createcolumnDefs();
    this.accountName = '';
  }

  ngOnInit() {
    this.globalService.remove(this.globalService.BULK);
    this.globalService.remove(this.globalService.BULKMAP);
    this.globalService.remove(this.globalService.BULKMAPWITHACCESSTYPE);
    this.globalService.remove(this.globalService.DETAIL);
    this.globalService.remove('ACCOUNT_LEVEL_ROLE_PERM');
    this.globalService.remove(this.globalService.REPORT);
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.spinner.show();
    this.initGridConfig();
    this.getAccounts();
  }

  initGridConfig() {
    this.defaultColDef = {
      editable: false,
      sortable: true,
      filter: true,
      resizable: true,
      tooltipComponent: 'customTooltip'
    };
    this.tooltipShowDelay = 0;
    this.frameworkComponents = { customTooltip: CustomTooltipComponent };
  }

  getAccounts() {
    this.accountService.getAccounts().subscribe(
      result => {
        this.rowData$ = result;
        this.originalData = result;

        if (this.rowData$ != null && this.rowData$.length) {
          const companyName = this.globalService.get(
            this.globalService.COMPANYNAME
          );
          if (companyName != null && companyName !== '') {
            this.accountName =
              companyName + ' - ' + this.rowData$.length + ' Account(s)';
          } else {
            this.accountName = this.rowData$.length + ' Account(s)';
          }
        } else {
          this.setGridHeight(
            'myGrid',
            this.gridApi,
            GlobalConstants.gridMinimumHeight,
            GlobalConstants.gridDefaultPageSize,
            0
          );
          this.spinner.hide();
        }
      },
      error => {
        console.log('Failed to get Accounts.');
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  onPaginationChanged(params) {
    this.setGridHeight_OnPaginationChanged(
      'myGrid',
      this.gridApi,
      GlobalConstants.gridMinimumHeight,
      GlobalConstants.gridDefaultPageSize,
      params
    );
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(GlobalConstants.gridHeaderHeight);
  }

  onFirstDataRendered(e) {
    let preference: any;
    const allPreference: any[] = this.userPreference.getPreferenceFromCache();
    const preferenceFiltered = allPreference.filter(x => {
      return x.type === this.userPreference.ACCOUNTLIST_PREFERENCE;
    });

    if (preferenceFiltered) {
      preference = preferenceFiltered[0];
    }

    if (preference) {
      const filterModel = preference.filterModel;

      if (filterModel) {
        this.gridApi.setFilterModel(filterModel);
      }
      const sortModel = preference.sortModel;
      if (sortModel) {
        this.gridApi.setSortModel(sortModel);
      }
      const columnState = preference.columnState;
      if (columnState) {
        this.gridColumnApi.setColumnState(columnState);
      }
    }

    this.gridApi.onFilterChanged();
    this.spinner.hide();
  }

  onViewCellClicked(event: any) {
    if (
      (event.column.colId === 'billingAccount' && !event.data['isUcb']) ||
      (event.column.colId === 'utilityAccount' && event.data['isUcb'])
    ) {
      const accessType: AccessTypes = this.utilityService.convertToAccessType(
        event.data['accessTypes']
      );
      const obj = {
        engieAccountId: event.data['engieAccountId'],
        billingAccount: event.data['billingAccount'],
        accessType: accessType
      };
      this.utilityService.setDetailContext(
        event.data.engieAccountId,
        event.data.utilityAccount,
        event.data.isUcb
      );
      this.utilityService.goToDetail(obj);
    }
  }

  saveState() {
    this.saveAccountListPreference();
  }

  saveAccountListPreference() {
    this.spinner.show();
    const filterModel = this.gridApi.getFilterModel();
    const sortModel = this.gridApi.getSortModel();
    const columnState = this.gridColumnApi.getColumnState();

    const columnOptions: GridOption = new GridOption();
    columnOptions.Type = 'AccountList';
    columnOptions.FilterModel = filterModel;
    columnOptions.SortModel = sortModel;
    columnOptions.ColumnState = columnState;

    this.userPreference
      .saveAccountListPreference(columnOptions)
      .subscribe(data => {
        data;
        this.spinner.hide();
      });
  }

  onColumnResized() {
    this.gridApi.resetRowHeights();
  }

  private createGridOpts() {
    this.excelStyles = [
      {
        id: 'textFormat',
        dataType: 'string'
      }
    ];
  }

  private createcolumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Engie ACCOUNT',
        field: 'engieAccountId',
        hide: true,
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'SELECT ALL',
        suppressMenu: true,
        minWidth: 100,
        maxWidth: 120,
        resizable: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: { justifyContent: 'left' },
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'BILLING ACCOUNT',
        field: 'billingAccount',
        minWidth: 120,
        maxWidth: 130,
        resizable: false,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat',
        cellRenderer: this.copyIcon.bind(this),
        tooltipComponentParams: (params: any) => {
          if (params.api.getDisplayedRowAtIndex(params.rowIndex).data.isUcb) {
            return {
              color: 'black',
              tooltip: 'Utility Consolidated Billing'
            };
          }
        },
        tooltipValueGetter: this.customTooltip.bind(this)
      },
      {
        headerName: 'UTILITY ACCOUNT',
        field: 'utilityAccount',
        minWidth: 190,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat',
        cellRenderer: this.copyIcon.bind(this)
      },
      {
        headerName: 'STATUS',
        field: 'status',
        suppressMenu: true,
        minWidth: 67,
        maxWidth: 67,
        cellStyle: { justifyContent: 'center' },
        cellRenderer: this.getStatusIcon.bind(this),
        tooltipComponentParams: (params: any) => {
          const accountStatus = params.api
            .getDisplayedRowAtIndex(params.rowIndex)
            .data.status.toLowerCase();

          if (accountStatus === 'account accepted') {
            return {
              color: 'black',
              tooltip: 'Account Accepted'
            };
          }
          if (accountStatus === 'account dropped') {
            return {
              color: 'black',
              tooltip: 'Account Dropped'
            };
          }
          if (accountStatus === 'account rejected') {
            return {
              color: 'black',
              tooltip: 'Account Rejected'
            };
          } else {
            return {
              color: 'black',
              tooltip: 'Unknown'
            };
          }
        },
        tooltipValueGetter: this.customTooltip
      },
      {
        headerName: 'SERVICE START',
        field: 'supplyStartDate',
        suppressMenu: true,
        minWidth: 80,
        maxWidth: 80,
        resizable: false,
        cellStyle: { justifyContent: 'left' },
        filter: false,
        valueFormatter: super.dateFormatter,
        getQuickFilterText: super.noQuickFilter
      },
      {
        headerName: 'DROP DATE',
        field: 'dropDate',
        suppressMenu: true,
        minWidth: 80,
        maxWidth: 80,
        hide: true,
        resizable: false,
        cellStyle: { justifyContent: 'left' },
        filter: false,
        valueFormatter: super.dateFormatter,
        getQuickFilterText: super.noQuickFilter
      },
      {
        headerName: 'NAME',
        field: 'name',
        flex: 4,
        minWidth: 110,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'BILLING ADDRESS',
        field: 'billingAddress',
        cellRenderer: this.billaddressFormatter,
        sortable: false,
        flex: 3,
        minWidth: 125,
        cellStyle: { justifyContent: 'left' },
        getQuickFilterText: super.billAddressQuickFilter,
        keyCreator: this.addressFilterKey
      },
      {
        headerName: 'SERVICE ADDRESS',
        field: 'serviceAddress',
        cellRenderer: this.serviceaddressFormatter,
        sortable: false,
        flex: 3,
        minWidth: 125,
        cellStyle: { justifyContent: 'left' },
        getQuickFilterText: super.serviceAddressQuickFilter,
        keyCreator: this.addressFilterKey
      },
      {
        headerName: 'UTILITY',
        field: 'utility',
        suppressMenu: true,
        flex: 2,
        minWidth: 70,
        cellStyle: { justifyContent: 'left' },
        getQuickFilterText: super.noQuickFilter
      },
      {
        headerName: 'Users Access',
        field: 'userAccessOwnership',
        suppressMenu: true,
        minWidth: 70,
        maxWidth: 70,
        resizable: false,
        sortable: false,
        filter: false,
        cellStyle: { justifyContent: 'center' },
        cellRenderer: this.UserAccessOwnership,
        tooltipComponentParams: (params: any) => {
          const newTooltip = params.api.getDisplayedRowAtIndex(params.rowIndex)
            .data.userAccessOwnership.shareeTooltip;
          return {
            color: 'black',
            tooltip: newTooltip
          };
        },
        tooltipValueGetter: this.customTooltip
      },
      {
        headerName: 'OWNER',
        field: 'ownership',
        suppressMenu: true,
        minWidth: 70,
        maxWidth: 70,
        resizable: false,
        sortable: false,
        filter: false,
        cellStyle: { justifyContent: 'center' },
        cellRenderer: this.ownership,
        tooltipComponentParams: (params: any) => {
          const newTooltip = params.api.getDisplayedRowAtIndex(params.rowIndex)
            .data.ownership.toolTip;
          return {
            color: 'black',
            tooltip: newTooltip
          };
        },
        tooltipValueGetter: this.customTooltip
      },
      {
        headerName: 'ACCOUNT BALANCE',
        field: 'accountBalance',
        suppressMenu: true,
        flex: 2,
        minWidth: 95,
        filter: 'agNumberColumnFilter',
        cellStyle: { justifyContent: 'flex-end' },
        cellRenderer: this.getFormattedBalance.bind(this),
        getQuickFilterText: super.noQuickFilter,
        tooltipComponentParams: (params: any) => {
          if (params.api.getDisplayedRowAtIndex(params.rowIndex).data.isUcb) {
            return {
              color: 'black',
              tooltip: 'Please pay your utility'
            };
          }
        },
        tooltipValueGetter: this.customTooltip.bind(this)
      },
      {
        headerName: 'DUE BY',
        field: 'dueDate',
        suppressMenu: true,
        minWidth: 80,
        maxWidth: 80,
        resizable: false,
        sort: 'desc',
        filter: false,
        cellStyle: { justifyContent: 'left' },
        cellRenderer: (params: any) => {
          return params.data.isUcb ? 'N/A' : super.dateFormatter(params);
        }
        // filterParams: {
        //   comparator: super.dateComparator,
        //   browserDatePicker: true
        // },
        // getQuickFilterText: super.noQuickFilter
      },
      {
        headerName: '',
        suppressMenu: true,
        field: 'autoPayEnabled',
        suppressColumnsToolPanel: true,
        sortable: false,
        minWidth: 40,
        maxWidth: 40,
        resizable: false,
        filter: 'agSetColumnFilter',
        cellRenderer: this.isAutoPayRenderer,
        keyCreator: this.autoPayFilterKey,
        tooltipComponentParams: (params: any) => {
          const isAutopayEnabled = params.api.getDisplayedRowAtIndex(
            params.rowIndex
          ).data.autoPayEnabled;
          if (isAutopayEnabled) {
            return {
              color: 'black',
              tooltip: 'Auto Pay Enabled'
            };
          } else {
            return {
              color: 'black'
            };
          }
        },
        tooltipValueGetter: this.customTooltip
      },
      {
        headerName: '',
        suppressMenu: true,
        minWidth: 75,
        maxWidth: 75,
        resizable: false,
        suppressColumnsToolPanel: true,
        sortable: false,
        filter: false,
        cellRenderer: this.pay.bind(this),
        tooltipComponentParams: (params: any) => {
          const accountBalance = params.api.getDisplayedRowAtIndex(
            params.rowIndex
          ).data.accountBalance;
          if (params.api.getDisplayedRowAtIndex(params.rowIndex).data.isUcb) {
            return {
              color: 'black',
              tooltip:
                'You cannot make a payment on a utility consolidated account'
            };
          } else if (accountBalance > 0) {
            return {
              color: 'black',
              tooltip: 'Make a Payment'
            };
          } else if (accountBalance <= 0) {
            return {
              color: 'black',
              tooltip:
                'You cannot make a payment on an account with zero balance'
            };
          }
        },
        tooltipValueGetter: this.customTooltip,
        cellRendererParams: {
          _self: this
        }
      },
      {
        headerName: '',
        suppressMenu: true,
        maxWidth: 35,
        minWidth: 35,
        resizable: false,
        field: 'status',
        sortable: false,
        filter: false,
        cellStyle: { justifyContent: 'center' },
        cellRenderer: this.billIcon.bind(this),
        suppressColumnsToolPanel: true,
        tooltipComponentParams: (params: any) => {
          if (
            params.api.getDisplayedRowAtIndex(params.rowIndex).data
              .engieStatementId === null
          ) {
            return {
              color: 'black',
              tooltip: 'No Available Statement'
            };
          } else {
            return {
              color: 'black',
              tooltip: 'View Statement'
            };
          }
        },
        tooltipValueGetter: this.customTooltip.bind(this)
      },
      {
        headerName: '',
        suppressMenu: true,
        maxWidth: 35,
        minWidth: 35,
        resizable: false,
        field: 'status',
        sortable: false,
        filter: false,
        cellStyle: { justifyContent: 'center' },
        cellRenderer: this.backingSheetIcon.bind(this),
        suppressColumnsToolPanel: true,
        tooltipComponentParams: (params: any) => {
          if (
            (params.data.engieStatementId === null && !params.data.isUcb) ||
            (params.data.engieInvoiceId === null && params.data.isUcb) ||
            params.data.billingType === BillingTypes.RateReady
          ) {
            return {
              color: 'black',
              tooltip: 'No Available Supporting Documents'
            };
          } else {
            return {
              color: 'black',
              tooltip: 'Download Supporting Documents'
            };
          }
        },
        tooltipValueGetter: this.customTooltip.bind(this)
      },
      {
        suppressMenu: true,
        maxWidth: 39,
        minWidth: 39,
        resizable: false,
        sortable: false,
        filter: false,
        cellRenderer: this.ellipse,
        tooltipComponentParams: {
          color: 'black',
          tooltip: `Additional Options`
        },
        tooltipValueGetter: this.customTooltip,
        suppressColumnsToolPanel: true,
        onCellClicked(params: any): void {
          const rowIndex = params.rowIndex;
          const rowNode = params.node;
          const column = params.column;
          const mouseEvent = params.event;
          const value = params.value;
          const cell = params.api.context.beanWrappers.rowRenderer.beanInstance.rowConsByRowIndex[
            rowIndex
          ].getRenderedCellForColumn(column);
          cell.beans.contextMenuFactory.showMenu(
            rowNode,
            column,
            value,
            mouseEvent
          );
        }
      }
    ];
    this.domLayout = 'autoHeight';
  }

  copyIcon(params) {
    const divTag = document.createElement('div');
    let fieldValue = '';
    if (params.value !== null) {
      if (
        (params.column.colId === 'billingAccount' && !params.data.isUcb) ||
        (params.column.colId === 'utilityAccount' && params.data.isUcb)
      ) {
        if (params.value.includes('_COO')) {
          fieldValue =
            `<a href="javascript:void(0);">` +
            params.value.substring(0, params.value.indexOf('_COO')) +
            `</a>`;
        } else {
          fieldValue = `<a href="javascript:void(0);">` + params.value + `</a>`;
        }
      } else {
        if (params.value.includes('_COO')) {
          fieldValue = params.value.substring(0, params.value.indexOf('_COO'));
        } else {
          fieldValue = params.value;
        }
        if (params.data.isUcb) {
          return fieldValue;
        }
      }
      this.utilityService.fieldWithCopyButton(divTag, fieldValue, params.value);
    }
    return divTag;
  }

  programQuickFilter(params) {
    if (params.data.autoPayEnabled) {
      return 'paper';
    } else {
      return 'less';
    }
  }

  customTooltip(params) {
    if (params.value === undefined) {
      return true;
    }

    return params.value;
  }

  getFormattedBalance(params) {
    return params.data.isUcb ? 'Pay Utility' : super.currencyFormatter(params);
  }

  getStatusIcon(params) {
    const self = this;
    let divTag: HTMLDivElement;
    let htmlString = '';
    const confirmBody = '';
    if (params.data.status) {
      if (params.data.status.toLowerCase() === 'account accepted') {
        htmlString = `<i style='color:green;' class='material-icons'>fiber_manual_record</i></span>`;
      } else if (params.data.status.toLowerCase() === 'account dropped') {
        htmlString = `<i style='color:gray;' class='material-icons'>fiber_manual_record</i></span>`;
      } else if (params.data.status.toLowerCase() === 'account rejected') {
        htmlString = `<i style='color:red;' class='material-icons'>fiber_manual_record</i></span>`;
      } else {
        htmlString = `<i style='color:gray;' class='material-icons'>fiber_manual_record</i></span>`;
      }
    } else {
      htmlString = `<i style='color:gray;' class='material-icons'>fiber_manual_record</i></span>`;
    }
    divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();
    return divTag;
  }

  billIcon(params) {
    const self = this;
    let htmlString = '';
    if (params.data.engieStatementId === null || params.data.isUcb) {
      htmlString = `<span class="btn-simple disabled"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>picture_as_pdf</i></span>`;
    } else {
      htmlString = `<span class="btn-simple"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>picture_as_pdf</i></span>`;
    }
    const divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();
    const eButtonBill = divTag.querySelector('.btn-simple');
    eButtonBill.addEventListener('click', () => {
      self.utilityService.viewStatement(
        new DownloadedFileDetails(
          null,
          null,
          params.data.engieStatementId,
          null,
          null
        ),
        true
      );
    });
    return divTag;
  }

  backingSheetIcon(params) {
    const self = this;
    let htmlString = '';
    if (
      (params.data.engieStatementId === null && !params.data.isUcb) ||
      (params.data.engieInvoiceId === null && params.data.isUcb) ||
      params.data.billingType === BillingTypes.RateReady
    ) {
      htmlString = `<span class="btn-simple disabled"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>article</i></span>`;
    } else {
      htmlString = `<span class="btn-simple"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>article</i></span>`;
    }
    const divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();
    const eButtonBackingSheet = divTag.querySelector('.btn-simple');
    eButtonBackingSheet.addEventListener('click', () => {
      self.utilityService.downloadBackingSheet(
        new DownloadedFileDetails(
          params.data.isUcb
            ? params.data.utilityAccount
            : params.data.billingAccount,
          params.data.isUcb ? params.data.engieAccountId : null,
          params.data.isUcb ? null : params.data.engieStatementId,
          params.data.isUcb ? params.data.engieInvoiceId : null,
          params.data.name
        ),
        false,
        true
      );
    });
    return divTag;
  }

  addressFilterKey(params) {
    if (params.value) {
      if (params.value.street2) {
        return `${params.value.street},${params.value.street2},${params.value.state},${params.value.postalCode},`;
      } else {
        return `${params.value.street},${params.value.state},${params.value.postalCode},`;
      }
    }
  }

  autoPayFilterKey(params) {
    if (params.value) {
      return 'Auto pay enabled';
    } else {
      return 'Auto pay disabled';
    }
  }

  achFilterKey(params) {
    if (params.value > 0) {
      return 'ACH enabled';
    } else {
      return 'ACH disabled';
    }
  }

  isAutoPayRenderer(params) {
    let htmlString: string;
    if (params.data.autoPayEnabled) {
      htmlString = `<i class='material-icons nj-icon--blue-corporate'>autorenew</i>`;
    } else {
      htmlString = '';
    }
    const divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();

    return htmlString;
  }

  pay(params) {
    const self = this;
    let divTag: HTMLDivElement;
    let htmlString: string;
    const accessType: AccessTypes = this.utilityService.convertToAccessType(
      params.node.data.accessTypes
    );
    const canPay = params._self.identity.hasAccessPermission(
      Permission.Pay_Bill,
      accessType
    );

    if (params.data.accountBalance > 0) {
      htmlString = `<a href="javascript:void(0);" class="btn-simple nj-btn nj-btn--primary"
        role="button" aria-pressed="true">PAY</a>`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
      const eButtonPay = divTag.querySelectorAll('.btn-simple')[0];
      eButtonPay.addEventListener('click', () => {
        const obj = {
          engieAccountId: params.node.data['engieAccountId'],
          billingAccount: params.node.data['billingAccount'],
          accessType: accessType
        };
        self.utilityService.setDetailContext(
          params.data.engieAccountId,
          params.data.utilityAccount,
          params.data.isUcb
        );
        self.utilityService.goToPayment(obj);
      });
    } else {
      htmlString = `<button type='button' disabled=true class='nj-btn nj-btn--primary'>PAY</button>`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
    }

    if (!canPay) {
      htmlString = `<button type='button' disabled=true class='nj-btn nj-btn--primary'>PAY</button>`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
    }

    return divTag;
  }

  onSelectionChanged() {
    if (
      this.gridApi.getSelectedRows().length > 0 &&
      this.gridApi.getDisplayedRowCount() > 0
    ) {
      this.bulkActionDisabled = false;
      const selectedRows = this.gridApi.getSelectedRows()[0];
      this.selectedBillingAccount = selectedRows;
    } else {
      this.bulkActionDisabled = true;
    }
  }

  ellipse(params) {
    const htmlString = `<i class='material-icons md-36 md--blue-engie'>more_horiz</i>`;
    const divTag = document.createElement('span');
    divTag.innerHTML = htmlString.trim();
    return divTag;
  }

  getContextMenuItems = params => {
    const accountNumber = params.node.data.isUcb
      ? params.node.data.utilityAccount
      : params.node.data.billingAccount;

    const accessType: AccessTypes = this.utilityService.convertToAccessType(
      params.node.data.accessTypes
    );
    let canShare = false;
    let canTransfer = false;
    let canRelease = false;
    let canPaper = false;
    let canAutoPay = false;
    let canAddPayMethod = false;
    let canUpdateBillingAddress = false;
    const obj = {
      engieAccountId: params.node.data['engieAccountId'],
      accountNumber: accountNumber,
      accessType: accessType
    };

    canShare = this.identity.hasAccessPermission(
      AccessPermission.Share_Account,
      accessType
    );
    canTransfer = this.identity.hasAccessPermission(
      AccessPermission.Transfer_Account,
      accessType
    );
    canRelease = this.identity.hasAccessPermission(
      AccessPermission.Release_Accounts,
      accessType
    );
    canPaper = this.identity.hasAccessPermission(
      AccessPermission.Enroll_Paperless,
      accessType
    );
    canAutoPay =
      !params.node.data.isUcb &&
      this.identity.hasAccessPermission(
        AccessPermission.Enroll_Auto_Pay,
        accessType
      );
    canAddPayMethod =
      !params.node.data.isUcb &&
      this.identity.hasAccessPermission(
        AccessPermission.Manage_PayMethods,
        accessType
      );
    canUpdateBillingAddress =
      !params.node.data.isUcb &&
      this.identity.hasAccessPermission(
        AccessPermission.Update_BillingAddress,
        accessType
      );

    const historyObj = {
      name: 'View History',
      action: () => {
        this.utilityService.setDetailContext(
          params.node.data.engieAccountId,
          params.node.data.utilityAccount,
          params.node.data.isUcb
        );
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.utilityService.goToHistory();
      }
    };
    const shareObj = {
      name: 'Share Account',
      action: () => {
        this.utilityService.setGlobalBulkFromSelectedAccount(
          accountNumber,
          params.node.data.engieAccountId
        );
        this.router.navigate([`/share`]);
      }
    };
    const transferObj = {
      name: 'Transfer Account',
      action: () => {
        this.utilityService.setGlobalBulkFromSelectedAccount(
          accountNumber,
          params.node.data.engieAccountId
        );
        this.router.navigate([`/transfer`]);
      }
    };
    const releaseObj = {
      name: 'Release Account',
      action: () => {
        this.utilityService.setGlobalBulkFromSelectedAccount(
          accountNumber,
          params.node.data.engieAccountId
        );
        this.router.navigate([`/release`]);
      }
    };

    const autoObj = {
      name:
        params.node.data.autoPayEnabled === true
          ? 'Unenroll in AutoPay'
          : 'Enroll in AutoPay',
      action: () => {
        this.utilityService.setDetailContext(
          params.node.data.engieAccountId,
          params.node.data.utilityAccount,
          params.node.data.isUcb
        );
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.utilityService.goToServices();
      }
    };
    // const paperObj = {
    //   name: params.node.data.billDeliveryMethod === 'EBill' ? 'UnEnroll in Paperless Billing' : 'Enroll in Paperless Billing',
    //   action: () => {
    //     this.globalService.set(this.globalService.DETAIL, `${params.node.data.engieAccountId}/${params.node.data.utilityAccount}`);
    //     this.globalService.set(this.globalService.BILLACCT, params.node.data.billingAccount);
    //     this.utilityService.goToServices();
    //   }
    // };
    const reportObj = {
      name: 'Generate Report',
      action: () => {
        this.globalService.remove(this.globalService.REPORT);
        this.utilityService.setDetailContext(
          params.node.data.engieAccountId,
          params.node.data.utilityAccount,
          params.node.data.isUcb
        );
        this.globalService.set(
          this.globalService.REPORT,
          params.node.data.engieAccountId
        );
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.utilityService.goToReporting(
          params.node.data.engieAccountId,
          accountNumber
        );
      }
    };

    const payMethod = {
      name: 'Add Payment Method',
      action: () => {
        this.globalService.remove(this.globalService.DETAIL);
        this.utilityService.setDetailContext(
          params.node.data.engieAccountId,
          params.node.data.utilityAccount,
          params.node.data.isUcb
        );
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.utilityService.goToPayMethod();
      }
    };

    const updateBillingAddress = {
      name: 'Update Billing Contact',
      action: () => {
        this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
        this.utilityService.goToUpdateBillingAddress(
          params.node.data.billingAccount,
          params.node.data.engieAccountId
        );
      }
    };

    const result = [];
    if (canAddPayMethod) {
      result.push(payMethod);
    }
    if (canUpdateBillingAddress) {
      result.push(updateBillingAddress);
    }
    if (canAutoPay) {
      result.push(autoObj);
    }
    // if (canPaper) {
    //   result.push(paperObj);
    // }

    if (canAddPayMethod || canAutoPay) {
      result.push('separator');
    }

    result.push(historyObj);
    result.push(reportObj);

    if (canShare || canTransfer || canRelease) {
      result.push('separator');
    }

    if (canShare) {
      result.push(shareObj);
    }
    if (canTransfer) {
      result.push(transferObj);
    }
    if (canRelease) {
      result.push(releaseObj);
    }

    if (params.column.colId === '2') {
      return result;
    }
  };

  ownership(params) {
    const ownership: Ownership = params.value;
    const divTag = document.createElement('div');
    if (ownership.emailInitial != null) {
      if (ownership.hasMultipleOwner) {
        const divAvatarPic = document.createElement('div');
        divAvatarPic.setAttribute('class', 'nj-avatar__picture');

        const divBadge = document.createElement('div');
        divBadge.setAttribute('class', 'nj-badge nj-badge--orange');
        divBadge.setAttribute('style', 'width: 1rem; height: 1rem;');
        divBadge.appendChild(
          document.createTextNode(ownership.numberOfAccountOwners?.toString())
        );

        const divAvatar = document.createElement('div');
        divAvatar.setAttribute('class', 'nj-avatar nj-avatar--sm');
        divAvatar.setAttribute('style', 'width: 2rem; height: 2rem;');
        divAvatar.appendChild(divAvatarPic);
        divAvatar.appendChild(divBadge);

        divTag.appendChild(divAvatar);
      } else {
        divTag.setAttribute('class', 'nj-badge nj-badge--blue-corporate');
        divTag.appendChild(document.createTextNode(ownership.emailInitial));
      }
    }
    return divTag;
  }

  UserAccessOwnership(params) {
    const userAccessOwnership: Ownership = params.value;

    if (userAccessOwnership.shareeTooltip !== null) {
      const elemImg = document.createElement('img');

      if (userAccessOwnership.iOwnThisAccount) {
        elemImg.setAttribute('src', '/assets/brand/Shared_Image.png');
        elemImg.setAttribute('alt', 'Shared');
      } else {
        elemImg.setAttribute('src', '/assets/brand/Sharee_Image.png');
        elemImg.setAttribute('alt', 'Sharee');
      }

      const divAvatarPic = document.createElement('div');
      divAvatarPic.setAttribute('class', 'nj-avatar__picture');
      divAvatarPic.appendChild(elemImg);

      const divAvatar = document.createElement('div');
      divAvatar.setAttribute('class', 'nj-avatar nj-avatar--sm');
      divAvatar.appendChild(divAvatarPic);

      const divTag = document.createElement('div');
      divTag.appendChild(divAvatar);

      return divTag;
    }
  }

  onQuickSearchChanged(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  onPageSizeChanged(selectedPageSize: number) {
    const value = selectedPageSize;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  filterGrid(type: any) {
    if (type === 'all') {
      this.rowData$ = this.originalData;
    } else if (type === 'acc_with_bal') {
    } else if (type === 'active') {
      this.rowData$ = this.rowData$.filter(this.getActiveAccounts);
    } else if (type === 'inactive') {
      this.rowData$ = this.rowData$.filter(this.getInActiveAccounts);
    }
  }

  getActiveAccounts(row: AccountOneTMany): boolean {
    return row.Status === 'Active';
  }

  getInActiveAccounts(row: AccountOneTMany): boolean {
    return row.Status === 'InActive';
  }

  exportToExcel() {
    const params = {
      columnWidth: 100,
      sheetName: 'AccountList',
      columnKeys: [
        'billingAccount',
        'utilityAccount',
        'status',
        'supplyStartDate',
        'dropDate',
        'name',
        'billingAddress',
        'serviceAddress',
        'utility',
        'accountBalance',
        'dueDate'
      ],

      processCellCallback: this.processExportedCell
    };
    this.gridApi.exportDataAsExcel(params);
  }

  processExportedCell(params) {
    if (
      params.column.colId === 'supplyStartDate' ||
      params.column.colId === 'dueDate'
    ) {
      if (params.value != null && params.value !== '0001-01-01T00:00:00') {
        return formatDate(params.value, 'MM/dd/yyyy', 'en-US');
      }
      return null;
    } else if (
      params.column.colId === 'billingAddress' ||
      params.column.colId === 'serviceAddress'
    ) {
      const data =
        params.column.colId === 'billingAddress'
          ? params.node.data.billingAddress
          : params.node.data.serviceAddress;
      if (data != null) {
        let currentAddress: any[];
        currentAddress = [
          data.street,
          data.street2,
          data.city,
          data.state,
          data.postalCode
        ];
        let formattedAddress = '';
        currentAddress.forEach(x => {
          if (formattedAddress && x) {
            formattedAddress = formattedAddress + ' ' + x;
          } else {
            if (!formattedAddress && x) {
              formattedAddress = x;
            }
          }
        });
        return formattedAddress;
      }
      return null;
    } else if (
      params.column.colId === 'autoPayEnabled' ||
      params.column.colId === '0' ||
      params.column.colId === 'accountBalance_1'
    ) {
      return null;
    } else {
      return params.value;
    }
  }

  bulkReport() {
    const selectedAccountEngieIds: string[] = [];
    const selectedAccountNumbers: string[] = [];
    this.gridApi.getSelectedRows().forEach(x => {
      const accountNumber = x.isUcb ? x.utilityAccount : x.billingAccount;
      selectedAccountNumbers.push(accountNumber);
      selectedAccountEngieIds.push(x.engieAccountId);
    });

    const selectedRows = new Array(
      selectedAccountNumbers,
      selectedAccountEngieIds
    );
    this.globalService.set(this.globalService.BULK, selectedRows);

    this.globalService.remove(this.globalService.REPORT);
    this.globalService.remove(this.globalService.DETAIL);
    this.globalService.set(this.globalService.REPORT, selectedAccountEngieIds);
    if (selectedAccountEngieIds.length === 1) {
      const selectedAccount = this.gridApi.getSelectedRows()[0];
      this.utilityService.setDetailContext(
        selectedAccount.engieAccountId,
        selectedAccount.utilityAccount,
        selectedAccount.isUcb
      );
    }
    this.router.navigate([`reporting`]);
  }

  bulkDownloadStatements() {
    this.downloadFileForSelectedRows('statements');
  }

  bulkDownloadSupportingDocuments() {
    this.downloadFileForSelectedRows('supporting documents');
  }

  downloadFileForSelectedRows(bulkAction: string) {
    let includedAccounts: DownloadedFileDetails[] = [];
    const excludedAccounts: string[] = [];
    this.gridApi.getSelectedRows().forEach(x => {
      if (x.isUcb) {
        if (bulkAction === 'statements') {
          excludedAccounts.push(x.utilityAccount);
        } else if (x.billingType === BillingTypes.RateReady) {
          excludedAccounts.push(x.utilityAccount);
        } else {
          includedAccounts.push(
            new DownloadedFileDetails(
              x.utilityAccount,
              x.engieAccountId,
              null,
              x.engieInvoiceId,
              x.name
            )
          );
        }
      } else if (x.engieStatementId === null) {
        excludedAccounts.push(x.billingAccount);
      } else {
        includedAccounts.push(
          new DownloadedFileDetails(
            x.billingAccount,
            null,
            x.engieStatementId,
            null,
            x.name
          )
        );
      }
    });

    this.showConfirmationModalToDownloadFile(
      includedAccounts,
      excludedAccounts,
      bulkAction
    );
  }

  showConfirmationModalToDownloadFile(
    includedAccounts: DownloadedFileDetails[],
    excludedAccounts: string[],
    bulkAction: string
  ) {
    let message = '';
    if (!includedAccounts.length) {
      message =
        'There are no available ' + bulkAction + ' for the selected accounts.';
      this.modalDialogService.openErrorDialog(message);
      return;
    }

    this.includedAccountsToDownloadFile = includedAccounts;
    this.bulkActionNavigation = bulkAction;
    this.isBulkActionForDownloadingFile = true;
    this.isBulkActionForMap = false;
    this.isBulkActionForMapWithAccessType = false;
    if (!excludedAccounts.length) {
      message =
        'Would you like to compress the selected files into a single .zip file or download as individual files?';
      this.modalDialogService.openWarningDialog(
        'Individual Files',
        () => {
          this.downloadIndividualFiles();
        },
        'Zip File',
        () => {
          this.downloadZipFile();
        },
        message,
        'Download Options'
      );
      return;
    }
    if (includedAccounts.length && excludedAccounts.length) {
      message =
        'The following accounts do not have available ' +
        bulkAction +
        ': ' +
        excludedAccounts.join(', ') +
        '. <br>Are you sure you want to continue?';
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continue();
        },
        '',
        null,
        message
      );
    }
  }

  bulkShare() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Share_Account,
      'share'
    );
    this.showConfirmationModal(selectedRows, 'share', 'share');
  }

  bulkTransfer() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Transfer_Account,
      'transfer'
    );
    this.showConfirmationModal(selectedRows, 'transfer', 'transfer');
  }

  bulkRelease() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Release_Accounts,
      'release'
    );
    this.showConfirmationModal(selectedRows, 'release', 'release');
  }

  bulkAddress() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Update_BillingAddress,
      'bulk-address'
    );
    this.showConfirmationModal(
      selectedRows,
      'bulk-address',
      'update billing address for'
    );
  }

  bulkAutopayAndPaperless() {
    const selectedRows = this.getSelectedRows(
      AccessPermission.Enroll_Auto_Pay,
      'bulk-autopay'
    );
    this.showConfirmationModal(
      selectedRows,
      'bulk-autopay',
      'enroll or unenroll in autopay or paperless for'
    );
  }

  getSelectedRows(
    accessPermissionToSelect: AccessPermission,
    bulkAction: string
  ): SelectedRows {
    const includedEngieAccounts: string[] = [];
    const includedAccountNumbers: string[] = [];
    const excludedAccounts: string[] = [];
    this.gridApi.getSelectedRows().forEach(x => {
      const accountAccessType: AccessTypes = this.utilityService.convertToAccessType(
        x.accessTypes
      );
      const hasAccessPermission = this.identity.hasAccessPermission(
        accessPermissionToSelect,
        accountAccessType
      );
      const accountNumber = x.isUcb ? x.utilityAccount : x.billingAccount;

      if (
        x.isUcb &&
        (bulkAction === 'bulk-address' || bulkAction === 'bulk-autopay')
      ) {
        excludedAccounts.push(accountNumber);
      } else if (hasAccessPermission) {
        includedAccountNumbers.push(accountNumber);
        includedEngieAccounts.push(x.engieAccountId);
      } else if (
        bulkAction === 'bulk-autopay' &&
        this.identity.hasAccessPermission(
          AccessPermission.Enroll_Paperless,
          accountAccessType
        )
      ) {
        includedAccountNumbers.push(accountNumber);
        includedEngieAccounts.push(x.engieAccountId);
      } else {
        excludedAccounts.push(accountNumber);
      }
    });
    const includedAccounts = new Array(
      includedAccountNumbers,
      includedEngieAccounts
    );
    return new SelectedRows(includedAccounts, excludedAccounts);
  }

  getSelectedRowsMap(
    accessPermissionToSelect: AccessPermission
  ): SelectedRowsMap {
    const included = new Map();
    const excluded = new Map();
    this.gridApi.getSelectedRows().forEach(x => {
      const accountAccessType: AccessTypes = this.utilityService.convertToAccessType(
        x.accessTypes
      );
      const hasAccessPermission = this.identity.hasAccessPermission(
        accessPermissionToSelect,
        accountAccessType
      );
      if (x.isUcb) {
        excluded.set(x.engieAccountId, x.utilityAccount);
      } else if (hasAccessPermission) {
        included.set(x.engieAccountId, x.billingAccount);
      } else {
        excluded.set(x.engieAccountId, x.billingAccount);
      }
    });
    return new SelectedRowsMap(included, excluded);
  }

  getSelectedRowsMapWithAccessType(
    accessPermissionToSelect: AccessPermission
  ): SelectedRowsMapWithAccessType {
    const included = new Map();
    const excluded = new Map();
    this.gridApi.getSelectedRows().forEach(x => {
      const accountAccessType: AccessTypes = this.utilityService.convertToAccessType(
        x.accessTypes
      );
      const hasAccessPermission = this.identity.hasAccessPermission(
        accessPermissionToSelect,
        accountAccessType
      );
      const excludedWithAccessType = new Map();
      const includedWithAccessType = new Map();
      if (x.isUcb) {
        excludedWithAccessType.set(x.utilityAccount, accountAccessType);
        excluded.set(x.engieAccountId, excludedWithAccessType);
      } else if (hasAccessPermission) {
        includedWithAccessType.set(x.billingAccount, accountAccessType);
        included.set(x.engieAccountId, includedWithAccessType);
      } else {
        excludedWithAccessType.set(x.billingAccount, accountAccessType);
        excluded.set(x.engieAccountId, excludedWithAccessType);
      }
    });
    return new SelectedRowsMapWithAccessType(included, excluded);
  }

  showConfirmationModal(
    selectedRows: SelectedRows,
    bulkActionNavigation: string,
    bulkAction
  ) {
    let message = '';
    if (!selectedRows.includedRows[0].length) {
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the selected accounts.';
      this.modalDialogService.openErrorDialog(message);
    }
    if (!selectedRows.excludedRows.length) {
      this.globalService.set(
        this.globalService.BULK,
        selectedRows.includedRows
      );
      this.router.navigate(['/' + bulkActionNavigation]);
    }
    if (
      selectedRows.includedRows[0].length &&
      selectedRows.excludedRows.length
    ) {
      this.includedSelectedRows = selectedRows.includedRows;
      this.bulkActionNavigation = bulkActionNavigation;
      this.isBulkActionForDownloadingFile = false;
      this.isBulkActionForMap = false;
      this.isBulkActionForMapWithAccessType = false;
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the following accounts: ' +
        selectedRows.excludedRows.join(', ') +
        '. <br>Are you sure you want to continue?';
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continue();
        },
        '',
        null,
        message
      );
    }
  }

  showConfirmationModalMap(
    selectedRows: SelectedRowsMap,
    bulkActionNavigation: string,
    bulkAction
  ) {
    let message = '';
    if (!selectedRows.includedRows.size) {
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the selected accounts.';
      this.modalDialogService.openErrorDialog(message);
    }
    if (!selectedRows.excludedRows.size) {
      this.globalService.set(
        this.globalService.BULKMAP,
        JSON.stringify(Array.from(selectedRows.includedRows.entries()))
      );
      this.router.navigate(['/' + bulkActionNavigation]);
    }
    if (selectedRows.includedRows.size && selectedRows.excludedRows.size) {
      this.includedSelectedRowsMap = selectedRows.includedRows;
      this.bulkActionNavigation = bulkActionNavigation;
      this.isBulkActionForDownloadingFile = false;
      this.isBulkActionForMap = true;
      this.isBulkActionForMapWithAccessType = false;
      console.log(selectedRows.excludedRows.values());
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the following accounts: ' +
        Array.from(
          selectedRows.excludedRows.values(),
          x => ' ' + x
        ).toString() +
        '. <br>Are you sure you want to continue?';
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continue();
        },
        '',
        null,
        message
      );
    }
  }

  showConfirmationModalMapWithAccessType(
    selectedRows: SelectedRowsMapWithAccessType,
    bulkActionNavigation: string,
    bulkAction
  ) {
    let message = '';
    if (!selectedRows.includedRows.size) {
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the selected accounts.';
      this.modalDialogService.openErrorDialog(message);
    }
    if (!selectedRows.excludedRows.size) {
      var includedRowsWithAccessType = this.mapToObjectRec(
        selectedRows.includedRows
      );
      this.globalService.set(
        this.globalService.BULKMAPWITHACCESSTYPE,
        JSON.stringify(includedRowsWithAccessType)
      );
      this.router.navigate(['/' + bulkActionNavigation]);
    }
    if (selectedRows.includedRows.size && selectedRows.excludedRows.size) {
      this.includedSelectedRowsMapWithAccessType = selectedRows.includedRows;
      this.bulkActionNavigation = bulkActionNavigation;
      this.isBulkActionForDownloadingFile = false;
      this.isBulkActionForMap = false;
      this.isBulkActionForMapWithAccessType = true;
      var excludedAccounts = new Map<string, string>();
      for (let value of selectedRows.excludedRows.values()) {
        for (let key of value.keys()) {
          excludedAccounts.set(key, value.get(key));
        }
      }
      message =
        'You do not have permissions to ' +
        bulkAction +
        ' the following accounts: ' +
        Array.from(excludedAccounts.keys(), x => ' ' + x).toString() +
        '. <br>Are you sure you want to continue?';
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continue();
        },
        '',
        null,
        message
      );
    }
  }

  mapToObjectRec(m) {
    let lo = {};
    for (let [k, v] of m) {
      if (v instanceof Map) {
        lo[k] = this.mapToObjectRec(v);
      } else {
        lo[k] = v;
      }
    }
    return lo;
  }

  continue() {
    if (this.isBulkActionForDownloadingFile) {
      let message =
        'Would you like to compress the selected files into a single .zip file or download as individual files?';
      this.modalDialogService.openWarningDialog(
        'Individual Files',
        () => {
          this.downloadIndividualFiles();
        },
        'Zip File',
        () => {
          this.downloadZipFile();
        },
        message,
        'Download Options'
      );
    } else {
      if (this.isBulkActionForMap) {
        this.globalService.set(
          this.globalService.BULKMAP,
          JSON.stringify(Array.from(this.includedSelectedRowsMap.entries()))
        );
      } else if (this.isBulkActionForMapWithAccessType) {
        var includedRowsWithAccessType = this.mapToObjectRec(
          this.includedSelectedRowsMapWithAccessType
        );
        this.globalService.set(
          this.globalService.BULKMAPWITHACCESSTYPE,
          JSON.stringify(includedRowsWithAccessType)
        );
      } else {
        this.globalService.set(
          this.globalService.BULK,
          this.includedSelectedRows
        );
      }
      this.router.navigate(['/' + this.bulkActionNavigation]);
    }
  }

  downloadIndividualFiles() {
    this.includedAccountsToDownloadFile.forEach(x => {
      if (this.bulkActionNavigation === 'statements') {
        this.utilityService.downloadStatement(x, false);
      } else {
        this.utilityService.downloadBackingSheet(x, false);
      }
    });
  }

  downloadZipFile() {
    if (this.bulkActionNavigation === 'statements') {
      this.utilityService.zipStatements(this.includedAccountsToDownloadFile);
    } else {
      this.utilityService.zipBackingSheets(this.includedAccountsToDownloadFile);
    }
  }
}
