import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SystemStatus } from '@shared/models/systemStatus';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { UtilityService } from '@core/services/utility.service';
import { SystemIncidentTypes } from '@shared/enums/SystemIncidentTypes';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-offline-form',
  templateUrl: './offline-form.component.html',
  styleUrls: ['./offline-form.component.css']
})
export class OfflineFormComponent implements OnInit {
  @Input() systemStatus: SystemStatus;
  @Input() initialStatus: boolean;
  @Output() formSubmit = new EventEmitter<SystemStatus>();
  INCIDENT_REASON1 =
    'The ENGIE Portal is temporarily offline for scheduled maintenance and system upgrades.';
  INCIDENT_REASON2 =
    'The ENGIE Portal is temporarily offline due to a network outage or service disruption.';
  offForm: FormGroup;
  incidentTypes: string[];
  body: string;
  result: SystemStatus;
  selectedIncidentStatusType: string;
  submitted = false;
  customMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private modalDialogService: ModalDialogService
  ) {}

  get f() {
    return this.offForm.controls;
  }

  ngOnInit(): void {
    this.createIncidentTypes();
    this.initForm(this.formBuilder);
    this.updateFormControls(this.systemStatus);
  }

  initForm(formBuilder: FormBuilder) {
    this.offForm = formBuilder.group({
      incidentDescription: new FormControl('', Validators.required),
      incidentType: new FormControl('', Validators.required)
    });
  }

  updateFormControls(data: SystemStatus) {
    if (this.offForm) {
      this.offForm.reset();
    }

    this.selectedIncidentStatusType = this.setInitialSelectedIncidentStatusType();

    let tempMessage: string;
    if (
      data.incidentDescription != this.INCIDENT_REASON1 &&
      data.incidentDescription != this.INCIDENT_REASON2
    ) {
      tempMessage = 'Custom Message';
      this.customMessage = data.incidentDescription;
    } else {
      tempMessage = data.incidentDescription;
    }

    this.offForm.patchValue({
      incidentDescription: tempMessage,
      incidentType: this.selectedIncidentStatusType
    });
  }

  onResetForm() {
    this.submitted = false;
    this.updateFormControls(this.systemStatus);
    document
      .getElementById('incidentDropdown')
      .setAttribute('data-selected-content', this.selectedIncidentStatusType);
  }

  onSave(e) {
    this.submitted = true;
    const incidentType = e.target.form.selectedIncidentType.defaultValue;
    this.f.incidentType.setValue(incidentType);
    if (this.offForm.invalid || !this.checkCustomMessageLength()) {
      return;
    }
    const message = 'Are you sure you want to continue?';
    this.modalDialogService.openWarningDialog(
      'Continue',
      () => {
        this.onTakeOffline();
      },
      '',
      null,
      message,
      'Confirm'
    );
  }

  onTakeOffline() {
    this.result = this.createPostData();
    this.formSubmit.emit(this.result);
    this.offForm.markAsPristine();
    this.submitted = false;
  }

  createPostData(): SystemStatus {
    let tempObject = new SystemStatus();
    tempObject = Object.assign({}, this.offForm.value);
    tempObject.isPortalUp = this.systemStatus.isPortalUp;
    tempObject.portalUpMessage = this.systemStatus.portalUpMessage;
    tempObject.dashboardMessage = this.systemStatus.dashboardMessage;
    if (tempObject.incidentDescription == 'Custom Message') {
      tempObject.incidentDescription = (document.getElementById(
        'customMessage'
      ) as HTMLInputElement).value;
    }
    return tempObject;
  }

  checkCustomMessageLength(): boolean {
    return this.f.incidentDescription.value == 'Custom Message' &&
      (document.getElementById('customMessage') as HTMLInputElement).value
        .length < 1
      ? false
      : true;
  }

  setInitialSelectedIncidentStatusType(): string {
    if (this.systemStatus.incidentType === 'Operational') {
      return '';
    } else {
      const incidentType: SystemIncidentTypes = (<any>SystemIncidentTypes)[
        this.systemStatus.incidentType
      ];
      return incidentType.toString();
    }
  }

  onIncidentTypeChangedHandler(e) {
    this.f.incidentType.markAsDirty();
    const incidentType = e.target.form.selectedIncidentType.defaultValue;
    this.f.incidentType.setValue(incidentType);
    this.f.incidentType.updateValueAndValidity();
  }

  createIncidentTypes() {
    this.incidentTypes = this.utilityService.getEnumList(SystemIncidentTypes);
    const index = this.incidentTypes.findIndex(
      d => d['key'] === SystemIncidentTypes.Operational
    );
    this.incidentTypes.splice(index, 1);
  }
}
