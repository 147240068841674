import { Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  MSG_PHONE = `${environment.customerCarePhoneAlt} (${environment.customerCarePhone})`;
  CURRENT_YEAR = new Date().getFullYear();

  constructor() {}
}
