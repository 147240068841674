import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalSettings } from '@shared/models/ModalSettings';
import { ModalDialogTypes } from '@shared/enums/ModalDialogTypes';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.sass']
})
export class ModalDialogComponent {
  modalDialog = ModalDialogTypes; //used in the template to enable ENUMerations
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalSettings,
    private sanitizer: DomSanitizer
  ) {}

  closeButton_click() {
    this.data.closeDialogCallback();
    this.data.closeButton_click();
  }

  button1_click() {
    this.data.closeDialogCallback();
    this.data.button1_click();
  }

  button2_click() {
    this.data.closeDialogCallback();
    this.data.button2_click();
  }

  safeHTML(unsafe: string) {
    return this.sanitizer.bypassSecurityTrustHtml(unsafe);
  }
}
