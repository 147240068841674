import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserAccount } from '@shared/models/userAccount';
import { Router } from '@angular/router';
import { AdminService } from '@core/services/admin.service';
import { Role } from '@shared/enums/Role';
import { UtilityService } from '@core/services/utility.service';
import { AccountTypes } from '@shared/enums/AccountTypes';
import { GridApi } from 'ag-grid-community';
import { GlobalConstants } from '@shared/constants/global-constants';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { AccessTypes } from '@shared/enums/AccessTypes';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {
  //#region Variables
  addUserForm: FormGroup;
  confirm = false;
  data$: UserAccount;
  roles: string[];
  accessTypes: string[];
  accountTypes: string[];
  defaultValue: string;
  defaultValueDesc: string;
  body: string;
  selectedAccounts = this.getDefaultBlankAccountNumbers();
  gridOptions;
  gridApi: GridApi;
  hasAccounts = false;
  isGridFiltered = false;
  //#endregion

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private adminService: AdminService,
    private utilityService: UtilityService,
    private modalDialogService: ModalDialogService
  ) {}

  //#region Initializations
  ngOnInit(): void {
    this.initForm(this.formBuilder);
    this.roles = this.utilityService.getEnumList(Role);
    this.accountTypes = this.utilityService.getEnumList(AccountTypes);
    this.initGridConfig();
  }

  initGridConfig() {
    this.gridOptions = {
      columnDefs: this.createColumnDefs(),
      rowSelection: 'single',
      onGridReady: params => {
        this.gridApi = params.api;
        this.gridOptions.api.setRowData(this.selectedAccounts);
      },
      processDataFromClipboard: params => {
        this.onPasteHandler(params);
      }
    };
  }

  initForm(formBuilder: FormBuilder) {
    this.addUserForm = this.formBuilder.group({
      emailAddress: new FormControl('', [
        Validators.required,
        Validators.pattern(GlobalConstants.emailRegex)
      ]),
      selectedRole: new FormControl('', [Validators.required]),
      selectedAccessType: new FormControl('', [Validators.required]),
      selectedAccountType: new FormControl('', [Validators.required]),
      validate: new FormControl(this.confirm)
    });
  }

  get f() {
    return this.addUserForm.controls;
  }

  getDefaultBlankAccountNumbers(): any[] {
    const accountNumbers = [];
    for (let i = 0; i < 11; i++) {
      accountNumbers.push({ accountNumber: '' });
    }

    return accountNumbers;
  }
  //#endregion

  //#region Buttons
  onSubmit(event) {
    this.confirm = true;
    const emailAddress: string = this.f.emailAddress.value;
    this.f.emailAddress.setValue(emailAddress.trim());
    const role = event.target.role.value;
    this.f.selectedRole.setValue(role);
    if (!this.isInternalRole()) {
      this.f.selectedAccessType.setValue(event.target.accessType.value);
      this.f.selectedAccountType.setValue(event.target.accountType.value);
    }

    this.removeInvalidAccountNumbers();
    this.resetValidations(role);
    if (
      this.addUserForm.invalid ||
      !this.isAccessTypeValid() ||
      this.isAccountNumberRequired()
    ) {
      return;
    }

    this.spinner.show();

    const newEmailAddress = this.f.emailAddress.value;
    const newRole = this.f.selectedRole.value;
    const newAccessType = this.f.selectedAccessType.value;
    const newAccountNumbers = this.selectedAccounts.map(x => x.accountNumber);
    const newAccountType = this.f.selectedAccountType.value;
    const createBillingAccount = newAccountType === 'Billing' ? true : false;

    const g: UserAccount = new UserAccount(
      newAccountNumbers,
      newEmailAddress,
      newRole,
      newAccessType,
      createBillingAccount
    );
    this.addUserAccount(g).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data.items && data.items.length > 0) {
          this.modalDialogService.openErrorDialog(data.message);
          const accounts: any[] = [];
          data.items.forEach(x => {
            accounts.push({ accountNumber: x });
          });
          this.selectedAccounts = accounts;
        } else {
          this.modalDialogService.openSuccessDialog(data.message);
          this.selectedAccounts = this.getDefaultBlankAccountNumbers();
        }
      },
      error => {
        this.spinner.hide();
        const message = `Failed to add the user account - ${error.error}.`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  //If this validation is created as a validator in the initForm(), for some reason it does not fire properly.
  //For now just adding it as a validation called on submit.
  isAccessTypeValid() {
    const partner = this.utilityService.getEnumKeyByEnumValue(
      Role,
      Role.Partner.toString()
    );
    const readOnly = this.utilityService.getEnumKeyByEnumValue(
      AccessTypes,
      AccessTypes.ReadOnly.toString()
    );
    if (
      this.f.selectedRole.value === partner &&
      this.f.selectedAccessType.value !== readOnly
    ) {
      this.f.selectedAccessType.setErrors({ invalidAccessType: true });
      return false;
    }
    return true;
  }

  removeInvalidAccountNumbers() {
    // needed this line to save the last cell change.
    // Ex. while editing a cell in the grid, click on Save. The cell changes is not saved.
    this.gridOptions.api.setRowData(this.gridOptions.rowData);

    const filteredAccounts: any[] = [];
    this.selectedAccounts.forEach(x => {
      if (x.accountNumber !== undefined) {
        x.accountNumber = x.accountNumber.trim().replace(/[^a-zA-Z0-9]/g, '');
        if (x.accountNumber !== '') {
          filteredAccounts.push(x);
        }
      }
    });

    if (filteredAccounts.length > 0) {
      this.selectedAccounts = filteredAccounts;
      this.hasAccounts = true;
    } else {
      this.hasAccounts = false;
    }
  }

  resetValidations(role: string) {
    if (this.isInternalRole()) {
      this.f.selectedAccessType.setValue('');
      this.f.selectedAccessType.clearValidators();
      this.f.selectedAccountType.setValue('');
      this.f.selectedAccountType.clearValidators();
    } else {
      const accessType = this.f.selectedAccessType.value;
      if (accessType === '') {
        this.f.selectedAccessType.setValidators(Validators.required);
      }
      const accountType = this.f.selectedAccountType.value;
      if (accountType === '') {
        this.f.selectedAccountType.setValidators(Validators.required);
      }
    }
    this.f.selectedAccessType.updateValueAndValidity();
    this.f.selectedAccountType.updateValueAndValidity();
  }

  addUserAccount(userAccount: UserAccount) {
    return this.adminService.addUserAccount(userAccount);
  }

  onReset() {
    this.confirm = false;
    this.router.navigate([`/admin`]);
  }

  //#endregion

  //#region Other Methods
  isAccountNumberRequired(): boolean {
    return (
      this.confirm &&
      !this.hasAccounts &&
      (!this.isInternalRole() || this.f.selectedRole.value === '')
    );
  }

  isInternalRole() {
    const selectedRoleType: Role = (<any>Role)[this.f.selectedRole.value];
    if (this.utilityService.isInternalRole(selectedRoleType)) {
      return true;
    }
    return false;
  }
  //#endregion

  //#region Grid
  private createColumnDefs() {
    return [
      {
        headerName: 'Account Numbers',
        field: 'accountNumber',
        sortable: true,
        editable: true,
        filter: true,
        minWidth: 100,
        flex: 1,
        cellStyle: { justifyContent: 'left' },
        hide: false,
        resizable: false,
        suppressMenu: false,
        suppressColumnsToolPanel: false,
        menuTabs: ['filterMenuTab']
      }
    ];
  }

  addAccountNumber() {
    if (this.isInternalRole() || this.isGridFiltered) {
      return;
    }
    this.gridOptions.api.deselectAll();
    this.addNewItem(true);
  }

  addNewItem(goToLastRecord: boolean) {
    const newAccountNumber: any = { accountNumber: '' };

    this.gridOptions.rowData.push(newAccountNumber);

    const addedRow = this.gridOptions.api.applyTransaction({
      add: [newAccountNumber]
    });

    if (goToLastRecord) {
      addedRow.add[0].setSelected(true);
      this.gridOptions.api.ensureIndexVisible(
        addedRow.add[0].rowIndex,
        'bottom'
      );
    }
  }

  removeAccountNumber() {
    if (this.isInternalRole() || this.isGridFiltered) {
      return;
    }
    const selectedRow = this.gridApi.getFocusedCell();

    this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
    this.gridApi.setRowData(this.gridOptions.rowData);

    const currentTotalRows = this.gridOptions.api.getDisplayedRowCount();
    if (currentTotalRows === 0) {
      this.addNewItem(true);
    }
  }

  clearAllAccountNumbers() {
    if (this.isInternalRole() || this.isGridFiltered) {
      return;
    }
    this.gridOptions.rowData.splice(
      0,
      this.gridOptions.api.getDisplayedRowCount()
    );
    this.gridApi.setRowData(this.gridOptions.rowData);
    for (let i = 0; i < 11; i++) {
      this.addNewItem(false);
    }
  }
  //#endregion

  //#region Events
  onRoleChangedHandler(event) {
    const role = event.target.value;
    this.f.selectedRole.setValue(role);
    this.resetValidations(role);
    this.accessTypes = this.utilityService.getAccessTypesByRole(role);
    if (this.isInternalRole()) {
      this.selectedAccounts = this.getDefaultBlankAccountNumbers();
      this.defaultValue = '';
      this.defaultValueDesc = '';
    }
  }

  onAccessTypeChangedHandler() {
    this.f.selectedAccessType.clearValidators();
    this.f.selectedAccessType.updateValueAndValidity();
  }

  onAccountTypeChangedHandler() {
    this.f.selectedAccountType.clearValidators();
    this.f.selectedAccountType.updateValueAndValidity();
  }

  onKeyDownHandler(event) {
    this.utilityService.IgnoreWhiteSpace(event);
  }

  onFilterChangedHandler() {
    this.isGridFiltered = this.gridOptions.api.filterManager.advancedFilterPresent;
  }

  onPasteHandler(event) {
    const pastedData = event.data;
    let numberOfRowsToAdd = pastedData.length;
    if (numberOfRowsToAdd > 0) {
      const selectedRowIndex = this.gridApi.getFocusedCell().rowIndex;
      for (let i = 0; i < numberOfRowsToAdd; i++) {
        const newAccountNumber: any = { accountNumber: pastedData[i][0] };
        this.gridOptions.rowData.splice(
          i + selectedRowIndex,
          0,
          newAccountNumber
        );

        this.gridApi.setRowData(this.gridOptions.rowData);
      }
    }
  }
  //#endregion
}
