import { Injectable } from '@angular/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalSettings } from '@shared/models/ModalSettings';
import { ModalDialogTypes } from '@shared/enums/ModalDialogTypes';

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {
  public isDialogOpen = false;
  public data: ModalSettings;

  constructor(public modalDialog: MatDialog) {}

  /** sample call to openDialog. All data properties are optional.
   * let data: ModalSettings = {
   *  modalType: ModalDialogTypes.Confirmation,
   *  mainMessage: 'This is the main message. Click continue to proceed.',
   *  additionalMessage: 'This is for additional message.',
   *  closeButtonLabel: 'Close',
   *  button1Label: 'Continue',
   *  hideButton2: true,
   *  button1_click: () => { this.Continue(); }
   * };
   * this.modalDialogService.openDialog(data);
   */
  public openDialog(data) {
    if (this.isDialogOpen) {
      return false;
    }

    this.isDialogOpen = true;
    data.closeDialogCallback = () => {
      this.closeDialog();
    };

    this.modalDialog.open(ModalDialogComponent, {
      width: '600px',
      data,
      maxHeight: '85vh',
      disableClose: true
    });
  }

  /** sample call to openErrorDialog
   * this.modalDialogService.openErrorDialog('This is the main error message');
   * this.modalDialogService.openErrorDialog('This is the main error message', 'This is for additional error message.');
   * this.modalDialogService.openErrorDialog('This is the main error message', 'This is for additional error message.', 'Dismiss');
   */
  public openErrorDialog(
    mainMessage: string,
    additionalMessage = '',
    closeButtonLabel = 'Dismiss',
    closeButtonClick = () => {},
    title = ''
  ) {
    let errorData: ModalSettings = {
      modalType: ModalDialogTypes.Error,
      modalTitle: title,
      mainMessage: mainMessage,
      additionalMessage: additionalMessage,
      closeButtonLabel: closeButtonLabel,
      closeButton_click: closeButtonClick,
      hideButton1: true,
      hideButton2: true
    };

    this.openDialog(errorData);
  }

  /** sample call to openSuccessDialog
   * this.modalDialogService.openSuccessDialog('This is the main success message');
   * this.modalDialogService.openSuccessDialog('This is the main success message', 'This is for additional success message.');
   * this.modalDialogService.openSuccessDialog('This is the main success message', 'This is for additional success message.', 'Dismiss');
   */
  public openSuccessDialog(
    mainMessage: string,
    additionalMessage = '',
    closeButtonLabel = 'Dismiss',
    closeButtonClick = () => {},
    button1Label = '',
    button1Click = () => {},
    hideButton1 = true
  ) {
    let successData: ModalSettings = {
      modalType: ModalDialogTypes.Success,
      mainMessage: mainMessage,
      additionalMessage: additionalMessage,
      closeButtonLabel: closeButtonLabel,
      closeButton_click: closeButtonClick,
      button1Label: button1Label,
      button1_click: button1Click,
      hideButton1: hideButton1,
      hideButton2: true
    };

    this.openDialog(successData);
  }

  /** sample call to openConfirmationDialog
   * this.modalDialogService.openConfirmationDialog('Continue', () => { this.Continue(); }, '', null, 'This is the main message');
   * this.modalDialogService.openConfirmationDialog('Continue', () => { this.Continue(); }, '', null, 'This is the main message', 'This is for additional message.');
   * this.modalDialogService.openConfirmationDialog('Continue', () => { this.Continue(); }, '', null, 'This is the main message', 'This is for additional message.', 'Dismiss');
   * this.modalDialogService.openConfirmationDialog('Continue', () => { this.Continue(); }, 'Delete', () => { this.Delete(); }, 'This is the main message');
   */
  public openWarningDialog(
    button1Label: string,
    button1_click,
    button2Label: string,
    button2_click,
    mainMessage: string,
    title = '',
    additionalMessage = '',
    closeButtonLabel = 'Cancel',
    closeButtonClick = () => {}
  ) {
    let warningData: ModalSettings = {
      modalType: ModalDialogTypes.Warning,
      modalTitle: title,
      mainMessage: mainMessage,
      additionalMessage: additionalMessage,
      closeButtonLabel: closeButtonLabel,
      closeButton_click: closeButtonClick,
      button1Label: button1Label,
      button1_click: button1_click,
      button2Label: button2Label,
      button2_click: button2_click,
      hideButton2: button2Label === '' ? true : false
    };

    this.openDialog(warningData);
  }

  public openInformationDialog(
    mainMessage: string,
    additionalMessage = '',
    closeButtonLabel = 'Dismiss',
    closeButtonClick = () => {},
    button1Label = '',
    button1Click = () => {},
    hideButton1 = true
  ) {
    let informationData: ModalSettings = {
      modalType: ModalDialogTypes.Information,
      mainMessage: mainMessage,
      additionalMessage: additionalMessage,
      closeButtonLabel: closeButtonLabel,
      closeButton_click: closeButtonClick,
      button1Label: button1Label,
      button1_click: button1Click,
      hideButton1: hideButton1,
      hideButton2: true
    };

    this.openDialog(informationData);
  }

  public closeDialog() {
    if (this.isDialogOpen) {
      this.isDialogOpen = false;
      this.modalDialog.closeAll();
    }
  }
}
