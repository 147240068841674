export class GuestPayment {
  constructor(
    account: string,
    utilAccount: string,
    postalCode: string,
    allowedMethods: string
  ) {
    this.billingAccount = account;
    this.utilityAccount = utilAccount;
    this.postalCode = postalCode;
    this.paymethodTypes = allowedMethods;
  }

  guestStatement: Statement;
  engieAccountId: string;
  billingAccount: string;
  utilityAccount: string;
  postalCode: string;
  amount: string;
  utcTimestamp: string;
  paymethodTypes: string;
  paymentAmount: number;
  paymentStatus: string;
  paymentDate: Date;
  pastDueAmount: number;
  currentCharges: number;
  statementBalance: number;
  currentBalance: number;
}

export class Statement {
  statementBalance: number;
  outstandingBalance: number;
}
