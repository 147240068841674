import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AccountSettingsService } from '@core/services/account-settings.service';
import { UserAccount } from '@shared/models/userAccount';
import { AccountSettingAdd } from '@shared/models/accountSettingAdd';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-add-account-setting',
  templateUrl: './add-account-setting.component.html',
  styleUrls: ['./add-account-setting.component.css']
})
export class AddAccountSettingComponent implements OnInit {
  data$: UserAccount;
  addSettingForm: FormGroup;
  confirm = false;
  BillingAccountNumber$;
  body: string;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private settingsService: AccountSettingsService,
    private router: Router,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit(): void {
    this.initForm(this.formBuilder);
    this.onChangesBillingAccount();
    this.onChangesUtilityAccount();
  }

  initForm(formBuilder: FormBuilder) {
    this.addSettingForm = this.formBuilder.group({
      BillingAccountNumber: new FormControl({ value: '', disabled: false }, [
        Validators.required
      ]),
      UtilityAccountNumber: new FormControl({ value: '', disabled: false }, [
        Validators.required
      ])
    });
  }

  get f() {
    return this.addSettingForm.controls;
  }

  onChangesBillingAccount() {
    this.addSettingForm
      .get('BillingAccountNumber')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((val: string) => {
        if (val !== undefined && val !== null && val !== '') {
          this.addSettingForm.get('UtilityAccountNumber').reset();
          this.addSettingForm.get('UtilityAccountNumber').disable();
        } else {
          this.addSettingForm.get('UtilityAccountNumber').enable();
        }
      });
  }

  onChangesUtilityAccount() {
    this.addSettingForm
      .get('UtilityAccountNumber')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(val => {
        if (val !== undefined && val !== null && val !== '') {
          this.addSettingForm.get('BillingAccountNumber').reset();
          this.addSettingForm.get('BillingAccountNumber').disable();
        } else {
          this.addSettingForm.get('BillingAccountNumber').enable();
        }
      });
  }

  onSubmit(event) {
    this.confirm = true;

    if (this.f.BillingAccountNumber.value !== null) {
      this.f.BillingAccountNumber.setValue(
        this.f.BillingAccountNumber.value.trim()
      );
    }
    if (this.f.UtilityAccountNumber.value !== null) {
      this.f.UtilityAccountNumber.setValue(
        this.f.UtilityAccountNumber.value.trim()
      );
    }

    if (this.addSettingForm.invalid) {
      return;
    }

    this.spinner.show();

    this.BillingAccountNumber$ = this.f.BillingAccountNumber.value;

    const result: AccountSettingAdd = Object.assign(
      {},
      this.addSettingForm.value
    );

    this.addSetting(result).subscribe(
      data => {
        const message = 'The settings have been successfully added.';
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(message);
      },
      error => {
        console.log('Failed to add the settings to account.');
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(error.error);
      }
    );
  }

  addSetting(account: AccountSettingAdd) {
    return this.settingsService.addSetting(account);
  }

  onReset() {
    this.confirm = false;
    this.router.navigate([`/admin`], { queryParams: { tab: 2 } });
  }
}
