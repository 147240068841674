import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { mustMatch } from '@shared/validators/must-match.validator';

@Component({
  selector: 'app-ach-form',
  templateUrl: './ach-form.component.html',
  styleUrls: ['./ach-form.component.css']
})
export class AchFormComponent implements OnInit {
  accountTypes: string[];
  AchGroup: FormGroup;
  @Input() submitted: boolean;

  constructor(private formBuilder: FormBuilder) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.AchGroup.controls;
  }

  ngOnInit(): void {
    this.accountTypes = this.getAccountTypes();
  }

  createGroup() {
    if (this.AchGroup === undefined) {
      this.AchGroup = this.formBuilder.group(
        {
          RoutingNumber: new FormControl('', [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(9)
          ]),
          ConfirmRoutingNumber: new FormControl('', [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(9)
          ]),
          AccountNumber: new FormControl('', [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(17)
          ]),
          ConfirmAccountNumber: new FormControl('', [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(17)
          ]),
          AccountType: new FormControl('', [Validators.required])
        },
        {
          validators: [
            mustMatch('RoutingNumber', 'ConfirmRoutingNumber'),
            mustMatch('AccountNumber', 'ConfirmAccountNumber')
          ]
        }
      );
    }

    return this.AchGroup;
  }

  getAccountTypes() {
    const accountTypes = [];
    accountTypes.push({ key: 'c', value: 'Checking' });
    accountTypes.push({ key: 's', value: 'Savings' });
    return accountTypes;
  }

  onAccountTypeChangedHandler(e) {
    this.f.AccountType.setValue(e.target.form.selectedAccountType.value);
    this.f.AccountType.updateValueAndValidity();
  }
}
