import { Component, OnInit } from '@angular/core';
import { checkboxOptions } from '@shared/models/reporting/checkboxOptions';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AccountService } from '@core/services/account.service';
import { ReportInfo } from '@shared/models/reporting/reportInfo';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { GlobalService } from '@core/services/global.service';
import { IdentityService } from '@core/services/identity.service';
import { UserPreferenceService } from '@core/services/user-preference.service';
import { UtilityService } from '@core/services/utility.service';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.sass']
})
export class ReportingComponent implements OnInit {
  accountId: string;
  accountType: any;
  meterOptions: checkboxOptions[];
  billingOptions: checkboxOptions[];
  reportForm: FormGroup;
  selectedMeter: any;
  selectedBilling: any;
  isBefore: boolean;
  isDateEmpty: boolean;
  isNoOptionsSelected: boolean;
  emulate = false;
  savePreference = false;
  selectedAccountEngieIds: string[];
  selectedAccountNumbers: string[];
  numberOfAccounts: number;
  emailAddresses$;
  invalidEmailAddresses$;
  submitted: boolean = false;
  usersEmailAddress: string;

  constructor(
    private location: Location,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private identity: IdentityService,
    private userPreference: UserPreferenceService,
    private utilityService: UtilityService,
    private modalDialogService: ModalDialogService
  ) {
    this.selectedAccountNumbers = this.globalService.get(
      this.globalService.BULK
    )[0];
    this.selectedAccountEngieIds = this.globalService.get(
      this.globalService.BULK
    )[1];
    this.numberOfAccounts = this.selectedAccountEngieIds.length;

    this.meterOptions = [
      {
        id: 'StatementDate',
        name: 'Statement Date',
        description: 'Date the transaction processed',
        selected: true
      },
      {
        id: 'ContractName',
        name: 'Contract Name',
        description: 'The Business name as it appears on the contract',
        selected: true
      },
      {
        id: 'CustomerName',
        name: 'Customer Name',
        description: 'The Business name',
        selected: true
      },
      {
        id: 'SupplierAccountNumber',
        name: 'Supplier Account Name',
        description: 'A unique number assigned to the account by the supplier',
        selected: true
      },
      {
        id: 'BillingAccountNumber',
        name: 'Billing Account Number',
        description: '',
        selected: true
      },
      {
        id: 'UtilityAccountNumber',
        name: 'Utility Account Number',
        description: 'A unique number assigned to the account by the utility',
        selected: true
      },
      {
        id: 'ClientID',
        name: 'Client Id',
        description:
          'Customer level identifier assigned to the customer by the supplier',
        selected: true
      },
      {
        id: 'MeterNumber',
        name: 'Meter Number',
        description: 'A unique number assigned to your meter by the utility',
        selected: true
      },
      {
        id: 'MeterMultiplier',
        name: 'Meter Multiplier',
        description:
          'A fixed value which is used when converting meter readings into actual energy use',
        selected: true
      },
      {
        id: 'BeginningMeterRead',
        name: 'Beginning Meter Read',
        description: 'The meter reading at the beginning of billing period',
        selected: true
      },
      {
        id: 'StartDate',
        name: 'Start Date',
        description: 'Beginning meter read date',
        selected: true
      },
      {
        id: 'EndDate',
        name: 'End Date',
        description: 'Ending meter read date',
        selected: true
      },
      {
        id: 'Quantity',
        name: 'Quantity',
        description: 'Amount of electricity used',
        selected: true
      },
      {
        id: 'MeasurementUnit',
        name: 'Measurement Unit',
        description:
          'A measure of electric energy example: KW (Kilowatt), KWh (Kilowatt - hour)',
        selected: true
      }
    ];
    this.billingOptions = [
      {
        id: 'StatementId',
        name: 'Statement Id',
        description: '',
        selected: true
      },
      {
        id: 'StatementDate',
        name: 'Statement Date',
        description: 'Date the transaction processed',
        selected: true
      },
      {
        id: 'ContractName',
        name: 'Contract Name',
        description: 'The Business name as it appears on the contract',
        selected: true
      },
      {
        id: 'CustomerName',
        name: 'Customer Name',
        description: 'The Business name',
        selected: true
      },
      {
        id: 'SupplierAccountNumber',
        name: 'Supplier Account Name',
        description: 'A unique number assigned to the account by the supplier',
        selected: true
      },
      {
        id: 'BillingAccountNumber',
        name: 'Billing Account Number',
        description: '',
        selected: true
      },
      {
        id: 'UtilityAccountNumber',
        name: 'Utility Account Number',
        description: 'A unique number assigned to the account by the utility',
        selected: true
      },
      {
        id: 'BillingAddress',
        name: 'Billing Address',
        description: '',
        selected: true
      },
      {
        id: 'ServiceAddress',
        name: 'Service Address',
        description: '',
        selected: true
      },
      {
        id: 'TransactionType',
        name: 'Transaction Type',
        description: '',
        selected: true
      },
      { id: 'FromDate', name: 'From Date', description: '', selected: true },
      { id: 'ToDate', name: 'To Date', description: '', selected: true },
      { id: 'kWhUsage', name: 'kWh Usage', description: '', selected: true },
      {
        id: 'PreviousBalance',
        name: 'Previous Balance',
        description: '',
        selected: true
      },
      {
        id: 'CurrentCharges',
        name: 'Current Charges',
        description: '',
        selected: true
      },
      {
        id: 'StatementBalance',
        name: 'Statement Balance',
        description: '',
        selected: true
      },
      {
        id: 'DueDate',
        name: 'Statement Due Date',
        description: 'The date payment is due in order to avoid late charges',
        selected: true
      },
      {
        id: 'SupplierCharges',
        name: 'Supplier Charges',
        description: '',
        selected: true
      },
      {
        id: 'SettlementCharges',
        name: 'Settlement Charges',
        description: '',
        selected: true
      },
      {
        id: 'DeliveryAndPassThroughCharges',
        name: 'Delivery And Pass Through Charges',
        description: '',
        selected: true
      },
      {
        id: 'TDSPCharges',
        name: 'TDSP Charges',
        description: '',
        selected: true
      },
      {
        id: 'OtherCharges',
        name: 'Other Charges',
        description: '',
        selected: true
      },
      {
        id: 'SupplierChargesTotal',
        name: 'Supplier Charges Total',
        description: '',
        selected: true
      },
      {
        id: 'Taxes',
        name: 'Taxes',
        description: '',
        selected: true
      }
    ];
  }

  ngOnInit() {
    if (this.selectedAccountEngieIds.length > 0) {
      this.accountId = this.selectedAccountEngieIds[0];
    }

    this.getUserPreferenceOptions();
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.createFormOptions();
    this.subscribeToSelectAllCheckboxes();
    this.subscribeToCheckboxes();
    this.reportForm
      .get('emailAddresses')
      .setValue(
        this.globalService.get(this.identity.USER_LEVEL_ROLE_PERM).userName
      );
  }

  getUserPreferenceOptions() {
    let preference: any;
    const allPreference: any[] = this.userPreference.getPreferenceFromCache();
    const preferenceFiltered = allPreference.filter(x => {
      return x.type === this.userPreference.REPORT_PREFERENCE;
    });

    if (preferenceFiltered) {
      preference = preferenceFiltered[0];
    }

    if (preference) {
      const meterOptions = preference.meterOptions;
      if (meterOptions) {
        this.meterOptions = meterOptions;
      }

      const billingOptions = preference.billingOptions;
      if (billingOptions) {
        this.billingOptions = billingOptions;
      }
    }
  }

  createFormOptions() {
    const formBillingControls = this.billingOptions.map(control => {
      return new FormControl(control.selected);
    });

    const formMeterControls = this.meterOptions.map(control => {
      return new FormControl(control.selected);
    });

    const allMeterChecked = this.meterOptions.every(x => x.selected);
    const allBillingChecked = this.billingOptions.every(x => x.selected);
    const selectAllMeter = new FormControl(allMeterChecked);
    const selectAllBilling = new FormControl(allBillingChecked);

    this.reportForm = new FormGroup({
      meterOptions: new FormArray(formMeterControls),
      selectAllMeter: selectAllMeter,
      billingOptions: new FormArray(formBillingControls),
      selectAllBilling: selectAllBilling,
      fromDate: new FormControl(moment().subtract(7, 'days').toDate()),
      toDate: new FormControl(new Date()),
      emailAddresses: new FormControl(
        '',
        [Validators.required],
        //@ts-ignore
        this.emailValidator.bind(this)
      )
    });
  }

  emailValidator(g: FormGroup) {
    const emailAddresses: string = this.f.emailAddresses.value;
    const invalidEmailAddresses = this.utilityService.validateEmailAddresses(
      emailAddresses
    );

    if (invalidEmailAddresses === '') {
      this.f.emailAddresses.setErrors(null);
      this.invalidEmailAddresses$ = invalidEmailAddresses;
      return null;
    }

    this.f.emailAddresses.setErrors({ pattern: true });
    this.invalidEmailAddresses$ = invalidEmailAddresses;
    return { pattern: true };
  }

  get f() {
    return this.reportForm.controls;
  }

  submit() {
    this.isDateEmpty = false;
    this.isBefore = false;
    this.isNoOptionsSelected = false;
    this.submitted = true;

    if (
      this.reportForm.value.fromDate == null ||
      this.reportForm.value.toDate == null
    ) {
      window.scrollTo(10, 10);
      this.isDateEmpty = true;
      return;
    }

    if (this.reportForm.invalid) return;

    const fromDt = moment(this.reportForm.value.fromDate)
      .startOf('day')
      .toDate();
    const toDt = moment(this.reportForm.value.toDate).startOf('day').toDate();
    this.isBefore = moment(fromDt).isAfter(toDt);

    if (this.isBefore) {
      window.scrollTo(10, 10);
      return;
    }

    this.selectedMeter = this.reportForm.value.meterOptions
      .map((checked, index) => (checked ? this.meterOptions[index].id : null))
      .filter(value => value !== null);

    this.selectedBilling = this.reportForm.value.billingOptions
      .map((checked, index) => (checked ? this.billingOptions[index].id : null))
      .filter(value => value !== null);

    if (this.selectedMeter.length === 0 && this.selectedBilling.length === 0) {
      this.isNoOptionsSelected = true;
      return;
    }
    this.getReports();
  }

  getReportInfo(): ReportInfo {
    let billingDataString: any;
    billingDataString = '{';
    this.billingOptions.forEach(val => {
      const isSelected = this.selectedBilling.find(
        selected => selected === val.id
      );
      if (isSelected === undefined) {
        billingDataString += '"' + val.id + '" : ' + false + ',';
      } else {
        billingDataString += '"' + val.id + '" : ' + true + ',';
      }
    });

    const slicedBilling =
      billingDataString.slice(0, billingDataString.length - 1) + '}';
    const billingObj = JSON.parse(slicedBilling);

    let meterDataString: any;
    meterDataString = '{';
    this.meterOptions.forEach(val => {
      const isSelected = this.selectedMeter.find(
        selected => selected === val.id
      );
      if (isSelected === undefined) {
        meterDataString += '"' + val.id + '" : ' + false + ',';
      } else {
        meterDataString += '"' + val.id + '" : ' + true + ',';
      }
    });

    const slicedMeter =
      meterDataString.slice(0, meterDataString.length - 1) + '}';
    const meterObj = JSON.parse(slicedMeter);

    const reportInfo: ReportInfo = new ReportInfo();
    reportInfo.EngieAccountIds = this.selectedAccountEngieIds;
    reportInfo.HasBilling = <boolean>this.selectedBilling.length;
    reportInfo.HasMeter = <boolean>this.selectedMeter.length;
    reportInfo.BillingInfo = billingObj;
    reportInfo.MeterInfo = meterObj;

    reportInfo.FromDate = moment(this.reportForm.value.fromDate).format('L');
    reportInfo.ToDate = moment(this.reportForm.value.toDate).format('L');
    reportInfo.SavePreference = this.savePreference;
    reportInfo.EmailAddresses = this.reportForm.get('emailAddresses').value;

    return reportInfo;
  }

  getReports() {
    const data = this.getReportInfo();
    this.spinner.show();
    this.accountService.getReports(data).subscribe(
      result => {
        this.spinner.hide();
        this.userPreference
          .RefreshUserPreferenceCache()
          .subscribe(data => data);
        const message =
          'Your report is being processed. Please check your email for the attached report, usually delivered within a few minutes.';
        this.modalDialogService.openSuccessDialog(message);
      },
      error => {
        this.spinner.hide();
        window.scrollTo(10, 10);
        const message = error.error;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  subscribeToSelectAllCheckboxes() {
    this.reportForm.get('selectAllBilling').valueChanges.subscribe(bool => {
      this.reportForm
        .get('billingOptions')
        .patchValue(Array(this.billingOptions.length).fill(bool), {
          emitEvent: false
        });
    });
    this.reportForm.get('selectAllMeter').valueChanges.subscribe(bool => {
      this.reportForm
        .get('meterOptions')
        .patchValue(Array(this.meterOptions.length).fill(bool), {
          emitEvent: false
        });
    });
  }

  subscribeToCheckboxes() {
    this.reportForm.get('billingOptions').valueChanges.subscribe(bool => {
      const allChecked = bool.every((val, i, arr) => val === arr[0]);
      if (allChecked === true) {
        this.updateSelectAllCheckboxes('selectAllBilling', bool[0]);
      } else {
        this.updateSelectAllCheckboxes('selectAllBilling', false);
      }
    });
    this.reportForm.get('meterOptions').valueChanges.subscribe(bool => {
      const allChecked = bool.every((val, i, arr) => val === arr[0]);
      if (allChecked === true) {
        this.updateSelectAllCheckboxes('selectAllMeter', bool[0]);
      } else {
        this.updateSelectAllCheckboxes('selectAllMeter', false);
      }
    });
  }

  updateSelectAllCheckboxes(who: any, boolVal: any) {
    this.reportForm.get(who).patchValue(boolVal, { emitEvent: false });
  }

  cancel() {
    this.location.back();
  }
}
