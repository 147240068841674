import { Injectable } from '@angular/core';
import { USStates } from '@shared/models/usStates';
import { AccountService } from './account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate } from '@angular/common';
import { GlobalService } from '@core/services/global.service';
import { Router } from '@angular/router';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { Role } from '@shared/enums/Role';
import {
  DownloadedFileDetails,
  ZipFile
} from '@shared/models/downloadedFileDetails';
import { StatementDocument } from '@shared/models/statementDocument';
import Tooltip from '@engie-group/fluid-design-system/lib-esm/components/tooltip';
import { GlobalConstants } from '@shared/constants/global-constants';
import { BillingTypes } from '@shared/enums/BillingTypes';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  headerProfileNotifier: Subject<null> = new Subject<null>();

  constructor(
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private globalService: GlobalService
  ) {}

  public getUSStates(): USStates[] {
    return [
      { value: 'AL', description: 'Alabama' },
      { value: 'AK', description: 'Alaska' },
      { value: 'AZ', description: 'Arizona' },
      { value: 'AR', description: 'Arkansas' },
      { value: 'CA', description: 'California' },
      { value: 'CO', description: 'Colorado' },
      { value: 'CT', description: 'Connecticut' },
      { value: 'DE', description: 'Delaware' },
      { value: 'DC', description: 'District Of Columbia' },
      { value: 'FL', description: 'Florida' },
      { value: 'GA', description: 'Georgia' },
      { value: 'HI', description: 'Hawaii' },
      { value: 'ID', description: 'Idaho' },
      { value: 'IL', description: 'Illinois' },
      { value: 'IN', description: 'Indiana' },
      { value: 'IA', description: 'Iowa' },
      { value: 'KS', description: 'Kansas' },
      { value: 'KY', description: 'Kentucky' },
      { value: 'LA', description: 'Louisiana' },
      { value: 'ME', description: 'Maine' },
      { value: 'MD', description: 'Maryland' },
      { value: 'MA', description: 'Massachusetts' },
      { value: 'MI', description: 'Michigan' },
      { value: 'MN', description: 'Minnesota' },
      { value: 'MS', description: 'Mississippi' },
      { value: 'MO', description: 'Missouri' },
      { value: 'MT', description: 'Montana' },
      { value: 'NE', description: 'Nebraska' },
      { value: 'NV', description: 'Nevada' },
      { value: 'NH', description: 'New Hampshire' },
      { value: 'NJ', description: 'New Jersey' },
      { value: 'NM', description: 'New Mexico' },
      { value: 'NY', description: 'New York' },
      { value: 'NC', description: 'North Carolina' },
      { value: 'ND', description: 'North Dakota' },
      { value: 'OH', description: 'Ohio' },
      { value: 'OK', description: 'Oklahoma' },
      { value: 'OR', description: 'Oregon' },
      { value: 'PA', description: 'Pennsylvania' },
      { value: 'RI', description: 'Rhode Island' },
      { value: 'SC', description: 'South Carolina' },
      { value: 'SD', description: 'South Dakota' },
      { value: 'TN', description: 'Tennessee' },
      { value: 'TX', description: 'Texas' },
      { value: 'UT', description: 'Utah' },
      { value: 'VT', description: 'Vermont' },
      { value: 'VA', description: 'Virginia' },
      { value: 'WA', description: 'Washington' },
      { value: 'WV', description: 'West Virginia' },
      { value: 'WI', description: 'Wisconsin' },
      { value: 'WY', description: 'Wyoming' }
    ];
  }

  public base64ToArrayBuffer(base64: any): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  viewStatement(
    downloadedFile: DownloadedFileDetails,
    showErrorMessage: boolean = false
  ) {
    this.spinner.show();
    this.accountService
      .viewStatement(downloadedFile.engieStatementId)
      .subscribe(
        data => {
          const fileObject = { filename: '', fileUrl: '' };
          this.createFile(fileObject, 'pdf', data);
          window.open(fileObject.fileUrl, '_blank');
          this.spinner.hide();
        },
        error => {
          console.log(error.error);
          this.spinner.hide();
          if (showErrorMessage) {
            const errorModalName = 'errorModal';
            const errorModalBody = 'errorBody';
            if (
              document.getElementById(errorModalName) !== null &&
              document.getElementById(errorModalBody) !== null
            ) {
              document.getElementById(errorModalBody).textContent = error.error;
              document.getElementById(errorModalName).style.display = 'block';
            }
          }
        }
      );
  }

  downloadStatement(
    downloadedFile: DownloadedFileDetails,
    includeAccountName: boolean
  ) {
    this.spinner.show();
    this.accountService
      .downloadStatement(
        downloadedFile.engieAccountId,
        downloadedFile.engieStatementId,
        includeAccountName
      )
      .subscribe(
        data => {
          this.createAndDownloadFile(
            downloadedFile,
            data,
            includeAccountName,
            'pdf'
          );
          this.spinner.hide();
        },
        error => {
          console.log(error.error);
          this.spinner.hide();
        }
      );
  }

  downloadBackingSheet(
    downloadedFile: DownloadedFileDetails,
    includeAccountName: boolean,
    showErrorMessage: boolean = false
  ) {
    this.spinner.show();
    this.accountService
      .downloadBackingSheet(
        downloadedFile.engieAccountId,
        downloadedFile.engieStatementId,
        downloadedFile.engieInvoiceId,
        includeAccountName
      )
      .subscribe(
        data => {
          this.createAndDownloadFile(
            downloadedFile,
            data,
            includeAccountName,
            'xlsx'
          );
          this.spinner.hide();
        },
        error => {
          console.log(error.error);
          this.spinner.hide();
          if (showErrorMessage) {
            const errorModalName = 'errorModal';
            const errorModalBody = 'errorBody';
            if (
              document.getElementById(errorModalName) !== null &&
              document.getElementById(errorModalBody) !== null
            ) {
              document.getElementById(errorModalBody).textContent = error.error;
              document.getElementById(errorModalName).style.display = 'block';
            }
          }
        }
      );
  }

  zipStatements(accountDetails: DownloadedFileDetails[]) {
    this.spinner.show();
    let zipFile = new ZipFile();
    zipFile.accountDetails = accountDetails;
    this.accountService.zipStatements(zipFile).subscribe(
      data => {
        const currentDate = formatDate(new Date(), 'MMddyyyy_HHmm', 'en-US');
        const newFilename = 'ENGIE_Statements_' + currentDate;
        const fileObject = { filename: newFilename, fileUrl: '' };
        this.createFile(fileObject, 'zip', data);
        this.downloadFile(fileObject);
        this.spinner.hide();
        if (data.message) {
          this.openModal(data.message);
        }
      },
      error => {
        console.log(error.error);
        this.spinner.hide();
      }
    );
  }

  zipBackingSheets(accountDetails: DownloadedFileDetails[]) {
    this.spinner.show();
    let zipFile = new ZipFile();
    zipFile.accountDetails = accountDetails;
    this.accountService.zipBackingSheets(zipFile).subscribe(
      data => {
        const currentDate = formatDate(new Date(), 'MMddyyyy_HHmm', 'en-US');
        const newFilename = 'ENGIE_BackingSheets_' + currentDate;
        const fileObject = { filename: newFilename, fileUrl: '' };
        this.createFile(fileObject, 'zip', data);
        this.downloadFile(fileObject);
        this.spinner.hide();
        if (data.message) {
          this.openModal(data.message);
        }
      },
      error => {
        console.log(error.error);
        this.spinner.hide();
      }
    );
  }

  private openModal(body: string) {
    document.getElementById('errorBody').innerHTML = body;
    document.getElementById('errorModal').style.display = 'block';
  }

  private createAndDownloadFile(
    downloadedFile: DownloadedFileDetails,
    data: StatementDocument,
    includeAccountName: boolean,
    typeofFile: string
  ) {
    let accountName = downloadedFile.accountName;
    if (includeAccountName) {
      accountName = data.accountName;
    }
    accountName = accountName.replace(/\s/g, '');
    const statementIssueDate = formatDate(
      data.documentDate,
      'MMddyyyy',
      'en-US'
    );
    const newFilename =
      downloadedFile.accountNumber +
      '_' +
      accountName.trim() +
      '_' +
      data.documentId +
      '_' +
      statementIssueDate;
    const fileObject = { filename: newFilename, fileUrl: '' };
    this.createFile(fileObject, typeofFile, data);
    this.downloadFile(fileObject);
  }

  private createFile(fileObject, typeofFile: string, data: any) {
    let filename: string;
    let file: Blob;
    const byteArray = this.base64ToArrayBuffer(data.document);
    switch (typeofFile) {
      case 'pdf':
        filename = fileObject.filename + '.pdf';
        file = new Blob([byteArray], { type: 'application/pdf' });
        break;
      case 'xlsx':
        filename = fileObject.filename + '.xlsx';
        file = new Blob([byteArray], { type: 'application/xlsx' });
        break;
      case 'zip':
        filename = fileObject.filename + '.zip';
        file = new Blob([byteArray], { type: 'application/zip' });
        break;
    }
    const fileUrl = URL.createObjectURL(file);
    fileObject.filename = filename;
    fileObject.fileUrl = fileUrl;
  }

  private downloadFile(fileObject) {
    const downloadLink = document.createElement('a');
    downloadLink.href = fileObject.fileUrl;
    downloadLink.download = fileObject.filename;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  convertToAccessType(val: number): AccessTypes {
    if (val === 0) {
      return AccessTypes.Owner;
    } else if (val === 1) {
      return AccessTypes.Sharee;
    } else if (val === 2) {
      return AccessTypes.PowerSharee;
    } else if (val === 3) {
      return AccessTypes.ReadOnly;
    }
    return undefined;
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  getEnumList(ENUM: any): string[] {
    const myEnum = [];
    const objectEnumKeys = Object.keys(ENUM);
    const objectEnumValues = Object.values(ENUM);

    for (let i = 0; i < objectEnumKeys.length; i++) {
      myEnum.push({ key: objectEnumKeys[i], value: objectEnumValues[i] });
    }
    return myEnum;
  }

  getAccessTypesByRole(role: Role): string[] {
    let myEnum = [];
    switch (role) {
      case Role.Customer:
        myEnum = this.getEnumList(AccessTypes);
        break;
      case Role.Partner:
        myEnum.push({
          key: Object.keys(AccessTypes).find(
            key => AccessTypes[key] === AccessTypes.ReadOnly
          ),
          value: AccessTypes.ReadOnly.toString()
        });
        break;
    }
    return myEnum;
  }

  goToDetail(obj: object) {
    if (obj !== undefined && JSON.stringify(obj).length > 0) {
      this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
    }
    this.router.navigate(['/account/detail']);
  }

  goToHistory() {
    this.router.navigate(['/account/history']);
  }

  goToPayment(obj: object) {
    if (obj !== undefined && JSON.stringify(obj).length > 0) {
      this.globalService.set('ACCOUNT_LEVEL_ROLE_PERM', obj);
    }
    this.router.navigate(['/account/payment']);
  }

  goToPayMethod() {
    this.router.navigate(['/account/paymethods']);
  }

  goToUpdateBillingAddress(billingAccount: string, accountId: string) {
    const account: string[][] = [[billingAccount], [accountId]];
    this.globalService.remove(this.globalService.BULK);
    this.globalService.set(this.globalService.BULK, account);
    this.router.navigate(['/bulk-address']);
  }

  goToServices() {
    this.router.navigate(['/account/services']);
  }

  goToRoute(accountNumber: string, route: string, engieAccountId: string = '') {
    this.setGlobalBulkFromSelectedAccount(accountNumber, engieAccountId);
    this.router.navigate([`/${route}`]);
  }

  goToReporting(engieAccountId: string, accountNumber: string) {
    this.globalService.remove(this.globalService.REPORT);
    const selectedAccounts: string[] = [];
    selectedAccounts.push(engieAccountId);
    this.globalService.set(this.globalService.REPORT, selectedAccounts);
    this.setGlobalBulkFromSelectedAccount(accountNumber, engieAccountId);
    this.router.navigate(['/reporting']);
  }

  setGlobalBulkFromSelectedAccount(
    accountNumber: string,
    engieAccountId: string = ''
  ) {
    const selectedEngieAccounts: string[] = [];
    if (engieAccountId !== '') {
      selectedEngieAccounts.push(engieAccountId);
    }
    const selectedBillingAccounts: string[] = [];
    selectedBillingAccounts.push(accountNumber);

    const selectedAccounts = new Array(
      selectedBillingAccounts,
      selectedEngieAccounts
    );
    this.globalService.set(this.globalService.BULK, selectedAccounts);
  }

  setDetailContext(engieAccount: string, uan: string, isUcb: boolean) {
    const val = `${engieAccount}/${uan}/${isUcb}`;
    this.globalService.set(this.globalService.DETAIL, val);
  }

  validateEmailAddresses(emailAddresses: string): string {
    const listOfEmailAddresses = emailAddresses.split(',');
    const regexp = new RegExp(GlobalConstants.emailRegex);

    let invalidEmailAddresses = '';
    listOfEmailAddresses.forEach(x => {
      const emailAddressToValidate = x.trim();
      const isValid = regexp.test(emailAddressToValidate);
      if (!isValid) {
        if (invalidEmailAddresses !== '') {
          invalidEmailAddresses = invalidEmailAddresses + ', ';
        }
        invalidEmailAddresses = invalidEmailAddresses + emailAddressToValidate;
      }
    });

    return invalidEmailAddresses;
  }

  IgnoreWhiteSpace(event) {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }

  fieldWithCopyButton(
    divTag: HTMLDivElement,
    fieldValue: string,
    valueToCopy: string,
    tooltipTitle: string = 'Copy to clipboard'
  ) {
    let htmlString =
      fieldValue +
      `<nj-tooltip><span class="btn-simple">
      <i data-toggle='tooltip' title='` +
      tooltipTitle +
      `' style='font-size: 18px; vertical-align:-3px; padding-left:5px;'
      data-placement="right" class='material-icons md--blue-engie'>content_copy</i></span></nj-tooltip>`;
    divTag.innerHTML = htmlString.trim();
    const eButton = divTag.querySelectorAll('.btn-simple')[0];
    eButton.addEventListener('click', () => {
      this.copyToClipboard(valueToCopy);
      htmlString =
        fieldValue +
        `<nj-tooltip><span class="btn-simple">
          <i data-toggle='tooltip' title='Copied' style='font-size: 16px; vertical-align:-3px; padding-left:5px;'
          data-placement="right" class='material-icons md--blue-engie'>content_copy</i></span></nj-tooltip>`;
      divTag.innerHTML = htmlString.trim();
      const divTooltip = divTag.querySelectorAll('[data-toggle="tooltip"]')[0];
      const copyTooltip = new Tooltip(divTooltip as HTMLElement);
      copyTooltip.show();

      const eInnerButton = divTag.querySelectorAll('.btn-simple')[0];
      eInnerButton.addEventListener('mouseleave', () => {
        copyTooltip.hide();
        this.fieldWithCopyButton(divTag, fieldValue, valueToCopy, tooltipTitle);
      });
    });
  }

  copyToClipboard(valueToCopy: string) {
    const selBox = document.createElement('textarea');
    selBox.value = valueToCopy;
    navigator.clipboard
      .writeText(selBox.value)
      .then()
      .catch(e => console.error(e));
  }

  titleCase(value: string) {
    return value.replace(/(?:^|\s)\S/g, res => res.toUpperCase());
  }

  isInternalRole(role: Role) {
    if (
      role === Role.Admin ||
      role === Role.Care_Agent ||
      role === Role.Employee ||
      role === Role.Sales ||
      role === Role.Collections
    ) {
      return true;
    }
    return false;
  }

  getOtherValidRoles(currentRole: Role) {
    const isCurrentRoleInternalRole = this.isInternalRole(currentRole);
    const validRoles = [];
    const allRoles = this.getEnumList(Role);
    allRoles.forEach(x => {
      const roleType: Role = (<any>Role)[x['key']];
      if (this.isInternalRole(roleType) === isCurrentRoleInternalRole) {
        validRoles.push(x);
      }
    });
    return validRoles;
  }

  convertBooleanToYesNo(from: boolean) {
    if (from) return 'Yes';

    return 'No';
  }

  isUCB(billingType: string): boolean {
    return billingType === BillingTypes.BillReady ||
      billingType === BillingTypes.RateReady
      ? true
      : false;
  }

  notifyHeaderProfile() {
    this.headerProfileNotifier.next();
  }
}
