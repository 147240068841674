import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { Address } from '@shared/models/address';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.css']
})
export class PhoneFormComponent implements OnInit {
  @Output() saveBtnClicked = new EventEmitter<any>();
  @Input() selectedAccounts: string[];
  submitted: boolean;
  PhoneGroup: FormGroup;
  result: Address;

  constructor(private formBuilder: FormBuilder) {}

  get f() {
    return this.PhoneGroup.controls;
  }

  ngOnInit(): void {
    this.initForm(this.formBuilder);
  }

  initForm(formBuilder: FormBuilder) {
    this.PhoneGroup = formBuilder.group({
      EngieAccountIds: new FormControl(this.selectedAccounts),
      BillingContactNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(10)
      ])
    });
  }

  onSave() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.PhoneGroup.invalid) {
      return;
    }

    // Take a deep copy of the form-model
    this.result = Object.assign({}, this.PhoneGroup.value);

    this.saveBtnClicked.emit({ data: this.result, childType: 'phone' });
  }
}
