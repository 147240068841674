import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable, throwError, of } from 'rxjs';
import { HealthCheckerService } from '@core/services/health-checker.service';
import { environment } from '@environments/environment';
import { SystemIncidents } from '@shared/models/systemStatusAndIncidents';
import { BaseGridComponentComponent } from '@shared/components/base-grid-component/base-grid-component.component';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { SystemIncidentTypes } from '@shared/enums/SystemIncidentTypes';
import { tap, catchError, map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { GlobalConstants } from '@shared/constants/global-constants';
import { SslCertDetail } from '@shared/models/sslCertDetail';

@Component({
  selector: 'app-health-checker',
  templateUrl: './health-checker.component.html',
  styleUrls: ['./health-checker.component.css']
})
export class HealthCheckerComponent
  extends BaseGridComponentComponent
  implements OnInit {
  accountId: string;
  statementId: string;
  billingServicePointId: string;
  customerId: string;
  sslExpiration: string;

  defaultIndicator = 'indicator-yellow';
  successIndicator = 'indicator-green';
  failureIndicator = 'indicator-red';
  allSystemsGoText = 'All Systems Go!';
  allSystemsNoGoText = 'Houston, we have a problem!';
  allSystemsGoNoGoText = 'Running System Checks...';

  isAllSystemsGo: boolean;
  indicatorPortalStatus: string;
  indicatorBillingAccountPatch: string;
  indicatorPostAccountSearch: string;
  indicatorGetAccountByID: string;
  indicatorInvoiceSearch: string;
  indicatorPaymentSearch: string;
  indicatorServicePoint: string;
  indicatorStatementSearch: string;
  indicatorStatementSearchById: string;
  indicatorGetTaxExemptions: string;
  indicatorGetPayMethods: string;
  indicatorGetSendEmail: string;
  indicatorGetUsageHistory: string;
  indicatorGetCustomerSearch: string;
  indicatorGetChargesServiceFee: string;
  indicatorGetChargesPaymethodTypes: string;
  indicatorSslCertificate: string;

  rowHeight;
  columnDefs;
  rowSelection;
  paginationPageSize;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  systemIncidents$: SystemIncidents[];
  excelStyles: any;

  constructor(private healthCheck: HealthCheckerService) {
    super();
    this.paginationPageSize = GlobalConstants.gridDefaultPageSize;
    this.createColumnDefs();
  }

  ngOnInit(): void {
    this.indicatorPortalStatus = this.defaultIndicator;
    this.indicatorPostAccountSearch = this.defaultIndicator;
    this.indicatorGetAccountByID = this.defaultIndicator;
    this.indicatorBillingAccountPatch = this.defaultIndicator;
    this.indicatorInvoiceSearch = this.defaultIndicator;
    this.indicatorPaymentSearch = this.defaultIndicator;
    this.indicatorServicePoint = this.defaultIndicator;
    this.indicatorStatementSearch = this.defaultIndicator;
    this.indicatorStatementSearchById = this.defaultIndicator;
    this.indicatorGetTaxExemptions = this.defaultIndicator;
    this.indicatorGetPayMethods = this.defaultIndicator;
    this.indicatorGetSendEmail = this.defaultIndicator;
    this.indicatorGetUsageHistory = this.defaultIndicator;
    this.indicatorGetCustomerSearch = this.defaultIndicator;
    this.indicatorGetChargesServiceFee = this.defaultIndicator;
    this.indicatorGetChargesPaymethodTypes = this.defaultIndicator;
    this.indicatorSslCertificate = this.defaultIndicator;

    this.accountId = environment.HealthCheck.accountId;
    this.statementId = environment.HealthCheck.statementId;
    this.billingServicePointId = environment.HealthCheck.billingServicePointId;
    this.customerId = environment.HealthCheck.customerId;

    this.runAllSystemChecks();
  }

  async runAllSystemChecks() {
    let allStatuses: any[];
    await new Promise(res => {
      forkJoin([
        this.postAccountSearch(),
        this.getAccountById(),
        this.postBillingAccountPatch(),
        this.getPaymentSearch(),
        this.postServicePoint(),
        this.getInvoiceSearch(),
        this.postStatementSearch(),
        this.getStatementSearch(),
        this.getTaxExceptions(),
        this.getPayMethods(),
        this.getSystemStatusAndIncidents(),
        this.getSendEmail(),
        this.getUsageHistory(),
        this.getCustomerSearch(),
        this.getChargesServiceFee(),
        this.getChargesPaymethodTypes(),
        this.getSslCerExpiration()
      ]).subscribe(
        docs => {
          res(docs);
          allStatuses = docs;
        },
        error => {
          console.log('At least one check failed to connect.');
          this.allSystemsGoNoGoText = this.allSystemsNoGoText;
          this.isAllSystemsGo = false;
        }
      );
    });

    this.setOverallStatus(allStatuses);
  }

  setOverallStatus(allStatuses: any[]) {
    this.isAllSystemsGo = allStatuses.every(x => x === true);
    this.allSystemsGoNoGoText = this.isAllSystemsGo
      ? this.allSystemsGoText
      : this.allSystemsNoGoText;
    console.log(this.allSystemsGoNoGoText);
  }

  getTaxExceptions(): Observable<boolean> {
    return this.healthCheck.getTaxExceptions(this.billingServicePointId).pipe(
      tap(data => {
        if (data) {
          this.indicatorGetTaxExemptions = this.successIndicator;
        } else {
          this.indicatorGetTaxExemptions = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getTaxExceptions');
        return throwError(err);
      })
    );
  }

  getPayMethods(): Observable<boolean> {
    return this.healthCheck.getPayMethods(this.accountId).pipe(
      tap(data => {
        if (data) {
          this.indicatorGetPayMethods = this.successIndicator;
        } else {
          this.indicatorGetPayMethods = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getPayMethods');
        return throwError(err);
      })
    );
  }

  getSendEmail(): Observable<boolean> {
    return this.healthCheck.getSendEmail().pipe(
      tap(data => {
        if (data) {
          this.indicatorGetSendEmail = this.successIndicator;
        } else {
          this.indicatorGetSendEmail = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getSendEmail');
        return throwError(err);
      })
    );
  }

  getCustomerSearch(): Observable<boolean> {
    return this.healthCheck.getCustomerSearch(this.customerId).pipe(
      tap(data => {
        if (data) {
          this.indicatorGetCustomerSearch = this.successIndicator;
        } else {
          this.indicatorGetCustomerSearch = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getCustomerSearch');
        return throwError(err);
      })
    );
  }

  getUsageHistory(): Observable<boolean> {
    return this.healthCheck.getUsageHistory(this.accountId, '2021').pipe(
      tap(data => {
        if (data) {
          this.indicatorGetUsageHistory = this.successIndicator;
        } else {
          this.indicatorGetUsageHistory = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getSendEmail');
        return throwError(err);
      })
    );
  }

  getChargesServiceFee(): Observable<boolean> {
    return this.healthCheck.getChargesServiceFee('Credit Card', '200').pipe(
      tap(data => {
        if (data) {
          this.indicatorGetChargesServiceFee = this.successIndicator;
        } else {
          this.indicatorGetChargesServiceFee = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getChargesServiceFee');
        return throwError(err);
      })
    );
  }

  getChargesPaymethodTypes(): Observable<boolean> {
    return this.healthCheck.getChargesPaymethodTypes(this.accountId).pipe(
      tap(data => {
        if (data) {
          this.indicatorGetChargesPaymethodTypes = this.successIndicator;
        } else {
          this.indicatorGetChargesPaymethodTypes = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getChargesPaymethodTypes');
        return throwError(err);
      })
    );
  }

  getStatementSearch(): Observable<boolean> {
    return this.healthCheck.getStatementSearch(this.statementId).pipe(
      tap(data => {
        if (data) {
          this.indicatorStatementSearchById = this.successIndicator;
        } else {
          this.indicatorStatementSearchById = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getStatementSearch');
        return throwError(err);
      })
    );
  }

  postStatementSearch(): Observable<boolean> {
    return this.healthCheck.postStatementSearch(this.accountId).pipe(
      tap(data => {
        if (data) {
          this.indicatorStatementSearch = this.successIndicator;
        } else {
          this.indicatorStatementSearch = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: postStatementSearch');
        return throwError(err);
      })
    );
  }

  postServicePoint(): Observable<boolean> {
    return this.healthCheck.postServicePoint(this.accountId).pipe(
      tap(data => {
        if (data) {
          this.indicatorServicePoint = this.successIndicator;
        } else {
          this.indicatorServicePoint = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: postServicePoint');
        return throwError(err);
      })
    );
  }

  getPaymentSearch(): Observable<boolean> {
    return this.healthCheck.getPaymentSearch(this.accountId).pipe(
      tap(data => {
        if (data) {
          this.indicatorPaymentSearch = this.successIndicator;
        } else {
          this.indicatorPaymentSearch = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getPaymentSearch');
        return throwError(err);
      })
    );
  }

  getInvoiceSearch(): Observable<boolean> {
    return this.healthCheck.getInvoiceSearch(this.statementId).pipe(
      tap(data => {
        if (data) {
          this.indicatorInvoiceSearch = this.successIndicator;
        } else {
          this.indicatorInvoiceSearch = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getInvoiceSearch');
        return throwError(err);
      })
    );
  }

  postAccountSearch(): Observable<boolean> {
    return this.healthCheck.postAccountSearch(this.accountId).pipe(
      tap(data => {
        if (data) {
          this.indicatorPostAccountSearch = this.successIndicator;
        } else {
          this.indicatorPostAccountSearch = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: postAccountSearch');
        return throwError(err);
      })
    );
  }

  getAccountById(): Observable<boolean> {
    return this.healthCheck.getAccountById(this.accountId).pipe(
      tap(data => {
        if (data) {
          this.indicatorGetAccountByID = this.successIndicator;
        } else {
          this.indicatorGetAccountByID = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: getAccountById');
        return throwError(err);
      })
    );
  }

  postBillingAccountPatch(): Observable<boolean> {
    return this.healthCheck.postBillingAccountPatch(this.accountId).pipe(
      tap(data => {
        if (data) {
          this.indicatorBillingAccountPatch = this.successIndicator;
        } else {
          this.indicatorBillingAccountPatch = this.failureIndicator;
        }
      }),
      catchError(err => {
        console.log('Failed: postBillingAccountPatch');
        return throwError(err);
      })
    );
  }

  getSystemStatusAndIncidents(): Observable<boolean> {
    return this.healthCheck.getSystemStatusAndIncidents().pipe(
      tap(data => {
        if (data.systemStatus.isPortalUp) {
          this.indicatorPortalStatus = this.successIndicator;
        } else {
          this.indicatorPortalStatus = this.failureIndicator;
        }
        this.systemIncidents$ = data.systemIncidents;
        console.log('Succeeded: getSystemStatusAndIncidents');
      }),
      map(result => result.systemStatus.isPortalUp),
      catchError(err => {
        console.log('Failed: getSystemStatusAndIncidents');
        return throwError(err);
      })
    );
  }

  getSslCerExpiration(): Observable<boolean> {
    var userdomain = location.host;

    return this.healthCheck.getCertificates(userdomain).pipe(
      tap(data => {
        this.sslExpiration = data.expiryDate;

        if (data.isValid) {
          this.indicatorSslCertificate = this.successIndicator;
        } else {
          this.indicatorSslCertificate = this.failureIndicator;
        }
        console.log('Succeeded: getSslCerExpiration');
      }),
      map(result => result.isValid),
      catchError(error => {
        console.log('Failed: getSslCertificates');
        return throwError(error);
      })
    );
  }

  private createColumnDefs() {
    this.columnDefs = [
      {
        headerName: '',
        field: 'incidentType',
        sortable: false,
        editable: false,
        filter: false,
        maxWidth: 35,
        minWidth: 35,
        cellStyle: { justifyContent: 'center' },
        hide: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        cellRenderer: this.getIncidentTypeIcon.bind(this)
      },
      {
        headerName: 'INCIDENT TYPE',
        field: 'statusDescription',
        sortable: true,
        editable: false,
        filter: true,
        width: 30,
        cellStyle: { justifyContent: 'left' },
        hide: false,
        resizable: true,
        cellRenderer: this.getIncidentType.bind(this)
      },
      {
        headerName: 'INCIDENT DATE',
        field: 'incidentDateTime',
        sortable: true,
        editable: false,
        width: 30,
        filter: true,
        hide: false,
        cellStyle: { justifyContent: 'left' },
        resizable: true,
        valueFormatter: super.datetimeFormatter,
        cellClass: 'textFormat'
      },
      {
        headerName: 'DESCRIPTION',
        field: 'description',
        sortable: true,
        editable: false,
        filter: true,
        width: 100,
        cellStyle: { justifyContent: 'left' },
        hide: false,
        resizable: true
      }
    ];
    this.rowHeight = 40;
    this.rowSelection = 'single';
  }

  getIncidentType(params) {
    const incidentType: SystemIncidentTypes = (<any>SystemIncidentTypes)[
      params.data.incidentType
    ];
    return incidentType.toString();
  }

  getIncidentTypeIcon(params) {
    const self = this;
    let htmlString;
    const incidentType: SystemIncidentTypes =
      SystemIncidentTypes[params.data.incidentType];
    switch (incidentType) {
      case SystemIncidentTypes.Operational:
        htmlString = `<span class="material-icons md--green-pine">check_circle</span>`;
        break;
      case SystemIncidentTypes.DegradedPerformance:
        htmlString = `<span class="material-icons md--blue-rhino">arrow_circle_down</span>`;
        break;
      case SystemIncidentTypes.PartialDisruption:
        htmlString = `<span class="material-icons md--orange">remove_circle</span>`;
        break;
      case SystemIncidentTypes.MajorDisruption:
        htmlString = `<span class="material-icons md--red">cancel</span>`;
        break;
      case SystemIncidentTypes.Maintenance:
        htmlString = `<span class="material-icons md--blue-corporate bg-white">settings_applications</span>`;
        break;
      default:
        htmlString = `<span class="material-icons md--yellow">help</span>`;
    }
    const divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();
    return divTag;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onPageSizeChanged(selectedValue: number) {
    const value = selectedValue;
    this.paginationPageSize = Number(value);
    this.gridApi.paginationSetPageSize(Number(value));
  }

  exportToExcel() {
    const params = {
      columnWidth: 100,
      sheetName: 'EventLog',
      processCellCallback: this.processExportedCell
    };
    this.gridApi.exportDataAsExcel(params);
  }

  processExportedCell(params) {
    if (params.column.colId === 'incidentDateTime') {
      if (params.value != null) {
        return formatDate(params.value, 'medium', 'en-US');
      }
      return null;
    } else if (
      params.column.colId === 'status_1' ||
      params.column.colId === 'status_2'
    ) {
      return null;
    } else {
      return params.value;
    }
  }
}
