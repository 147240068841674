import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  formatCurrency,
  formatDate,
  getCurrencySymbol,
  Location
} from '@angular/common';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { AccountService } from '@core/services/account.service';
import { Payment } from '@shared/models/payment';
import * as moment from 'moment/moment';
import { environment } from '@environments/environment';
import { PayDetail } from '@shared/models/payDetail';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '@core/services/utility.service';
import { GlobalService } from '@core/services/global.service';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { AccessPermission } from '@shared/enums/AccessPermission';
import { IdentityService } from '@core/services/identity.service';
import { DownloadedFileDetails } from '@shared/models/downloadedFileDetails';
import { PayMethod } from '@shared/models/payMethod';
import { ChargesService } from '@core/services/charges.service';
import { Router } from '@angular/router';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class PaymentComponent implements OnInit, OnChanges {
  paymentDetail$: PayDetail;
  emulate: boolean;
  selectPaymethod;
  selectPayAmount;
  defaultPaymethod: PayMethod;
  isCreditCard = false;
  isACH = false;
  charges;
  totalAmount;
  submitted = false;
  accountId;
  accountType;
  amount;
  paymentDate;
  authorizationCode;
  revoked = false;
  settingSet = false;
  expirySet = false;
  expiryDateSet = false;
  body: string = '';
  showReviewModalBannerMessage = false;
  display = 'none';
  engieStatementId;
  today = moment().format();
  payForm: FormGroup;
  disableSubmitBtn = false;
  hasManagePayMethod = false;
  selectedPaymentMethodNickname;
  forteServiceFee = `${environment.forteServiceFee}`;
  MSG_NOTFOUND = `This account is not set up in the payment system. Please contact customer care at ${environment.customerCarePhoneAlt} (${environment.customerCarePhone}) or ${environment.customerCareEmail}.`;
  MSG_NOTELIGIBLE = `This account is not eligible for ACH Payments. For questions, or to make a payment by phone, contact Customer Care at ${environment.customerCarePhoneAlt} (${environment.customerCarePhone}).`;
  MSG_SERVICEFEE = `${environment.forteServiceFeeMsg}`;
  MSG_ACHADVISORY = `${environment.fiservAchAdvisoryMsg}`;
  MSG_PAYMENTNOTE = `${environment.paymentNoteMsg}`;
  ADD_PAYMENTMETHOD_NICKNAME = 'Add Payment Method';
  isOtherAmountSelected = false;
  otherAmount = 0;
  Other = 'Other';
  @Input() engieAccountId;
  @Input() showVerticalMenu = true;

  constructor(
    private accountService: AccountService,
    private location: Location,
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private chargesService: ChargesService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private identity: IdentityService,
    private router: Router,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.engieAccountId) {
      this.accountId = this.engieAccountId;
      this.spinner.show();
      this.getPaymentDetail(this.accountId);
      this.initPayForm(this.formBuilder);
    }
  }

  ngOnInit() {
    if (this.engieAccountId) {
      this.accountId = this.engieAccountId;
    } else {
      const detail: string = this.globalService.get(this.globalService.DETAIL);
      this.accountId = detail?.split('/')[0];
    }
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }

    if (this.accountId) {
      this.spinner.show();
      this.getPaymentDetail(this.accountId);
      this.initPayForm(this.formBuilder);
    }
  }

  initPayForm(formBuilder: FormBuilder) {
    this.payForm = this.formBuilder.group({
      Amount: new FormControl(this.selectPayAmount, [Validators.required]),
      OtherAmount: new FormControl(this.otherAmount, [Validators.required]),
      Date: new FormControl(this.today, [Validators.required]),
      PaymethodTokenId: new FormControl(this.selectPaymethod, [
        Validators.required
      ])
    });
  }

  getPaymentDetail(accountId: string) {
    this.accountService.getPayMethodList(accountId).subscribe(
      data => {
        this.paymentDetail$ = data;
        if (this.paymentDetail$.payMethods === null) {
          this.paymentDetail$.payMethods = [];
        }
        this.addNewPaymentMethodOption(this.paymentDetail$.payMethods);
        if (this.paymentDetail$.payMethods.length > 0) {
          this.defaultPaymethod = this.getDefaultPayMethod(
            this.paymentDetail$.payMethods
          );
          this.f.PaymethodTokenId.setValue(
            this.defaultPaymethod.payMethodToken
          );
          this.isCreditCard =
            this.defaultPaymethod.type === 'creditcard' ? true : false;
          this.selectedPaymentMethodNickname = this.defaultPaymethod.nickname;
          this.onChangeMethodInner(
            0,
            this.defaultPaymethod.nickname,
            this.defaultPaymethod.type
          );
        }

        this.engieStatementId = this.paymentDetail$.engieStatementId;
        const accessType: AccessTypes = this.utilityService.convertToAccessType(
          this.paymentDetail$.accessType
        );
        this.hasManagePayMethod = this.identity.hasAccessPermission(
          AccessPermission.Manage_PayMethods,
          accessType
        );
        this.checkPaymentRestricted();
        this.spinner.hide();
      },
      error => {
        this.body = this.MSG_NOTFOUND;
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(this.body);
      }
    );
  }

  addNewPaymentMethodOption(paymethods: PayMethod[]) {
    let newPaymethod = new PayMethod();
    newPaymethod.isDefault = paymethods.length > 0 ? 'false' : 'true';
    newPaymethod.nickname = this.ADD_PAYMENTMETHOD_NICKNAME;
    newPaymethod.type = 'add';
    paymethods.push(newPaymethod);
  }

  getDefaultPayMethod(payMethods: PayMethod[]) {
    let defaultPayMethod = payMethods.find(x => x.isDefault === 'true');
    return defaultPayMethod === undefined ? payMethods[0] : defaultPayMethod;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.payForm.controls;
  }

  async onSubmit(e) {
    this.submitted = true;

    if (e.target.form.selectPaymethod !== undefined) {
      this.f.PaymethodTokenId.setValue(e.target.form.selectPaymethod.value);
    }
    if (this.isOtherAmountSelected) {
      this.f.Amount.setValue(this.payForm.get('OtherAmount').value);
    }

    if (this.payForm.invalid) {
      return;
    }

    let confirmationMessage: string;
    let paymentAmount = `${formatCurrency(
      this.paymentDetail$.paymentAmount,
      'en-US',
      getCurrencySymbol('USD', 'wide')
    )}`;
    let paymentDate = formatDate(
      this.paymentDetail$.paymentDate,
      'MM/dd/yyyy',
      'en_US'
    );
    if (this.paymentDetail$.paymentStatus === 'Pending') {
      confirmationMessage =
        'A payment of ' +
        paymentAmount +
        ' was submitted on ' +
        paymentDate +
        ' and is pending, are you sure you want to make an additional payment?';
      this.body = confirmationMessage;
      this.showReviewModalBannerMessage = true;
    }
    if (this.paymentDetail$.paymentStatus === 'Scheduled') {
      confirmationMessage =
        'A payment of ' +
        paymentAmount +
        ' is scheduled for ' +
        paymentDate +
        ', are you sure you want to make an additional payment?';
      this.body = confirmationMessage;
      this.showReviewModalBannerMessage = true;
    }
    this.modalDialogService.openWarningDialog(
      'Submit Payment',
      () => {
        this.sendPayment();
      },
      '',
      null,
      this.getReviewModalText(),
      'Review Payment',
      this.reviewModalBannerMessage()
    );
  }

  sendPayment() {
    // Take a deep copy of the form-model
    const result: Payment = Object.assign({}, this.payForm.value);
    this.spinner.show();
    this.accountService
      .sendPayment(
        this.accountId,
        result.Amount,
        this.charges ? this.charges.serviceFee : 0,
        this.charges ? this.charges.totalAmount : result.Amount,
        result.Date,
        result.PaymethodTokenId
      )
      .subscribe(response => {
        this.reset();
        if (!response.success) {
          this.spinner.hide();
          this.body = `Status: ${response.status} - ${response.errorMessage} `;
          this.modalDialogService.openErrorDialog(this.body);
        } else {
          this.paymentDate = result.Date;
          this.amount = result.Amount;
          this.authorizationCode = response.authorizationCode;
          this.modalDialogService.openSuccessDialog(
            this.getPaymentSuccessMainMessage(),
            this.getPaymentSuccessAdditionalMessage(),
            'Dismiss',
            () => {
              if (this.showVerticalMenu) {
                this.goBack();
              } else {
                this.ngOnInit();
              }
            }
          );
          this.spinner.hide();
        }
      });
  }

  reset() {
    this.submitted = false;
    this.payForm.get('Amount').reset('');
    this.isOtherAmountSelected = false;
    this.payForm.get('OtherAmount').reset(0);
    this.payForm.get('Date').setValue = this.today.toString;
    this.payForm.get('PaymethodTokenId').reset('');
  }

  checkPaymentRestricted() {
    // Collections requirement: check if Payment should be restricted due to NSF.
    // Check AccountSettings container for accounts that are restricted.
    // Must check if expiration date has passed as well.
    this.paymentDetail$.settings.forEach(setting => {
      setting.settings.forEach(s => {
        if (s.key === 'paymentRestricted' && s.value === 'true') {
          this.settingSet = true;
          s.extendedProperties.forEach(ep => {
            if (ep.key === 'setExpiryDate' && ep.value === 'true') {
              this.expirySet = true;
            }
            if (ep.key === 'expiryDate') {
              if (Date.parse(ep.value) > Date.now()) {
                this.expiryDateSet = true;
              }
            }
          });
        }
      });
    });

    if (this.settingSet && this.expirySet && this.expiryDateSet) {
      this.revoked = true;
    } else if (this.settingSet && !this.expirySet) {
      this.revoked = true;
    } else {
      this.revoked = false;
    }
  }

  viewStatement() {
    this.utilityService.viewStatement(
      new DownloadedFileDetails(null, null, this.engieStatementId, null, null)
    );
  }

  onChangeAmount(amount: any) {
    let maximumAllowedAmount = Number(
      this.paymentDetail$.accountOutstandingBalance
    );
    if (amount === 'Other') {
      this.isOtherAmountSelected = true;
      this.f.OtherAmount.setValidators([
        Validators.min(1),
        Validators.max(maximumAllowedAmount)
      ]);
      this.f.OtherAmount.updateValueAndValidity();
      this.f.Amount.clearValidators();
      this.f.Amount.updateValueAndValidity();
    } else {
      this.isOtherAmountSelected = false;
      this.f.Amount.setValue(amount);
      this.f.Amount.setValidators([
        Validators.required,
        Validators.min(0.01),
        Validators.max(maximumAllowedAmount)
      ]);
      this.f.Amount.updateValueAndValidity();
      this.f.OtherAmount.clearValidators();
      this.f.OtherAmount.updateValueAndValidity();
    }
    if (this.isCreditCard && amount !== 'Other') {
      this.disableSubmitBtn = true;
      this.getChargeFees('creditcard', amount);
    }
    if (
      this.isCreditCard &&
      amount === 'Other' &&
      this.f.OtherAmount.value !== ''
    ) {
      this.disableSubmitBtn = true;
      this.getChargeFees('creditcard', this.f.OtherAmount.value);
    }
  }

  onChangeOtherAmount(e: any) {
    if (e.target.value === '$') {
      this.f.OtherAmount.setValue(0);
    }
    this.f.OtherAmount.setValue(
      parseFloat(this.f.OtherAmount.value).toFixed(2)
    );
    if (this.isCreditCard) {
      this.disableSubmitBtn = true;
      this.getChargeFees('creditcard', this.f.OtherAmount.value);
    }
  }

  onChangeMethod(index: number, element: any) {
    this.onChangeMethodInner(index, element.nickname, element.type);
  }

  onChangeMethodInner(index: number, nickname: string, type: string) {
    if (nickname === this.ADD_PAYMENTMETHOD_NICKNAME) {
      this.router.navigateByUrl(`/account/paymethods`);
      return;
    }
    this.selectedPaymentMethodNickname = nickname;
    this.f.PaymethodTokenId.clearValidators();
    this.f.PaymethodTokenId.updateValueAndValidity();
    this.isCreditCard = false;
    this.charges = null;
    if (type === 'creditcard') {
      this.isCreditCard = true;
      this.isACH = false;
      this.disableSubmitBtn = false;
      if (this.isOtherAmountSelected) {
        this.getChargeFees('creditcard', this.f.OtherAmount.value);
      } else {
        this.getChargeFees('creditcard', this.f.Amount.value);
      }
    } else if (type === 'checking' || type === 'savings') {
      this.isCreditCard = false;
      this.isACH = true;
      if (this.revoked) {
        this.disableSubmitBtn = true;
      }
    }
  }

  getChargeFees(type: string, amount: any) {
    this.chargesService.getServiceFee(type, amount).subscribe(data => {
      this.charges = JSON.parse(data.message);
      this.disableSubmitBtn = false;
    });
  }

  goBack() {
    this.location.back();
  }

  getReviewModalText(): string {
    return `
  <div class="row">
    <div class="col">
      <div class="review-modal-header">Billing Account:</div>
    </div>
    <div class="col">
      <div>
        ${this.paymentDetail$.accountId}
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <div class="review-modal-header">Payment Amount:</div>
    </div>
    <div class="col">
      <div>${formatCurrency(
        this.f.Amount.value,
        'en-US',
        getCurrencySymbol('USD', 'wide')
      )}</div>
    </div>
  </div>
  ${
    this.isCreditCard && this.charges
      ? `<div class="row mt-2">
    <div class="col">
      <div class="review-modal-header">Credit Card Service Fee:</div>
    </div>
    <div class="col">
      <div>${formatCurrency(
        this.charges.serviceFee,
        'en-US',
        getCurrencySymbol('USD', 'wide')
      )}</div>
    </div>
  </div>`
      : ''
  }
  ${
    this.isCreditCard && this.charges
      ? `<div class="row mt-2">
    <div class="col">
      <div class="review-modal-header">Total Amount:</div>
    </div>
    <div class="col">
      <div>${formatCurrency(
        this.charges.totalAmount,
        'en-US',
        getCurrencySymbol('USD', 'wide')
      )}</div>
    </div>
  </div>`
      : ''
  }
  <div class="row mt-2">
    <div class="col">
      <div class="review-modal-header">Payment Date:</div>
    </div>
    <div class="col">
      <div>${formatDate(this.f.Date.value, 'MM/dd/yyyy', 'en_US')}</div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <div class="review-modal-header">Payment Method:</div>
    </div>
    <div class="col">
      <div>${this.selectedPaymentMethodNickname}</div>
    </div>
  </div>`;
  }

  reviewModalBannerMessage(): string {
    return `
    ${
      this.showReviewModalBannerMessage
        ? `
    <ng-container>
      <div class="row justify-content-center">
        <div class="pr-1 pl-1 pb-0" style="font-size: 0.8rem; text-align: left">
          <nj-alert>
            <div class="nj-alert nj-alert--danger mb-0 mt-4" role="alert">
              <i class="material-icons nj-alert__icon">error</i>
              <div>
                ${this.body}
              </div>
            </div>
          </nj-alert>
        </div>
      </div>
    </ng-container>
    `
        : ''
    }`;
  }

  getPaymentSuccessMainMessage(): string {
    return `
      <div class="row">
        <div class="col">
          <div class="review-modal-header">
            Billing Account:
          </div>
        </div>
        <div class="col">
          <div>${this.paymentDetail$.accountId}</div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="review-modal-header">
            Payment Date:
          </div>
        </div>
        <div class="col">
          <div>${formatDate(this.paymentDate, 'MM/dd/yyyy', 'en_US')}
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="review-modal-header">
            Payment Method:
          </div>
        </div>
        <div class="col">
          <div>${this.selectedPaymentMethodNickname}</div>
        </div>
      </div>
      ${
        this.authorizationCode
          ? `
            <div class="row mt-2">
              <div class="col">
                <div class="review-modal-header">
                  Confirmation Code:
                </div>
              </div>
              <div class="col">
                <div>${this.authorizationCode}</div>
              </div>
            </div>`
          : ``
      }
      ${
        !this.isCreditCard
          ? `
      <div class="row mt-2">
        <div class="col">
          <div class="review-modal-header">Total Amount:</div>
        </div>
        <div class="col">
          <div>${formatCurrency(
            this.amount,
            'en-US',
            getCurrencySymbol('USD', 'wide')
          )}
          </div>
        </div>
      </div> `
          : `
      <div>
        <div class="row mt-2">
          <div class="col">
            <div class="review-modal-header">Subtotal:</div>
          </div>
          <div class="col">
            <div>${formatCurrency(
              this.amount,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            )} 
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div class="review-modal-header">Service Fee:</div>
          </div>
          <div class="col">
            <div>${formatCurrency(
              this.charges.serviceFee,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            )}
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div class="review-modal-header">Total Amount:</div>
          </div>
          <div class="col">
            <div>${formatCurrency(
              this.charges.totalAmount,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            )}
            </div>
          </div>
        </div>
      </div>`
      }
    `;
  }

  getPaymentSuccessAdditionalMessage(): string {
    return `Thank you for your payment. A confirmation message has been sent
    to the account owner and any linked users who have elected to
    receive payment notifications.`;
  }
}
