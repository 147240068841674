export class BillingDetails {
  constructor() {
    this.Details = [];
  }
  Details: BillingDetail[];
}

export class BillingDetail {
  constructor(
    engieAccountId: string,
    billingAccount: string,
    isAutopay: boolean,
    isPaperless: boolean
  ) {
    this.EngieAccountId = engieAccountId;
    this.BillingAccount = billingAccount;
    this.IsAutoPay = isAutopay;
    this.IsPaperless = isPaperless;
  }
  EngieAccountId: string;
  BillingAccount?: string;
  IsAutoPay?: boolean;
  IsPaperless?: boolean;
}
