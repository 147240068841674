import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { Location } from '@angular/common';
import { Guid } from 'guid-typescript';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { AccountService } from '@core/services/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '@environments/environment';
import { FiservWalletFundingAccount } from '@shared/models/fiservWalletFundingAccount';
import { GuestPayment } from '@shared/models/guestPayment';
import { GuestPaymentModalComponent } from '../guest-payment-modal/guest-payment-modal.component';
import { ChargesService } from '@core/services/charges.service';

@Component({
  selector: 'app-fiserv-modal',
  templateUrl: './fiserv-modal.component.html',
  styleUrls: ['./fiserv-modal.component.sass']
})
export class FiservModalComponent implements OnInit, OnDestroy {
  public isDialogOpen = false;
  submitButtonEnabled = false;
  fiservEventListener: EventListener;
  showACH: boolean;
  sessionToken: string;
  submitButtonText: string;
  mainHeadingText: string;
  isCreditCard: boolean;
  charges: boolean;

  constructor(
    @Optional() public dialogRef: MatDialogRef<FiservModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: FiservDialogData,
    private modalDialogService: ModalDialogService,
    private accountService: AccountService,
    private chargesService: ChargesService,
    private spinner: NgxSpinnerService,
    private location: Location,
    public modalDialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.data.guestPayment) {
      this.submitButtonText = 'Next';
      this.mainHeadingText = 'Payment Method';
    } else {
      this.submitButtonText = 'Submit';
      this.mainHeadingText = 'Add Payment Method';
    }
    this.addCustomFiservEvents();
    this.getSessionToken(this.data.engieAccountId);

    this.isCreditCard = true;
    this.charges = true;
  }

  getSessionToken(accountId: string) {
    this.spinner.show();
    this.chargesService.getFiservSessionToken(accountId).subscribe(
      data => {
        this.sessionToken = data.sessionToken;
        if (data.sessionToken != null && data.sessionToken !== '') {
          this.showACH = false;
          this.toggleswitch_change();
        } else {
          this.modalDialogService.openErrorDialog(
            'Unable to get Fiserv Session Token',
            '',
            'Dismiss',
            () => {
              this.goBack();
            }
          );
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(
          'Unable to get Fiserv Session Token',
          '',
          'Dismiss',
          () => {
            this.goBack();
          }
        );
      }
    );
  }

  goBack() {
    this.location.back();
  }

  addCustomFiservEvents() {
    this.fiservEventListener = this.fiservCallback.bind(this);
    window.addEventListener('message', this.fiservEventListener);
  }

  removeCustomFiservEvents() {
    window.removeEventListener('message', this.fiservEventListener);
  }

  ngOnDestroy() {
    this.removeCustomFiservEvents();
  }

  closeButton_click() {
    this.dialogRef.close();
  }

  toggleswitch_change() {
    if (!this.showACH) {
      (document.getElementById('billerUIiFrame') as HTMLIFrameElement).src =
        environment.fiservUrl +
        `/Funding/CardDetails?LanguageIndicator=en-us&billerShortName=${environment.fiservBillerShortName}&InlineError=show`;
    } else {
      (document.getElementById('billerUIiFrame') as HTMLIFrameElement).src =
        environment.fiservUrl +
        `/Funding/CheckDetails?LanguageIndicator=en-us&billerShortName=${environment.fiservBillerShortName}&InlineError=show`;
    }
  }

  fiservCallback(event) {
    switch (event.data.Type) {
      case 'resize':
        (document.getElementById(
          'billerUIiFrame'
        ) as HTMLIFrameElement).height = event.data.Height;
        break;
      case 'payframevalidate':
        if (event.data.IsPayframeValid) this.submitButtonEnabled = true;
        else this.submitButtonEnabled = false;
        break;
      case 'message':
      default:
        this.closeButton_click();
        this.spinner.hide();
        if (this.data.guestPayment) {
          this.openGuestPaymentDialog(JSON.parse(event.data.Message));
        } else {
          this.addFundingTokenToWallet(JSON.parse(event.data.Message));
        }
        break;
    }
  }

  submitButton_click() {
    this.spinner.show();
    var frame = document.getElementById('billerUIiFrame') as HTMLIFrameElement;
    frame.contentWindow.postMessage(
      {
        APIKey: environment.fiservApiKey,
        SessionToken: this.sessionToken,
        RequestTimeStamp: Date.now(),
        MessageId: Guid.create().toString()
      },
      '*'
    );
  }

  addFundingTokenToWallet(response: any) {
    var isSuccess = response.result.statusCode == 'Created' ? true : false;
    if (isSuccess) {
      this.spinner.show();
      let newPaymethod = new FiservWalletFundingAccount();
      newPaymethod.fundingAccountToken =
        response.fundingAccountValidationResult.fundingAccount.fundingAccountToken;
      newPaymethod.isDefaultFundingSource = false;
      newPaymethod.nickName =
        response.fundingAccountValidationResult.fundingAccount.fundingMethod +
        ' - ' +
        response.fundingAccountValidationResult.fundingAccount
          .fundingAccountLastFourDigit;

      this.accountService
        .addPayMethodToWallet(this.data.engieAccountId, newPaymethod)
        .subscribe(
          data => {
            this.spinner.hide();
            this.openDialogAfterSavingFiserv(isSuccess);
          },
          error => {
            this.spinner.hide();
            this.modalDialogService.openErrorDialog(
              'Unable to add Funding Token To Wallet',
              '',
              'Dismiss',
              () => {
                this.goBack();
              }
            );
          }
        );
    } else {
      this.modalDialogService.openErrorDialog(
        'Unable to add new Payment Method. Please try again.',
        response.result.statusCode +
          ' - ' +
          response.result.statusMessages[0].description,
        'Dismiss',
        () => {
          this.data.onCloseHandled();
        },
        'Unable to Add Payment Method'
      );
    }
  }

  openDialogAfterSavingFiserv(isSuccess: boolean) {
    let message = '';
    if (isSuccess) {
      message = `The payment method has been successfully added.`;
      this.modalDialogService.openSuccessDialog(message, '', 'Dismiss', () => {
        this.data.onCloseHandled();
      });
    } else {
      message = `Unable to add new Payment Method. Please try again.`;
      this.modalDialogService.openErrorDialog(
        message,
        '',
        'Dismiss',
        () => {
          this.data.onCloseHandled();
        },
        'Unable to Add Payment Method'
      );
    }
  }

  openGuestPaymentDialog(response: any) {
    var paymethodNickname =
      response.fundingAccountValidationResult.fundingAccount.fundingMethod +
      ' - ' +
      response.fundingAccountValidationResult.fundingAccount
        .fundingAccountLastFourDigit;

    this.modalDialog.open(GuestPaymentModalComponent, {
      width: '800px',
      maxHeight: '100vh',
      data: {
        engieAccountId: this.data.engieAccountId,
        billingAccount: this.data.billingAccount,
        fundingToken:
          response.fundingAccountValidationResult.fundingAccount
            .fundingAccountToken,
        guestPaymentDetail: this.data.guestPaymentDetail,
        paymethodNickname: paymethodNickname,
        isCreditCard:
          response.fundingAccountValidationResult.fundingAccount
            .fundingCategory === 'Credit'
            ? true
            : false
      }
    });
  }
}

export interface FiservDialogData {
  engieAccountId: string;
  billingAccount: string;
  guestPayment: boolean;
  onCloseHandled?();
  guestPaymentDetail?: GuestPayment;
}
