export class AccountAccessType {
  constructor(
    emailAddress: string,
    engieAccountId: string,
    accessType: string,
    isDeleted: boolean
  ) {
    this.engieAccountId = engieAccountId;
    this.accessType = accessType;
    this.emailAddress = emailAddress;
    this.isDeleted = isDeleted;
  }
  emailAddress: string;
  engieAccountId: string;
  accessType: string;
  isDeleted: boolean;
}
