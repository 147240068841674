export class UserAccount {
  constructor(
    accountNumbers: string[],
    emailAddress: string,
    role: string,
    accessType: string,
    createBillingAccounts: boolean
  ) {
    this.AccountNumbers = accountNumbers;
    this.EmailAddress = emailAddress;
    this.Role = role;
    this.AccessType = accessType;
    this.CreateBillingAccounts = createBillingAccounts;
  }

  EmailAddress: string;
  Role: string;
  AccessType: string;
  CreateBillingAccounts: boolean;
  AccountNumbers: string[];
}
