import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '@core/services/account.service';
import { GlobalService } from '@core/services/global.service';
import { IdentityService } from '@core/services/identity.service';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { UtilityService } from '@core/services/utility.service';
import { environment } from '@environments/environment';
import { BaseGridComponentComponent } from '@shared/components/base-grid-component/base-grid-component.component';
import { CheckboxRendererComponent } from '@shared/components/checkbox-renderer';
import { CustomTooltipComponent } from '@shared/components/grid-custom-tooltip/custom-tooltip.component';
import { GlobalConstants } from '@shared/constants/global-constants';
import { AccessPermission } from '@shared/enums/AccessPermission';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { BillingDetail, BillingDetails } from '@shared/models/billingDetails';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-autopay-paperless',
  templateUrl: './autopay-paperless.component.html',
  styleUrls: ['./autopay-paperless.component.sass']
})
export class AutopayPaperlessComponent
  extends BaseGridComponentComponent
  implements OnInit {
  selectedEngieAccountIds: string[];
  billingDetails$: any;
  updatedBillingDetails: BillingDetails;
  emulate: boolean;
  columnDefs: any;
  defaultColDef: any;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridRowHeight = GlobalConstants.gridRowHeight;
  paginationPageSize = GlobalConstants.gridDefaultPageSize;
  tooltipShowDelay;
  excelStyles: any;
  frameworkComponents;
  canAutoPay: boolean;
  agreeChecked = false;
  agreementToggle: FormControl;
  MSG_FEE = `${environment.forteServiceFeeMsg}`;
  MSG_ACHADVISORY = `${environment.fiservAchAdvisoryMsg}`;
  MSG_AUTHORIZE = `I hereby authorize my financial institution to charge the above account in the amount of my monthly ENGIE Resources bill and send that amount to ENGIE Resources.
    I agree that each charge to my account shall be the same as if I had signed a check to pay my bill.  This authority will remain in effect until I notify ENGIE Resources otherwise.  If I change the account or financial institution specified, I will provide written authorization for the new financial institution to ENGIE Resources.  I understand both the financial institution and ENGIE Resources reserve the right to terminate this payment plan and/or my participation therein.
    I agree to have any past due balances paid using the above account upon ENGIE Resources’ receipt of this form, including late payment charges and returned check fees.`;

  //#region Initialization
  constructor(
    private accountService: AccountService,
    private utilityService: UtilityService,
    private identity: IdentityService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private modalDialogService: ModalDialogService,
    private router: Router,
    private location: Location
  ) {
    super();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.agreementToggle = new FormControl('', []);
    this.getGlobalVariables();
    this.initializeGrid();
    this.getBillingDetails();
  }

  getGlobalVariables() {
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.selectedEngieAccountIds = this.globalService.get(
      this.globalService.BULK
    )[1];
  }
  //#endregion

  //#region Grid
  createcolumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Engie ACCOUNT',
        field: 'engieAccountId',
        hide: true,
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'ACCOUNT NUMBER',
        field: 'accountNumber',
        minWidth: 200,
        flex: 1,
        editable: false,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat',
        cellRenderer: this.copyIcon.bind(this)
      },
      {
        headerName: 'ACCOUNT NAME',
        field: 'accountName',
        minWidth: 200,
        flex: 3,
        editable: false,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'ACCESS TYPE',
        field: 'accessType',
        cellRenderer: params => {
          const accessType: AccessTypes = this.utilityService.convertToAccessType(
            params.data.accessType
          );
          return accessType.toString();
        },
        minWidth: 130,
        maxWidth: 130,
        editable: false,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'DEFAULT PAYMETHOD',
        field: 'defaultPaymentMethod',
        minWidth: 200,
        maxWidth: 200,
        editable: false,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'AUTOPAY',
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '      <span class="btn-simple">' +
            '        <input type="checkbox" id="autopayHeaderCheckbox" style="margin-right:10px;width:15px;height:15px;"/>AUTOPAY' +
            '       </span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            '  </div>' +
            '</div>'
        },
        field: 'isAutopayNewValue',
        minWidth: 140,
        maxWidth: 140,
        editable: false,
        sortable: false,
        cellStyle: params => {
          if (
            params.data.isAutopayPreviousValue !== params.data.isAutopayNewValue
          ) {
            return { backgroundColor: '#90EE90', justifyContent: 'left' };
          }
          return { backgroundColor: null, justifyContent: 'left' };
        },
        cellRenderer: 'checkboxCellRenderer',
        cellRendererParams: (params: any) => {
          if (
            !this.hasAutopayPermission(params.data.accessType) ||
            params.data.isPaymentRestricted
          ) {
            return {
              isDisabled: true
            };
          } else {
            return {
              isDisabled: false
            };
          }
        }
      },
      {
        headerName: 'PAPERLESS',
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '      <span class="btn-simple">' +
            '        <input type="checkbox" id="paperlessHeaderCheckbox" style="margin-right:10px;width:15px;height:15px;"/>PAPERLESS' +
            '       </span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            '  </div>' +
            '</div>'
        },
        field: 'isPaperlessNewValue',
        tooltipValueGetter: params => {
          return params.data.accountEmailAddress;
        },
        tooltipComponentParams: (params: any) => {
          return {
            color: 'black',
            tooltip: params.data.accountEmailAddress
          };
        },
        minWidth: 130,
        maxWidth: 130,
        editable: false,
        sortable: false,
        cellStyle: params => {
          if (
            params.data.isPaperlessPreviousValue !==
            params.data.isPaperlessNewValue
          ) {
            return { backgroundColor: '#90EE90', justifyContent: 'left' };
          }
          return { backgroundColor: null, justifyContent: 'left' };
        },
        cellRenderer: 'checkboxCellRenderer',
        cellRendererParams: (params: any) => {
          if (!this.hasPaperlessPermission(params.data.accessType)) {
            return {
              isDisabled: true
            };
          } else {
            return {
              isDisabled: false
            };
          }
        }
      },
      {
        headerName: 'NOTE',
        field: 'note',
        cellRenderer: params => {
          let allNotes = params.data.note;
          const accessType: AccessTypes = this.utilityService.convertToAccessType(
            params.data.accessType
          );
          if (!this.hasAutopayPermission(params.data.accessType)) {
            if (allNotes !== '') {
              allNotes += `<br/>`;
            }
            allNotes += `${accessType.toString()} is not allowed to update the autopay.`;
          }
          if (!this.hasPaperlessPermission(params.data.accessType)) {
            if (allNotes !== '') {
              allNotes += `<br/>`;
            }
            allNotes += `${accessType.toString()} is not allowed to update the paperlesss.`;
          }
          return allNotes;
        },
        minWidth: 180,
        flex: 2,
        wrapText: true,
        editable: false,
        cellStyle: params => {
          if (
            params.value !== '' ||
            !this.hasAutopayPermission(params.data.accessType) ||
            !this.hasPaperlessPermission(params.data.accessType)
          ) {
            return { background: '#F08080', justifyContent: 'left' };
          } else {
            return { justifyContent: 'left' };
          }
        },
        cellClass: 'textFormat'
      }
    ];

    this.frameworkComponents = {
      checkboxCellRenderer: CheckboxRendererComponent,
      customTooltip: CustomTooltipComponent
    };
  }

  initializeGrid() {
    this.createcolumnDefs();
    this.defaultColDef = {
      sortable: true,
      flex: 2,
      filter: true,
      resizable: true,
      menuTabs: ['filterMenuTab'],
      tooltipComponent: 'customTooltip'
    };
    this.tooltipShowDelay = 0;
    this.excelStyles = [
      {
        id: 'textFormat',
        dataType: 'string'
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout('normal');
    this.gridApi.setHeaderHeight(GlobalConstants.gridHeaderHeight);
    document.getElementById('myGrid').style.height =
      GlobalConstants.gridMinimumHeight;
    this.addEventListenersToGridHeader();
  }

  addEventListenersToGridHeader() {
    document
      .getElementById('autopayHeaderCheckbox')
      .addEventListener('click', this.autopayCheckedHandler.bind(this));
    document
      .getElementById('paperlessHeaderCheckbox')
      .addEventListener('click', this.paperlessCheckedHandler.bind(this));
  }
  //#endregion

  //#region Buttons/Events
  onSubmit() {
    this.updatedBillingDetails = new BillingDetails();
    this.billingDetails$.forEach(x => {
      if (
        x.isAutopayPreviousValue !== x.isAutopayNewValue ||
        x.isPaperlessPreviousValue !== x.isPaperlessNewValue
      ) {
        const isAutopay =
          x.isAutopayPreviousValue === x.isAutopayNewValue
            ? null
            : x.isAutopayNewValue;
        const isPaperless =
          x.isPaperlessPreviousValue === x.isPaperlessNewValue
            ? null
            : x.isPaperlessNewValue;
        this.updatedBillingDetails.Details.push(
          new BillingDetail(
            x.engieAccountId,
            x.accountNumber,
            isAutopay,
            isPaperless
          )
        );
      }
    });

    let message = '';
    if (!this.updatedBillingDetails.Details.length) {
      message = 'There are no changes to save.';
      this.modalDialogService.openErrorDialog(message);
      return;
    } else {
      message = `All billing changes will be saved. Are you sure you want to continue?`;
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continue();
        },
        '',
        null,
        message,
        '',
        this.getAdditionalMessage()
      );
    }
  }

  continue() {
    if (this.atleastOneAutopayHasBeenEnabled()) {
      document.getElementById('enableAPModal').style.display = 'block';
    } else {
      this.saveChanges();
    }
  }

  saveChanges() {
    this.spinner.show();
    this.accountService
      .updateBillingDetails(this.updatedBillingDetails)
      .subscribe(
        response => {
          this.resetCurrentValues();
          this.spinner.hide();
          this.modalDialogService.openSuccessDialog(
            'The billing details have been successfully updated.'
          );
        },
        error => {
          console.log(
            'Failed to update autopay and paperless details. ' + error
          );
          this.spinner.hide();
          this.modalDialogService.openErrorDialog(
            error.error,
            '',
            'Dismiss',
            () => {
              this.reloadPage(`/bulk-autopay`);
            }
          );
        }
      );
  }

  async reloadPage(url: string): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigateByUrl(url);
  }

  atleastOneAutopayHasBeenEnabled() {
    const x = this.billingDetails$.filter(
      x => !x.isAutopayPreviousValue && x.isAutopayNewValue
    );
    return x.length > 0;
  }

  onAcceptAgreement(value) {
    this.agreeChecked = value;
  }

  AgreeOnAuthorization() {
    this.onCloseAuthorizationModal('enableAPModal');
    this.saveChanges();
  }

  getAdditionalMessage(): string {
    return `<ng-container>
      <div class="row justify-content-center">
        <div class="pr-1 pl-1 pb-0" style="font-size: 0.8rem; text-align: left">
          <nj-alert>
            <div class="nj-alert nj-alert--warning mb-0 mt-4" role="alert">
              <i class="material-icons nj-alert__icon">warning</i>
              <div>
              ${this.MSG_ACHADVISORY}
              </div>
            </div>
            <div class="nj-alert nj-alert--warning mb-0 mt-4" role="alert">
              <i class="material-icons nj-alert__icon">warning</i>
              <div>
              ${this.MSG_FEE}
              </div>
            </div>
          </nj-alert>
        </div>
      </div>
    </ng-container>
    `;
  }

  resetCurrentValues() {
    this.billingDetails$.forEach(detail => {
      detail.isAutopayPreviousValue = detail.isAutopayNewValue;
      detail.isPaperlessPreviousValue = detail.isPaperlessNewValue;
    });
    this.gridApi.redrawRows();
  }

  ResetChanges() {
    this.billingDetails$.forEach(detail => {
      detail.isAutopayNewValue = detail.isAutopayPreviousValue;
      detail.isPaperlessNewValue = detail.isPaperlessPreviousValue;
    });
    this.gridApi.redrawRows();
  }

  onReset() {
    this.location.back();
  }

  getBillingDetails() {
    this.spinner.show();
    const selectedAccounts = new BillingDetails();
    for (let engieAccountId of this.selectedEngieAccountIds) {
      selectedAccounts.Details.push(
        new BillingDetail(engieAccountId, null, null, null)
      );
    }

    this.accountService.getBillingDetails(selectedAccounts).subscribe(
      result => {
        this.billingDetails$ = result;
        this.setGridHeight(
          'myGrid',
          this.gridApi,
          GlobalConstants.gridMinimumHeight,
          GlobalConstants.gridDefaultPageSize,
          0
        );
        this.spinner.hide();
      },
      error => {
        console.log('Failed to get autopay and paperless details. ' + error);
        this.spinner.hide();
      }
    );
  }

  copyIcon(params) {
    const divTag = document.createElement('div');
    if (params.column.colId === 'accountNumber') {
      this.utilityService.fieldWithCopyButton(
        divTag,
        params.value,
        params.value
      );
    }
    return divTag;
  }

  onCellValueChanged(params) {
    if (
      params.column.colId === 'isAutopay' ||
      params.column.colId === 'isPaperless'
    ) {
      // params.data.newAccessType = this.utilityService.titleCase(
      //   params.data.newAccessType
      // );
      this.gridApi.refreshCells(params);
    }
  }

  onPageSizeChanged(selectedPageSize: number) {
    const value = selectedPageSize;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  onPaginationChanged(params) {
    this.setGridHeight_OnPaginationChanged(
      'myGrid',
      this.gridApi,
      GlobalConstants.gridMinimumHeight,
      GlobalConstants.gridDefaultPageSize,
      params
    );
  }

  onQuickSearchChanged(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  exportToExcel() {
    const params = {
      columnWidth: 100,
      sheetName: 'Billing Account',
      processCellCallback: this.processExportedCell,
      columnKeys: [
        'accountNumber',
        'accountName',
        'defaultPaymentMethod',
        'isAutopayNewValue',
        'isPaperlessNewValue',
        'note'
      ]
    };
    this.gridApi.exportDataAsExcel(params);
  }

  processExportedCell(params) {
    return params.value.toString();
  }

  autopayCheckedHandler() {
    const elementName = 'autopayHeaderCheckbox';
    const element = document.getElementById(elementName) as HTMLInputElement;
    this.billingDetails$.forEach(detail => {
      if (
        this.hasAutopayPermission(detail.accessType) &&
        !detail.isPaymentRestricted
      ) {
        detail.isAutopayNewValue = element.checked;
      }
    });
    this.gridApi.redrawRows();
  }

  paperlessCheckedHandler() {
    const elementName = 'paperlessHeaderCheckbox';
    const element = document.getElementById(elementName) as HTMLInputElement;
    this.billingDetails$.forEach(detail => {
      if (this.hasPaperlessPermission(detail.accessType)) {
        detail.isPaperlessNewValue = element.checked;
      }
    });
    this.gridApi.redrawRows();
  }

  hasAutopayPermission(fromAccessType: any): boolean {
    const accessType: AccessTypes = this.utilityService.convertToAccessType(
      fromAccessType
    );
    return this.identity.hasAccessPermission(
      AccessPermission.Enroll_Auto_Pay,
      accessType
    );
  }

  hasPaperlessPermission(fromAccessType: any): boolean {
    const accessType: AccessTypes = this.utilityService.convertToAccessType(
      fromAccessType
    );
    return this.identity.hasAccessPermission(
      AccessPermission.Enroll_Paperless,
      accessType
    );
  }

  onCloseAuthorizationModal(elem: string) {
    this.agreeChecked = false;
    this.agreementToggle.reset(false);
    document.getElementById(elem).style.display = 'none';
  }
  //#endregion
}
