import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AdminGuard } from './modules/core/guards/admin.guard';
import { PaymentGuard } from './modules/core/guards/payment.guard';
import { PaymethodsGuard } from './modules/core/guards/paymethods.guard';
import { ServiceGuard } from './modules/core/guards/services.guard';
import { UserGuard } from './modules/core/guards/user.guard';
import { LogoutComponent } from './modules/auth/components/logout/logout.component';

// Account components
import { AccountComponent } from '@customer/components/accounts/account/account.component';
import { HistoryComponent } from '@customer/components/accounts/history/history.component';
import { ShareComponent } from '@customer/components/accounts/share/share.component';
import { AccountListSummaryComponent } from '@customer/components/accounts/account-list-summary/account-list-summary.component';
import { TransferComponent } from '@customer/components/accounts/transfer/transfer.component';
import { ReleaseComponent } from '@customer/components/accounts/release/release.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';
import { HealthCheckerComponent } from '@system/components/health-checker/health-checker.component';
import { PaymentComponent } from '@customer/components/accounts/payment/payment.component';
import { ReportingComponent } from '@customer/components/accounts/reporting/reporting.component';
import { GuestComponent } from '@customer/components/accounts/payment/guest/guest.component';
import { MethodComponent } from '@customer/components/accounts/payment/method/method.component';
import { ServicesComponent } from '@customer/components/accounts/services/services.component';
import { ResendActivationComponent } from '@customer/components/accounts/resend-activation/resend-activation.component';
import { AddAccountSettingComponent } from '@admin/components/account-setting/add-account-setting/add-account-setting.component';
import { ReleaseNotesComponent } from '@system/components/release-notes/release-notes.component';
import { DashboardComponent } from '@customer/components/dashboard/dashboard.component';
import { BillingAddressComponent } from '@customer/components/accounts/bulk/billing-contact-information/billing-address/billing-address.component';
import { AccountListComponent } from '@customer/components/accounts/account-list/account-list.component';
import { AdminComponent } from '@admin/components/admin/admin.component';
import { ActivateAccountComponent } from '@admin/components/activate-account/activate-account.component';
import { ChangeEmailComponent } from '@admin/components/change-email/change-email.component';
//import { ChangeRoleComponent } from '@admin/components/change-role/account-access-management.component';
import { NewUserComponent } from '@admin/components/new-user/new-user.component';
import { ResetPasswordComponent } from '@admin/components/reset-password/reset-password.component';
import { UserProfileComponent } from '@admin/components/user-profile/user-profile.component';
import { AutopayPaperlessComponent } from '@customer/components/accounts/bulk/autopay-paperless/autopay-paperless.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'bulk-address',
    component: BillingAddressComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'bulk-autopay',
    component: AutopayPaperlessComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'account',
    canActivate: [MsalGuard],
    children: [
      {
        path: 'list',
        component: AccountListComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'summary',
        component: AccountListSummaryComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'detail',
        component: AccountComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'history',
        component: HistoryComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'paymethods',
        component: MethodComponent,
        canActivate: [MsalGuard, PaymethodsGuard]
      },
      {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [MsalGuard, PaymentGuard]
      },
      {
        path: 'services',
        component: ServicesComponent,
        canActivate: [MsalGuard, ServiceGuard]
      }
    ]
  },
  { path: 'share', component: ShareComponent, canActivate: [MsalGuard] },
  { path: 'transfer', component: TransferComponent, canActivate: [MsalGuard] },
  { path: 'release', component: ReleaseComponent, canActivate: [MsalGuard] },
  {
    path: 'health',
    component: HealthCheckerComponent
    // canActivate: [MsalGuard]
  },
  {
    path: 'help',
    children: [
      { path: '', component: HelpCenterComponent },
      { path: ':contactus', component: HelpCenterComponent }
    ]
  },
  { path: 'release-notes', component: ReleaseNotesComponent },
  {
    path: 'reporting',
    component: ReportingComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [MsalGuard, AdminGuard]
  },
  {
    path: 'setting',
    component: AddAccountSettingComponent,
    canActivate: [MsalGuard, AdminGuard]
  },
  {
    path: 'reset-pswd/:emailaddress',
    component: ResetPasswordComponent,
    canActivate: [MsalGuard]
  },
  // {
  //   path: 'user/:emailaddress/role',
  //   component: ChangeRoleComponent,
  //   canActivate: [MsalGuard]
  // },
  {
    path: 'email/:emailaddress/change',
    canActivate: [MsalGuard],
    children: [
      { path: '', component: ChangeEmailComponent },
      { path: ':resend', component: ChangeEmailComponent }
    ]
  },
  {
    path: 'user-profile',
    canActivate: [MsalGuard],
    children: [
      { path: '', component: UserProfileComponent, canActivate: [MsalGuard] },
      {
        path: ':email',
        component: UserProfileComponent,
        canActivate: [MsalGuard]
      }
    ]
  },
  { path: 'logout', component: LogoutComponent, canActivate: [MsalGuard] },
  { path: 'resend', component: ResendActivationComponent },
  { path: 'guest', component: GuestComponent },
  { path: 'user/activate/:key/:email', component: ActivateAccountComponent },
  {
    path: 'user',
    component: NewUserComponent,
    canActivate: [MsalGuard, UserGuard]
  },
  {
    path: 'faqs',
    // lazy load the module
    loadChildren: () =>
      import('./modules/faqs/faqs.module').then(m => m.FaqsModule)
  },
  { path: '**', component: DashboardComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
