import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IdentityService } from '@core/services/identity.service';
import { GlobalService } from '@core/services/global.service';
import { AccessPermission } from '@shared/enums/AccessPermission';

@Injectable()
export class TransferGuard implements CanActivate {
  constructor(
    private identityService: IdentityService,
    private router: Router,
    private globalService: GlobalService
  ) {}

  canActivate(): Observable<boolean> {
    var access = this.globalService.get('ACCOUNT_LEVEL_ROLE_PERM');

    if (access !== undefined && JSON.stringify(access).length > 0) {
      var hasPerm = this.identityService.hasAccessPermission(
        AccessPermission.Transfer_Account,
        access['accessType']
      );
      if (hasPerm) {
        return of(true);
      } else {
        console.log('Insufficient Permissions');
        console.log('AccessType => ' + JSON.stringify(access));
        return of(false);
      }
    } else {
      console.log('Insufficient Permissions');
      console.log('Missing account context.');
      return of(false);
    }
  }
}
