import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import 'ag-grid-enterprise';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorComponent } from './components/error/error.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { CustomerModule } from './modules/customer/customer.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './modules/auth/auth.module';
import { AdminModule } from './modules/admin/admin.module';
import { SystemModule } from './modules/system/system.module';
import { GuidedTourModule } from 'ngx-guided-tour';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    AdminModule,
    CustomerModule,
    SystemModule,
    GuidedTourModule.forRoot(),
    // keep these last
    MsalModule,
    CoreModule,
    SharedModule
  ],

  declarations: [AppComponent, ErrorComponent, HelpCenterComponent],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
