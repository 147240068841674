import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '@core/services/admin.service';
import { ApplicationEventLog } from '@shared/models/applicationEventLog';
import { BaseGridComponentComponent } from '@shared/components/base-grid-component/base-grid-component.component';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { formatDate } from '@angular/common';
import { GlobalConstants } from '@shared/constants/global-constants';

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.css']
})
export class EventLogComponent extends BaseGridComponentComponent {
  eventLogs$: ApplicationEventLog[];
  columnDefs;
  defaultColDef;
  rowHeight;
  rowSelection;
  paginationPageSize;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  excelStyles: any;

  constructor(
    private adminService: AdminService,
    private spinner: NgxSpinnerService
  ) {
    super();
    this.paginationPageSize = GlobalConstants.gridDefaultPageSize;
    this.createGridOpts();
    this.createColumnDefs();
  }

  search() {
    this.spinner.show();
    const self = this;
    this.adminService.getEventLogs().subscribe(
      result => {
        self.eventLogs$ = result;
        this.gridApi.sizeColumnsToFit();
        this.spinner.hide();
      },
      error => {
        console.log('Failed to get application event logs.');
        this.spinner.hide();
      }
    );
  }

  onQuickSearchChanged(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  private createColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'EMAIL ADDRESS',
        field: 'userName',
        sortable: true,
        editable: false,
        width: 120,
        filter: true,
        hide: false,
        resizable: true,
        cellStyle: { justifyContent: 'left' }
      },
      {
        headerName: 'BILLING ACCOUNT',
        field: 'billingAccountNumber',
        sortable: true,
        editable: false,
        width: 80,
        filter: true,
        hide: false,
        resizable: true,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'UTILITY ACCOUNT',
        field: 'utilityAccountNumber',
        sortable: true,
        editable: false,
        width: 80,
        filter: true,
        hide: false,
        resizable: true,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'EVENT DATE',
        field: 'eventDateTime',
        sortable: true,
        editable: false,
        width: 80,
        filter: true,
        hide: false,
        resizable: true,
        cellStyle: { justifyContent: 'left' },
        valueFormatter: super.datetimeFormatter
      },
      {
        headerName: 'EVENT TYPE',
        field: 'eventType',
        sortable: true,
        editable: false,
        width: 110,
        filter: true,
        hide: false,
        resizable: true,
        cellStyle: { justifyContent: 'left' }
      },
      {
        headerName: 'EVENT DETAIL',
        field: 'eventDetail',
        sortable: true,
        editable: false,
        width: 110,
        filter: true,
        hide: false,
        resizable: true,
        cellStyle: { justifyContent: 'left' },
        tooltipField: 'eventDetail'
      }
    ];
    this.rowHeight = 65;
    this.rowSelection = 'single';
    this.defaultColDef = { flex: 1 };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onPageSizeChanged(selectedValue: number) {
    const value = selectedValue;
    this.paginationPageSize = Number(value);
    this.gridApi.paginationSetPageSize(Number(value));
  }

  private createGridOpts() {
    this.excelStyles = [
      {
        id: 'textFormat',
        dataType: 'string'
      }
    ];
  }

  exportToExcel() {
    const params = {
      columnWidth: 100,
      sheetName: 'EventLog',
      processCellCallback: this.processExportedCell
    };
    this.gridApi.exportDataAsExcel(params);
  }

  processExportedCell(params) {
    if (params.column.colId === 'eventDateTime') {
      if (params.value != null) {
        return formatDate(params.value, 'medium', 'en-US');
      }
      return null;
    } else if (
      params.column.colId === 'status_1' ||
      params.column.colId === 'status_2'
    ) {
      return null;
    } else {
      return params.value;
    }
  }
}
