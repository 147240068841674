import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { IdentityService } from '@core/services/identity.service';
import { Role } from '@shared/enums/Role';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private identityService: IdentityService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      this.identityService.isInRole(Role.Admin) ||
      this.identityService.isInRole(Role.Care_Agent) ||
      this.identityService.isInRole(Role.Collections) ||
      this.identityService.isInRole(Role.Sales) ||
      this.identityService.isInRole(Role.Employee)
    ) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
