import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { BulkPayMethod } from '@shared/models/bulkPayMethod';
import { BulkPayMethodFaultResponse } from '@shared/models/responseObjects/bulkPayMethodFaultResponse';
import { Address } from '@shared/models/address';
import { HttpHelperService } from '@core/services/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class BulkService {
  constructor(private httpHelper: HttpHelperService) {}

  bulkPayMethod(bulk: BulkPayMethod) {
    return this.httpHelper
      .httpPostRequest<BulkPayMethod>(
        `${environment.apimWebRoot}${environment.bulkPayMethod}`,
        bulk
      )
      .pipe(
        map((response: BulkPayMethod) => response),
        catchError(err => throwError(err))
      );
  }

  bulkUpdateBillingAddress(bulk: Address) {
    return this.httpHelper
      .httpPostRequest<Address>(
        `${environment.apimWebRoot}${environment.bulkAddress}`,
        bulk
      )
      .pipe(
        map((response: Address) => response),
        catchError(err => throwError(err))
      );
  }
}
