import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DynamiteResponse } from '@shared/models/dynamiteResponse';
import { HttpHelperService } from '@core/services/http-helper.service';
import { HttpHelperGuestService } from './http-helper-guest.service';
import { FiservSessionToken } from '@shared/models/fiservSessionToken';

@Injectable({
  providedIn: 'root'
})
export class ChargesService {
  constructor(
    private msalService: MsalService,
    private httpHelper: HttpHelperService,
    private guestHttpHelper: HttpHelperGuestService
  ) {}

  getServiceFee(paymethodType: string, amount: string) {
    const payload = {
      PaymethodType: paymethodType,
      Amount: amount,
      statusCode: '',
      isSuccess: false,
      message: ''
    };
    return this.guestHttpHelper
      .httpPostRequestNoAuth<DynamiteResponse>(
        `${environment.apimGuestRoot}${environment.charges.serviceFee}`,
        payload
      )
      .pipe(
        tap(data => data),
        catchError(response => throwError(response))
      );
  }

  getPaymethodTypes(engieAccountId: string) {
    const payload = {
      EngieAccountId: engieAccountId,
      statusCode: '',
      isSuccess: false,
      message: ''
    };
    return this.guestHttpHelper
      .httpPostRequestNoAuth<DynamiteResponse>(
        `${environment.apimGuestRoot}${environment.charges.paymethodTypes}`,
        payload
      )
      .pipe(
        tap(data => data),
        catchError(response => throwError(response))
      );
  }

  public getFiservSessionToken(
    engieAccountId: string
  ): Observable<FiservSessionToken> {
    const url = `${
      environment.apimGuestRoot
    }${environment.charges.fiServSessionToken.replace('{0}', engieAccountId)}`;
    return this.guestHttpHelper
      .httpGetRequestNoAuth<FiservSessionToken>(url)
      .pipe(
        map((response: FiservSessionToken) => response),
        catchError(response => throwError(response))
      );
  }
}
