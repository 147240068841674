import { Component, OnInit } from '@angular/core';
import {
  formatDate,
  formatCurrency,
  getCurrencySymbol,
  formatNumber
} from '@angular/common';
import { GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-base-grid-component',
  template: ` <p>base-grid-component works!</p> `,
  styleUrls: ['./base-grid-component.component.sass']
})
export class BaseGridComponentComponent {
  constructor() {}

  public dateFormatter(params): string {
    var dt = new Date(Date.parse(params.value));

    if (dt.getFullYear() <= 1) return '';
    if (params.value) return formatDate(params.value, 'MM/dd/yyyy', 'en-US');
  }

  public datetimeFormatter(params): string {
    const newDate = new Date(Date.parse(params.value));
    if (newDate.getFullYear() <= 1) {
      return '';
    }
    if (params.value) {
      return formatDate(params.value, 'MM/dd/yyyy h:mm a', 'en-US');
    }
  }

  public decimalFormatter(params) {
    let num: number;
    if (params.value) {
      num = Number(params.value.toFixed(2));
    } else num = params.value;
    return num;
  }

  public numberFormatter(params) {
    if (params.value) {
      return formatNumber(params.value, 'en-US', '1.0-2');
    }
    return params.value;
  }

  public currencyFormatter(params) {
    if (params.value.toString().startsWith('-')) {
      var amount = parseFloat(params.value) * -1;
      var formattedamount = formatCurrency(
        amount,
        'en-US',
        getCurrencySymbol('USD', 'wide')
      );
      return `(${formattedamount})`;
    } else
      return formatCurrency(
        params.value,
        'en-US',
        getCurrencySymbol('USD', 'wide')
      );
  }

  public billaddressFormatter(params) {
    let address: any = params.data.billingAddress;
    if (address) {
      if (address.street2)
        return `<div align='left'>
          ${address.street} <br/>
          ${address.street2} <br/>
          ${address.city},
          ${address.state}
          ${address.postalCode} </div>`;
      else
        return `<div align='left'>
          ${address.street} <br/>
          ${address.city},
          ${address.state}
          ${address.postalCode} </div>`;
    } else {
      return 'N/A';
    }
  }

  public serviceaddressFormatter(params) {
    let address: any = params.data.serviceAddress;
    if (address) {
      if (address.street2)
        return `<div align='left'>
          ${address.street} <br/>
          ${address.street2} <br/>
          ${address.city},
          ${address.state}
          ${address.postalCode} </div>`;
      else
        return `<div align='left'>
          ${address.street} <br/>
          ${address.city},
          ${address.state}
          ${address.postalCode} </div>`;
    }
  }

  public dateComparator(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue.split('T')[0];
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('-');
    var cellDate = new Date(dateAsString);

    if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  }

  public noQuickFilter(params) {
    return null;
  }

  public serviceAddressQuickFilter(params) {
    let address: any = params.data.serviceAddress;
    let result: string;
    if (address) {
      if (address.street2) {
        result =
          address.street +
          ' ' +
          address.street2 +
          ' ' +
          address.city +
          ' ' +
          address.state +
          ' ' +
          address.postalCode;
      } else {
        result =
          address.street +
          ' ' +
          address.city +
          ' ' +
          address.state +
          ' ' +
          address.postalCode;
      }
    }
    return result;
  }

  public billAddressQuickFilter(params) {
    let address: any = params.data.billingAddress;
    let result: string;
    if (address) {
      if (address.street2) {
        result =
          address.street +
          ' ' +
          address.street2 +
          ' ' +
          address.city +
          ' ' +
          address.state +
          ' ' +
          address.postalCode;
      } else {
        result =
          address.street +
          ' ' +
          address.city +
          ' ' +
          address.state +
          ' ' +
          address.postalCode;
      }
    }
    return result;
  }

  public setGridHeight(
    gridId: string,
    gridApi: GridApi,
    minimumGridHeight: string,
    minimumPageSize: number,
    pageNumberOfRows: number
  ) {
    if (pageNumberOfRows < minimumPageSize) {
      document.getElementById(gridId).style.height = minimumGridHeight;
      gridApi.setDomLayout('normal');
    } else {
      document.getElementById(gridId).style.height = '';
      gridApi.setDomLayout('autoHeight');
    }
  }

  public setGridHeight_OnPaginationChanged(
    gridId: string,
    gridApi: GridApi,
    minimumGridHeight: string,
    minimumPageSize: number,
    params: any
  ) {
    if (gridApi != undefined && (params.newPage || params.keepRenderedRows)) {
      const currentPage = gridApi.paginationGetCurrentPage();
      const totalNumberOfRows = gridApi.paginationGetRowCount();
      const currentPageSize = gridApi.paginationGetPageSize();
      const pageFirstRowNumber = currentPage * currentPageSize + 1;
      const pageLastRowNumber = Math.min(
        pageFirstRowNumber + currentPageSize - 1,
        totalNumberOfRows
      );

      let pageNumberOfRows = pageLastRowNumber - pageFirstRowNumber + 1;
      this.setGridHeight(
        gridId,
        gridApi,
        minimumGridHeight,
        minimumPageSize,
        pageNumberOfRows
      );
    }
  }
}
