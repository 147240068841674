export class SystemStatus {
  isPortalUp: boolean;
  portalUpMessage: string;
  dashboardMessage: string;
  incidentType: string;
  incidentDateTime: string;
  incidentDescription: string;
}

export class SystemStatusOnline {
  portalUpMessage: string;
  dashboardMessage: string;
}

export class SystemStatusOffline {
  incidentType: string;
  incidentDescription: string;
}
