import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-tooltip',
  template: `
    <div
      *ngIf="data.tooltip != undefined"
      class="custom-tooltip"
      [style.background-color]="data.color"
    >
      <p>
        <span>{{ data.tooltip }}</span>
      </p>
    </div>
  `,
  styles: [
    `
      :host {
        position: absolute;
        background-color:black;
        width: auto;
        max-width:200px;
        height: auto;

        overflow: hidden;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        text-align:center;
        color:white;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    }
    `
  ]
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  params: any;
  data: any;

  agInit(params): void {
    this.params = params;
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    this.data.color = this.params.color || 'white';
    this.data.tooltip = this.params.tooltip;
  }
}
