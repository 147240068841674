export enum Permission {
  Search_User = 'Search_User',
  Add_User = 'Add_User',
  Edit_Profile = 'Edit_Profile',
  Emulate_User = 'Emulate_User',
  Enable_User = 'Enable_User',
  Disable_User = 'Disable_User',
  Delete_User = 'Delete_User',
  Share_Account = 'Share_Account',
  Transfer_Account = 'Transfer_Account',
  Release_Accounts = 'Release_Accounts',
  Pay_Bill = 'Pay_Bill',
  Manage_PayMethods = 'Manage_PayMethods',
  Enroll_Auto_Pay = 'Enroll_Auto_Pay',
  Enroll_Paperless = 'Enroll_Paperless',
  Update_BillingAddress = 'Update_BillingAddress',
  Read_Only = 'Read_Only',
  Change_Role = 'Change_Role'
}
