import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserProfile } from '@shared/models/userProfile';
import { MsalService } from '@azure/msal-angular';
import { GuidedTourService, Orientation, GuidedTour } from 'ngx-guided-tour';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class GuideService {
  constructor(
    private guideTourService: GuidedTourService,
    private adminService: AdminService,
    private authService: MsalService
  ) { }

  public listViewTour: GuidedTour = {
    tourId: 'list-view-tour',
    useOrb: false,
    preventBackdropFromAdvancing: false,
    skipCallback: step => {
      this.onSkipCallback(step);
    },
    steps: [
      {
        title: 'Get Started with MY ENGIE Portal',
        selector: '.outlet',
        content: `<img src="/assets/guide/01-ListView-IntroScreen.png" height="100%" width="100%"/>
              Take a tour for steps to start managing your accounts and to see what new features are available for
              you. If you would like to revisit this guide, you can find an icon in the header at the top of the screen
              called What’s New.`,
        orientation: Orientation.Center
      },
      {
        title: 'You Have the Power',
        selector: '.outlet',
        content: `<img src="/assets/guide/02-YouHaveThePower.jpg" height="100%" width="100%"/>
        <br/><br/><b>Power Share:</b> User has access to all functionality and can share, transfer, or release accounts. 
        <br/><br/><b>Share:</b> User has access to partial functionality including editing their user profile, paying bills, adding payment information, enrolling in autopay, viewing account history, and generating reports. 
        <br/><br/><b>Read Only:</b> User has read only functionality and can only edit their user profile.`,
        orientation: Orientation.Center
      },
      {
        title: 'Share an Account ',
        selector: '.outlet',
        content: `<img src="/assets/guide/03-Share.jpg" height="100%" width="100%"/>
        <br/>1. Click on the <b>View Accounts</b> button. 
        <br/>2. Click on the <b>three dots</b> to the right of the specific account you want to share, and a dialog box will appear. 
        <br/>3. Select <b>Share Account</b> from the list of options.`,
        orientation: Orientation.Center
      },
      {
        title: 'Transfer an Account',
        selector: '.outlet',
        content: `<img src="/assets/guide/04-Transfer.jpg" height="100%" width="100%"/>
        <br/>1. Click on the <b>View Accounts</b> button. 
        <br/>2. Click on the <b>three dots</b> to the right of the specific account you want to transfer to another user, and a dialog box will appear. 
        <br/>3. Select <b>Transfer Account</b> from the list of options.`,
        orientation: Orientation.Center
      },
      {
        title: 'Release an Account',
        selector: '.outlet',
        content: `<img src="/assets/guide/05-Release.jpg" height="100%" width="100%"/>
        <br/>1. Click on the <b>View Accounts</b> button. 
        <br/>2. Click on the <b>three dots</b> to the right of the specific account you want to release or remove from your account list, and a dialog box will appear.
        <br/>3. Select <b>Release Account</b> from the list of options.`,
        orientation: Orientation.Center
      }
    ]
  };

  public accountViewTour: GuidedTour = {
    tourId: 'account-view-tour',
    useOrb: false,
    preventBackdropFromAdvancing: true,
    skipCallback: step => {
      this.onSkipCallback(step);
    },
    steps: [
      {
        title: 'Get Started with MY ENGIE Portal',
        selector: '.outlet',
        content: `<img src="/assets/guide/01-ListView-IntroScreen.png" height="100%" width="100%"/>
              Take a tour for steps to start managing your accounts and to see what new features are available for
              you. If you would like to revisit this guide, you can find an icon in the header at the top of the screen
              called What’s New.`,
        orientation: Orientation.Center
      },
      {
        title: 'You Have the Power',
        selector: '.outlet',
        content: `<img src="/assets/guide/account_share_transfer_release/02-YouHaveThePower.jpg" height="100%" width="100%"/>
        <br/><b>Power Share:</b> User has access to all functionality and can share, transfer, or release accounts. 
        <br/><b>Share:</b> User has access to partial functionality including editing their user profile, paying bills, adding payment information, enrolling in autopay, viewing account history, and generating reports. 
        <br/><b>Read Only:</b> User has read only functionality and can only edit their user profile.`,
        orientation: Orientation.Center
      },
      {
        title: 'Share an Account ',
        selector: '.outlet',
        content: `<img src="/assets/guide/account_share_transfer_release/03-Share.jpg" height="100%" width="100%"/>
        <br/>1. Click on the View Accounts button. 
        <br/>2. Click on the three dots to the right of the specific account you want to share, and a dialog box will appear. 
        <br/>3. Select Share Account from the list of options.`,
        orientation: Orientation.Center
      },
      {
        title: 'Transfer an Account',
        selector: '.outlet',
        content: `<img src="/assets/guide/account_share_transfer_release/04-Transfer.jpg" height="100%" width="100%"/>
        <br/>1. Click on the View Accounts button. 
        <br/>2. Click on the three dots to the right of the specific account you want to transfer to another user, and a dialog box will appear. 
        <br/>3. Select Transfer Account from the list of options.`,
        orientation: Orientation.Center
      },
      {
        title: 'Set up Email Notifications',
        selector: '.outlet',
        content: `<img src="/assets/guide/account_share_transfer_release/05-Release.jpg" height="100%" width="100%"/>
        <br/>1. Click on the View Accounts button. 
        <br/>2. Click on the three dots to the right of the specific account you want to release or remove from your account list, and a dialog box will appear.
        <br/>3. Select Release Account from the list of options.`,
        orientation: Orientation.Center
      }
    ]
  };

  public startTour(url: string): void {
    switch (url) {
      case '/account/detail':
        this.guideTourService.startTour(this.accountViewTour);
        break;
      default:
        this.guideTourService.startTour(this.listViewTour);
        break;
    }
  }

  public onSkipCallback(step: number) {
    // update CosmosDB UserProfile.showUserGuide = false;
    const userInfo: any = this.authService.instance.getActiveAccount()
      .idTokenClaims;
    const userProfile: Observable<UserProfile> = this.adminService.getUserProfile(
      userInfo.email
    );
    userProfile.subscribe((data: UserProfile) => {
      if (data.showUserGuide) {
        data.showUserGuide = false;
        this.adminService.addUserProfile(data).toPromise();
      }
    });
  }
}
