import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Account } from '@shared/models/account';
import { AccountView } from '@shared/models/accountView';
import { environment } from '@environments/environment';
import { AccountHistory } from '@shared/models/accountHistory';
import { AccountOneTMany } from '@shared/models/accountOneTMany';
import { UserLandingPage } from '@shared/models/userLandingPage';
import { SharedAccount } from '@shared/models/shareAccount';
import { AccountSummary } from '@shared/models/accountSummary';
import { ResetPassword } from '@shared/models/resetPassword';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivateUser } from '@shared/models/activateUser';
import { UsageHistory } from '@shared/models/usageHistory';
import { PayDetail } from '@shared/models/payDetail';
import { FortePayMethodSignature } from '@shared/models/forteAddPayMethod';
import { Payment } from '@shared/models/payment';
import { ReportInfo } from '@shared/models/reporting/reportInfo';
import { DynamiteResponse } from '@shared/models/dynamiteResponse';
import { AccountList } from '@shared/models/accountList';
import { StatementDocument } from '@shared/models/statementDocument';
import { HttpHelperGuestService } from '@core/services/http-helper-guest.service';
import { HttpHelperService } from './http-helper.service';
import { ZipFile } from '@shared/models/downloadedFileDetails';
import { BillingDetails } from '@shared/models/billingDetails';
import { Invoice } from '@shared/models/invoice';
import { FiservSessionToken } from '@shared/models/fiservSessionToken';
import { FiservWalletFundingAccount } from '@shared/models/fiservWalletFundingAccount';
import { FiservOneTimePayment } from '@shared/models/fiservOneTimePayment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private httpHelper: HttpHelperService,
    private guestHelper: HttpHelperGuestService,
    private http: HttpClient
  ) {}

  public getAccounts(): Observable<AccountOneTMany[]> {
    const accUrl = `${environment.apimWebRoot}${environment.getAccountList}`;
    return this.httpHelper.httpGetRequest<AccountOneTMany[]>(accUrl).pipe(
      map((response: AccountOneTMany[]) => response),
      catchError(response => throwError(response))
    );
  }

  getUserAccountList() {
    const accUrl = `${environment.apimWebRoot}${environment.getUserAccountList}`;
    return this.httpHelper.httpGetRequest<AccountList[]>(accUrl).pipe(
      map((response: AccountList[]) => response),
      catchError(response => throwError(response))
    );
  }

  public getAccountHistory(
    from: string,
    to: string,
    id?: string
  ): Observable<AccountHistory[]> {
    const params = new HttpParams({
      fromString: `fromDate=${from}&toDate=${to}`
    });
    return this.httpHelper
      .httpGetRequest(
        `${environment.apimWebRoot}${environment.getAccountHistory}/${id}`,
        '',
        params
      )
      .pipe(
        // @ts-ignore
        map((response: AccountHistory[]) => response),
        catchError(response => throwError(response))
      );
  }

  // public getAccountHistory(id: string): Observable<AccountHistory[]> {
  //   return this.httpHelper.httpGetRequest(`${environment.apimWebRoot}${environment.getAccountHistory}`)
  //     .pipe(
  //       map((response: AccountHistory[]) =>
  //         response
  //       ),
  //       catchError(response =>
  //         throwError(response)
  //       ),
  //     );
  // }

  public getAccountSummary(): Observable<AccountSummary[]> {
    return this.httpHelper
      .httpGetRequest<AccountSummary[]>(
        `${environment.apimWebRoot}${environment.getAccountSummary}`
      )
      .pipe(
        map((response: AccountSummary[]) => response),
        catchError(response => throwError(response))
      );
  }

  public getAccountView(id: string, utility: string) {
    return this.httpHelper
      .httpGetRequest<AccountView>(
        `${environment.apimWebRoot}${environment.getAccountView}/${id}/${utility}`
      )
      .pipe(
        tap(data => data),
        catchError(response => throwError(response))
      );
  }

  public getUserLandingPage(): Observable<UserLandingPage> {
    return this.httpHelper
      .httpGetRequest<UserLandingPage>(
        `${environment.apimWebRoot}${environment.getUserLandingPage}`
      )
      .pipe(
        map((response: UserLandingPage) => response),
        catchError(response => throwError(response))
      );
  }

  public getPayMethodList(id: string): Observable<PayDetail> {
    return this.httpHelper
      .httpGetRequest<PayDetail>(
        `${environment.apimWebRoot}${environment.getPaymentDetail}/${id}`
      )
      .pipe(
        tap(data => data),
        catchError(response => throwError(response))
      );
  }

  removePayMethod(
    account: string,
    token: string
  ): Observable<DynamiteResponse> {
    return this.httpHelper
      .httpDeleteRequest(
        `${environment.apimWebRoot}${environment.deletePayMethod
          .replace('{0}', account)
          .replace('{1}', token)}`
      )
      .pipe(
        // @ts-ignore
        map((response: DynamiteResponse) => response),
        catchError(response => throwError(response))
      );
  }

  setDefaultPayMethod(
    account: string,
    token: string
  ): Observable<DynamiteResponse> {
    return this.httpHelper
      .httpPutRequest(
        `${environment.apimWebRoot}${environment.defaultPayMethod
          .replace('{0}', account)
          .replace('{1}', token)}`
      )
      .pipe(
        // @ts-ignore
        map((response: DynamiteResponse) => response),
        catchError(response => throwError(response))
      );
  }

  addPayMethod(account: string): Observable<FortePayMethodSignature> {
    return this.httpHelper
      .httpGetRequest<FortePayMethodSignature>(
        `${environment.apimWebRoot}${environment.addPayMethod}/${account}`
      )
      .pipe(
        map((response: FortePayMethodSignature) => response),
        catchError(response => throwError(response))
      );
  }

  setAutoPay(
    accountId: string,
    billingAccount: string,
    enabled: boolean,
    user: string
  ) {
    const payload = {
      EngieAccountId: accountId,
      BillingAccount: billingAccount,
      AutoPayEnabled: enabled ? 'true' : 'false'
    };
    return this.httpHelper.httpPostRequest(
      `${environment.apimWebRoot}${environment.accountPatch}`,
      payload
    );
  }

  setPaperless(
    accountId: string,
    billingAccount: string,
    enabled: boolean,
    userName: string
  ) {
    const payload = {
      EngieAccountId: accountId,
      BillingAccount: billingAccount,
      DeliveryMethod: enabled ? 'EBill' : 'Paper'
    };
    return this.httpHelper.httpPostRequest(
      `${environment.apimWebRoot}${environment.accountPatch}`,
      payload
    );
  }

  sendPayment(
    id: string,
    amount: string,
    serviceFee: string,
    totalAmount: string,
    payDate: Date,
    payMethodToken: string
  ): Observable<any> {
    const payment: Payment = new Payment(
      amount,
      serviceFee,
      totalAmount,
      payDate,
      payMethodToken
    );
    return this.httpHelper
      .httpPostRequest<Payment>(
        `${environment.apimWebRoot}${environment.sendPayment}/${id}`,
        payment
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }

  shareAccount(account: SharedAccount) {
    return this.httpHelper.httpPostRequest<SharedAccount>(
      `${environment.apimWebRoot}${environment.accountShare}`,
      account
    );
  }

  transferAccount(account: SharedAccount) {
    return this.httpHelper.httpPostRequest<SharedAccount>(
      `${environment.apimWebRoot}${environment.accountTransfer}`,
      account
    );
  }

  releaseAccount(account: SharedAccount) {
    return this.httpHelper.httpPostRequest<SharedAccount>(
      `${environment.apimWebRoot}${environment.accountRelease}`,
      account
    );
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.httpHelper.httpPostRequest<ResetPassword>(
      `${environment.apimWebRoot}${environment.resetPassword}`,
      resetPassword
    );
  }

  activateUser(activateUser: ActivateUser) {
    return this.guestHelper.httpPostRequestNoAuth<ActivateUser>(
      `${environment.apimGuestRoot}${environment.activateUser}`,
      activateUser
    );
  }

  getReports(data: ReportInfo): Observable<any> {
    const reportURL = `${environment.apimReportRoot}${environment.reporting}`;
    return this.httpHelper.httpPostRequest<ReportInfo>(reportURL, data).pipe(
      map((response: any) => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getUsageHistory(spa: string, year: string): Observable<UsageHistory> {
    return this.httpHelper
      .httpGetRequest<UsageHistory>(
        `${environment.apimWebRoot}${environment.getUsageHistory}/${spa}/${year}`
      )
      .pipe(
        map((response: UsageHistory) => response),
        catchError(response => throwError(response))
      );
  }

  public getJSON(fileName): Observable<any> {
    return this.http.get('assets/' + fileName);
  }

  viewStatement(statementId: string): Observable<StatementDocument> {
    const url = `${environment.apimWebRoot}${environment.viewStatement}/${statementId}`;

    return this.httpHelper.httpGetRequest<StatementDocument>(url);
  }

  downloadStatement(
    engieAccountId: string,
    statementId: string,
    includeAccountName: boolean
  ): Observable<StatementDocument> {
    let url = '';
    if (includeAccountName) {
      url = `${environment.apimWebRoot}${environment.downloadStatement}/${engieAccountId}/${statementId}`;
    } else {
      url = `${environment.apimWebRoot}${environment.downloadStatement}/${statementId}`;
    }

    return this.httpHelper.httpGetRequest<StatementDocument>(url);
  }

  downloadBackingSheet(
    engieAccountId: string,
    statementId: string,
    invoiceId: string,
    includeAccountName: boolean
  ): Observable<StatementDocument> {
    let url = '';
    if (statementId !== null) {
      if (includeAccountName) {
        url = `${environment.apimWebRoot}${environment.downloadBackingSheet}/${engieAccountId}/${statementId}`;
      } else {
        url = `${environment.apimWebRoot}${environment.downloadBackingSheet}/${statementId}`;
      }
    } else {
      url = `${environment.apimWebRoot}${environment.downloadInvoiceBackingSheet}/${engieAccountId}/${invoiceId}`;
    }

    return this.httpHelper.httpGetRequest<StatementDocument>(url);
  }

  zipStatements(zipFile: ZipFile): Observable<StatementDocument> {
    return this.httpHelper.httpPostRequest<any>(
      `${environment.apimWebRoot}${environment.zipStatements}`,
      zipFile
    );
  }

  zipBackingSheets(zipFile: ZipFile): Observable<StatementDocument> {
    return this.httpHelper.httpPostRequest<any>(
      `${environment.apimWebRoot}${environment.zipBackingSheets}`,
      zipFile
    );
  }

  resendActivationEmail(email: string) {
    return this.guestHelper
      .httpPatchRequestNoAuth(
        `${
          environment.apimGuestRoot
        }${environment.sendGuestActivationEmail.replace('{email}', email)}`,
        ''
      )
      .pipe(
        map((response: any) => response),
        catchError(err =>
          err.code === 404 ? throwError('Not found') : throwError(err)
        )
      );
  }

  cancelPayment(
    engieAccountId: string,
    engiePaymentId: string
  ): Observable<DynamiteResponse> {
    const url = `${environment.apimWebRoot}${environment.cancelPayment
      .replace('{0}', engieAccountId)
      .replace('{1}', engiePaymentId)}`;
    return this.httpHelper.httpDeleteRequest(url).pipe(
      // @ts-ignore
      map((response: DynamiteResponse) => response),
      catchError(response => throwError(response))
    );
  }

  sendPayMethodNotification(account: string): Observable<DynamiteResponse> {
    return this.httpHelper
      .httpPostRequest(
        `${environment.apimWebRoot}${environment.sendPayMethodNotification}`,
        account
      )
      .pipe(
        // @ts-ignore
        map((response: DynamiteResponse) => response),
        catchError(response => throwError(response))
      );
  }

  getBillingDetails(billingDetails: BillingDetails): Observable<any> {
    return this.httpHelper
      .httpPostWithReturnRequest<BillingDetails>(
        `${environment.apimWebRoot}${environment.getBillingDetails}`,
        billingDetails
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }

  updateBillingDetails(billingDetails: BillingDetails) {
    return this.httpHelper.httpPostRequest<BillingDetails>(
      `${environment.apimWebRoot}${environment.updateBillingDetails}`,
      billingDetails
    );
  }

  addPayMethodToWallet(
    engieAccountId: string,
    data: FiservWalletFundingAccount
  ): Observable<FiservWalletFundingAccount> {
    return this.httpHelper
      .httpPostRequest<FiservWalletFundingAccount>(
        `${
          environment.apimWebRoot
        }${environment.fiservAddPayMethodToWallet.replace(
          '{0}',
          engieAccountId
        )}`,
        data
      )
      .pipe(
        map((response: FiservWalletFundingAccount) => response),
        catchError(response => throwError(response))
      );
  }

  fiservOneTimeGuestPayment(
    id: string,
    amount: string,
    serviceFee: string,
    totalAmount: string,
    payDate: Date,
    payMethodToken: string
  ): Observable<any> {
    const payment: Payment = new Payment(
      amount,
      serviceFee,
      totalAmount,
      payDate,
      payMethodToken
    );
    return this.guestHelper
      .httpPostRequestNoAuth<Payment>(
        `${environment.apimGuestRoot}${environment.fiservOneTimePayment.replace(
          '{0}',
          id
        )}`,
        payment
      )
      .pipe(
        map((response: any) => response),
        catchError(response => throwError(response))
      );
  }
}
