import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '@environments/environment';
import { LogContext } from '@shared/enums/LogContext';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
    // load custom telemetry properties
    this.appInsights.addTelemetryInitializer(envelope => {
      var item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties.ApplicationPlatform = 'WEB';
      item.properties.ApplicationName = 'PORTAL';
    });
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(
    name: string,
    logContext: LogContext,
    properties?: { [key: string]: any }
  ) {
    properties = {
      ...properties,
      logContext
    };
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    logContext: LogContext,
    properties?: { [key: string]: any }
  ) {
    properties = {
      ...properties,
      logContext
    };
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(
    exception: Error,
    logContext: LogContext,
    severityLevel?: number
  ) {
    exception.message = `logContext=${logContext} ${exception.message}`;
    this.appInsights.trackException({
      exception,
      severityLevel
    });
  }

  logTrace(
    message: string,
    logContext: LogContext,
    properties?: { [key: string]: any }
  ) {
    properties = {
      ...properties,
      logContext
    };
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
