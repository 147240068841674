import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MsalInterceptor,
  MsalService,
  MSAL_INSTANCE,
  MsalInterceptorConfiguration,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MsalModule
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel
} from '@azure/msal-browser';
import { environment } from '@environments/environment';
import { AdminGuard } from './guards/admin.guard';
import { PaymentGuard } from './guards/payment.guard';
import { PaymethodsGuard } from './guards/paymethods.guard';
import { ReleaseGuard } from './guards/release.guard';
import { ServiceGuard } from './guards/services.guard';
import { ShareGuard } from './guards/share.guard';
import { TransferGuard } from './guards/transfer.guard';
import { UserGuard } from './guards/user.guard';
import { ErrorHandlingService } from './services/error-handling.service';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2cClientId,
      authority: environment.b2cAuthority,
      knownAuthorities: environment.b2cKnownAuthorities,
      redirectUri: environment.b2cRedirectUri,
      postLogoutRedirectUri: environment.b2cPostLogoutRedirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false
    },
    system: {
      loadFrameTimeout: 30000,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apimWebRoot, [
    environment.b2cScopeUserImpersonation
  ]);
  protectedResourceMap.set(environment.apimReportRoot, [
    environment.b2cScopeUserImpersonation
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * The CoreModule should be imported into the AppModule only. The constructor
 * should complain if you attempt to import this module elsewhere. The Angular
 * recommended way of providing singleton services is not using `providedIn: root`
 * inside @Injectable(). That being said, the CoreModule is still the best place
 * to hold Guards and Interceptors since they also should be singleton
 */
@NgModule({
  imports: [],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorHandlingService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    AdminGuard,
    PaymentGuard,
    PaymethodsGuard,
    ReleaseGuard,
    ServiceGuard,
    ShareGuard,
    TransferGuard,
    UserGuard,
    MsalGuard,
    MsalService,
    MsalBroadcastService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`
      );
    }
  }
}
