import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpHeaderResponse,
  HttpParams
} from '@angular/common/http';

import { environment } from '@environments/environment';
import { MsalService } from '@azure/msal-angular';
import { GlobalService } from '@core/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  profile;
  email: string;
  emulator: string = '';

  constructor(
    private authService: MsalService,
    private globalService: GlobalService,
    private http: HttpClient
  ) {}

  public getUsername(): void {
    if (this.globalService.has('EmulatingUser')) {
      // this will be the emulated user's email when admin is emulating
      this.email = this.globalService.get('EmulatingUser');
    } else {
      this.profile = this.authService.instance.getActiveAccount().idTokenClaims;
      this.email = this.profile.email;
    }

    if (this.globalService.has('EmulatorUser')) {
      // this will be the emulator user's email who is emulating other user
      const globalEmulator = this.globalService.get('EmulatorUser');
      if (globalEmulator !== undefined && globalEmulator.userName !== null) {
        this.emulator = globalEmulator.userName;
      }
    }
  }

  public httpGetRequest<T>(
    url: string,
    user?: string,
    queryString?: HttpParams
  ) {
    this.getUsername();
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.coreApiKey,
      UserName: this.email,
      Emulator: this.emulator
    });
    let httpOptions = {};

    httpOptions =
      queryString !== undefined && queryString.keys().length > 0
        ? (httpOptions = { headers, params: queryString })
        : (httpOptions = { headers });

    return this.http.get<T>(url, httpOptions).pipe(
      map((response: T) => {
        return response;
      })
    );
  }

  public httpPostRequest<T>(url: string, data: T) {
    this.getUsername();
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': environment.coreApiKey,
      UserName: this.email,
      Emulator: this.emulator
    });
    const httpOptions = new HttpHeaderResponse({ headers });
    return this.http.post<T>(url, data, httpOptions).pipe(
      map((response: T) => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public httpPostRequestWithTimeZone<T>(url: string, data: T) {
    this.getUsername();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': environment.coreApiKey,
      UserName: this.email,
      Emulator: this.emulator,
      TimeZone: timeZone
    });
    const httpOptions = new HttpHeaderResponse({ headers });
    return this.http.post<T>(url, data, httpOptions).pipe(
      map((response: T) => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public httpPostRequestBase(url: string, data: any): Observable<any> {
    this.getUsername();
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': environment.coreApiKey,
      UserName: this.email,
      Emulator: this.emulator
    });
    const httpOptions = new HttpHeaderResponse({ headers });

    return this.http.post(url, data, httpOptions).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public httpPostWithReturnRequest<T>(url: string, data: T) {
    this.getUsername();
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': environment.coreApiKey,
      UserName: this.email,
      Emulator: this.emulator
    });
    const httpOptions = new HttpHeaderResponse({ headers });

    return this.http.post<T>(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public httpPostBlobRequest<T>(url: string, data: T) {
    this.getUsername();
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.coreApiKey,
      UserName: this.email,
      Emulator: this.emulator
    });
    const httpOptions = new HttpHeaderResponse({ headers });

    return this.http
      .post<T>(url, data, { headers, responseType: 'blob' as 'json' })
      .pipe(
        map((response: T) => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public httpDeleteRequest(url: string) {
    this.getUsername();
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.coreApiKey,
      UserName: this.email,
      Emulator: this.emulator
    });
    const httpOptions = new HttpHeaderResponse({ headers });

    return this.http.delete(url, httpOptions);
  }

  public httpPutRequest<T>(url: string, body?: string) {
    this.getUsername();
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.coreApiKey,
      UserName: this.email,
      Emulator: this.emulator
    });
    const httpOptions = new HttpHeaderResponse({ headers, url });
    return this.http.put<T>(url, body, httpOptions);
  }

  public httpPatchRequest<T>(url: string, data: T) {
    this.getUsername();
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': environment.coreApiKey,
      UserName: this.email,
      Emulator: this.emulator
    });
    const httpOptions = new HttpHeaderResponse({ headers });

    return this.http.patch<T>(url, data, httpOptions).pipe(
      map((response: T) => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
