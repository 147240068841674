import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalService } from '@core/services/global.service';
import { UserRolePermission } from '@shared/models/userRolePermission';
import { map, catchError } from 'rxjs/operators';
import { AccessTypePermission } from '@shared/models/accessTypePermission';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { Role } from '../../shared/enums/Role';
import { HttpHelperService } from './http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  public USER_LEVEL_ROLE_PERM = 'USER_LEVEL_ROLE_PERM';
  public ACCESS_TYPE_ROLE_PERM = 'ACCESS_TYPE_ROLE_PERM';
  // private rolePermission = new Subject<UserRolePermission>();

  constructor(
    private httpHelper: HttpHelperService,
    private globalService: GlobalService
  ) {}

  isInRole(role: Role): boolean {
    const val = this.globalService.get(this.USER_LEVEL_ROLE_PERM);
    if (val !== undefined && val.role.length > 0) {
      return val.role.includes(role);
    }
    return false;
  }

  hasPermission(permission: string): boolean {
    const val = this.globalService.get(this.USER_LEVEL_ROLE_PERM);
    if (val !== undefined && val.permission.length > 0) {
      return val.permission.includes(permission);
    }
    return false;
  }

  hasAccessPermission(permission: string, accessType: AccessTypes): boolean {
    const isEmulated = this.isEmulated();
    const isEmulatorAdminOrCareAgent = this.isEmulatorAdminOrCareAgent();
    // emulator as Admin/Care Agent OR normal flow without emulation
    if ((isEmulated && isEmulatorAdminOrCareAgent) || !isEmulated) {
      return this.checkAccessPermissionFromLoggedInUser(permission, accessType);
    }

    // emulator as Sales, Employee and Collection
    if (isEmulated && !isEmulatorAdminOrCareAgent) {
      const emulatorUser: any = this.globalService.get('EmulatorUser');
      if (emulatorUser !== undefined && emulatorUser.permission.length > 0) {
        return emulatorUser.permission.includes(permission);
      }
      return false;
    }
    return false;
  }

  checkAccessPermissionFromLoggedInUser(
    permission: string,
    accessType: AccessTypes
  ) {
    const cachedAccesses = this.globalService.get(
      this.ACCESS_TYPE_ROLE_PERM
    ) as AccessTypePermission[];
    if (cachedAccesses) {
      const cachedAccess = cachedAccesses.find(x => x.access === accessType);
      if (cachedAccess.permission.length > 0) {
        const result = cachedAccess.permission.includes(permission);
        return result;
      }
    }
    return false;
  }

  isEmulatorAdminOrCareAgent() {
    if (this.isEmulated()) {
      const emulatorUser = this.globalService.get('EmulatorUser');
      if (emulatorUser !== undefined && emulatorUser.role.length > 0) {
        return (
          emulatorUser.role.includes(Role.Care_Agent) ||
          emulatorUser.role.includes(Role.Admin)
        );
      }
      return false;
    }
    return false;
  }

  isEmulated() {
    return this.globalService.has('EmulatingUser');
  }

  isAdminOrCareAgent() {
    return this.isInRole(Role.Admin) || this.isInRole(Role.Care_Agent);
  }

  public getUserRolesPermissions(): Observable<UserRolePermission> {
    return this.httpHelper
      .httpGetRequest<UserRolePermission>(
        environment.apimWebRoot + environment.GetRolesPermissionByUser
      )
      .pipe(
        map((response: UserRolePermission) => response),
        catchError(response => throwError(response))
      );
  }

  public loadAccessTypePermissions(): Observable<AccessTypePermission[]> {
    return this.httpHelper
      .httpGetRequest<AccessTypePermission[]>(
        environment.apimWebRoot + environment.LoadAccessTypePermissions
      )
      .pipe(
        map((response: AccessTypePermission[]) => response),
        catchError(response => throwError(response))
      );
  }

  getAccessType(id: string, billingAcct: boolean) {
    return this.httpHelper.httpGetRequest<object>(
      `${environment.apimWebRoot}${environment.accessType}/${id}/${billingAcct}`
    );
  }

  getAccessTypeByAccount(id: string, billingAcct: boolean): Observable<any> {
    return this.httpHelper.httpGetRequest<any>(
      `${environment.apimWebRoot}${environment.accessType}/${id}/${billingAcct}`
    );
  }
}
