import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GuestPayment } from '@shared/models/guestPayment';
import { GuestService } from '@core/services/guest.service';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { FiservModalComponent } from '../method/fiserv-modal/fiserv-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.sass']
})
export class GuestComponent implements OnInit {
  MSG_ACCOUNT_ERROR = `Account not found. For assistance, call customer care at ${environment.customerCarePhoneAlt} (${environment.customerCarePhone}).`;
  MSG_PAYMENTNOTE = `${environment.paymentNoteMsg}`;
  guestForm: FormGroup;
  guestPay: GuestPayment;
  amount$;
  time$;
  paymentAmount$;
  paymentStatus$;
  paymentDate$;
  self = this;
  newBillAccount = '';
  billPostalCode$;
  zipLabel = '';
  paymethodToken$;
  submitted = false;
  valid = false;
  confirm = false;
  error = false;
  errorMessage;
  display;
  accountId;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private guestService: GuestService,
    private ngZone: NgZone,
    private modalDialogService: ModalDialogService,
    public modalDialog: MatDialog
  ) {
    window['fireNgEvent'] = { component: this, zone: this.ngZone };
  }

  ngOnInit() {
    this.initGuestForm(this.formBuilder);
    this.onChangesBillingAccount();
    this.onChangesUtilityAccount();
  }

  initGuestForm(formBuilder: FormBuilder) {
    this.guestForm = this.formBuilder.group(
      {
        billingAccount: new FormControl({ value: '', disabled: false }, [
          Validators.required,
          Validators.pattern('^[0-9]*$')
        ]),
        utilityAccount: new FormControl({ value: '', disabled: false }, [
          Validators.required
        ]),
        postalCode: new FormControl({ value: '', disabled: false }, [
          Validators.required,
          Validators.pattern('^[0-9]{5}(?:[0-9]{4})?$')
        ])
      },
      this.requireAccount()
    );
  }

  onChangesBillingAccount() {
    this.guestForm
      .get('billingAccount')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((val: string) => {
        if (val !== undefined && val !== null && val !== '') {
          this.guestForm.get('utilityAccount').disable();
          if (!this.submitted || !this.valid) {
            this.zipLabel = 'Billing Address';
          }
        } else {
          this.guestForm.get('utilityAccount').enable();
          if (!this.submitted) {
            this.zipLabel = '';
          }
        }
      });
  }

  onChangesUtilityAccount() {
    this.guestForm
      .get('utilityAccount')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(val => {
        if (val !== undefined && val !== null && val !== '') {
          this.guestForm.get('billingAccount').disable();
          if (!this.submitted || !this.valid) {
            this.zipLabel = 'Service Address';
          }
        } else {
          this.guestForm.get('billingAccount').enable();
          if (!this.submitted) {
            this.zipLabel = '';
          }
        }
      });
  }

  requireAccount() {
    return formGroup => {
      if (
        formGroup.get('billingAccount').value === '' &&
        formGroup.get('utilityAccount').value === ''
      ) {
        return {
          required: 'at least one Billing or Utility Account is required'
        };
      }
      return null;
    };
  }

  get f() {
    return this.guestForm.controls;
  }

  onSubmit(e: Event) {
    this.submitted = true;
    this.error = false;

    if (this.guestForm.invalid) {
      return;
    }

    this.confirm = true; // set true will disable Confirm button while pulling data from API

    const result: GuestPayment = Object.assign({}, this.guestForm.value);
    this.billPostalCode$ = result.postalCode;

    const g: GuestPayment = new GuestPayment(
      result.billingAccount,
      result.utilityAccount,
      result.postalCode,
      result.paymethodTypes
    );

    this.getPaymentDetail(g).subscribe(
      (data: GuestPayment) => {
        if (data.engieAccountId !== null) {
          this.valid = true;
          this.guestPay = data;
          this.guestForm.get('billingAccount').setValue(data.billingAccount);
          this.guestForm.get('utilityAccount').setValue(data.utilityAccount);
          this.newBillAccount = data.billingAccount;
          if (data.utilityAccount !== null) {
            this.newBillAccount = data.billingAccount;
          }
          this.guestForm.get('billingAccount').disable();
          this.guestForm.get('utilityAccount').disable();
          this.guestForm.get('postalCode').disable();
          this.time$ = data.utcTimestamp;
          this.amount$ = data.amount;
          this.accountId = data.engieAccountId;
          this.paymentAmount$ = data.paymentAmount;
          this.paymentStatus$ = data.paymentStatus;
          this.paymentDate$ = data.paymentDate;
        } else {
          this.error = true;
          this.errorMessage = this.MSG_ACCOUNT_ERROR;
          this.confirm = false;
        }
      },
      error => {
        this.error = true;
        this.errorMessage = error.error;
        this.confirm = false;
      }
    );
  }

  onReset() {
    this.guestForm.reset();
    this.error = false;
    this.valid = false;
    this.submitted = false;
    this.confirm = false;
    this.newBillAccount = '';
    this.guestForm.get('billingAccount').reset('');
    this.guestForm.get('billingAccount').enable();
    this.guestForm.get('utilityAccount').reset('');
    this.guestForm.get('utilityAccount').enable();
    this.guestForm.get('postalCode').reset('');
    this.guestForm.get('postalCode').enable();
  }

  getPaymentDetail(account: GuestPayment): Observable<GuestPayment> {
    return this.guestService.postPaymentDetail(account);
  }

  goBack() {
    this.router.navigate(['/']);
  }

  async reload(url: string): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigateByUrl(url);
  }

  whitespaceValidator(g: FormGroup) {
    if ((g.value as string).indexOf(' ') >= 0) {
      g.setErrors({ cannotContainSpace: true });
      return of({ cannotContainSpace: true });
    }

    g.setErrors(null);
    return new Promise(null);
  }

  fiservButtonClick() {
    this.modalDialog.open(FiservModalComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: {
        engieAccountId: this.accountId,
        billingAccount: this.newBillAccount,
        guestPayment: true,
        onCloseHandled: () => {},
        guestPaymentDetail: this.guestPay
      }
    });
  }
}
