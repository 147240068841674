import { Component, OnInit, Input } from '@angular/core';
import { BaseGridComponentComponent } from '@shared/components/base-grid-component/base-grid-component.component';
import { AccountView } from '@shared/models/accountView';
import { GridApi, ColumnApi, AgChartOptions } from 'ag-grid-community';
import { AccountService } from '@core/services/account.service';
import { UsageHistory } from '@shared/models/usageHistory';
import { formatDate } from '@angular/common';
import { GlobalConstants } from '@shared/constants/global-constants';

@Component({
  selector: 'app-account-detail-ucb',
  templateUrl: './account-detail-ucb.component.html',
  styleUrls: ['./account-detail-ucb.component.css']
})
export class AccountDetailUcbComponent
  extends BaseGridComponentComponent
  implements OnInit {
  @Input() invoice: AccountView;
  isBillCycle: boolean;
  rowHeight: number;
  domLayout;
  originalData: UsageHistory;
  servicePointNumber: string;
  columnDefs: any;
  year: number;
  yearlyUsageHasRun: boolean;
  usageFrom: string;
  usageTo: string;
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  gridOptions;
  paginationPageSize = GlobalConstants.gridDefaultPageSize;
  private error = '';
  public loadingMessage = 'Loading...';
  donutChartoptions: AgChartOptions;
  lineGraphUsageOptions: AgChartOptions;
  lineGraphCostOptions: AgChartOptions;
  showUsage: boolean;

  constructor(private accountService: AccountService) {
    super();
    this.createColumnDefs();
  }

  ngOnInit() {
    this.isBillCycle = true;
    this.showUsage = false;
    this.year = new Date().getFullYear();
    this.initGridConfig();
    this.servicePointNumber = this.invoice.servicePointAgreement.engieServicePointNumber;

    this.updateDonutChart();
    this.updateUsageLineGraph('');
  }

  initGridConfig() {
    this.gridOptions = {
      rowHeight: 40,
      columnDefs: this.createColumnDefs(),
      defaultColDef: { resizable: false },
      onGridReady: params => {
        if (this.yearlyUsageHasRun) {
          this.gridOptions.api.setRowData(this.originalData.usageHistoryItems);
        } else {
          this.yearlyUsageHasRun = true;
        }
      },
      getRowHeight: params => {
        if (params.node && params.node.detail) {
          const offset = 80;
          const allDetailRowHeight = params.data.uan.length * 40;
          return allDetailRowHeight + offset;
        } else {
          return 50;
        }
      }
    };
  }

  getUsages() {
    const spa = this.servicePointNumber;
    const year = this.year.toString();
    this.accountService.getUsageHistory(spa, year).subscribe(
      result => {
        this.gridOptions.api.setRowData(result.usageHistoryItems);
        this.originalData = result;
        this.usageFrom = result.fromDate;
        this.usageTo = result.toDate;
        this.gridOptions.api.sizeColumnsToFit();
        this.loadingMessage = '';
        this.updateUsageLineGraph(result.usageHistoryItems);
      },
      error => {
        console.log('Failed to get Usages.');
        this.error = error;
        this.loadingMessage = '';
      }
    );
  }

  toggleBillCycle(isBillCycle: boolean) {
    this.isBillCycle = isBillCycle;
    if (!this.isBillCycle && !this.yearlyUsageHasRun) {
      this.getUsages();
    }
  }

  updateDonutChart() {
    this.donutChartoptions = {
      data: this.invoice.invoice.categories,
      series: [
        {
          type: 'pie',
          labelKey: 'name',
          angleKey: 'total',
          innerRadiusOffset: -15,
          label: {
            minAngle: 360
          },
          tooltip: {
            renderer: function (params) {
              return {
                title: params.datum.name,
                content: params.angleValue.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })
              };
            }
          }
        }
      ]
    };
  }

  updateUsageLineGraph(items: any) {
    var data: {
      invoiceFrom: Date;
      invoiceTo: Date;
      totalKwh: number;
      amountTotal: number;
    }[] = [];

    if (items) {
      items.forEach(x => {
        data.push({
          invoiceFrom: new Date(x.invoiceFrom),
          invoiceTo: new Date(x.invoiceTo),
          totalKwh: x.totalKwh,
          amountTotal: x.amountTotal
        });
      });
    }

    this.lineGraphUsageOptions = {
      autoSize: true,
      data: data,
      series: [
        {
          xKey: 'invoiceFrom',
          yKey: 'totalKwh',
          yName: 'kWh',
          stroke: '#03a9f4',
          marker: {
            fill: '#03a9f4',
            stroke: '#0276ab'
          },
          tooltip: {
            renderer: function (params) {
              return {
                title: params.datum.invoiceFrom.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }),
                content: params.yValue.toLocaleString() + ' kWh'
              };
            }
          }
        }
      ],
      axes: [
        {
          type: 'time',
          position: 'bottom',
          title: {
            text: 'Bill Cycle',
            fontSize: 15,
            fontWeight: 'bold'
          }
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: function (params) {
              return params.value.toLocaleString();
            }
          },
          title: {
            text: 'kWh',
            fontSize: 15,
            fontWeight: 'bold'
          }
        }
      ],
      legend: {
        position: 'bottom',
        enabled: false
      }
    };   
    
    this.lineGraphCostOptions = {
      autoSize: true,
      data: data,
      series: [
        {
          xKey: 'invoiceFrom',
          yKey: 'amountTotal',
          yName: 'Cost',
          stroke: '#03a9f4',
          marker: {
            fill: '#03a9f4',
            stroke: '#0276ab'
          },
          tooltip: {
            renderer: function (params) {
              return {
                title: params.datum.invoiceFrom.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }),
                content: params.yValue.toLocaleString("en-US", {style:"currency", currency:"USD"})
              };
            }
          }
        }
      ],
      axes: [
        {
          type: 'time',
          position: 'bottom',
          title: {
            text: 'Bill Cycle',
            fontSize: 15,
            fontWeight: 'bold'
          }
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: function (params) {
              return params.value.toLocaleString("en-US", {style:"currency", currency:"USD"});
            }
          },
          title: {
            text: 'Cost',
            fontSize: 15,
            fontWeight: 'bold'
          }
        }
      ],
      legend: {
        position: 'bottom',
        enabled: false
      }
    };
  }

  nextYear() {
    this.year += 1;
    this.getUsages();
  }

  previousYear() {
    this.year -= 1;
    this.getUsages();
  }

  onGridReady(e) {
    this.gridApi = e.api;
    this.gridColumnApi = e.columnApi;
    e.api.sizeColumnsToFit();
  }

  onColumnResized() {
    this.gridApi.resetRowHeights();
  }

  private createColumnDefs() {
    return [
      {
        headerName: 'BILL PERIOD FROM',
        field: 'invoiceFrom',
        sortable: true,
        editable: false,
        resizable: true,
        cellStyle: { justifyContent: 'left' },
        valueFormatter: super.dateFormatter
      },
      {
        headerName: 'BILL PERIOD TO',
        field: 'invoiceTo',
        sortable: true,
        editable: false,
        cellStyle: { justifyContent: 'left' },
        resizable: true,
        valueFormatter: super.dateFormatter
      },
      {
        headerName: 'METER NUMBER',
        field: 'meterNumber',
        sortable: true,
        cellStyle: { justifyContent: 'left' },
        filter: true,
        width: 200,
        resizable: true
      },
      {
        headerName: 'TOTAL kWh',
        width: 200,
        field: 'totalKwh',
        sortable: true,
        filter: true,
        cellStyle: { justifyContent: 'left' },
        resizable: true,
        valueFormatter: super.numberFormatter
      },
      {
        headerName: 'Amount',
        width: 200,
        field: 'amountTotal',
        sortable: true,
        filter: true,
        cellStyle: { justifyContent: 'left' },
        resizable: true,
        valueFormatter: super.currencyFormatter
      }
    ];
  }

  exportToExcel() {
    const params = {
      columnWidth: 100,
      sheetName: 'Usage Details',
      processCellCallback: this.processExportedCell
    };
    this.gridApi.exportDataAsExcel(params);
  }

  processExportedCell(params) {
    if (
      params.column.colId === 'invoiceFrom' ||
      params.column.colId === 'invoiceTo'
    ) {
      return formatDate(params.value, 'MM/dd/yyyy', 'en-US');
    } else {
      return params.value;
    }
  }

  public toggle(element: HTMLElement) {
    if (element.classList.contains('fa-chevron-right')) {
      element.classList.remove('fa-chevron-right');
      element.classList.add('fa-chevron-down');
    } else {
      element.classList.remove('fa-chevron-down');
      element.classList.add('fa-chevron-right');
    }
  }
}
