import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { Observable, merge } from 'rxjs';
import { formatDate, formatCurrency, getCurrencySymbol } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment/moment';
import Collapse from '@engie-group/fluid-design-system/lib-esm/components/collapse';
import { startWith, map } from 'rxjs/operators';
import { BaseGridComponentComponent } from '@shared/components/base-grid-component/base-grid-component.component';
import { AccountHistory } from '@shared/models/accountHistory';
import { AccountList } from '@shared/models/accountList';
import { DownloadedFileDetails } from '@shared/models/downloadedFileDetails';
import { AccountService } from '@core/services/account.service';
import { GlobalService } from '@core/services/global.service';
import { UtilityService } from '@core/services/utility.service';
import { CustomTooltipComponent } from '@shared/components/grid-custom-tooltip/custom-tooltip.component';
import { DynamiteResponse } from '@shared/models/dynamiteResponse';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { Permission } from '@shared/enums/Permission';
import { BillingTypes } from '@shared/enums/BillingTypes';
import { areValid } from '@shared/validators/both-valid.validator';
import { GlobalConstants } from '@shared/constants/global-constants';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { IdentityService } from '@core/services/identity.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.sass']
})
export class HistoryComponent
  extends BaseGridComponentComponent
  implements OnInit {
  public ACCOUNT_LISTING = 'ACCOUNT_LISTING';
  excelStyles: any;
  accountId = null;
  billingAccount = null;
  paymentId;
  accountHistory: AccountHistory[];
  userAccounts: AccountList[];
  filteredBillingAccounts$: Observable<AccountList[]>;
  filteredUtilityAccounts$: Observable<AccountList[]>;
  mergedAccounts$: Observable<AccountList[]>;
  selectedAccount;
  activeOption;
  isAuthenticated: boolean;
  body;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridOptions;
  columnDefs;
  paginationPageSize = GlobalConstants.gridDefaultPageSize;
  paginationNumberFormatter;
  domLayout;
  defaultColDef;
  tooltipShowDelay;
  frameworkComponents;
  emulate = false;
  accountType;
  inputFrom;
  inputTo;
  billType = 'Bill';
  invoiceType = 'Invoice';
  defaultNumberOfMonthsInThePast = 6;
  toDate = moment(moment.now()).add(1, 'M').format('MM-DD-yyyy 23:59:59');
  fromDate = moment(moment.now())
    .subtract(this.defaultNumberOfMonthsInThePast, 'months')
    .format('MM-DD-yyyy 00:00:00');
  submitted = false;
  isLoading = false;
  historyForm: FormGroup;
  bulkAction: string;
  bulkSelectedAccounts: DownloadedFileDetails[];
  isUCB = false;
  isRateReady = false;
  gridRowHeight = GlobalConstants.gridRowHeight;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private router: Router,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private utilityService: UtilityService,
    private modalDialogService: ModalDialogService,
    private identityService: IdentityService
  ) {
    super();
    this.createGridOpts();
    this.createColumnDefs();
  }

  ngOnInit() {
    this.isLoading = true;
    const detail: string = this.globalService.get(this.globalService.DETAIL);
    if (detail !== undefined) {
      this.accountId = detail.split('/')[0];
    }

    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }

    this.accountType = this.globalService.get('accountType');
    this.initHistoryForm(this.formBuilder);
    this.getUserAccounts();
  }

  get f() {
    return this.historyForm.controls;
  }

  initHistoryForm(formBuilder: FormBuilder) {
    this.historyForm = this.formBuilder.group(
      {
        billingAccountNumber: new FormControl(''),
        fromDate: new FormControl(new Date(this.fromDate), [
          Validators.required
        ]),
        toDate: new FormControl(new Date(this.toDate), [Validators.required]),
        utilityAccountNumber: new FormControl('')
      },
      {
        validators: [areValid('billingAccountNumber', 'utilityAccountNumber')]
      }
    );
  }

  filterAccounts() {
    this.filteredBillingAccounts$ = this.historyForm
      .get('billingAccountNumber')
      .valueChanges.pipe(
        startWith(''),
        map(value => this._billingFilter(value))
      );
    this.filteredUtilityAccounts$ = this.historyForm
      .get('utilityAccountNumber')
      .valueChanges.pipe(
        startWith(''),
        map(value => this._utilityFilter(value))
      );
    this.mergedAccounts$ = merge(
      this.filteredBillingAccounts$,
      this.filteredUtilityAccounts$
    );
  }

  onlyUniqueValues(value, index, self) {
    return (
      self.findIndex(
        v => v.billingAccountNumber === value.billingAccountNumber
      ) === index
    );
  }

  getUserAccounts() {
    this.accountService.getUserAccountList().subscribe(
      result => {
        //TODO: temporary code fix for null BANs. Remove when data issue is fixed
        this.userAccounts = result.filter(x => x.billingAccountNumber !== null);

        if (this.accountId !== undefined && this.accountId !== null) {
          if (
            this.userAccounts.some(x => x.engieAccountId === this.accountId)
          ) {
            this.historyForm
              .get('billingAccountNumber')
              .setValue(
                this.userAccounts.find(x => x.engieAccountId === this.accountId)
              );
          } else {
            this.historyForm
              .get('utilityAccountNumber')
              .setValue(
                this.userAccounts.find(x => x.engieAccountId === this.accountId)
              );
          }
        }
        this.isLoading = false;
        this.filterAccounts();
      },
      error => {
        const message = `Failed to get User Account listing - ${error.error}.`;
        this.modalDialogService.openErrorDialog(message);
        this.isLoading = false;
      }
    );
  }

  getAccountHistory(fromDate: string, toDate: string, id?: string) {
    this.spinner.show();
    this.accountService.getAccountHistory(fromDate, toDate, id).subscribe(
      result => {
        this.hideCollapse();
        this.accountHistory = result;

        this.setGridHeight(
          'historyGrid',
          this.gridApi,
          GlobalConstants.gridMinimumHeight,
          GlobalConstants.gridDefaultPageSize,
          this.accountHistory.length
        );

        if (result.length > 0) {
          this.isUCB = result[0].isUcb;
          if (this.isUCB) {
            this.columnDefs = this.columnDefsUCB;
          } else {
            this.columnDefs = this.columnDefsSupplier;
          }
          this.isRateReady =
            result[0].billingType === BillingTypes.RateReady ? true : false;
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        const message = `Failed to get Account History - ${error.error}.`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  onPaginationChanged(params) {
    if (this.accountHistory !== undefined) {
      this.setGridHeight_OnPaginationChanged(
        'historyGrid',
        this.gridApi,
        GlobalConstants.gridMinimumHeight,
        GlobalConstants.gridDefaultPageSize,
        params
      );
    }
  }

  onSubmit(e) {
    this.submitted = true;
    if (this.historyForm.invalid) {
      return;
    }

    const result: AccountHistory = Object.assign({}, this.historyForm.value);
    this.inputFrom = moment(result.fromDate).format('MM-DD-YYYY 00:00:00');
    this.inputTo = moment(result.toDate).format('MM-DD-YYYY 23:59:59');

    const selectedUtilityEngieAccountId = this.historyForm.get(
      'utilityAccountNumber'
    ).value.engieAccountId;

    if (selectedUtilityEngieAccountId !== undefined) {
      this.selectedAccount = selectedUtilityEngieAccountId;
    } else {
      this.selectedAccount = this.historyForm.get(
        'billingAccountNumber'
      ).value.engieAccountId;
    }
    this.getAccountHistory(this.inputFrom, this.inputTo, this.selectedAccount);
  }

  missingFields(): boolean {
    return (
      this.historyForm.controls.billingAccountNumber.errors?.bothEmpty ||
      this.historyForm.controls.fromDate.errors?.required ||
      this.historyForm.controls.toDate.errors?.required
    );
  }

  clearBillingAccountField(trigger) {
    trigger.openPanel();
    this.historyForm.get('billingAccountNumber').setValue('');
    this.historyForm.get('billingAccountNumber').updateValueAndValidity();
    this.historyForm.get('utilityAccountNumber').setValue('');
    this.historyForm.get('utilityAccountNumber').updateValueAndValidity();
  }

  clearUtilityAccountField(trigger) {
    trigger.openPanel();
    this.historyForm.get('utilityAccountNumber').setValue('');
    this.historyForm.get('utilityAccountNumber').updateValueAndValidity();
  }

  onGridReady(e) {
    this.gridApi = e.api;
    this.gridColumnApi = e.columnApi;
    this.gridApi.setDomLayout('normal');
    this.gridApi.setHeaderHeight(GlobalConstants.gridHeaderHeight);
    document.getElementById('historyGrid').style.height =
      GlobalConstants.gridMinimumHeight;
  }

  billingFieldOnSelectionChange(e) {
    if (e.source.selected) {
      let selectedItem = e.source.value.billingAccountNumber;
      this.resetValuesOnBillingChange(selectedItem);
    }
  }

  utilityFieldOnSelectionChange(e) {
    if (e.source.selected) {
      let selectedItem = e.source.value.utilityAccountNumber;
      this.resetValuesOnUtilityChange(selectedItem);
    }
  }

  billingFieldOnKeyUp(e) {
    let input = this.historyForm.get('billingAccountNumber').value;
    this.resetValuesOnBillingChange(input);
  }

  utilityFieldOnKeyUp(e) {
    let input = this.historyForm.get('utilityAccountNumber').value;
    this.resetValuesOnUtilityChange(input);
  }

  resetValuesOnBillingChange(input) {
    if (typeof input === 'object') {
      input = input.billingAccountNumber;
    }
    input = input.toUpperCase();
    if (
      input &&
      this.userAccounts.some(x => x.billingAccountNumber === input)
    ) {
      let numberOfMatchedAccounts = this.userAccounts.filter(
        x => x.billingAccountNumber === input
      );
      if (numberOfMatchedAccounts.length === 1) {
        this.historyForm
          .get('utilityAccountNumber')
          .setValue(numberOfMatchedAccounts[0]);
      }
      this.historyForm
        .get('billingAccountNumber')
        .setValue(
          this.userAccounts.find(x => x.billingAccountNumber === input)
        );
    }
  }

  resetValuesOnUtilityChange(input) {
    if (typeof input === 'object') {
      input = input.utilityAccountNumber;
    }
    if (
      input &&
      this.userAccounts.some(x => x.utilityAccountNumber === input)
    ) {
      let matchedAccount = this.userAccounts.find(
        x => x.utilityAccountNumber === input
      );
      this.historyForm.get('billingAccountNumber').setValue(matchedAccount);
      this.historyForm.get('utilityAccountNumber').setValue(matchedAccount);
    }
  }

  _billingFilter(value: any): AccountList[] {
    if (typeof value === 'object') {
      value = value.billingAccountNumber;
    }
    const utilityField = this.historyForm.get('utilityAccountNumber').value
      .utilityAccountNumber;
    if (utilityField) {
      return this.userAccounts.filter(
        option =>
          option.billingAccountNumber.includes(value.toUpperCase()) &&
          option.utilityAccountNumber.includes(utilityField)
      );
    } else {
      return this.userAccounts.filter(option =>
        option.billingAccountNumber.includes(value.toUpperCase())
      );
    }
  }

  _utilityFilter(value: any): AccountList[] {
    if (typeof value === 'object') {
      value = value.utilityAccountNumber;
    }
    const billingField = this.historyForm.get('billingAccountNumber').value
      .billingAccountNumber;
    if (billingField) {
      return this.userAccounts.filter(
        option =>
          option.utilityAccountNumber.includes(value) &&
          option.billingAccountNumber.includes(billingField)
      );
    } else {
      return this.userAccounts.filter(option =>
        option.utilityAccountNumber.includes(value)
      );
    }
  }

  BillingAutoCompleteDisplay(item: any): string {
    if (item === undefined) {
      return;
    }
    return item.billingAccountNumber;
  }

  UtilityAutoCompleteDisplay(item: any): string {
    if (item === undefined) {
      return;
    }
    return item.utilityAccountNumber;
  }

  onColumnResized() {
    this.gridApi.resetRowHeights();
  }

  onFirstDataRendered(params) {
    setTimeout(() => {
      params.api.getDisplayedRowAtIndex(1).setExpanded(true);
    }, 0);
  }

  onQuickSearchChanged(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  transactionPeriodFormatter(params) {
    let data: AccountHistory = params.data;
    if (
      data.transactionPeriodFrom == null ||
      data.transactionPeriodTo == null
    ) {
      return null;
    }

    const periodFrom = formatDate(
      data.transactionPeriodFrom,
      'MM/dd/yyyy',
      'en-US'
    );
    const periodTo = formatDate(
      data.transactionPeriodTo,
      'MM/dd/yyyy',
      'en-US'
    );

    return periodFrom + '&nbsp;-&nbsp;' + periodTo;
  }

  getStatusIcon(params) {
    let divTag: HTMLDivElement;
    let htmlString = '';
    let data: AccountHistory = params.data;
    if (
      data.status.toLowerCase() === 'scheduled' &&
      data.transactionDate.toString() > this.toDate
    ) {
      htmlString = data.status;
      const accessType: AccessTypes = params._self.utilityService.convertToAccessType(
        params.node.data.accessTypes
      );

      const canPay = params._self.identityService.hasAccessPermission(
        Permission.Pay_Bill,
        accessType
      );
      if (canPay) {
        htmlString += `<span class="btn-simple"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie' title='Cancel Payment'>cancel</i></span>`;
      }
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();

      const eButtonCancelPayment = divTag.querySelectorAll('.btn-simple')[0];
      if (eButtonCancelPayment) {
        const message = `Are you sure you want to cancel this payment?`;
        eButtonCancelPayment.addEventListener('click', () => {
          this.accountId = data.engieAccountId;
          this.paymentId = data.engiePaymentId;
          this.modalDialogService.openWarningDialog(
            'Cancel Payment',
            () => {
              this.cancelPayment(this.accountId, this.paymentId);
            },
            '',
            null,
            message,
            'Cancel Scheduled Payment',
            '',
            "Don't Cancel"
          );
        });
      }
    } else {
      htmlString = data.status;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
    }
    return divTag;
  }

  billIcon(params) {
    let divTag: HTMLDivElement;
    let htmlString = '';
    const confirmBody = '';
    let data: AccountHistory = params.data;
    if (data.type === this.billType) {
      htmlString = `<span class="btn-simple"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>picture_as_pdf</i></span>`;
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
      const eButtonBill = divTag.querySelector('.btn-simple');
      eButtonBill.addEventListener('click', () => {
        this.utilityService.viewStatement(
          new DownloadedFileDetails(
            null,
            null,
            data.engieStatementId,
            null,
            null
          ),
          true
        );
      });
      return divTag;
    }
  }

  backingSheetIcon(params) {
    let divTag: HTMLDivElement;
    let htmlString = '';
    const confirmBody = '';
    let data: AccountHistory = params.data;
    if (
      data.type === this.billType ||
      (data.isUcb && data.billingType === BillingTypes.BillReady)
    ) {
      if (data.engieStatementId === null && !data.isUcb) {
        htmlString = `<span class="btn-simple disabled"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>article</i></span>`;
      } else {
        htmlString = `<span class="btn-simple"><i style='color:#009de9; vertical-align:-3px; padding-left:5px;' class='material-icons md--blue-engie'>article</i></span>`;
      }
      divTag = document.createElement('div');
      divTag.innerHTML = htmlString.trim();
      const eButtonBackingSheet = divTag.querySelector('.btn-simple');
      eButtonBackingSheet.addEventListener('click', () => {
        this.utilityService.downloadBackingSheet(
          new DownloadedFileDetails(
            data.isUcb ? data.utilityAccountNumber : data.billingAccountNumber,
            data.engieAccountId,
            data.isUcb ? null : data.engieStatementId,
            data.isUcb ? data.engieInvoiceId : null,
            null
          ),
          true,
          true
        );
      });
    }
    return divTag;
  }

  cancelPayment(engieAccountId: string, engiePaymentId: string) {
    this.spinner.show();
    return this.accountService
      .cancelPayment(engieAccountId, engiePaymentId)
      .subscribe(
        (response: DynamiteResponse) => {
          if (!response.isSuccess) {
            this.spinner.hide();
            const message = `Unable to cancel the scheduled payment.`;
            const additionalMessage = `Reason: ${response.message}.`;
            this.modalDialogService.openErrorDialog(
              message,
              additionalMessage,
              'Dismiss',
              () => {
                this.getAccountHistory(
                  this.fromDate,
                  this.toDate,
                  this.accountId
                );
              }
            );
          } else {
            this.getAccountHistory(
              this.inputFrom,
              this.inputTo,
              this.accountId
            );
          }
        },
        error => {
          this.spinner.hide();
          const message = `Unable to cancel the scheduled payment.`;
          this.modalDialogService.openErrorDialog(message, error.error);
        }
      );
  }

  async reload(url: string): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigateByUrl(url);
  }

  onPageSizeChanged(selectedValue: number) {
    const value = selectedValue;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  exportToExcel() {
    const params = {
      columnWidth: 100,
      sheetName: 'AccountHistory',
      processCellCallback: this.processExportedCell
    };
    this.gridApi.exportDataAsExcel(params);
  }

  processExportedCell(params) {
    if (
      params.column.colId === 'transactionDate' ||
      params.column.colId === 'periodStartSupplier' ||
      params.column.colId === 'periodEndSupplier' ||
      params.column.colId === 'periodStartUCB' ||
      params.column.colId === 'periodEndUCB' ||
      params.column.colId === 'invoiceDateUCB'
    ) {
      if (params.value != null) {
        return formatDate(params.value, 'MM/dd/yyyy', 'en-US');
      }
      return null;
    } else if (
      params.column.colId === 'status_1' ||
      params.column.colId === 'status_2'
    ) {
      return null;
    } else {
      return params.value;
    }
  }

  private createColumnDefs() {
    this.columnDefs = this.columnDefsSupplier;
    this.defaultColDef = {
      minHeight: 85,
      tooltipComponent: 'customTooltip'
    };
    this.tooltipShowDelay = 0;
    this.frameworkComponents = { customTooltip: CustomTooltipComponent };
  }

  copyIcon(params) {
    const divTag = document.createElement('div');
    this.utilityService.fieldWithCopyButton(divTag, params.value, params.value);
    return divTag;
  }

  displayCheckBox(params) {
    let data: AccountHistory = params.data;
    return data.type === this.billType || data.type === this.invoiceType;
  }

  onSelectionChanged() {
    let selectedBills = this.gridApi
      .getSelectedRows()
      .filter(x => x.type === this.billType || x.type === this.invoiceType);

    if (selectedBills.length > 0) {
      this.showCollapse();
    } else {
      this.hideCollapse();
    }
  }

  hideCollapse() {
    let collapseNode: HTMLElement = document.querySelector('#ShowBulkOption');
    let collapse = Collapse.getInstance(collapseNode);
    collapse.hide();
  }

  showCollapse() {
    let collapseNode: HTMLElement = document.querySelector('#ShowBulkOption');
    let collapse = Collapse.getInstance(collapseNode);
    collapse.show();
  }

  customTooltip() {
    return true;
  }

  private createGridOpts() {
    this.excelStyles = [
      {
        id: 'textFormat',
        dataType: 'string'
      }
    ];
    this.gridOptions = {
      isRowSelectable: function (row) {
        return row.data.type === 'Bill' || row.data.type === 'Invoice'
          ? true
          : false;
      }
    };
  }

  bulkDownloadStatements() {
    this.bulkAction = 'statements';
    this.showConfirmationModalToDownloadFile();
  }

  bulkDownloadSupportingDocuments() {
    this.bulkAction = 'supporting documents';
    this.showConfirmationModalToDownloadFile();
  }

  showConfirmationModalToDownloadFile() {
    let selectedAccounts: DownloadedFileDetails[];
    selectedAccounts = [];
    this.gridApi.getSelectedRows().forEach(x => {
      selectedAccounts.push(
        new DownloadedFileDetails(
          x.isUcb ? x.utilityAccountNumber : x.billingAccountNumber,
          x.engieAccountId,
          x.isUcb ? null : x.engieStatementId,
          x.isUcb ? x.engieInvoiceId : null,
          null
        )
      );
    });
    this.bulkSelectedAccounts = selectedAccounts;

    let message =
      'Would you like to compress the selected files into a zip archive or download individual files?';
    this.modalDialogService.openWarningDialog(
      'Individual Files',
      () => {
        this.downloadIndividualFiles();
      },
      'Zip File',
      () => {
        this.downloadZipFile();
      },
      message,
      'Download Options'
    );
  }

  downloadIndividualFiles() {
    this.bulkSelectedAccounts.forEach(x => {
      if (this.bulkAction === 'statements') {
        this.utilityService.downloadStatement(x, true);
      } else {
        this.utilityService.downloadBackingSheet(x, true);
      }
    });
  }

  downloadZipFile() {
    if (this.bulkAction === 'statements') {
      this.utilityService.zipStatements(this.bulkSelectedAccounts);
    } else {
      this.utilityService.zipBackingSheets(this.bulkSelectedAccounts);
    }
  }

  columnDefsUCB = [
    {
      suppressMenu: true,
      minWidth: 40,
      maxWidth: 40,
      resizable: false,
      checkboxSelection: this.displayCheckBox.bind(this),
      headerCheckboxSelection: true,
      cellStyle: { justifyContent: 'left' },
      suppressColumnsToolPanel: true
    },
    {
      headerName: 'UTILITY ACCOUNT',
      field: 'utilityAccountNumber',
      flex: 6,
      minWidth: 200,
      sortable: true,
      filter: true,
      resizable: true,
      cellStyle: { justifyContent: 'left' },
      cellClass: 'textFormat',
      cellRenderer: this.copyIcon.bind(this)
    },
    {
      headerName: 'TYPE',
      flex: 3,
      minWidth: 140,
      sortable: true,
      filter: true,
      resizable: true,
      cellStyle: { justifyContent: 'left' },
      valueFormatter: (params: any) => {
        let data: AccountHistory = params.data;
        if (data.isUcb) {
          if (data.billingType === BillingTypes.BillReady) {
            return 'Bill Ready Charges';
          } else {
            return 'Rate Ready Charges';
          }
        } else {
          return data.type;
        }
      }
    },
    {
      headerName: 'INVOICE DATE',
      colId: 'invoiceDateUCB',
      flex: 3,
      minWidth: 120,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: super.dateComparator,
        browserDatePicker: true
      },
      resizable: true,
      cellStyle: { justifyContent: 'left' },
      valueGetter: (params: any) => {
        let data: AccountHistory = params.data;
        let transactionDt = data.transactionDate;
        let dt = new Date(Date.parse(transactionDt.toString()));
        if (dt.getFullYear() <= 1) return '';
        if (transactionDt)
          return formatDate(transactionDt, 'MM/dd/yyyy', 'en-US');
      }
    },
    {
      headerName: 'PERIOD START',
      colId: 'periodStartUCB',
      field: 'transactionPeriodFrom',
      valueFormatter: super.dateFormatter,
      flex: 3,
      minWidth: 120,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: super.dateComparator,
        browserDatePicker: true
      },
      resizable: true,
      cellStyle: { justifyContent: 'left' }
    },
    {
      headerName: 'PERIOD END',
      colId: 'periodEndUCB',
      field: 'transactionPeriodTo',
      valueFormatter: super.dateFormatter,
      flex: 3,
      minWidth: 120,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: super.dateComparator,
        browserDatePicker: true
      },
      resizable: true,
      cellStyle: { justifyContent: 'left' }
    },
    {
      headerName: 'USAGE',
      field: 'usage',
      sortable: false,
      flex: 3,
      minWidth: 80,
      filter: 'agNumberColumnFilter',
      resizable: true,
      cellStyle: { justifyContent: 'flex-end' },
      valueFormatter: super.numberFormatter
    },
    {
      headerName: 'SUPPLIER CHARGES',
      valueGetter: (params: any) => {
        let data: AccountHistory = params.data;
        let currentCharges = data.currentCharges;
        if (currentCharges) {
          let parsedAmount: number;
          let formattedamount: string;
          parsedAmount = parseFloat(currentCharges);
          formattedamount = formatCurrency(
            parsedAmount,
            'en-US',
            getCurrencySymbol('USD', 'wide')
          );
          return formattedamount;
        }
      },
      sortable: true,
      flex: 3,
      minWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: true,
      cellStyle: { justifyContent: 'flex-end' }
    },
    {
      headerName: '',
      maxWidth: 40,
      minWidth: 40,
      sortable: false,
      filter: false,
      resizable: false,
      cellStyle: { justifyContent: 'center' },
      cellRenderer: this.backingSheetIcon.bind(this),
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      tooltipComponentParams: {
        color: 'black',
        tooltip: `Download Supporting Documents`
      },
      tooltipValueGetter: this.customTooltip.bind(this)
    }
  ];

  columnDefsSupplier = [
    {
      suppressMenu: true,
      minWidth: 40,
      maxWidth: 40,
      resizable: false,
      checkboxSelection: this.displayCheckBox.bind(this),
      headerCheckboxSelection: true,
      cellStyle: { justifyContent: 'left' },
      suppressColumnsToolPanel: true
    },
    {
      headerName: 'BILLING ACCOUNT',
      field: 'billingAccountNumber',
      flex: 4,
      minWidth: 115,
      sortable: true,
      filter: true,
      resizable: true,
      cellStyle: { justifyContent: 'left' },
      cellClass: 'textFormat',
      cellRenderer: this.copyIcon.bind(this)
    },
    {
      headerName: 'TYPE',
      field: 'type',
      flex: 2,
      minWidth: 75,
      sortable: true,
      filter: true,
      resizable: true,
      cellStyle: { justifyContent: 'left' }
    },
    {
      headerName: 'STATUS',
      flex: 2,
      minWidth: 85,
      field: 'status',
      sortable: true,
      filter: true,
      resizable: true,
      cellStyle: { justifyContent: 'left' },
      cellRenderer: this.getStatusIcon.bind(this),
      cellRendererParams: {
        _self: this
      }
    },
    {
      headerName: 'PAYMENT AMOUNT',
      valueGetter: (params: any) => {
        let data: AccountHistory = params.data;
        let amount = data.paymentAmount;
        if (amount) {
          let parsedAmount: number;
          let formattedamount: string;
          if (amount.toString().startsWith('-')) {
            parsedAmount = parseFloat(amount) * -1;
            formattedamount = `(${formatCurrency(
              parsedAmount,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            )})`;
          } else {
            parsedAmount = parseFloat(amount);
            formattedamount = formatCurrency(
              parsedAmount,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            );
          }
          return formattedamount;
        }
      },
      sortable: true,
      flex: 2,
      minWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: true,
      cellStyle: { justifyContent: 'flex-end' }
    },
    {
      headerName: 'TRANSACTION DATE',
      colId: 'transactionDateSupplier',
      field: 'transactionDate',
      flex: 2,
      minWidth: 130,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: super.dateComparator,
        browserDatePicker: true
      },
      resizable: true,
      cellStyle: { justifyContent: 'left' },
      valueGetter: (params: any) => {
        let data: AccountHistory = params.data;
        let transactionDt = data.transactionDate;
        let dt = new Date(Date.parse(transactionDt.toString()));
        if (dt.getFullYear() <= 1) return '';
        if (transactionDt)
          return formatDate(transactionDt, 'MM/dd/yyyy', 'en-US');
      }
    },
    {
      headerName: 'STATEMENT ID',
      field: 'statementNumber',
      hide: true,
      minWidth: 120,
      cellStyle: { justifyContent: 'left' },
      cellClass: 'textFormat'
    },
    {
      headerName: 'STATEMENT DATE',
      field: 'issueDate',
      hide: true,
      minWidth: 110,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: super.dateComparator,
        browserDatePicker: true
      },
      resizable: true,
      valueFormatter: super.dateFormatter,
      getQuickFilterText: super.noQuickFilter,
      cellStyle: { justifyContent: 'left' }
    },
    {
      headerName: 'PERIOD START',
      colId: 'periodStartSupplier',
      field: 'transactionPeriodFrom',
      valueFormatter: super.dateFormatter,
      flex: 3,
      minWidth: 90,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: super.dateComparator,
        browserDatePicker: true
      },
      resizable: true,
      cellStyle: { justifyContent: 'left' }
    },
    {
      headerName: 'PERIOD END',
      colId: 'periodEndSupplier',
      field: 'transactionPeriodTo',
      valueFormatter: super.dateFormatter,
      flex: 3,
      minWidth: 90,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: super.dateComparator,
        browserDatePicker: true
      },
      resizable: true,
      cellStyle: { justifyContent: 'left' }
    },
    {
      headerName: 'USAGE',
      field: 'usage',
      sortable: false,
      flex: 2,
      minWidth: 80,
      filter: 'agNumberColumnFilter',
      resizable: true,
      cellStyle: { justifyContent: 'flex-end' },
      valueFormatter: super.numberFormatter
    },
    {
      headerName: 'CURRENT CHARGES',
      valueGetter: (params: any) => {
        let data: AccountHistory = params.data;
        let currentCharges = data.currentCharges;
        if (currentCharges) {
          let parsedAmount: number;
          let formattedamount: string;
          parsedAmount = parseFloat(currentCharges);
          formattedamount = formatCurrency(
            parsedAmount,
            'en-US',
            getCurrencySymbol('USD', 'wide')
          );
          return formattedamount;
        }
      },
      sortable: true,
      flex: 2,
      minWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: true,
      cellStyle: { justifyContent: 'flex-end' }
    },
    {
      headerName: 'STATEMENT BALANCE',
      flex: 2,
      minWidth: 110,
      field: 'statementBalance',
      valueGetter: (params: any) => {
        let data: AccountHistory = params.data;
        if (data.type === this.billType) {
          let amount = data.statementBalance;
          let parsedAmount: number;
          let formattedamount: string;
          if (amount.toString().startsWith('-')) {
            parsedAmount = parseFloat(amount) * -1;
            formattedamount = `(${formatCurrency(
              parsedAmount,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            )})`;
          } else {
            parsedAmount = parseFloat(amount);
            formattedamount = formatCurrency(
              parsedAmount,
              'en-US',
              getCurrencySymbol('USD', 'wide')
            );
          }
          return formattedamount;
        }
      },
      sortable: true,
      filter: 'agNumberColumnFilter',
      resizable: true,
      cellStyle: { justifyContent: 'flex-end' }
    },
    {
      headerName: 'DUE DATE',
      field: 'statementDueDate',
      hide: true,
      flex: 2,
      minWidth: 95,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: super.dateComparator,
        browserDatePicker: true
      },
      resizable: true,
      valueFormatter: super.dateFormatter,
      getQuickFilterText: super.noQuickFilter,
      cellStyle: { justifyContent: 'left' }
    },
    {
      headerName: '',
      maxWidth: 40,
      minWidth: 40,
      field: 'status',
      sortable: false,
      filter: false,
      resizable: false,
      cellStyle: { justifyContent: 'center' },
      cellRenderer: this.billIcon.bind(this),
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      tooltipComponentParams: {
        color: 'black',
        tooltip: `View Statement`
      },
      tooltipValueGetter: this.customTooltip.bind(this)
    },
    {
      headerName: '',
      maxWidth: 40,
      minWidth: 40,
      sortable: false,
      filter: false,
      resizable: false,
      cellStyle: { justifyContent: 'center' },
      cellRenderer: this.backingSheetIcon.bind(this),
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      tooltipComponentParams: {
        color: 'black',
        tooltip: `Download Supporting Documents`
      },
      tooltipValueGetter: this.customTooltip.bind(this)
    }
  ];
}
