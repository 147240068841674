import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '@core/services/account.service';
import { MsalService } from '@azure/msal-angular';
import { PayDetail } from '@shared/models/payDetail';
import { IdentityService } from '@core/services/identity.service';
import { AccessPermission } from '@shared/enums/AccessPermission';
import { GlobalService } from '@core/services/global.service';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-paperless',
  templateUrl: './paperless.component.html',
  styleUrls: ['./paperless.component.sass']
})
export class PaperlessComponent implements OnInit {
  @Input() account: PayDetail;
  @Input() accountId;
  userInfo = this.authService.instance.getActiveAccount();
  checked;
  display;
  paperlessToggle: FormControl;

  constructor(
    private router: Router,
    private authService: MsalService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private globalService: GlobalService,
    private identity: IdentityService,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit() {
    this.paperlessToggle = new FormControl(
      this.account.billDeliveryMethod === 'EBill' ? true : false,
      []
    );
    var access = this.globalService.get('ACCOUNT_LEVEL_ROLE_PERM');
    let canPaperless = false;
    canPaperless = this.identity.hasAccessPermission(
      AccessPermission.Enroll_Paperless,
      access['accessType']
    );
    if (!canPaperless) {
      this.paperlessToggle.disable();
    }

    this.paperlessToggle.valueChanges.subscribe(value => {
      this.checked = value;
    });
  }

  onTogglePaperless(value: boolean, billingAccount: string) {
    this.spinner.show();
    this.accountService
      .setPaperless(this.accountId, billingAccount, value, this.userInfo.name)
      .subscribe(
        response => {
          this.reload(`/account/services`);
          // TODO: need success modal?
        },
        error => {
          this.reload(`/account/services`);
          // TODO: need error modal?
        }
      );
  }

  onChangePaperless(value: boolean) {
    if (value) {
      const message = `You are changing settings for this account. Are you sure you would
      like to start paperless billing for this account?`;
      this.modalDialogService.openWarningDialog(
        'Start Paperless',
        () => {
          this.onTogglePaperless(true, this.account.accountId);
        },
        '',
        null,
        message,
        'Enable Paperless Billing',
        '',
        'Cancel',
        () => {
          this.paperlessToggle.reset(!this.checked, { emitEvent: false });
        }
      );
    } else {
      const message = `You are changing settings for this account. Are you sure you would
      like to stop paperless billing for this account?`;
      this.modalDialogService.openWarningDialog(
        'Stop Paperless',
        () => {
          this.onTogglePaperless(false, this.account.accountId);
        },
        '',
        null,
        message,
        'Disable Paperless Billing',
        '',
        'Cancel',
        () => {
          this.paperlessToggle.reset(!this.checked, { emitEvent: false });
        }
      );
    }
  }

  async reload(url: string): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigateByUrl(url);
  }
}
