import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SystemStatus } from '@shared/models/systemStatus';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-online-form',
  templateUrl: './online-form.component.html',
  styleUrls: ['./online-form.component.css']
})
export class OnlineFormComponent implements OnInit {
  @Input() systemStatus: SystemStatus;
  @Input() initialStatus: boolean;
  @Output() formSubmit = new EventEmitter<SystemStatus>();
  onForm: FormGroup;
  result: SystemStatus;
  body: string;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalDialogService: ModalDialogService
  ) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.onForm.controls;
  }

  ngOnInit(): void {
    this.initForm(this.formBuilder);
    this.updateFormControls(this.systemStatus);
  }

  initForm(formBuilder: FormBuilder) {
    this.onForm = formBuilder.group({
      portalUpMessage: new FormControl(''),
      dashboardMessage: new FormControl('')
    });
  }

  updateFormControls(data: SystemStatus) {
    if (this.onForm) {
      this.onForm.reset();
    }

    this.onForm.patchValue({
      portalUpMessage: data.portalUpMessage,
      dashboardMessage: data.dashboardMessage
    });
  }

  onResetForm() {
    this.submitted = false;
    this.updateFormControls(this.systemStatus);
  }

  onSave(e) {
    this.submitted = true;

    if (this.onForm.invalid) {
      return;
    }
    const message = 'Are you sure you want to continue?';
    this.modalDialogService.openWarningDialog(
      'Continue',
      () => {
        this.onTakeOnline();
      },
      '',
      null,
      message,
      'Confirm'
    );
  }

  onTakeOnline() {
    this.result = this.createPostData();
    this.formSubmit.emit(this.result);
    this.onForm.markAsPristine();
    this.submitted = false;
  }

  createPostData(): SystemStatus {
    let tempObject = new SystemStatus();
    tempObject = Object.assign({}, this.onForm.value);
    tempObject.isPortalUp = this.systemStatus.isPortalUp;
    tempObject.incidentType = 'Operational';
    tempObject.incidentDescription = this.systemStatus.incidentDescription;
    return tempObject;
  }
}
