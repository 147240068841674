export class SelectedRows {
  constructor(includedRows: string[][], excludedRows: string[]) {
    this.includedRows = includedRows;
    this.excludedRows = excludedRows;
  }
  includedRows: string[][];
  excludedRows: string[];
}

export class SelectedRowsMap {
  constructor(
    includedRows: Map<string, string>,
    excludedRows: Map<string, string>
  ) {
    this.includedRows = includedRows;
    this.excludedRows = excludedRows;
  }
  includedRows: Map<string, string>;
  excludedRows: Map<string, string>;
}

export class SelectedRowsMapWithAccessType {
  constructor(
    includedRows: Map<string, Map<string,string>>,
    excludedRows: Map<string, Map<string,string>>
  ) {
    this.includedRows = includedRows;
    this.excludedRows = excludedRows;
  }
  includedRows: Map<string, Map<string,string>>;
  excludedRows: Map<string, Map<string,string>>;
}
