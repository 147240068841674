import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '@core/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { UtilityService } from '@core/services/utility.service';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { AccountAccessType } from '@shared/models/AccountAccessType';
import { GlobalConstants } from '@shared/constants/global-constants';
import { BaseGridComponentComponent } from '@shared/components/base-grid-component/base-grid-component.component';
import { ModalDialogService } from '@core/services/modal-dialog.service';
import { UserSearch } from '@shared/models/userSearch';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-account-access-management',
  templateUrl: './account-access-management.component.html',
  styleUrls: ['./account-access-management.component.css']
})
export class AccountAccessManagementComponent
  extends BaseGridComponentComponent
  implements OnInit {
  usersEmailAddress: string;
  usersBillingAccountNo: string;
  usersUtilityAccountNo: string;
  currentAccountAccess: any;
  newUserAccesses: AccountAccessType[];
  validAccessTypes: string[];
  defaultColDef: any;
  columnDefs: any;
  tooltipShowDelay;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  paginationPageSize = GlobalConstants.gridDefaultPageSize;
  gridRowHeight = GlobalConstants.gridRowHeight;
  excelStyles: any;
  adminForm: FormGroup;

  constructor(
    private adminService: AdminService,
    private utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalDialogService: ModalDialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createcolumnDefs();
    this.initForm(this.formBuilder);
    this.initGridConfig();
    this.validAccessTypes = this.utilityService.getEnumList(AccessTypes);
  }

  initForm(formBuilder: FormBuilder) {
    this.adminForm = this.formBuilder.group({
      usersEmailAddress: new FormControl(''),
      usersBillingAccountNo: new FormControl(''),
      usersUtilityAccountNo: new FormControl('')
    });
  }

  get f() {
    return this.adminForm.controls;
  }

  searchSpecificUsers(event: any) {
    this.resetSearchFields();
    if (
      this.usersEmailAddress === '' &&
      this.usersBillingAccountNo === '' &&
      this.usersUtilityAccountNo === ''
    ) {
      return;
    }

    const userSearch = new UserSearch(
      this.usersEmailAddress,
      this.usersBillingAccountNo,
      this.usersUtilityAccountNo
    );
    this.getUserAccess(userSearch);
  }

  resetSearchFields() {
    this.usersEmailAddress =
      this.usersEmailAddress === undefined ? '' : this.usersEmailAddress.trim();
    this.usersBillingAccountNo =
      this.usersBillingAccountNo === undefined
        ? ''
        : this.usersBillingAccountNo.trim();
    this.usersUtilityAccountNo =
      this.usersUtilityAccountNo === undefined
        ? ''
        : this.usersUtilityAccountNo.trim();

    this.f.usersEmailAddress.setValue(this.usersEmailAddress);
    this.f.usersBillingAccountNo.setValue(this.usersBillingAccountNo);
    this.f.usersUtilityAccountNo.setValue(this.usersUtilityAccountNo);
  }

  disableBillingAccountNo() {
    if (
      this.usersUtilityAccountNo !== undefined &&
      this.usersUtilityAccountNo !== ''
    ) {
      return true;
    }
  }

  disableUtilityAccountNo() {
    if (
      this.usersBillingAccountNo !== undefined &&
      this.usersBillingAccountNo !== ''
    ) {
      return true;
    }
  }

  getUserAccess(userSearch: UserSearch) {
    this.spinner.show();
    this.adminService.getUserAccess(userSearch).subscribe(
      result => {
        this.currentAccountAccess = result;
        this.spinner.hide();
      },
      error => {
        console.log('Failed to get user data.');
        this.spinner.hide();
      }
    );
  }

  getRowStyle(params) {
    if (params.data.isDeleted) {
      return {
        'background-color': '#F08080'
      };
    }
  }

  initGridConfig() {
    this.defaultColDef = {
      sortable: true,
      flex: 2,
      filter: true,
      resizable: true,
      menuTabs: ['filterMenuTab']
    };
    this.tooltipShowDelay = 0;
    this.excelStyles = [
      {
        id: 'textFormat',
        dataType: 'string'
      }
    ];
  }

  private createcolumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Engie ACCOUNT',
        field: 'engieAccountId',
        hide: true,
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'EMAIL',
        field: 'emailAddress',
        editable: false,
        flex: 1,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'NAME',
        field: 'usersName',
        editable: false,
        flex: 1,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'ACCOUNT NUMBER',
        field: 'accountNumber',
        minWidth: 230,
        flex: 1,
        editable: false,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat',
        cellRenderer: this.copyIcon.bind(this)
      },
      {
        headerName: 'BILLING TYPE',
        field: 'billingType',
        minWidth: 150,
        maxWidth: 150,
        editable: false,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerName: 'ACCOUNT NAME',
        field: 'accountName',
        minWidth: 200,
        flex: 3,
        editable: false,
        cellStyle: { justifyContent: 'left' },
        cellClass: 'textFormat'
      },
      {
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span><nj-tooltip>' +
            '      <span class="btn-simple">ACCESS TYPE' +
            '        <i data-toggle="tooltip" data-html="true" style="font-size: 20px; color:#009de9; vertical-align:-3px;"' +
            '           title="<p style=text-align:left>Type or double-click on the cell to show the dropdown. Below are the valid options:' +
            '            <ul style=text-align:left>' +
            '              <li>Owner</li>' +
            '              <li>Sharee</li>' +
            '              <li>Power Sharee</li>' +
            '              <li>Read Only</li>' +
            '            </ul>"' +
            '           class="material-icons md--blue-engie</p>">help' +
            '         </i>' +
            '       </span>' +
            '    </nj-tooltip></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '  </div>' +
            '</div>'
        },
        field: 'newAccessType',
        minWidth: 150,
        maxWidth: 150,
        editable: true,
        cellClass: 'textFormat',
        cellStyle: params => {
          if (
            params.data.currentAccessType !== params.data.newAccessType &&
            !params.data.isDeleted
          ) {
            if (this.isAccessTypeValid(params.data.newAccessType)) {
              return { backgroundColor: '#90EE90', justifyContent: 'left' };
            } else {
              return { backgroundColor: '#F08080', justifyContent: 'left' };
            }
          }
          return { backgroundColor: null, justifyContent: 'left' };
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: [
            AccessTypes.Owner.toString(),
            AccessTypes.Sharee.toString(),
            AccessTypes.PowerSharee.toString(),
            AccessTypes.ReadOnly.toString()
          ]
        }
      },
      {
        headerName: '',
        field: 'newAccessType',
        minWidth: 35,
        maxWidth: 35,
        resizable: false,
        sortable: false,
        filter: false,
        editable: false,
        cellStyle: { justifyContent: 'center' },
        cellClass: 'textFormat',
        cellClassRules: 'readonly-cell',
        cellRenderer: this.copyIcon.bind(this),
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        getQuickFilterText: () => ''
      },
      {
        headerName: '',
        field: 'currentAccessType',
        minWidth: 35,
        maxWidth: 35,
        resizable: false,
        sortable: false,
        filter: false,
        editable: false,
        cellStyle: { justifyContent: 'center' },
        cellClass: 'textFormat',
        cellClassRules: 'readonly-cell',
        cellRenderer: this.undoIcon.bind(this),
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        getQuickFilterText: () => ''
      },
      {
        headerName: '',
        field: 'isDeleted',
        minWidth: 35,
        maxWidth: 35,
        resizable: false,
        sortable: false,
        filter: false,
        editable: false,
        cellStyle: { justifyContent: 'center' },
        cellClass: 'textFormat',
        cellClassRules: 'readonly-cell',
        cellRenderer: this.deleteRow.bind(this),
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        getQuickFilterText: () => ''
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout('normal');
    this.gridApi.setHeaderHeight(GlobalConstants.gridHeaderHeight);
    document.getElementById('myGrid').style.height =
      GlobalConstants.gridMinimumHeight;
  }

  onCellValueChanged(params) {
    if (params.column.colId === 'newAccessType') {
      params.data.newAccessType = this.utilityService.titleCase(
        params.data.newAccessType
      );
      this.gridApi.refreshCells(params);
    }
  }

  copyIcon(params) {
    const divTag = document.createElement('div');
    if (params.column.colId === 'accountNumber') {
      this.utilityService.fieldWithCopyButton(
        divTag,
        params.value,
        params.value
      );
    } else {
      this.utilityService.fieldWithCopyButton(
        divTag,
        '',
        params.value,
        'Copy access type to clipboard'
      );
    }
    return divTag;
  }

  undoIcon(params) {
    const htmlString = `<nj-tooltip><span class='btn-simple'><i data-toggle='tooltip' title='Undo changes'
      data-placement='right' style='font-size: 20px; color:#009de9; vertical-align:-3px;
      padding-left:5px;' class='material-icons md--blue-engie'>undo</i></span></nj-tooltip>`;
    const divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();
    const eButtonBill = divTag.querySelector('.btn-simple');
    eButtonBill.addEventListener('click', () => {
      const accountToUndo = this.currentAccountAccess.find(
        x =>
          x.engieAccountId === params.data.engieAccountId &&
          x.emailAddress === params.data.emailAddress
      );
      accountToUndo.newAccessType = accountToUndo.currentAccessType;
      accountToUndo.isDeleted = false;
      this.gridApi.redrawRows(params);
    });
    return divTag;
  }

  deleteRow(params) {
    const htmlString = `<nj-tooltip><span class='btn-simple'><i data-toggle='tooltip' title='Delete Row'
      data-placement='right' style='font-size: 20px; color:#009de9; vertical-align:-3px;
      padding-left:5px;' class='material-icons md--blue-delete'>delete</i></span></nj-tooltip>`;
    const divTag = document.createElement('div');
    divTag.innerHTML = htmlString.trim();
    const eButtonBill = divTag.querySelector('.btn-simple');
    eButtonBill.addEventListener('click', () => {
      const accountToDelete = this.currentAccountAccess.find(
        x =>
          x.engieAccountId === params.data.engieAccountId &&
          x.emailAddress === params.data.emailAddress
      );
      accountToDelete.isDeleted = true;
      this.gridApi.redrawRows();
    });
    return divTag;
  }

  onPageSizeChanged(selectedPageSize: number) {
    const value = selectedPageSize;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  onPaginationChanged(params) {
    this.setGridHeight_OnPaginationChanged(
      'myGrid',
      this.gridApi,
      GlobalConstants.gridMinimumHeight,
      GlobalConstants.gridDefaultPageSize,
      params
    );
  }

  exportToExcel() {
    const params = {
      columnWidth: 100,
      sheetName: 'Billing Account',
      processCellCallback: this.processExportedCell,
      columnKeys: [
        'emailAddress',
        'usersName',
        'accountNumber',
        'billingType',
        'accountName',
        'newAccessType'
      ]
    };
    this.gridApi.exportDataAsExcel(params);
  }

  processExportedCell(params) {
    if (params.value != null) {
      return params.value.toString();
    } else {
      return null;
    }
  }

  onQuickSearchChanged(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  onSubmit() {
    this.spinner.show();
    const invalidAccountNumbers: string[] = [];
    const validUpdatedUserAccess: AccountAccessType[] = [];
    this.currentAccountAccess.forEach(x => {
      if (this.isAccessTypeValid(x.newAccessType) || x.isDeleted) {
        if (x.currentAccessType !== x.newAccessType || x.isDeleted) {
          validUpdatedUserAccess.push(
            new AccountAccessType(
              x.emailAddress,
              x.engieAccountId,
              x.newAccessType,
              x.isDeleted
            )
          );
        }
      } else {
        invalidAccountNumbers.push(x.accountNumber);
      }
    });

    let message = '';
    if (invalidAccountNumbers.length) {
      this.spinner.hide();
      message =
        'The following billing accounts have invalid access type: ' +
        invalidAccountNumbers.join(', ');
      this.modalDialogService.openErrorDialog(message);
      return;
    }

    if (!validUpdatedUserAccess.length) {
      this.spinner.hide();
      message = 'There are no changes to save.';
      this.modalDialogService.openErrorDialog(message);
      return;
    } else {
      message = 'All account access type changes will be saved.';
    }

    this.newUserAccesses = validUpdatedUserAccess;
    message = message + ' Are you sure you want to continue?';
    this.spinner.hide();
    this.modalDialogService.openWarningDialog(
      'Continue',
      () => {
        this.continue();
      },
      '',
      null,
      message
    );
  }

  isAccessTypeValid(accessType: string): boolean {
    const index = this.validAccessTypes.findIndex(
      d => d['value'] === accessType
    );
    return index !== -1 ? true : false;
  }

  updateUserAccess(userAccesses: AccountAccessType[]) {
    return this.adminService.updateUserAccess(userAccesses);
  }

  continue() {
    this.spinner.show();
    this.updateUserAccess(this.newUserAccesses).subscribe(
      (data: any) => {
        this.resetCurrentValues(data.items);
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(data.message);
      },
      error => {
        console.log('Failed to update the user account access types.');
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(error.error);
      }
    );
  }

  resetCurrentValues(accountsConvertedToSharee: any[]) {
    this.currentAccountAccess = this.currentAccountAccess.filter(
      x => x.isDeleted === false
    );
    this.newUserAccesses.forEach(usrAccess => {
      if (!usrAccess.isDeleted) {
        const acctAccess = this.currentAccountAccess.find(
          accs =>
            accs.engieAccountId === usrAccess.engieAccountId &&
            accs.emailAddress === usrAccess.emailAddress
        );
        const isConvertedToSharee = accountsConvertedToSharee.find(
          x =>
            x.engieAccountId == acctAccess.engieAccountId &&
            x.emailAddress == acctAccess.emailAddress
        )
          ? true
          : false;
        if (isConvertedToSharee) {
          acctAccess.newAccessType = AccessTypes.Sharee.toString();
        }
        acctAccess.currentAccessType = acctAccess.newAccessType;
      }
    });
    this.gridApi.redrawRows();
  }
}
