import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AccountSetting } from '@shared/models/accountSetting';
import { Property } from '@shared/models/accountSettingProperty';

@Injectable({
  providedIn: 'root'
})
export class SettingControlService {
  public group: FormGroup;

  constructor() {}

  toFormGroup(account: string, setting: AccountSetting): FormGroup {
    this.group = new FormGroup({
      [setting.key]: this.getSettingControl(setting)
    });

    setting.extendedProperties.forEach(ep => {
      this.group.addControl(ep.key, this.getPropertyControl(ep));
    });

    this.group.addControl('notes', new FormControl(setting.notes || ''));

    return this.group;
  }

  getSettingControl(setting: AccountSetting): FormControl {
    let control: FormControl;
    if (setting.controlType === 'checkbox') {
      control =
        setting.value === 'true'
          ? new FormControl(true)
          : new FormControl(false);
    } else {
      control = setting.required
        ? new FormControl(setting.value || '', Validators.required)
        : new FormControl(setting.value || '');
    }
    return control;
  }

  getPropertyControl(property: Property): FormControl {
    switch (property.controlType) {
      case 'checkbox':
        return property.value === 'true'
          ? new FormControl(true)
          : new FormControl(false);
        break;
      case 'datetime':
        return property.required
          ? new FormControl(new Date(property.value), Validators.required)
          : new FormControl(new Date(property.value));
        break;
      default:
        return property.required
          ? new FormControl(property.value || '', Validators.required)
          : new FormControl(property.value || '');
        break;
    }
  }
}
