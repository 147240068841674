import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { Ticket } from '@shared/models/ticket';
import { AdminService } from '@core/services/admin.service';

@Component({
  selector: 'app-freshdesk-viewer',
  templateUrl: './freshdesk-viewer.component.html',
  styleUrls: ['./freshdesk-viewer.component.css']
})
export class FreshdeskViewerComponent implements OnInit {
  ticket: Ticket;
  result: number;
  form: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService
  ) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.form;
  }

  ngOnInit(): void {
    this.initForm(this.formBuilder);
  }

  initForm(formBuilder: FormBuilder) {
    this.form = formBuilder.group({
      EngieTicketId: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern('^[0-9]*$')
      ])
    });
  }

  onLookup(e) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    // Take a deep copy of the form-model
    const result: Ticket = Object.assign({}, this.form.value);

    this.adminService
      .fetchFreshdeskTicket(result.EngieTicketId)
      .then((res: Ticket) => {
        this.ticket = res;
      });
  }
}
