export class GlobalConstants {
  public static emailRegex: string =
    '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,63}$';
  public static gridHeaderHeight: number = 43;
  public static gridFooterHeight: number = 35;
  public static gridRowHeight: number = 53;
  public static gridDefaultPageSize: number = 10;
  public static gridMinimumHeight: string =
    GlobalConstants.gridRowHeight * GlobalConstants.gridDefaultPageSize +
    GlobalConstants.gridHeaderHeight +
    GlobalConstants.gridFooterHeight +
    'px';
}
