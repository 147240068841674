import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-cell-renderer',
  template: `
    <button
      (click)="btnClickedHandler($event)"
      class="nj-btn nj-btn--primary nj-btn--sm"
      aria-pressed="true"
    >
      Emulate
    </button>
  `
})
export class ButtonCellRendererComponent implements ICellRendererAngularComp {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler() {
    this.params.clicked(this.params.value);
  }

  refresh(): boolean {
    return false;
  }
}
