import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AccountService } from '@core/services/account.service';
import { PayDetail } from '@shared/models/payDetail';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '@core/services/global.service';
import { environment } from '@environments/environment';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.sass']
})
export class ServicesComponent implements OnInit {
  accountId;
  accountType;
  singleAccount$: PayDetail;
  body;
  display;
  emulate = false;
  MSG_NOTFOUND = `This account is not set up in our payment system. Please contact our Customer Care team at ${environment.customerCarePhoneAlt} (${environment.customerCarePhone}) or ${environment.customerCareEmail}.`;

  constructor(
    private accountService: AccountService,
    private globalService: GlobalService,
    private location: Location,
    private spinner: NgxSpinnerService,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit() {
    const detail: string = this.globalService.get(this.globalService.DETAIL);
    this.accountId = detail.split('/')[0];
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.getAccount(this.accountId);
  }

  getAccount(id: string) {
    this.spinner.show();
    this.accountService.getPayMethodList(id).subscribe(
      data => {
        this.singleAccount$ = data;
        this.spinner.hide();
      },
      error => {
        this.body = this.MSG_NOTFOUND;
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(
          this.body,
          '',
          'Dismiss',
          () => {
            this.goBack();
          }
        );
      }
    );
  }

  goBack() {
    this.location.back();
  }
}
