import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { FormGroup } from '@angular/forms';
import { AccountSettingsService } from '@core/services/account-settings.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingControlService } from '@core/services/setting-control.service';
import { AccountSettingBase } from '@shared/models/accountSettingBase';
import { AccountSetting } from '@shared/models/accountSetting';
import * as moment from 'moment/moment';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css']
})
export class AccountSettingComponent implements OnInit {
  settings$: AccountSettingBase[];
  targetSetting: AccountSetting;
  form: FormGroup;
  payLoad = '';
  refId;
  account;
  isValid = false;
  submitted = false;
  billingAccount;
  companyName;
  body;
  expanded = false;

  isAuthenticated: boolean;
  userName: string;
  private error = '';
  public loadingMessage = 'Loading...';
  filteredValue;
  edit = false;
  today = moment().format();

  constructor(
    private authService: MsalService,
    private settingsService: AccountSettingsService,
    private settingControlService: SettingControlService,
    private spinner: NgxSpinnerService,
    private modalDialogService: ModalDialogService
  ) {}

  identify = (idx: number, item: any) => item.label;

  ngOnInit() {
    this.isAuthenticated = this.authService.instance.getActiveAccount() != null;
    this.getSettingsData();
  }

  onToggle(account: string, setting: AccountSetting, e) {
    switch (setting.key) {
      case 'autoPayRestricted':
      case 'paymentRestricted':
        if (e.target.checked) {
          this.enableFormControl('setExpiryDate');
        } else {
          this.disableFormControl('setExpiryDate', true);
          this.disableFormControl('expiryDate', true);
        }
        break;
      default:
        break;
    }
  }

  onCheck(account: string, key: string, e) {
    switch (key) {
      case 'setExpiryDate':
        // enable expiryDate if true
        if (e.target.checked) {
          this.enableFormControl('expiryDate');
        } else {
          // clear expiryDate and then disable
          this.disableFormControl('expiryDate', true);
        }
        break;
      default:
        break;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSave(e: Event) {
    this.submitted = true;

    if (this.form.invalid) {
      this.submitted = false;
      return;
    }

    // Take a deep copy of the form-model
    const result: any = Object.assign({}, this.form.value);

    this.spinner.show();
    this.settingsService
      .saveSetting(this.account, this.targetSetting.key, result)
      .subscribe(
        response => {
          this.spinner.hide();
          const message = 'The settings have been successfully saved.';
          this.targetSetting.updatedBy = response.settings.find(
            s => s.key === this.targetSetting.key
          ).updatedBy;
          this.targetSetting.updatedDateTime = response.settings.find(
            s => s.key === this.targetSetting.key
          ).updatedDateTime;
          this.modalDialogService.openSuccessDialog(
            message,
            '',
            'Dismiss',
            () => {
              this.getSettingsData();
            }
          );
        },
        error => {
          console.log('Failed to Save Setting.');
          this.spinner.hide();
          this.modalDialogService.openErrorDialog(error.error);
        }
      );
  }

  onDelete(account: string, setting: string) {
    this.spinner.show();
    this.settingsService.deleteSetting(account, setting).subscribe(
      response => {
        this.spinner.hide();
        const message = 'The setting was successfully deleted.';
        this.modalDialogService.openSuccessDialog(
          message,
          '',
          'Dismiss',
          () => {
            this.getSettingsData();
          }
        );
      },
      error => {
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(error.error);
      }
    );
  }

  editSetting(item: AccountSetting, account: AccountSettingBase) {
    this.edit = true;
    this.targetSetting = item;
    this.account = account.billingAccountNumber;
    this.companyName = account.name;
    this.form = this.settingControlService.toFormGroup(
      account.billingAccountNumber,
      item
    );
    this.setupForm(account.billingAccountNumber, item);
  }

  setupForm(account: string, setting: AccountSetting) {
    switch (setting.key) {
      case 'autoPayRestricted':
      case 'paymentRestricted':
        if (setting.value === 'false') {
          // disable other controls when toggle is off
          this.disableFormControl('setExpiryDate', true);
          this.disableFormControl('expiryDate', true);
        }
        if (setting.value === 'true') {
          // enable other controls when toggle is on
          this.enableFormControl('setExpiryDate');
          setting.extendedProperties.forEach(ep => {
            if (ep.key === 'setExpiryDate' && ep.value === 'true') {
              this.enableFormControl('expiryDate');
            }
            if (ep.key === 'setExpiryDate' && ep.value === 'false') {
              this.disableFormControl('expiryDate', true);
            }
          });
        }
        break;
      case 'widget':
        break;
      default:
    }
  }

  disableFormControl(key: string, reset?: boolean) {
    if (reset) {
      this.form.controls[key].reset();
    }
    this.form.controls[key].disable();
  }

  enableFormControl(key: string) {
    this.form.controls[key].enable();
  }

  getSettingsData() {
    this.settingsService.getSettings().subscribe(
      data => {
        this.settings$ = data;
      },
      error => {
        const message = `Failed to load the settings - ${error.error}`;
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }
}
