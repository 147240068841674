import { IdentityService } from '@core/services/identity.service';
import { AccessTypes } from '@shared/enums/AccessTypes';
import { AccessPermission } from '@shared/enums/AccessPermission';
import { UtilityService } from '@core/services/utility.service';

export abstract class AccountShared {
  baseAccessType: AccessTypes;
  showCopiedBillingTooltip: boolean;
  showCopiedUtilityTooltip: boolean;

  constructor(
    private identityService: IdentityService,
    private utilityService: UtilityService
  ) {}

  canPay() {
    return this.identityService.hasAccessPermission(
      AccessPermission.Pay_Bill,
      this.baseAccessType
    );
  }
  canShare(): boolean {
    return this.identityService.hasAccessPermission(
      AccessPermission.Share_Account,
      this.baseAccessType
    );
  }
  canTransfer(): boolean {
    return this.identityService.hasAccessPermission(
      AccessPermission.Transfer_Account,
      this.baseAccessType
    );
  }
  canRelease(): boolean {
    return this.identityService.hasAccessPermission(
      AccessPermission.Release_Accounts,
      this.baseAccessType
    );
  }
  canPaper(): boolean {
    return this.identityService.hasAccessPermission(
      AccessPermission.Enroll_Paperless,
      this.baseAccessType
    );
  }
  canAutoPay(): boolean {
    return this.identityService.hasAccessPermission(
      AccessPermission.Enroll_Auto_Pay,
      this.baseAccessType
    );
  }
  canAddPayMethod(): boolean {
    return this.identityService.hasAccessPermission(
      AccessPermission.Manage_PayMethods,
      this.baseAccessType
    );
  }
  canUpdateBillingAddress(): boolean {
    return this.identityService.hasAccessPermission(
      AccessPermission.Update_BillingAddress,
      this.baseAccessType
    );
  }
  canReport(): boolean {
    return true;
  }
  showContactAdditionalOptions() {
    return this.canUpdateBillingAddress();
  }

  toggleTooltip(
    copyToClipboard: boolean,
    fieldToCopy: string,
    valueToCopy: string
  ) {
    if (copyToClipboard) {
      this.utilityService.copyToClipboard(valueToCopy);
    }
    switch (fieldToCopy) {
      case 'billingAccount':
        this.showCopiedBillingTooltip = copyToClipboard;
        break;
      case 'utilityAccount':
        this.showCopiedUtilityTooltip = copyToClipboard;
        break;
    }
  }
}
