import { Component, OnInit, NgZone, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.sass']
})
export class ConfirmationComponent implements OnInit {
  refId$: string;
  amt$: string;

  constructor(
    private route: ActivatedRoute,
    private zone: NgZone,
    private location: Location
  ) {}

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      this.refId$ = this.route.snapshot.paramMap.get('ref');
      this.amt$ = this.route.snapshot.paramMap.get('amt');
    });
  }
}
