import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { UserSearch } from '@shared/models/userSearch';
import { AccountService } from '@core/services/account.service';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resend-activation',
  templateUrl: './resend-activation.component.html',
  styleUrls: ['./resend-activation.component.css']
})
export class ResendActivationComponent implements OnInit {
  activateForm: FormGroup;
  submitted = false;
  valid = false;
  confirm = false;
  error = false;
  message = '';
  errorMessage = '';
  display;

  constructor(
    private accountService: AccountService,
    private location: Location,
    private routerService: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm(this.formBuilder);
  }

  initForm(formBuilder: FormBuilder) {
    this.activateForm = this.formBuilder.group({
      EmailAddress: new FormControl('', [Validators.required, Validators.email])
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.activateForm.controls;
  }

  async onSubmit(e: Event) {
    this.submitted = true;
    this.error = false;
    this.valid = false;
    this.errorMessage = '';
    this.message = '';

    // stop here if form is invalid
    if (this.activateForm.invalid) {
      return;
    }

    this.confirm = true; // set true will disable Confirm button while pulling data from API

    // Take a deep copy of the form-model
    const result: UserSearch = Object.assign({}, this.activateForm.value);

    this.resendActivateEmail(result.EmailAddress).subscribe(
      data => {
        this.valid = true;
        this.submitted = false;
        this.confirm = false;
        this.message = data;
      },
      error => {
        this.error = true;
        if (error.status === 400) {
          this.errorMessage = error.error;
        }
        this.confirm = false;
      }
    );
  }

  resendActivateEmail(account: string) {
    return this.accountService.resendActivationEmail(account);
  }

  goBack(): void {
    this.routerService.navigate(['/']);
  }
}
