import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { version } from '@root/package.json';
import { Router } from '@angular/router';
import { GuideService } from '@core/services/guide.service';
import { GlobalService } from '@core/services/global.service';
import { IdentityService } from '@core/services/identity.service';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus
} from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { UtilityService } from '@core/services/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  showGuideIcon = false;
  userName: string;
  version: string;
  @Input() accountId: string;
  private readonly _destroying$ = new Subject<void>();
  profileSubscription: Subscription = this.utilityService.headerProfileNotifier.subscribe(notified => {
    this.ngOnInit();
  });

  constructor(
    private authService: MsalService,
    private router: Router,
    private identityService: IdentityService,
    private globalService: GlobalService,
    private guideService: GuideService,
    private broadcastService: MsalBroadcastService,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.version = version;
    const profile: any = this.getActiveAccount();

    if (profile !== null && profile !== undefined) {
      this.checkAndSetUsername(profile);
    } else {
      this.broadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          ),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.checkAndSetUsername(payload.account?.idTokenClaims);
        });
    }
  }

  checkAndSetUsername(profile: any) {
    this.isAuthenticated = true;
    if (this.isEmulated()) {
      this.userName = this.globalService.get('EmulatingUser');
    } else {
      this.userName = profile?.email;
    }
  }

  getActiveAccount() {
    return this.authService.instance.getActiveAccount()?.idTokenClaims;
  }

  isAdminOrCareAgent() {
    return this.identityService.isAdminOrCareAgent();
  }

  isEmulated() {
    return this.identityService.isEmulated();
  }

  canEditProfile() {
    if (this.isEmulated()) {
      return this.identityService.isEmulatorAdminOrCareAgent();
    } else {
      return true;
    }
  }

  startTour() {
    this.guideService.startTour(this.router.url);
  }

  logout() {
    this.authService.logout();
  }

  toggleLoginDropdown() {
    document
      .getElementById('login-dropdown').classList.toggle("show");
  }

  hideLoginDropdown() {
    document
      .getElementById('login-dropdown')
      .setAttribute('class', 'nj-dropdown__list nj-collapse hide');
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
