import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AccountService } from '@core/services/account.service';
import { SharedAccount } from '@shared/models/shareAccount';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { GlobalService } from '@core/services/global.service';
import { UserLandingPage } from '@shared/models/userLandingPage';
import { UserLandingPageTypes } from '@shared/enums/UserLandingPageTypes';
import { ModalDialogService } from '@core/services/modal-dialog.service';

@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.sass']
})
export class ReleaseComponent implements OnInit {
  releaseForm: FormGroup;
  confirm = false;
  data$: SharedAccount;
  emulate = false;
  selectedEngieAccountIds: string[];
  selectedAccountNumbers: string[];
  numberOfAccounts: number;

  constructor(
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private location: Location,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit() {
    if (this.globalService.has('EmulatingUser')) {
      this.emulate = true;
    }
    this.initForm(this.formBuilder);
    this.selectedAccountNumbers = this.globalService.get(
      this.globalService.BULK
    )[0];
    this.selectedEngieAccountIds = this.globalService.get(
      this.globalService.BULK
    )[1];
    this.numberOfAccounts = this.selectedEngieAccountIds.length;
  }

  initForm(formBuilder: FormBuilder) {
    this.releaseForm = this.formBuilder.group({
      validate: new FormControl(this.confirm)
    });
  }

  get f() {
    return this.releaseForm.controls;
  }

  onSubmit() {
    this.confirm = true;
    if (this.releaseForm.invalid || !this.selectedEngieAccountIds.length) {
      return;
    }

    if (this.emulate) {
      const message =
        'Are you sure you want to Release these accounts?' +
        ' These accounts may be active or have a balance, are you sure you want to proceed?';
      this.modalDialogService.openWarningDialog(
        'Continue',
        () => {
          this.continueReleasingAccounts();
        },
        '',
        null,
        message
      );
    } else {
      this.continueReleasingAccounts();
    }
  }

  continueReleasingAccounts() {
    this.spinner.show();

    const g: SharedAccount = new SharedAccount(null);
    g.EngieAccountIds = this.selectedEngieAccountIds;
    g.IsEmulatingUser = this.emulate;

    this.releaseAccounts(g).subscribe(
      (data: any) => {
        if (!data) {
          let noAccountLandingPage = new UserLandingPage();
          noAccountLandingPage.userLandingPageType =
            UserLandingPageTypes.NoAccounts;
          this.globalService.set('accountType', noAccountLandingPage);
        }
        const message = 'Your accounts have been successfully released.';
        this.spinner.hide();
        this.modalDialogService.openSuccessDialog(
          message,
          '',
          'Go To Accounts',
          () => {
            this.goBack();
          }
        );
      },
      error => {
        console.log('Failed to release accounts.');
        const message = error.error;
        this.spinner.hide();
        this.modalDialogService.openErrorDialog(message);
      }
    );
  }

  releaseAccounts(account: SharedAccount) {
    return this.accountService.releaseAccount(account);
  }

  onReset() {
    this.confirm = false;
    this.location.back();
  }

  goBack() {
    this.location.back();
  }
}
