export class Payment {
  constructor(
    amount: string,
    serviceFee: string,
    totalAmount: string,
    payDate: Date,
    token: string
  ) {
    this.Amount = amount;
    this.ServiceFee = serviceFee;
    this.TotalAmount = totalAmount;
    this.Date = payDate;
    this.PaymethodTokenId = token;
  }
  Amount: string;
  ServiceFee: string;
  TotalAmount: string;
  Date: Date;
  PaymethodTokenId: string;
  AuthorizationCode: string;
}
